import axios from "axios";

import {
  DEALS_LOADING,
  GET_DEALS,
} from "./types";

// Get deals
export const getDeals = () => dispatch => {
  dispatch(setDealsLoading());
  axios
    .get(`/api/coral/getdeals`)
    .then(res => {
      dispatch({
        type: GET_DEALS,
        payload: res.data
      });
    })
    .catch(err => {
      console.log(err);
    });
};

// // Set loading state
export const setDealsLoading = () => {
  return {
    type: DEALS_LOADING
  };
};

// // // Set loading state
// export const clearCoralLoading = () => {
//   return {
//     type: CLEAR_CORAL_LOADING
//   };
// };

import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getDeals } from 'actions/dealsActions'
import { Helmet } from 'react-helmet'
import CoralEbayPreview from 'components/CoralPreview/CoralEbayPreview.js'
import ebaySmallLogo from 'assets/ebayLogos/US/Right-Now-108x45.gif'
import { Row, Container, Card } from 'reactstrap'

class Home extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: false
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateScreen)
    this.updateScreen()

    //get coral deals in redux
    this.props.getDeals()
    // console.log(this.props.history.location.pathname)
  }

  updateScreen = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true
      })
    } else {
      this.setState({
        screenSizeSmall: false
      })
    }
  }

  render () {
    const { deals } = this.props.coralDeals

    return (
      <>
        <Helmet>
          <title style={{ textTransform: 'uppercase' }}>
            CORAL DEALS - Immortal Reef
          </title>

          <meta
            name='description'
            content={
              'Coral deals are a continually updated selection of corals each of which have a buy it now price of less than $20.'
            }
          />
        </Helmet>
        <Container>
          {!this.props.coralDeals.loading ? (
            <>
              <h2 style={{ margin: 10 }}>Coral Deals</h2>
              <p>
                Deals are generated automatically, and feature corals with
                buy-it-now prices of less than $20. Coral sellers will appear on
                this page so long as they have at least 5 corals listed with
                buy-it-now price of less than $20.
              </p>
              {deals.map(seller => (
                <>
                  <Card style={{ margin: 5 }}>
                    <h5>
                      <span style={{ display: 'inline-block' }}>
                        <div style={{ height: 45 }}>
                          <a
                            href='https://www.ebay.com'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <img
                              src={ebaySmallLogo}
                              style={{
                                height: '100%',

                                marginLeft: 10
                              }}
                              alt='ebay logo'
                            />
                          </a>
                        </div>
                      </span>{' '}
                      Seller Name - {seller.name}
                    </h5>

                    <Row style={{ justifyContent: 'center' }}>
                      {seller.coralList.map(coral => (
                        <div style={{ margin: 10, backgroundColor: 'white' }}>
                          <CoralEbayPreview
                            item={coral}
                            screenSizeSmall={this.state.screenSizeSmall}
                            keywordTitle={`${coral.title}`}
                            keywordAlt={`${coral.title} for sale $${coral.price} plus $${coral.shippingCost} shipping`}
                          />{' '}
                        </div>
                      ))}
                    </Row>
                  </Card>
                </>
              ))}
            </>
          ) : (
            <div>Loading</div>
          )}
        </Container>
      </>
    )
  }
}

Home.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  coralDeals: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coralDeals: state.coralDeals,
  errors: state.errors
})

export default connect(mapStateToProps, { getDeals })(Home)

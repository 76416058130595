import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import CoralEbayPreview from 'components/CoralPreview/CoralEbayPreview.js'
import ebaySmallLogo from 'assets/ebayLogos/US/Right-Now-108x45.gif'
import CustomUpload from 'components/CustomUpload/ExifImageUpload.jsx'
import { updateCoral, myCoral } from 'actions/coralActions'
import styles from './CoralNames.module.css'
import {
  Row,
  Container,
  Label,
  Col,
  Input,
  FormGroup,
  Form,
  Button,
  Spinner
} from 'reactstrap'
import colors from 'variables/colorFamilies.jsx'
import ColorCheckBox from 'components/ColorCheckBox/ColorCheckBox.js'
import { isNull } from 'lodash'
import classes from 'variables/coral'

class EditCoral extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      coral: null,
      coralName: null,
      price: null,
      tradeValue: null,
      description: null,
      authorization: false,
      forSaleCheck: false,
      forTradeCheck: false,
      postalCode: null,
      file: null,
      file2: null,
      imgPreviewUrl: null,
      zip: null,
      species: null,
      otherSpecies: null,
      errors: { zip: null, price: null, tradeValue: null },
      placeholderImage: 'image_placeholder.jpg',
      placeholderFragImage: 'image_placeholder.jpg',
      primaryHues: [],
      primaryHues2: [],
      primaryHues3: []
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateScreen)
    this.updateScreen()

    axios
      .get(`/api/coral/getsinglecoral/${this.props.match.params.id}`)
      .then(res => {
        console.log(res.data)
        if (this.props.auth.user.id === res.data.user._id) {
          this.setState({ coral: res.data, authorization: true })
          if (res.data.about) {
            this.setState({ description: res.data.about })
          }
          if (res.data.species) {
            this.setState({ species: res.data.species })
          }
          if (res.data.otherSpecies) {
            this.setState({ otherSpecies: res.data.otherSpecies })
          }
          if (res.data.forSale) {
            this.setState({ forSaleCheck: true })
          }
          if (res.data.forTrade) {
            this.setState({ forTradeCheck: true })
          }
          if (res.data.tradeValue) {
            this.setState({ tradeValue: res.data.tradeValue })
          }
          if (res.data.price) {
            this.setState({ price: res.data.price })
          }
          if (res.data.postalCode) {
            this.setState({ postalCode: res.data.postalCode })
          }

          if (res.data.image) {
            this.setState({ placeholderImage: res.data.image.address })
          }

          if (res.data.genericFragImage) {
            this.setState({
              placeholderFragImage: res.data.genericFragImage.address,
              genericFragImage: res.data.genericFragImage
            })
          }

          if (res.data.name) {
            this.setState({ coralName: res.data.name.name })
          } else if (res.data.unverifiedName) {
            this.setState({ coralName: res.data.unverifiedName })
          }

          if (res.data.extracted150Hex) {
            this.setState({ primaryHues: [res.data.extracted150Hex] })
          }
          if (res.data.extracted250Hex) {
            this.setState({ primaryHues2: [res.data.extracted250Hex] })
          }
          if (res.data.extracted350Hex) {
            this.setState({ primaryHues3: [res.data.extracted350Hex] })
          }

          this.setState({ loading: false })
        }
      })
  }

  updateScreen = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true
      })
    } else {
      this.setState({
        screenSizeSmall: false
      })
    }
  }

  // onChange = e => {
  //   console.log(e.target.value)
  //   this.setState({ [e.target.name]: !this.state.forSaleCheck })
  //   console.log(this.state.forSaleCheck)
  // }

  saveCoral = e => {
    e.preventDefault()
    let valErrors = {
      zip: null,
      price: null,
      tradeValue: null
    }

    let checkReturn = false

    // if (
    //   (this.state.forSaleCheck || this.state.forTradeCheck) &&
    //   !this.props.auth.location.postal_code
    // ) {
    //   valErrors.zip = 'Zip code required for sale or trade.'
    //   checkReturn = true
    // }

    // if (this.state.forSaleCheck && !this.state.price) {
    //   valErrors.price = 'Price Required'
    //   checkReturn = true
    // }

    if (this.state.species === 'Other' && !this.state.otherSpecies) {
      valErrors.otherSpecies = 'Other Species Required'
      checkReturn = true
    }

    // if (this.state.forTradeCheck && !this.state.tradeValue) {
    //   valErrors.tradeValue = 'Trade Value Required.'
    //   checkReturn = true
    // }

    if (checkReturn) {
      // console.log("returning");
      return this.setState({
        errors: {
          zip: valErrors.zip,
          price: valErrors.price,
          tradeValue: valErrors.tradeValue,
          otherSpecies: valErrors.otherSpecies
        }
      })
    }

    const coralData = {
      // type: this.state.type,
      // otherType: this.state.otherType,
      // genus: this.state.genus,
      // otherGenus: this.state.otherGenus,
      // species: this.state.species,
      // otherSpecies: this.state.otherSpecies,
      // commonClass: this.state.commonClass,
      // commonName: this.state.commonName,
      coralId: this.state.coral._id,
      about: this.state.description,
      postalCode: this.state.postalCode,
      forSale: this.state.forSaleCheck,
      forTrade: this.state.forTradeCheck,
      price: this.state.price,
      tradeValue: this.state.tradeValue,
      extracted150Hex: this.state.primaryHues[0],
      extracted250Hex: this.state.primaryHues2[0],
      extracted350Hex: this.state.primaryHues3[0],
      coralName: this.state.coralName,
      species: this.state.species,
      otherSpecies: this.state.otherSpecies,
      genericFragImage: this.state.genericFragImage
      // waterParams: this.state.waterParams,
      // flow: this.state.flow,
      // lighting: this.state.lighting,
      // feeding: this.state.feeding,
      // primaryHue: this.state.primaryHue,
      // primaryHue2: this.state.primaryHue2,
      // primaryHue3: this.state.primaryHue3,
      // extracted1: this.state.extracted1,
      // extracted2: this.state.extracted2,
      // extracted3: this.state.extracted3,
      // commonnameinput: this.state.commonnameinput,
      // commonclassinput: this.state.commonclassinput,
      // color: this.state.color,
      // expLevel: this.state.expLevel,
      // rarity: this.state.rarity,
      // nameAuthor: this.state.nameAuthor,
      // origin: this.state.origin
      // original: this.state.original,
      // parent: this.state.parent,
      // fragSize: this.state.fragSize,
      // location: this.state.location,
    }
    this.props.updateCoral(coralData, this.state.file, this.state.file2)
    // this.setState(this.baseState)
    // this.props.toggle()
    // this.props.history.push(`/public/allcoral`);
    // this.props.myCoral()
    // this.props.history.goBack()
    this.setState({ loading: true })
    setTimeout(() => {
      // this.props.history.push(`/coralshow/${this.state.coral._id}`)
      this.props.history.push(`/myreef`)
    }, 3000)
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  saveFileHandler = (file, imgPreviewUrl) => {
    console.log('save file handler hit')
    this.setState({
      file: file,
      imgPreviewUrl: imgPreviewUrl
    })
  }

  saveFileHandler2 = (file, imgPreviewUrl) => {
    console.log('save file handler2 hit')
    this.setState({
      file2: file,
      imgPreviewUrl2: imgPreviewUrl
    })
  }

  deleteFrag = fragId => {
    console.log(this.state.coral._id, fragId)
    axios.post('/api/coral/deletefrag', {
      coralId: this.state.coral._id,
      fragId: fragId
    })
    window.location.reload()
  }

  updateColor = (color, action, abundance) => {
    if (!action) {
      if (abundance === 'most') {
        this.setState({ primaryHues: [color] })
        // this.setState({ primaryHues: [...this.state.primaryHues, color] });
      }
      if (abundance === '2nd') {
        this.setState({ primaryHues2: [color] })
        // this.setState({ primaryHues2: [...this.state.primaryHues2, color] });
      }
      if (abundance === '3rd') {
        this.setState({ primaryHues3: [color] })
        // this.setState({ primaryHues3: [...this.state.primaryHues3, color] });
      }
    } else {
      //slice from primary array
      if (abundance === 'most') {
        let hueIndex = this.state.primaryHues.indexOf(color)
        this.setState(prevState => ({
          primaryHues: prevState.primaryHues.filter((_, i) => i !== hueIndex)
        }))
      }
      if (abundance === '2nd') {
        let hueIndex1 = this.state.primaryHues2.indexOf(color)
        this.setState(prevState => ({
          primaryHues2: prevState.primaryHues2.filter((_, i) => i !== hueIndex1)
        }))
      }
      if (abundance === '3rd') {
        let hueIndex2 = this.state.primaryHues3.indexOf(color)
        this.setState(prevState => ({
          primaryHues3: prevState.primaryHues3.filter((_, i) => i !== hueIndex2)
        }))
      }
    }
  }

  render () {
    console.log(this.state)
    let speciesList = []
    if (this.state.coral) {
      let flatGenus = classes.map(type => type.genus).flat(1)
      let genusIndex = flatGenus
        .map(genus => genus.name)
        .indexOf(this.state.coral.genus)
      console.log(flatGenus[genusIndex].species)
      speciesList = flatGenus[genusIndex].species
    }
    return (
      <>
        <Container style={{ margin: 10 }}>
          {this.state.authorization && !this.state.loading ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexWrap: 'wrap'
                }}
              >
                <div
                  style={{
                    border: '1px solid lightgrey',
                    padding: 20,
                    borderRadius: 5,
                    margin: 5,
                    background: 'lightblue'
                  }}
                >
                  <h5 style={{ textAlign: 'center' }}>Mother Colony Image</h5>
                  <CustomUpload
                    currentImage={`https://s3.amazonaws.com/reefconnector/${this.state.placeholderImage}`}
                    saveFileHandler={this.saveFileHandler}
                  />
                  {this.state.file ? (
                    <div
                      className='text-danger text-center'
                      style={{ margin: 5 }}
                    >
                      Image Not Yet Saved - Click the SAVE button below to save
                      changes!
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    border: '1px solid lightgrey',
                    padding: 20,
                    borderRadius: 5,
                    margin: 5,
                    background: 'lightcoral'
                  }}
                >
                  <h5 style={{ textAlign: 'center' }}>Generic Frag Image</h5>
                  <CustomUpload
                    currentImage={`https://s3.amazonaws.com/reefconnector/${this.state.placeholderFragImage}`}
                    saveFileHandler={this.saveFileHandler2}
                  />
                  {this.state.file2 ? (
                    <div
                      className='text-light text-center'
                      style={{ margin: 5 }}
                    >
                      Image Not Yet Saved - Click the SAVE button below to save
                      changes!
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                id='Available Frags'
                style={{
                  textAlign: 'center',
                  margin: 10,
                  padding: 10,
                  background: '#F2F3F5'
                }}
              >
                <h6>Available Frags</h6>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap'
                  }}
                >
                  {this.state.coral.frags.length > 0 ? (
                    <>
                      {this.state.coral.frags
                        .filter(frag => frag.status === 'active')
                        .map(frag => (
                          <div
                            style={{
                              borderRadius: 5,
                              height: 150,
                              width: 150,
                              backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${frag.image.address})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              margin: 3,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between'
                            }}
                          >
                            <div
                              style={{
                                // color: 'white',
                                textAlign: 'end',
                                margin: 5,
                                display: 'flex',
                                alignItems: 'start',
                                justifyContent: 'space-between'
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: 'rgba(0, 0, 0, 0.35)',

                                  borderRadius: 2,
                                  padding: 2
                                }}
                              >
                                {/* {!coralInCart &&
                              this.props.auth.user.id != coral.user._id ? ( */}
                                <div className={styles.tooltip}>
                                  <Button
                                    color='danger'
                                    style={{ padding: 3, border: 0 }}
                                    size='sm'
                                    outline
                                    onClick={() => this.deleteFrag(frag._id)}
                                  >
                                    <i
                                      style={{ fontSize: 18 }}
                                      className='fa fa-trash'
                                      //className='fa fa-check'
                                    />
                                  </Button>
                                  <span className={styles.tooltiptext}>
                                    Delete Frag
                                  </span>
                                </div>
                                {/* ) : null} */}

                                {/* {selectedFrag === frag._id ? (
                                <div className={styles.tooltip}>
                                  <Button
                                    color='light'
                                    style={{ padding: 3, border: 0 }}
                                    size='sm'
                                    outline
                                    onClick={this.removeFromCart}
                                  >
                                    <i
                                      style={{ fontSize: 18 }}
                                     
                                      className='fa fa-check'
                                    />
                                  </Button>
                                  <span className={styles.tooltiptext}>
                                    Remove From Cart
                                  </span>
                                </div>
                              ) : null} */}
                              </span>
                              <span
                                className='text-light'
                                style={{
                                  backgroundColor: 'rgba(0, 0, 0, 0.35)',
                                  borderRadius: 2,
                                  padding: 2
                                }}
                              >
                                ${frag.price}
                              </span>
                            </div>
                            <div
                              className='text-light'
                              style={{
                                textAlign: 'right'
                              }}
                            >
                              <span
                                style={{
                                  background: 'rgba(0, 0, 0, 0.35)',
                                  borderRadius: 2,
                                  padding: 1
                                }}
                              >
                                <small>
                                  {frag._id
                                    .toString()
                                    .substr(frag._id.toString().length - 4)}
                                </small>
                              </span>
                            </div>
                          </div>
                        ))}
                    </>
                  ) : (
                    <div>None</div>
                  )}
                </div>
              </div>
              <Form>
                <FormGroup>
                  {/* <Label for='coralName'>Name (Optional)</Label>
                  <Input
                    type='text'
                    name='coralName'
                    value={this.state.coralName}
                    onChange={this.onChange}
                    id='coralName'
                  /> */}
                  {/* <Label for='genus'>Genus</Label> */}
                  <p id='genus' style={{ marginTop: 8 }}>
                    Genus - {this.state.coral.genus}
                  </p>
                  <Label for='exampleSelect'>
                    Species (Optional) -{' '}
                    <small>
                      Providing species information will help us improve our
                      classification system.
                    </small>
                  </Label>
                  <Input
                    type='select'
                    name='species'
                    id='exampleSelect'
                    value={this.state.species}
                    style={{ height: '3rem' }}
                    onChange={this.onChange}
                  >
                    {speciesList.map(species => (
                      <option>{species}</option>
                    ))}
                  </Input>
                  {this.state.species === 'Other' ? (
                    <>
                      <Label for='otherSpecies' style={{ marginTop: 5 }}>
                        Other Species Name (<span>Required</span>) -{' '}
                        <small>
                          This is required because you chose "Other" for
                          Species.
                        </small>
                        {this.state.errors.otherSpecies ? (
                          <span className='text-danger'>
                            {' '}
                            * {this.state.errors.otherSpecies}
                          </span>
                        ) : null}
                      </Label>
                      <Input
                        type='text'
                        name='otherSpecies'
                        id='otherSpecies'
                        value={this.state.otherSpecies}
                        style={{ height: '3rem' }}
                        onChange={this.onChange}
                      ></Input>
                    </>
                  ) : null}
                  <Label for='coralName' style={{ marginTop: 5 }}>
                    Common Name (Optional)
                  </Label>
                  <Input
                    type='text'
                    name='coralName'
                    value={this.state.coralName}
                    onChange={this.onChange}
                    id='coralName'
                  />
                </FormGroup>

                <FormGroup>
                  <Label for='exampleText'>Desription</Label>

                  <Input
                    type='textarea'
                    name='description'
                    value={this.state.description}
                    onChange={this.onChange}
                    id='exampleText'
                  />
                </FormGroup>
                <Row form>
                  {/* <Col md={2}>
                    <FormGroup check>
                      <Input
                        type='checkbox'
                        name='forSaleCheck'
                        id='exampleCheck'
                        checked={this.state.forSaleCheck}
                        onChange={() => {
                          this.setState({
                            forSaleCheck: !this.state.forSaleCheck
                          })
                          console.log(this.state.forSaleCheck)
                        }}
                      />
                      <Label for='exampleCheck' check>
                        For Sale
                      </Label>
                    </FormGroup>
                  </Col>
                  {this.state.forSaleCheck ? (
                    <Col md={2}>
                      <FormGroup>
                        <Label for='exampleCity'>
                          Sale Price ($){' '}
                          {this.state.errors.price ? (
                            <span className='text-danger'>
                              {' '}
                              * {this.state.errors.price}
                            </span>
                          ) : null}
                        </Label>
                        <Input
                          type='text'
                          name='price'
                          value={this.state.price}
                          onChange={this.onChange}
                          id='exampleCity'
                        />
                      </FormGroup>
                    </Col>
                  ) : null} */}
                  <Col md={2}>
                    <FormGroup check>
                      <Input
                        type='checkbox'
                        name='check'
                        id='exampleCheck'
                        checked={this.state.forTradeCheck}
                        onChange={() => {
                          this.setState({
                            forTradeCheck: !this.state.forTradeCheck
                          })
                          console.log(this.state.forTradeCheck)
                        }}
                      />
                      <Label for='exampleCheck' check>
                        For Trade
                      </Label>
                    </FormGroup>
                  </Col>
                  {/* <Col md={2}>
                    <Link
                      to={`/addfrag/${this.state.coral._id}`}
                      className='text-info'
                    >
                      <i className='fa fa-plus' /> Add Frag
                    </Link>
                  </Col> */}
                  {/* {this.state.forTradeCheck ? (
                    <Col md={2}>
                      <FormGroup>
                        <Label for='exampleZip'>
                          Trade Value ($){' '}
                          {this.state.errors.tradeValue ? (
                            <span className='text-danger'>
                              {' '}
                              * {this.state.errors.tradeValue}
                            </span>
                          ) : null}
                        </Label>
                        <Input
                          type='text'
                          name='tradeValue'
                          value={this.state.tradeValue}
                          onChange={this.onChange}
                          id='exampleZip'
                        />
                      </FormGroup>
                    </Col>
                  ) : null} */}
                  {/* {this.state.forTradeCheck || this.state.forSaleCheck ? (
                    <Col md={3}>
                      <FormGroup>
                        <Label for='exampleZip'>
                          Location - Postal Code
                          {this.state.errors.zip ? (
                            <span className='text-danger'>
                              {' '}
                              * {this.state.errors.zip}
                            </span>
                          ) : null}
                        </Label>
                        {this.props.auth.location ? (
                          <p id='exampleZip'>
                            {this.props.auth.location.country_short_name} -{' '}
                            {this.props.auth.location.postal_code}
                          </p>
                        ) : (
                          <p>No Address Available</p>
                        )}
                        <Link to='/settings' className='text-info'>
                          <i className='fa fa-edit' /> Edit My Address
                        </Link>
                        
                      </FormGroup>
                    </Col>
                  ) : null} */}
                </Row>
                <Row style={{ padding: 15 }}>
                  {/* <Col md={1} /> */}
                  <Label for='color' style={{ marginRight: 10 }}>
                    Most Abundant Color
                  </Label>
                  {colors.map(color => (
                    <ColorCheckBox
                      id='color'
                      color={color}
                      updateColor={this.updateColor}
                      abundance={'most'}
                      currentColor={
                        this.state.primaryHues.length > 0
                          ? this.state.primaryHues[0]
                          : null
                      }
                    />
                  ))}
                </Row>

                <Row style={{ padding: 15 }}>
                  {/* <Col md={1} /> */}
                  <Label for='color2' style={{ marginRight: 10 }}>
                    2nd Most Abundant Color
                  </Label>
                  {colors.map(color => (
                    <ColorCheckBox
                      id='color2'
                      color={color}
                      updateColor={this.updateColor}
                      abundance={'2nd'}
                      currentColor={
                        this.state.primaryHues.length > 0
                          ? this.state.primaryHues2[0]
                          : null
                      }
                    />
                  ))}
                </Row>

                <Row style={{ padding: 15 }}>
                  {/* <Col md={1} /> */}
                  <Label for='color3' style={{ marginRight: 10 }}>
                    3rd Most Abundant Color
                  </Label>
                  {colors.map(color => (
                    <ColorCheckBox
                      id='color3'
                      color={color}
                      updateColor={this.updateColor}
                      abundance={'3rd'}
                      currentColor={
                        this.state.primaryHues.length > 0
                          ? this.state.primaryHues3[0]
                          : null
                      }
                    />
                  ))}
                </Row>

                <Row>
                  <Col md={4} />
                  <Col md={4} style={{ textAlign: 'center' }}>
                    <Button
                      className='button-info'
                      color='info'
                      onClick={this.saveCoral}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          ) : (
            <div style={{ textAlign: 'center', margin: 100 }}>
              <Spinner>Loading...</Spinner>
            </div>
          )}
        </Container>
      </>
    )
  }
}

EditCoral.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { updateCoral, myCoral })(EditCoral)

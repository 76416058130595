import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import CoralEbayPreview from 'components/CoralPreview/CoralEbayPreview.js'
import ebaySmallLogo from 'assets/ebayLogos/US/Right-Now-108x45.gif'

import { Row, Container } from 'reactstrap'

class Nameshow extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      name: null
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateScreen)
    this.updateScreen()

    axios
      .get(`/api/coral/getsinglename/${this.props.match.params.id}`)
      .then(res => {
        console.log(res.data)
        this.setState({ name: res.data })
      })
  }

  updateScreen = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true
      })
    } else {
      this.setState({
        screenSizeSmall: false
      })
    }
  }

  render () {
    let forSaleCoral = []
    let commonality = 'sometimes'
    let uniqueStores = []

    if (this.state.name && this.state.name.coral.length > 0) {
      forSaleCoral = this.state.name.coral.filter(coral => !coral.completed)
      forSaleCoral.sort((a, b) => (a.listingType > b.listingType ? -1 : 1))
      if (this.state.name.coral.length > 5) {
        commonality = 'often'
      }
      if (this.state.name.coral.length > 10) {
        commonality = 'well known to be'
      }

      this.state.name.coral
        .map(coral => coral.storeName)
        .forEach(seller => {
          if (uniqueStores.indexOf(seller) === -1) {
            uniqueStores.push(seller)
          }
        })
    }

    return (
      <>
        <Container>
          {this.state.name ? (
            <>
              <Helmet>
                <title style={{ textTransform: 'uppercase' }}>
                  {this.state.name.name.toUpperCase()} -{' '}
                  {this.state.name.genus.toUpperCase()}
                </title>

                <meta
                  name='description'
                  content={`A list of ${this.state.name.genus} coral with the name ${this.state.name.name}`}
                />

                {this.state.name && this.state.name.coral[0] ? (
                  <meta
                    property='og:image'
                    content={this.state.name.coral[0].imageURL}
                  />
                ) : null}

                <meta
                  property='og:url'
                  content={`https://www.immortalreef.com/coralnames/${this.props.match.params.id}`}
                />
              </Helmet>
              <div>
                <h2 style={{ textAlign: 'center', marginTop: 10 }}>
                  {this.state.name.name} {this.state.name.genus}
                </h2>
                <h4>Description:</h4>
                <p>
                  The coral common name "
                  <span style={{ textTransform: 'capitalize' }}>
                    {this.state.name.name}
                  </span>
                  " is{' '}
                  <span>
                    <i>{commonality}</i>
                  </span>{' '}
                  associated with the genus or type{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {this.state.name.genus}
                  </span>
                  . Currently available frags of{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {this.state.name.name}
                  </span>{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {this.state.name.genus}
                  </span>{' '}
                  are shown below.
                </p>
              </div>
              <h4>
                {this.state.name.name} {this.state.name.genus} Currently For
                Sale:{' '}
                <span style={{ display: 'inline-block' }}>
                  <div style={{ height: 45 }}>
                    <a
                      href='https://www.ebay.com'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img
                        src={ebaySmallLogo}
                        alt='ebay logo'
                        style={{
                          height: '100%',

                          marginLeft: 10
                        }}
                      />
                    </a>
                  </div>
                </span>
              </h4>
              <Row style={{ justifyContent: 'center' }}>
                {forSaleCoral.map(coral => (
                  <div style={{ margin: 10 }}>
                    <CoralEbayPreview
                      item={coral}
                      screenSizeSmall={this.state.screenSizeSmall}
                      keywordTitle={`${this.state.name.name} ${this.state.name.genus}`}
                      keywordAlt={`${this.state.name.name} ${this.state.name.genus} for sale $${coral.price} plus $${coral.shippingCost} shipping`}
                    />{' '}
                  </div>
                ))}
              </Row>
            </>
          ) : null}
        </Container>
      </>
    )
  }
}

Nameshow.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, {})(Nameshow)

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import profileReducer from "./profileReducer";
import postReducer from "./postReducer";
import itemReducer from "./itemReducer";
import RequestReducer from "./RequestReducer";
import communityReducer from "./communityReducer";
import coralReducer from "./coralReducer";
import notificationsReducer from "./notificationsReducer";
import coralDealsReducer from "./coralDealsReducer";
import coralNamesReducer from "./coralNamesReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  post: postReducer,
  item: itemReducer,
  requests: RequestReducer,
  community: communityReducer,
  coral: coralReducer,
  notifications: notificationsReducer,
  coralDeals: coralDealsReducer,
  coralNames: coralNamesReducer,
});

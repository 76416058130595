import axios from 'axios'
import setAuthToken from '../utils/setAuthToken'
import jwt_decode from 'jwt-decode'
import qs from 'qs'

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  GET_WISH_LIST,
  GET_MY_CART,
  GET_MY_TRADE_SALES,
  GET_MY_TRADE_PURCHASES,
  GET_MY_TRADES_COMPLETED,
  GET_USER_LOCATION,
  GET_MY_MESSAGES
} from './types'

// Login - Get User Token
export const getWebToken = (search, history) => dispatch => {
  let sso = qs.parse(search, {
    ignoreQueryPrefix: true
  }).sso
  let sig = qs.parse(search, {
    ignoreQueryPrefix: true
  }).sig
  let nonce = localStorage.getItem('nonce')
  axios
    .post('/api/users/discourseverify', {
      sso: sso,
      sig: sig,
      nonce: nonce
    })
    .then(res => {
      const { token } = res.data
      // Set token to ls
      localStorage.setItem('jwtToken', token)
      // Set token to Auth header
      setAuthToken(token)

      // Decode token to get user data
      const decoded = jwt_decode(token)

      // Set current user
      dispatch(setCurrentUser(decoded))

      localStorage.removeItem('nonce')

      history.push('/myreef')
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Login - Get User Token
//ONLY FOR ADMIN
export const loginUser = () => dispatch => {
  var randomString = function (length) {
    var text = ''
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

  let nonce = randomString(10)

  console.log(nonce)

  localStorage.setItem('nonce', nonce)

  // console.log(localStorage.getItem("nonce"));

  axios
    .post('/api/users/discoursehash', { nonce: nonce })
    .then(res => {
      console.log(res.data)
      if (res.data) {
        console.log(res.data)
        window.location.href = res.data.url
      }
    })
    .catch(err => console.log(err))

  // axios
  //   .post("/api/users/login", userData)
  //   .then((res) => {
  //     // Save to localStorage
  //     const { token } = res.data;
  //     // Set token to ls
  //     localStorage.setItem("jwtToken", token);

  //     // Set token to Auth header
  //     setAuthToken(token);

  //     // Decode token to get user data
  //     const decoded = jwt_decode(token);

  //     // Set current user
  //     dispatch(setCurrentUser(decoded));
  //   })
  //   .catch((err) =>
  //     dispatch({
  //       type: GET_ERRORS,
  //       payload: err.response.data,
  //     })
  //   );
}

// Get user location
export const getUserLocation = () => dispatch => {
  // dispatch(setProfileLoading());
  // console.log("is this working?");
  axios
    .get(`/api/users/getuserlocation`)
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_USER_LOCATION,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    })
}

// Get my messages
export const getMyMessages = () => dispatch => {
  // dispatch(setProfileLoading());
  // console.log("is this working?");
  axios
    .get(`/api/users/getmymessages`)
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_MY_MESSAGES,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    })
}

// Get my cart
export const getMyCart = () => dispatch => {
  // dispatch(setProfileLoading());
  // console.log("is this working?");
  axios
    .get(`/api/users/getmycart`)
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_MY_CART,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    })
}

// Get My Trades
export const getMyTradeSales = () => dispatch => {
  axios
    .get(`/api/users/getmytradesales`)
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_MY_TRADE_SALES,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    })
}

// Get My Trade Purchases
export const getMyTradePurchases = () => dispatch => {
  axios
    .get(`/api/users/getmytradepurchases`)
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_MY_TRADE_PURCHASES,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    })
}

// Get My Trades COmpleted
export const getMyTradesCompleted = () => dispatch => {
  axios
    .get(`/api/users/getmytradescompleted`)
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_MY_TRADES_COMPLETED,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    })
}

// Get my wish list
export const getWishList = () => dispatch => {
  // dispatch(setProfileLoading());
  // console.log("is this working?");
  axios
    .get(`/api/users/getwishlist`)
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_WISH_LIST,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    })
}

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  }
}

// Log user out
export const logoutUser = (urlRedirect, id) => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem('jwtToken')
  // Remove auth header for future requests
  setAuthToken(false)
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}))
  axios
    .post(`/api/users/discourselogout`, { id: id }) // change this to the passed ID
    .then(res => console.log(res.data))
    .catch(err => console.log(err))
  //need to end session via api
  window.location.href = `/${urlRedirect}`
}

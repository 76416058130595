import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import CoralEbayPreview from 'components/CoralPreview/CoralEbayPreview.js'
import ebaySmallLogo from 'assets/ebayLogos/US/Right-Now-108x45.gif'
import CustomUpload from 'components/CustomUpload/ExifImageUpload.jsx'
import { updateCoral, myCoral } from 'actions/coralActions'
import { getMyCart } from 'actions/authActions'
import defaultAvatar from 'assets/img/default-avatar.png'
import {
  Row,
  Container,
  Label,
  Col,
  Input,
  FormGroup,
  Form,
  Button,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Nav,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import colors from 'variables/colorFamilies.jsx'
import ColorCheckBox from 'components/ColorCheckBox/ColorCheckBox.js'
import { isNull } from 'lodash'
import classnames from 'classnames'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon
} from 'react-share'
import styles from './CoralNames.module.css'
var dateFormat = require('dateformat')
// dateFormat.masks.pretty = '"Image Date:" mmm d, yyyy';
dateFormat.masks.pretty = 'mmm d, yyyy'

class EditCoral extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      coral: null,
      activeTab: '1',
      animating: false,
      activeIndex: 0,
      added: false
      // coralName: null,
      // price: null,
      // traderValue: null,
      // description: null,
      // authorization: false,
      // forSaleCheck: false,
      // forTradeCheck: false,
      // postalCode: null,
      // file: null,
      // imgPreviewUrl: null,
      // zip: null,
      // errors: { zip: null, price: null, tradeValue: null },
      // placeholderImage: 'image_placeholder.jpg',
      // primaryHues: [],
      // primaryHues2: [],
      // primaryHues3: []
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateScreen)
    this.updateScreen()

    this.getCoral(this.props.match.params.coralId)
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   console.log('Latitude is :', position.coords.latitude)
    //   console.log('Longitude is :', position.coords.longitude)
    // })

    // axios
    //   .get(`/api/coral/getsinglecoral/${this.props.match.params.id}`)
    //   .then(res => {
    //     console.log(res.data)
    //     this.setState({ coral: res.data })

    //     this.setState({ loading: false })
    //   })
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.match.params.coralId !== this.props.match.params.coralId) {
      console.log(nextProps.match.params.coralId)
      this.setState({ loading: true })
      this.getCoral(nextProps.match.params.coralId)
    }
  }

  getCoral = id => {
    axios.get(`/api/coral/getsinglecoral/${id}`).then(res => {
      console.log(res.data)
      this.setState({ coral: res.data })

      this.setState({ loading: false })
    })
  }

  updateScreen = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true
      })
    } else {
      this.setState({
        screenSizeSmall: false
      })
    }
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  next = () => {
    if (this.state.animating) return
    const nextIndex =
      this.state.activeIndex === this.state.coral.imageHistory.length - 1
        ? 0
        : this.state.activeIndex + 1

    this.setState({ activeIndex: nextIndex })
  }

  previous = () => {
    if (this.state.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.coral.imageHistory.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex = newIndex => {
    if (this.state.animating) return
    this.setState({ activeIndex: newIndex })
  }

  addToCart = fragId => {
    axios
      .post('/api/users/addtocart', {
        // userId: this.state.coral.user._id,
        // coralId: this.state.coral._id
        userId: this.state.coral.user._id,
        coral: this.state.coral,
        fragId: fragId
      })
      .then(res => {
        console.log(res.data)
        if (res.data !== 'Cannot trade with self!') {
          this.props.getMyCart()
          this.setState({ added: true })
        }
      })
  }

  removeFromCart = () => {
    axios
      .post('/api/users/removefromcart', {
        userId: this.state.coral.user._id,
        coralId: this.state.coral._id
      })
      .then(res => {
        console.log(res.data)
        this.props.getMyCart()
      })
  }

  render () {
    const { coral } = this.state
    console.log(coral)
    let coralMeta = ''

    const { myCart } = this.props.auth
    let cartIndex = -1
    let localCart = null
    let coralInCart = false
    let selectedFrag = null
    if (myCart && coral) {
      cartIndex = myCart.map(cart => cart.user._id).indexOf(coral.user._id)
      if (cartIndex != -1) {
        localCart = myCart[cartIndex]
        let coralIndex = localCart.coral
          .map(coral => coral.coral._id)
          .indexOf(coral._id)
        if (coralIndex != -1) {
          coralInCart = true
          selectedFrag = localCart.coral[coralIndex].fragId
          // console.log(coralInCart, selectedFrag)
        }
      }
      console.log(localCart)
    }

    if (coral) {
      coralMeta = coral.genus
      if (coral.species !== 'unknown') {
        coralMeta = coralMeta + ' ' + coral.species
      }
      if (coral.name) {
        coralMeta = coralMeta + ' - ' + coral.name.name
      }
    }
    let coralSlides = []
    if (coral && coral.image) {
      coralSlides = coral.imageHistory.map((image, index) => (
        <CarouselItem
          onExiting={() => {
            this.setState({ animating: true })
          }}
          onExited={() => {
            this.setState({ animating: false })
          }}
          key={image.address}
        >
          <div
            style={
              this.state.screenSizeSmall
                ? {
                    height: 200,
                    textAlign: 'center',
                    background: 'lightgrey'
                  }
                : {
                    height: 400,
                    textAlign: 'center',
                    background: 'lightgrey'
                  }
            }
          >
            <img
              style={{ height: '100%' }}
              src={`https://s3.amazonaws.com/reefconnector/${image.address}`}
              alt={coral.genus}
            />
          </div>
          <div>{dateFormat(image.date, 'pretty')}</div>
          <CarouselCaption
          // captionText={dateFormat(image.date, 'pretty')}
          // captionHeader={'caption header'}
          />
        </CarouselItem>
      ))
    }
    return (
      <>
        <Container style={{ marginTop: 10 }}>
          {!this.state.loading ? (
            <>
              {coral ? (
                <Helmet>
                  <title style={{ textTransform: 'uppercase' }}>
                    {coral.user.username}'s - {coralMeta}
                  </title>

                  <meta
                    name='description'
                    content={`A profile detailing ${coral.user.username}'s - ${coralMeta}`}
                  />

                  {coral.image ? (
                    <meta
                      property='og:image'
                      content={`https://s3.amazonaws.com/reefconnector/${coral.image.address}`}
                    />
                  ) : null}
                  <meta
                    property='og:url'
                    content={`https://www.immortalreef.com/coralshow/${this.props.match.params.id}`}
                  />
                </Helmet>
              ) : null}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginBottom: 3
                }}
              >
                <FacebookShareButton
                  style={{ marginRight: 3 }}
                  url={`immortalreef.com/fragshow/${coral._id}/${this.props.match.params.fragId}`}
                >
                  <FacebookIcon size={28} />
                </FacebookShareButton>
                <TwitterShareButton
                  style={{ marginRight: 3 }}
                  url={`immortalreef.com/fragshow/${coral._id}/${this.props.match.params.fragId}`}
                >
                  <TwitterIcon size={28} />
                </TwitterShareButton>
              </div>
              <div
              // style={
              //   this.state.screenSizeSmall
              //     ? {}
              //     : {
              //         marginLeft: 100,
              //         marginRight: 100
              //       }
              // }
              >
                {this.state.screenSizeSmall ? (
                  <h6 style={{ textAlign: 'center' }}>
                    {coral.genus}
                    {coral.species && coral.species !== 'unknown' ? (
                      <span> - {coral.species}</span>
                    ) : null}
                    {coral.name ? (
                      <small> - {coral.name.name}</small>
                    ) : (
                      <>
                        {coral.unverifiedName ? (
                          <small>{coral.unverifiedName} (unregistered)</small>
                        ) : // <small>Name Unknown</small>
                        null}
                      </>
                    )}
                  </h6>
                ) : (
                  <h4 style={{ textAlign: 'center' }}>
                    {coral.genus}
                    {coral.species && coral.species !== 'unknown' ? (
                      <span> - {coral.species}</span>
                    ) : null}
                    {coral.name ? (
                      <small> - {coral.name.name}</small>
                    ) : (
                      <>
                        {coral.unverifiedName ? (
                          <small>{coral.unverifiedName} (unregistered)</small>
                        ) : // <small>Name Unknown</small>
                        null}
                      </>
                    )}
                  </h4>
                )}
                <div
                  style={
                    this.state.screenSizeSmall
                      ? {
                          height: 200,
                          textAlign: 'center'
                        }
                      : {
                          height: 400,
                          textAlign: 'center'
                        }
                  }
                >
                  <img
                    style={{ height: '100%' }}
                    src={`https://s3.amazonaws.com/reefconnector/image_placeholder.jpg`}
                  />

                  {/* {!coral.image ? (
                    <img
                      style={{ height: '100%' }}
                      src={`https://s3.amazonaws.com/reefconnector/image_placeholder.jpg`}
                    />
                  ) : (
                    <>
                      <Carousel
                        activeIndex={this.state.activeIndex}
                        next={this.next}
                        previous={this.previous}
                        // pause={['hover', true]}
                        interval={false}
                      >
                        <CarouselIndicators
                          items={coral.imageHistory}
                          activeIndex={this.state.activeIndex}
                          onClickHandler={this.goToIndex}
                          style={{ color: 'black' }}
                        />
                        {coralSlides}
                        <CarouselControl
                          direction='prev'
                          directionText='Previous'
                          onClickHandler={this.previous}
                        />
                        <CarouselControl
                          direction='next'
                          directionText='Next'
                          onClickHandler={this.next}
                        />
                      </Carousel>
                    </>
                  )} */}
                </div>
                <div style={{ height: 10 }} />
                {this.props.auth.user.id === coral.user._id ? (
                  <div style={{ textAlign: 'center', marginTop: 10 }}>
                    <Link
                      to={`/coraledit/${coral._id}`}
                      className='text-info'
                      style={{ marginRight: 20 }}
                    >
                      <i className='fa fa-edit' /> Edit Coral
                    </Link>
                    <Link to={`/addfrag/${coral._id}`} className='text-info'>
                      <i className='fa fa-plus' /> Add Frag
                    </Link>
                  </div>
                ) : null}
                <div
                  style={
                    this.state.screenSizeSmall
                      ? { marginTop: 10, marginLeft: 20, marginRight: 20 }
                      : {
                          marginTop: 10,
                          marginLeft: 180,
                          marginRight: 180
                        }
                  }
                >
                  {/* <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div
                      id='For Sale'
                      style={{
                        textAlign: 'center',
                        margin: 10,
                        padding: 10,
                        background: '#F2F3F5'
                      }}
                    >
                      <h6>For Sale</h6>
                      {coral.forSale ? (
                        <i className='fa fa-check text-success' />
                      ) : (
                        <i className='fa fa-remove' />
                      )}
                    </div>
                    {coral.forSale ? (
                      <div
                        id='Sale Price'
                        style={{
                          textAlign: 'center',
                          padding: 10,
                          margin: 10,
                          background: '#F2F3F5'
                        }}
                      >
                        <h6>Sale Price</h6>
                        <span>${coral.price}</span>
                      </div>
                    ) : null}
                    <div
                      id='For Trade'
                      style={{
                        textAlign: 'center',
                        padding: 10,
                        margin: 10,
                        background: '#F2F3F5'
                      }}
                    >
                      <h6>For Trade</h6>
                      {coral.forTrade ? (
                        <i className='fa fa-check text-success' />
                      ) : (
                        <i className='fa fa-remove ' />
                      )}
                    </div>

                    <div
                      id='Color Profile'
                      style={{
                        textAlign: 'center',
                        padding: 10,
                        margin: 10,
                        background: '#F2F3F5'
                      }}
                    >
                      <h6>Color Profile</h6>
                      {coral.extracted150Hex ? (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around'
                            }}
                          >
                            <div
                              style={{
                                background: `#${coral.extracted150Hex}`,
                                height: 20,
                                width: 20,
                                borderRadius: 5
                              }}
                            />
                            {coral.extracted250Hex ? (
                              <div
                                style={{
                                  background: `#${coral.extracted250Hex}`,
                                  height: 20,
                                  width: 20,
                                  borderRadius: 5
                                }}
                              />
                            ) : null}
                            {coral.extracted350Hex ? (
                              <div
                                style={{
                                  background: `#${coral.extracted350Hex}`,
                                  height: 20,
                                  width: 20,
                                  borderRadius: 5
                                }}
                              />
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <span>None</span>
                      )}
                    </div>
                  </div> */}

                  <div
                    id='Available Frags'
                    style={{
                      textAlign: 'center',
                      margin: 10,
                      padding: 10,
                      background: '#F2F3F5'
                    }}
                  >
                    <h6>Available Frags</h6>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexWrap: 'wrap'
                      }}
                    >
                      {coral.frags.length > 0 ? (
                        <>
                          {coral.frags.map(frag => (
                            <div
                              style={{
                                borderRadius: 5,
                                height: 150,
                                width: 150,
                                backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${frag.image.address})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                margin: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                              }}
                            >
                              <div
                                style={{
                                  // color: 'white',
                                  textAlign: 'end',
                                  margin: 5,
                                  display: 'flex',
                                  alignItems: 'start',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <span
                                  style={{
                                    backgroundColor:
                                      !coralInCart || selectedFrag === frag._id
                                        ? 'rgba(0, 0, 0, 0.35)'
                                        : 'rgba(0, 0, 0, 0)',
                                    borderRadius: 2,
                                    padding: 2
                                  }}
                                >
                                  {!coralInCart &&
                                  this.props.auth.user.id != coral.user._id ? (
                                    <div className={styles.tooltip}>
                                      <Button
                                        color='secondary'
                                        style={{ padding: 3, border: 0 }}
                                        size='sm'
                                        outline
                                        onClick={() => this.addToCart(frag._id)}
                                      >
                                        <i
                                          style={{ fontSize: 18 }}
                                          className='fa fa-shopping-cart'
                                          //className='fa fa-check'
                                        />
                                      </Button>
                                      <span className={styles.tooltiptext}>
                                        Add To Cart
                                      </span>
                                    </div>
                                  ) : null}

                                  {selectedFrag === frag._id ? (
                                    <div className={styles.tooltip}>
                                      <Button
                                        color='light'
                                        style={{ padding: 3, border: 0 }}
                                        size='sm'
                                        outline
                                        onClick={this.removeFromCart}
                                      >
                                        <i
                                          style={{ fontSize: 18 }}
                                          // className='fa fa-shopping-cart'
                                          className='fa fa-check'
                                        />
                                      </Button>
                                      <span className={styles.tooltiptext}>
                                        Remove From Cart
                                      </span>
                                    </div>
                                  ) : null}
                                </span>
                                <span
                                  className='text-light'
                                  style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.35)',
                                    borderRadius: 2,
                                    padding: 2
                                  }}
                                >
                                  ${frag.price}
                                </span>
                              </div>
                              <div
                                className='text-light'
                                style={{
                                  textAlign: 'right'
                                }}
                              >
                                <span
                                  style={{
                                    background: 'rgba(0, 0, 0, 0.35)',
                                    borderRadius: 2,
                                    padding: 1
                                  }}
                                >
                                  <small>
                                    {frag._id
                                      .toString()
                                      .substr(frag._id.toString().length - 4)}
                                  </small>
                                </span>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div>None</div>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      id='For Trade'
                      style={{
                        textAlign: 'center',
                        padding: 10,
                        margin: 10,
                        background: '#F2F3F5'
                      }}
                    >
                      <h6>For Trade</h6>
                      {coral.forTrade ? (
                        <i className='fa fa-check text-success' />
                      ) : (
                        <i className='fa fa-remove ' />
                      )}
                    </div>

                    <div
                      id='Color Profile'
                      style={{
                        textAlign: 'center',
                        padding: 10,
                        margin: 10,
                        background: '#F2F3F5'
                      }}
                    >
                      <h6>Color Profile</h6>
                      {coral.extracted150Hex ? (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around'
                            }}
                          >
                            <div
                              style={{
                                background: `#${coral.extracted150Hex}`,
                                height: 20,
                                width: 20,
                                borderRadius: 5
                              }}
                            />
                            {coral.extracted250Hex ? (
                              <div
                                style={{
                                  background: `#${coral.extracted250Hex}`,
                                  height: 20,
                                  width: 20,
                                  borderRadius: 5
                                }}
                              />
                            ) : null}
                            {coral.extracted350Hex ? (
                              <div
                                style={{
                                  background: `#${coral.extracted350Hex}`,
                                  height: 20,
                                  width: 20,
                                  borderRadius: 5
                                }}
                              />
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <span>None</span>
                      )}
                    </div>

                    {/* <div class='my-6'>
                      <a
                        href='#'
                        class='d-block h5 mb-0'
                        style={{ fontSize: 13, padding: 3 }}
                      >
                        Julienne Moore
                      </a>
                    </div> */}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                    >
                      <Link
                        to={`/membercollection/${coral.user._id}`}
                        class='avatar avatar-sm rounded-circle'
                      >
                        <img
                          alt='...'
                          src={coral.user.avatar}
                          style={{ height: 40, borderRadius: 50 }}
                        />
                      </Link>

                      <div class='my-6' style={{ padding: 10 }}>
                        <Link
                          to={`/membercollection/${coral.user._id}`}
                          class='d-block h5 mb-0'
                          style={{ fontSize: 13, padding: 3 }}
                        >
                          {coral.user.username}
                          <div style={{ height: 5 }}></div>
                          <span style={{ color: 'grey' }}>
                            {coral.user.locality} -{' '}
                            {coral.user.administrative_area_level_1_short_name}
                          </span>
                        </Link>
                      </div>
                    </div>
                    {/* {this.props.auth.user.id !== coral.user._id ? (
                      <>
                        {coral.status === 'active' &&
                        (coral.forSale || coral.forTrade) ? (
                          <div>
                            {this.state.added ? (
                              <div className='text-success'>
                                <i className='fa fa-check' />
                                Added To Cart
                              </div>
                            ) : (
                              <Button color='info' onClick={this.addToCart}>
                                Add To Cart
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div>Coral Not Available</div>
                        )}
                      </>
                    ) : null} */}
                  </div>
                  <div
                    id='about'
                    style={{ padding: 10, margin: 10, background: '#F2F3F5' }}
                  >
                    <h6>Description</h6>
                    <p>{coral.about}</p>
                  </div>
                </div>
                <h4 style={{ textAlign: 'center', marginTop: 14 }}>Lineage</h4>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        // href='#parents'
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: this.state.activeTab === '1'
                        })}
                        onClick={() => {
                          this.toggleTab('1')
                        }}
                      >
                        Parents <span>({coral.parents.length})</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        // href='#children'
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: this.state.activeTab === '2'
                        })}
                        onClick={() => {
                          this.toggleTab('2')
                        }}
                      >
                        Children <span>({coral.children.length})</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId='1'>
                      <>
                        <Table>
                          <Thead>
                            <Tr style={{ background: '#F2F3F5' }}>
                              <Th className='text-center'>Image</Th>
                              <Th className='text-center'>Generation</Th>
                              <Th className='text-center'>Type</Th>
                              <Th className='text-center'>Genus</Th>
                              <Th className='text-center'>Name</Th>
                              <Th className='text-center'>Color</Th>
                              <Th className='text-center'>Owner</Th>
                              {/* <Th className='text-center'>Price</Th>
                                <Th className='text-center'>Trade</Th>
                                <Th className='text-center'>Cart</Th> */}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {coral.parents.map((coral, index) => (
                              <Tr>
                                <Td className='text-center'>
                                  <Link to={`/coralshow/${coral._id}`}>
                                    {coral.image ? (
                                      <img
                                        style={{
                                          maxHeight: '8rem',
                                          maxWidth: '8rem',
                                          borderRadius: 5
                                        }}
                                        src={`https://s3.amazonaws.com/reefconnector/${coral.image.address}`}
                                      />
                                    ) : (
                                      <i
                                        className='fa fa-image'
                                        style={{ fontSize: 64 }}
                                      />
                                    )}
                                  </Link>
                                </Td>
                                <Td className='text-center'>{index + 1}</Td>
                                <Td className='text-center'>{coral.type}</Td>
                                <Td>{coral.genus}</Td>

                                <Td>
                                  {coral.name ? (
                                    <Link to={`/coralnames/${coral.name._id}`}>
                                      <span
                                        style={{
                                          textTransform: 'capitalize'
                                        }}
                                        className='text-info'
                                      >
                                        {coral.name.name}
                                      </span>
                                    </Link>
                                  ) : (
                                    <>
                                      {coral.unverifiedName ? (
                                        <span>
                                          <span
                                            style={{
                                              textTransform: 'capitalize'
                                            }}
                                          >
                                            {coral.unverifiedName}
                                          </span>{' '}
                                          {/* <small>(unregistered)</small> */}
                                        </span>
                                      ) : (
                                        <span>N/A</span>
                                      )}
                                    </>
                                  )}
                                </Td>
                                {coral.extracted150Hex ? (
                                  <Td
                                    className='text-center'
                                    // style={{
                                    //   background: `#${coral.extracted150Hex}`
                                    // }}
                                  >
                                    <Link
                                      to={`/coralcolorshow/${coral.extracted150Hex}/${coral.genus}`}
                                    >
                                      <div
                                        style={{
                                          background: `#${coral.extracted150Hex}`,
                                          height: 20,
                                          width: 20,
                                          textAlign: 'center',
                                          borderRadius: 5,
                                          margin: 'auto'
                                        }}
                                      />
                                    </Link>
                                  </Td>
                                ) : (
                                  <Td className='text-center'>N/A</Td>
                                )}
                                {/* <Td className='text-center'>Coral Color</Td> */}
                                {/* <Td className='text-center'>
                                    {coral.forSale ? (
                                      <span>${coral.price}</span>
                                    ) : (
                                      <i className='fa fa-times' />
                                    )}
                                  </Td> */}
                                {/* <Td className='text-center'>
                                    {coral.forTrade ? (
                                      <span>
                                        <i className='fa fa-check text-success' />
                                      </span>
                                    ) : (
                                      <i className='fa fa-times' />
                                    )}
                                  </Td> */}
                                {/* <Td className='text-center'>
                                    {coral.forTrade || coral.forSale ? (
                                      <>
                                        {localCart &&
                                        localCart.coral
                                          .map(coral => coral._id)
                                          .some(id => id === coral._id) ? (
                                          <Button
                                            outline
                                            color='danger'
                                            onClick={() =>
                                              this.removeFromCart(coral._id)
                                            }
                                          >
                                            remove
                                          </Button>
                                        ) : (
                                          <Button
                                            outline
                                            color='success'
                                            onClick={() =>
                                              this.addToCart(coral)
                                            }
                                          >
                                            Add
                                          </Button>
                                        )}
                                      </>
                                    ) : (
                                      <i className='fa fa-times' />
                                    )}
                                  </Td> */}
                                <Td className='text-center'>
                                  <Link
                                    to={`/membercollection/${coral.user._id}`}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <a class='avatar avatar-sm rounded-circle'>
                                        <img
                                          alt='...'
                                          src={coral.user.avatar}
                                          style={{
                                            height: 40,
                                            borderRadius: 50
                                          }}
                                        />
                                      </a>

                                      <div class='my-6' style={{ padding: 10 }}>
                                        <a
                                          class='d-block h5 mb-0'
                                          style={{ fontSize: 13, padding: 3 }}
                                        >
                                          {coral.user.username}
                                        </a>
                                      </div>
                                    </div>
                                  </Link>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </>
                    </TabPane>
                    <TabPane tabId='2'>
                      <>
                        <Table>
                          <Thead>
                            <Tr style={{ background: '#F2F3F5' }}>
                              <Th className='text-center'>Image</Th>

                              <Th className='text-center'>Type</Th>
                              <Th className='text-center'>Genus</Th>
                              <Th className='text-center'>Name</Th>
                              <Th className='text-center'>Color</Th>
                              <Th className='text-center'>Owner</Th>
                              {/* <Th className='text-center'>Price</Th>
                                <Th className='text-center'>Trade</Th>
                                <Th className='text-center'>Cart</Th> */}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {coral.children.map((coral, index) => (
                              <Tr>
                                <Td className='text-center'>
                                  <Link to={`/coralshow/${coral._id}`}>
                                    {coral.image ? (
                                      <img
                                        style={{
                                          maxHeight: '8rem',
                                          maxWidth: '8rem',
                                          borderRadius: 5
                                        }}
                                        src={`https://s3.amazonaws.com/reefconnector/${coral.image.address}`}
                                      />
                                    ) : (
                                      <i
                                        className='fa fa-image'
                                        style={{ fontSize: 64 }}
                                      />
                                    )}
                                  </Link>
                                </Td>

                                <Td className='text-center'>{coral.type}</Td>
                                <Td>{coral.genus}</Td>

                                <Td>
                                  {coral.name ? (
                                    <Link to={`/coralnames/${coral.name._id}`}>
                                      <span
                                        style={{
                                          textTransform: 'capitalize'
                                        }}
                                        className='text-info'
                                      >
                                        {coral.name.name}
                                      </span>
                                    </Link>
                                  ) : (
                                    <>
                                      {coral.unverifiedName ? (
                                        <span>
                                          <span
                                            style={{
                                              textTransform: 'capitalize'
                                            }}
                                          >
                                            {coral.unverifiedName}
                                          </span>{' '}
                                          {/* <small>(unregistered)</small> */}
                                        </span>
                                      ) : (
                                        <span>N/A</span>
                                      )}
                                    </>
                                  )}
                                </Td>
                                {coral.extracted150Hex ? (
                                  <Td
                                    className='text-center'
                                    // style={{
                                    //   background: `#${coral.extracted150Hex}`
                                    // }}
                                  >
                                    <Link
                                      to={`/coralcolorshow/${coral.extracted150Hex}/${coral.genus}`}
                                    >
                                      <div
                                        style={{
                                          background: `#${coral.extracted150Hex}`,
                                          height: 20,
                                          width: 20,
                                          textAlign: 'center',
                                          borderRadius: 5,
                                          margin: 'auto'
                                        }}
                                      />
                                    </Link>
                                  </Td>
                                ) : (
                                  <Td className='text-center'>N/A</Td>
                                )}
                                {/* <Td className='text-center'>Coral Color</Td> */}
                                {/* <Td className='text-center'>
                                    {coral.forSale ? (
                                      <span>${coral.price}</span>
                                    ) : (
                                      <i className='fa fa-times' />
                                    )}
                                  </Td> */}
                                {/* <Td className='text-center'>
                                    {coral.forTrade ? (
                                      <span>
                                        <i className='fa fa-check text-success' />
                                      </span>
                                    ) : (
                                      <i className='fa fa-times' />
                                    )}
                                  </Td> */}
                                {/* <Td className='text-center'>
                                    {coral.forTrade || coral.forSale ? (
                                      <>
                                        {localCart &&
                                        localCart.coral
                                          .map(coral => coral._id)
                                          .some(id => id === coral._id) ? (
                                          <Button
                                            outline
                                            color='danger'
                                            onClick={() =>
                                              this.removeFromCart(coral._id)
                                            }
                                          >
                                            remove
                                          </Button>
                                        ) : (
                                          <Button
                                            outline
                                            color='success'
                                            onClick={() =>
                                              this.addToCart(coral)
                                            }
                                          >
                                            Add
                                          </Button>
                                        )}
                                      </>
                                    ) : (
                                      <i className='fa fa-times' />
                                    )}
                                  </Td> */}
                                <Td className='text-center'>
                                  <Link
                                    to={`/membercollection/${coral.user._id}`}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <a class='avatar avatar-sm rounded-circle'>
                                        <img
                                          alt='...'
                                          src={coral.user.avatar}
                                          style={{
                                            height: 40,
                                            borderRadius: 50
                                          }}
                                        />
                                      </a>

                                      <div class='my-6' style={{ padding: 10 }}>
                                        <a
                                          class='d-block h5 mb-0'
                                          style={{ fontSize: 13, padding: 3 }}
                                        >
                                          {coral.user.username}
                                        </a>
                                      </div>
                                    </div>
                                  </Link>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </>
          ) : (
            <div>Loading...</div>
          )}
          <div style={{ height: 100, width: 1 }} />
        </Container>
      </>
    )
  }
}

EditCoral.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { updateCoral, myCoral, getMyCart })(
  EditCoral
)

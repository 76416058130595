import axios from 'axios'
import setAuthToken from 'utils/setAuthToken'

import {
  GET_ERRORS,
  GET_CORAL_LIST,
  GET_CORAL,
  GET_MEMBER_CORAL,
  CORAL_SEARCH_CRITERIA,
  GET_CORAL_SEARCH_LIST,
  CORAL_SORT,
  CORAL_PAGE_SET,
  GET_STRAIN_LIST,
  STRAIN_GO_BACK,
  CORAL_LOADING,
  CLEAR_CORAL_LOADING,
  GET_TAGGED_CORAL
} from './types'

// Get Individual Coral
export const getCoral = id => dispatch => {
  // dispatch(setProfileLoading());
  axios
    .get(`/api/coral/getcoral/${id}`)
    .then(res => {
      console.log(res)
      dispatch({
        type: GET_CORAL,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
      // dispatch({
      //   type: GET_CORAL_LIST,
      //   payload: {}
      // });
    })
}

// Get Member Coral List
export const getMemberCoral = id => dispatch => {
  axios
    .get(`/api/coral/getmembercoral/${id}`)
    .then(res => {
      console.log(res)
      dispatch({
        type: GET_MEMBER_CORAL,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
      // dispatch({
      //   type: GET_CORAL_LIST,
      //   payload: {}
      // });
    })
}

// Get Strain Coral List
export const getStrainCoral = id => dispatch => {
  axios
    .get(`/api/coral/getstraincoral/${id}`)
    .then(res => {
      console.log(res)
      dispatch({
        type: GET_STRAIN_LIST,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
      // dispatch({
      //   type: GET_CORAL_LIST,
      //   payload: {}
      // });
    })
}

// Get Strain Coral List
export const strainGoBack = path => dispatch => {
  // axios
  //   .get(`/api/coral/getstraincoral/${id}`)
  //   .then(res => {
  //     console.log(res);
  console.log(path)
  dispatch({
    type: STRAIN_GO_BACK,
    payload: path
  })
  // })
  // .catch(err => {
  //   console.log(err);
  //   // dispatch({
  //   //   type: GET_CORAL_LIST,
  //   //   payload: {}
  //   // });
  // });
}

// Get Coral List
export const myCoral = () => dispatch => {
  // dispatch(setProfileLoading());
  // console.log('is this working?')
  axios
    .get(`/api/coral/getmycoral`)
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_CORAL_LIST,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
      // dispatch({
      //   type: GET_CORAL_LIST,
      //   payload: {}
      // });
    })
}

// Get Coral List
export const memberCoral = id => dispatch => {
  // dispatch(setProfileLoading());
  // console.log('is this working?')
  axios
    .post(`/api/coral/getmembercoral`, { id: id })
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_MEMBER_CORAL,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
      // dispatch({
      //   type: GET_CORAL_LIST,
      //   payload: {}
      // });
    })
}

// Get my taggd coral
export const taggedCoral = () => dispatch => {
  // dispatch(setProfileLoading());
  // console.log("is this working?");
  axios
    .get(`/api/coral/gettaggedcoral`)
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_TAGGED_CORAL,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
      // dispatch({
      //   type: GET_CORAL_LIST,
      //   payload: {}
      // });
    })
}

// Get Coral List
export const getCoralList = (criteria, sort, page) => dispatch => {
  const buildQuery = criteria => {
    console.log(criteria)
    let query = {}

    // if (criteria.keyword) {
    //   query.$text = { $search: criteria.keyword };
    // }
    // query.status = { $or: [{ status: "registered" }, { status: "available" }] };

    if (criteria.status === 'available') {
      query.status = { $eq: criteria.status }
    }

    if (criteria.type) {
      query.type = { $eq: criteria.type }
    }

    if (criteria.genus) {
      query.genus = { $eq: criteria.genus }
    }

    if (criteria.species) {
      query.species = { $eq: criteria.species }
    }
    if (criteria.shipping) {
      query.shipping = { $eq: true }
    }

    if (criteria.minPrice && criteria.maxPrice) {
      query.price = { $gte: criteria.minPrice, $lte: criteria.maxPrice }
    } else if (criteria.minPrice && !criteria.maxPrice) {
      query.price = { $gte: criteria.minPrice }
    } else if (!criteria.minPrice && criteria.maxPrice) {
      query.price = { $lte: criteria.maxPrice }
    }

    let statusQuery = []
    statusQuery = [
      { status: { $eq: 'registered' } },
      { status: { $eq: 'available' } }
    ]

    let orQuery = []
    if (criteria.keyword) {
      // orQuery = [
      //   { commonNames: { $in: criteria.keyword } },
      //   { commonClass: { $in: criteria.keyword } },
      // ];

      //criteria.keyword is set to the first element of array because the original version searched an array of various formats of the keyword. the first element of the array is the original keyword
      query.$text = { $search: criteria.keyword[0] }
    }

    // if (criteria.location) {
    //   query.location = {
    //     $geoWithin: {
    //       //distance radians
    //       $centerSphere: [criteria.location, criteria.distance / 3959]
    //     }
    //   };
    // }

    if (
      criteria.location
      //  && !criteria.keyword
    ) {
      query.location = {
        $nearSphere: {
          $geometry: {
            type: 'Point',
            coordinates: criteria.location
          },
          //distance in meters
          $minDistance: 0,
          $maxDistance: criteria.distance * 1609.34
        }
      }
    }

    // if (criteria.extracted150Hex) {
    //   query.extracted150Hex = {
    //     $in: criteria.extracted150Hex
    //   };
    // }

    // if (criteria.extracted250Hex) {
    //   query.extracted250Hex = {
    //     $in: criteria.extracted250Hex
    //   };
    // }
    // if (criteria.extracted350Hex) {
    //   query.extracted350Hex = {
    //     $in: criteria.extracted350Hex
    //   };
    // }
    if (criteria.primaryHues.length > 0) {
      query.extracted150Hex = {
        $in: criteria.primaryHues
      }
    }

    if (criteria.primaryHues2.length > 0) {
      query.extracted250Hex = {
        $in: criteria.primaryHues2
      }
    }
    if (criteria.primaryHues3.length > 0) {
      query.extracted350Hex = {
        $in: criteria.primaryHues3
      }
    }

    const fullQuery = {
      query: query,
      orQuery: orQuery,
      statusQuery: statusQuery
    }

    return fullQuery
  }

  console.log(buildQuery(criteria))
  let searchParams = {
    criteria: buildQuery(criteria),
    sort: sort,
    page: page
  }

  axios
    // .post(`/api/coral/coralsearch`, buildQuery(criteria))
    .post(`/api/coral/coralsearch`, searchParams)
    .then(res => {
      console.log(res)
      dispatch({
        type: GET_CORAL_SEARCH_LIST,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
      // dispatch({
      //   type: GET_CORAL_LIST,
      //   payload: {}
      // });
    })
}

//save sort
export const saveSort = sort => async dispatch => {
  dispatch({
    type: CORAL_SORT,
    payload: sort
  })
}

//save search criteria
export const saveSearch = criteria => async dispatch => {
  dispatch({
    type: CORAL_SEARCH_CRITERIA,
    payload: criteria
  })
}

//set search results page number
export const setPage = page => async dispatch => {
  dispatch({
    type: CORAL_PAGE_SET,
    payload: page
  })
}

//create new frag action
export const createNewFrag = () => async dispatch => {
  console.log('create new frag action')
}

// create Coral
// export const createCoral = (coralData, file, history) => async (dispatch) => {
//   const token = localStorage.getItem("jwtToken");

//   let coralKey;

//   try {
//     const uploadConfig = await axios.post("/api/upload", { file: file.name });
//     coralKey = uploadConfig.data.key;
//     setAuthToken(false);

//     try {
//       const checkUpload = await axios.put(uploadConfig.data.url, file, {
//         headers: {
//           "Content-Type": file.type,
//         },
//       });
//       console.log(checkUpload);
//     } catch (error) {
//       console.log(error);
//     }
//   } catch (error) {
//     console.log(error);
//   }

//   // Set token to Auth header
//   setAuthToken(token);

//   axios
//     .post("/api/coral/create", {
//       ...coralData,
//       coralImage: coralKey,
//     })
//     .then((res) => {
//       // if (res) {
//       //   history.push(`/public/allcoral`);
//       // }
//       dispatch(myCoral());
//       // dispatch(getCoral(res.data));

//       console.log("Update Success!" + res.data);
//       dispatch(getSubscriptionData());
//       // getBraintreeData();

//       // dispatch({
//       //   type: GET_CORAL_LIST,
//       //   payload: res.data.lists.corals
//       // });
//       // console.log(res.data);
//       console.log(res);
//       // if (res.data) {
//       //   dispatch(getCoralList(res.data));

//       // }
//     })
//     .catch(
//       (err) => console.log(err)
//       // dispatch({
//       //   type: GET_ERRORS,
//       //   payload: err.response.data
//       // })
//     );
// };

// update Coral
export const updateCoral = (coralData, file, file2) => async dispatch => {
  let coralKey = ''
  let coralKey2 = ''

  if (file || file2) {
    const token = localStorage.getItem('jwtToken')
    // setAuthToken(false)
    if (file) {
      console.log('filetrue')
      try {
        const uploadConfig = await axios.post('/api/upload', {
          file: file.name
        })
        coralKey = uploadConfig.data.key
        setAuthToken(false)

        try {
          const checkUpload = await axios.put(uploadConfig.data.url, file, {
            headers: {
              'Content-Type': file.type
            }
          })
          console.log(checkUpload)
          setAuthToken(token)
        } catch (error) {
          console.log(error)
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (file2) {
      console.log('file2true')
      try {
        const uploadConfig2 = await axios.post('/api/upload', {
          file: file2.name
        })
        coralKey2 = uploadConfig2.data.key
        setAuthToken(false)

        try {
          const checkUpload2 = await axios.put(uploadConfig2.data.url, file2, {
            headers: {
              'Content-Type': file2.type
            }
          })
          console.log(checkUpload2)
          setAuthToken(token)
        } catch (error) {
          console.log(error)
        }
      } catch (error) {
        console.log(error)
      }
    }

    // setAuthToken(token)
  }

  axios
    .post(
      '/api/coral/update',
      {
        ...coralData,
        coralImage: coralKey,
        newGenericFragImage: coralKey2
      }
      // coralData
    )
    .then(res => {
      // console.log('Update Success!' + res.data)
      // dispatch(getCoral(res.data))
      // if (res.data) {
      dispatch(myCoral())
      // }

      // history.push(`/profile/${profileId}/details/corals`);
    })
    .catch(
      err => console.log(err)
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data

      // })
    )
}

// add frag
export const addFrag = (coralData, file) => async dispatch => {
  let coralKey = ''

  if (file) {
    const token = localStorage.getItem('jwtToken')
    try {
      const uploadConfig = await axios.post('/api/upload', { file: file.name })
      coralKey = uploadConfig.data.key
      setAuthToken(false)

      try {
        const checkUpload = await axios.put(uploadConfig.data.url, file, {
          headers: {
            'Content-Type': file.type
          }
        })
        console.log(checkUpload)
      } catch (error) {
        console.log(error)
      }
    } catch (error) {
      console.log(error)
    }

    setAuthToken(token)
  }

  axios
    .post(
      '/api/coral/addfrag',
      {
        ...coralData,
        coralImage: coralKey
      }
      // coralData
    )
    .then(res => {
      // console.log('Update Success!' + res.data)
      // dispatch(getCoral(res.data))
      // if (res.data) {
      dispatch(myCoral())
      // }

      // history.push(`/profile/${profileId}/details/corals`);
    })
    .catch(
      err => console.log(err)
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data

      // })
    )
}

// share image upload
export const shareImageUpload = file => async dispatch => {
  let coralKey = ''

  if (file) {
    const token = localStorage.getItem('jwtToken')
    try {
      const uploadConfig = await axios.post('/api/upload', { file: file.name })
      coralKey = uploadConfig.data.key
      setAuthToken(false)

      try {
        const checkUpload = await axios.put(uploadConfig.data.url, file, {
          headers: {
            'Content-Type': file.type
          }
        })
        console.log(checkUpload)
      } catch (error) {
        console.log(error)
      }
    } catch (error) {
      console.log(error)
    }

    setAuthToken(token)
  }

  axios
    .post(
      '/api/users/updateshareimage',
      {
        shareImage: coralKey
      }
      // coralData
    )
    .then(res => {
      axios
        .post('/api/upload/deletefile', res.data)
        .then(res2 => console.log(res2))
        .catch(err => console.log(err))
    })
    // .then(res => {
    //   // console.log('Update Success!' + res.data)
    //   // dispatch(getCoral(res.data))
    //   // if (res.data) {
    //   dispatch(myCoral())
    //   // }

    //   // history.push(`/profile/${profileId}/details/corals`);
    // })
    .catch(
      err => console.log(err)
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data

      // })
    )
}

//add recent photo
export const addRecentImage = (imageData, type) => async dispatch => {
  dispatch(setCoralLoading())
  let coralKey = ''

  const token = localStorage.getItem('jwtToken')
  try {
    const uploadConfig = await axios.post('/api/upload', {
      file: imageData.file.name
    })
    coralKey = uploadConfig.data.key
    setAuthToken(false)

    try {
      const checkUpload = await axios.put(
        uploadConfig.data.url,
        imageData.file,
        {
          headers: {
            'Content-Type': imageData.file.type
          }
        }
      )
      console.log(checkUpload)
    } catch (error) {
      console.log(error)
    }
  } catch (error) {
    console.log(error)
  }

  setAuthToken(token)

  if (type === 'addRecent') {
    axios
      .post('/api/coral/addrecentimage', {
        coralId: imageData.coralId,
        coralImage: coralKey
      })
      .then(res => {
        if (res) {
          dispatch(getCoral(imageData.coralId))
          dispatch(clearCoralLoading())
        }
      })
      .catch(err => console.log(err))
  } else {
    axios
      .post('/api/coral/replaceoriginalimage', {
        coralId: imageData.coralId,
        coralImage: coralKey
      })
      .then(res => {
        if (res) {
          console.log(res.data)

          axios.post('/api/upload/deletefile', { key: res.data }).then(res => {
            console.log('success image removed', res)
          })

          dispatch(getCoral(imageData.coralId))
          dispatch(clearCoralLoading())
        }
      })
      .catch(err => console.log(err))
  }
}

//create Comment
export const createComment = commentData => async dispatch => {
  axios
    .post('/api/coral/comment', commentData)
    .then(res => {
      // console.log('Update Success!' + res.data)
      // dispatch({
      //   // type: GET_PROFILE,
      //   type: GET_CORAL,
      //   payload: res.data
      // });
      console.log(res.data)
      dispatch(getCoral(res.data))
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    )
}

//add to shopping bag
export const addToShoppingBag = coralid => async dispatch => {
  axios
    .post(`/api/coral/addtoshoppingbag/${coralid}`)
    .then(res => {
      console.log(res)
      // dispatch({
      //   type: GET_CORAL,
      //   payload: res.data
      // });
      // dispatch(getCoralList());
    })
    .catch(
      err => console.log(err)
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // })
    )
}

//new coral request
export const newCoralRequest = (newRequest, id) => async dispatch => {
  axios
    .post(`/api/coral/newrequest/${id}`, newRequest)
    .then(res => {
      dispatch({
        type: GET_CORAL,
        payload: res.data
      })
      dispatch(getCoralList())
    })
    .catch(
      err => console.log(err)
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // })
    )
}

//like coral
export const likeCoral = (coralData, updatedStore) => async dispatch => {
  axios
    .post('/api/coral/like', coralData)
    .then(res => {
      // console.log("Update Success!", res.data);
      dispatch({
        type: GET_CORAL_LIST,
        payload: updatedStore
      })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    )
}

//delete coral
export const deleteCoral = (coralId, imgkey, coralList) => dispatch => {
  let deleteKey = {
    key: imgkey
  }

  console.log(deleteKey, coralId)

  axios.post('/api/upload/deletefile', deleteKey).then(res => {
    console.log(res)
  })

  axios.post('/api/coral/deleteimage', deleteKey).then(res => {
    console.log('success image removed')
  })

  axios.post('/api/coral/delete', { coralId }).then(res => {
    console.log(res)
    console.log('coral deleted')

    let coralIndex = coralList.map(coral => coral._id).indexOf(coralId)

    console.log(coralIndex)

    coralList.splice(coralIndex, 1)

    dispatch({
      type: GET_CORAL_LIST,
      payload: coralList
    })
  })
}

// // Set loading state
export const setCoralLoading = () => {
  return {
    type: CORAL_LOADING
  }
}

// // Set loading state
export const clearCoralLoading = () => {
  return {
    type: CLEAR_CORAL_LOADING
  }
}

import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getWishList } from 'actions/authActions'
import axios from 'axios'

// reactstrap components
import { Button, Container } from 'reactstrap'

import { FacebookShareButton, TwitterShareButton } from 'react-share'

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon
} from 'react-share'

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

class MyWishList extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      user: null,
      errors: {}
    }
  }

  componentDidMount () {
    axios
      .post('/api/users/getuserwishlist', { id: this.props.match.params.id })
      .then(res => {
        console.log(res.data)
        this.setState({ loading: false, user: res.data })
      })
  }

  // deleteCoral (coral) {
  //   axios.post('/api/coral/deletecoral', { id: coral._id }).then(res => {
  //     if (res.data === 'success') {
  //       this.props.myCoral()
  //     }
  //   })
  // }

  removeFromWishList = coral => {
    console.log(coral)
    axios.post('/api/users/removewishlist', coral).then(res => {
      console.log(res.data)
      if (res.data === 'success') {
        this.setState({ loading: true })
        axios
          .post('/api/users/getuserwishlist', {
            id: this.props.match.params.id
          })
          .then(res => {
            console.log(res.data)
            this.setState({ loading: false, user: res.data })
          })
      }
    })
  }

  render () {
    let sortedWishList = []
    if (this.state.user) {
      const { wishList } = this.state.user

      if (wishList) {
        sortedWishList = wishList.sort((a, b) =>
          a.type > b.type
            ? 1
            : a.type === b.type
            ? a.genus > b.genus
              ? 1
              : a.genus === b.genus
              ? a.name > b.name
                ? 1
                : -1
              : -1
            : -1
        )
        console.log(sortedWishList)
      }
    }
    return (
      <div className='login-page'>
        {this.state.user ? (
          <>
            <Helmet>
              <title style={{ textTransform: 'uppercase' }}>
                {this.state.user.username}'s Wish List
              </title>

              <meta
                name='description'
                content={`${this.state.user.username} has ${sortedWishList.length} corals on their wishlist.`}
              />

              <meta
                property='og:image'
                content='https://s3.amazonaws.com/reefconnector/facebooklogopreview.png'
              />
              <meta
                property='og:url'
                content={`https://www.immortalreef.com/userwishlist/${this.props.match.params.id}`}
              />
            </Helmet>

            <Container>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  src={this.state.user.avatar}
                  style={{ margin: 10, borderRadius: 50 }}
                />
                <h4 style={{ textAlign: 'center', marginTop: 10 }}>
                  {this.state.user.username}'s Wish List
                </h4>
              </div>
              <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <a
                  className='text-info'
                  href={`https://community.immortalreef.com/u/${this.state.user.username}/summary`}
                >
                  Contact
                </a>
                {' - '}
                {this.state.user.locality},{' '}
                {this.state.user.administrative_area_level_1_short_name}
              </div>
              <div style={{ textAlign: 'center' }}>
                <Link
                  className='text-info'
                  to={`/membercollection/${this.state.user._id}`}
                >
                  View Collection
                </Link>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  margin: 8
                }}
              >
                <FacebookShareButton
                  style={{ marginRight: 3 }}
                  url={`immortalreef.com/userwishlist/${this.props.match.params.id}`}
                >
                  <FacebookIcon size={28} />
                </FacebookShareButton>
                <TwitterShareButton
                  style={{ marginRight: 3 }}
                  url={`immortalreef.com/userwishlist/${this.props.match.params.id}`}
                >
                  <TwitterIcon size={28} />
                </TwitterShareButton>
              </div>

              {sortedWishList.length > 0 ? (
                <>
                  <Table>
                    <Thead>
                      <Tr style={{ background: '#F2F3F5' }}>
                        <Th className='text-center'>Type</Th>
                        <Th>Genus</Th>
                        <Th>Species</Th>
                        <Th>Name</Th>
                        <Th className='text-center'>Color</Th>
                        {this.props.auth.isAuthenticated &&
                        this.props.auth.user.id ===
                          this.props.match.params.id ? (
                          <Th className='text-center'>Action</Th>
                        ) : null}

                        {/* <Th className='text-center'>Actions</Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sortedWishList.map(coral => (
                        <Tr
                          style={{
                            borderBottom: '1px solid lightgrey'
                          }}
                        >
                          <Td className='text-center'>{coral.type}</Td>
                          <Td>{coral.genus}</Td>
                          {coral.species ? (
                            <Td>
                              <Link
                                to={`/species/${coral.species.toLowerCase()}`}
                                className='text-info'
                              >
                                {coral.species}
                              </Link>
                            </Td>
                          ) : (
                            <Td>-</Td>
                          )}
                          <Td>
                            {coral.name !== 'zzz' ? (
                              <Link to={`/coralnames/${coral.nameId}`}>
                                <span
                                  style={{ textTransform: 'capitalize' }}
                                  className='text-info'
                                >
                                  {coral.name}
                                </span>
                              </Link>
                            ) : (
                              <>
                                <span>-</span>
                              </>
                            )}
                          </Td>
                          {coral.color ? (
                            <Td>
                              <Link
                                to={`/coralcolorshow/${coral.color}/${coral.genus}`}
                              >
                                <div
                                  style={{
                                    background: `#${coral.color}`,
                                    height: 20,
                                    width: 20,
                                    // textAlign: 'center',
                                    borderRadius: 5,
                                    margin: 'auto'
                                  }}
                                />
                              </Link>
                            </Td>
                          ) : (
                            <Td className='text-center'>-</Td>
                          )}
                          {this.props.auth.isAuthenticated &&
                          this.props.auth.user.id ===
                            this.props.match.params.id ? (
                            <Td className='text-center' style={{ padding: 5 }}>
                              {` `}
                              <Button
                                outline
                                className='btn-icon'
                                color='danger'
                                size='sm'
                                style={{ borderRadius: 5, margin: 5 }}
                                onClick={() => {
                                  this.removeFromWishList({
                                    type: coral.type,
                                    genus: coral.genus,
                                    nameId: coral.nameId,
                                    name: coral.name,
                                    color: coral.color
                                  })
                                }}
                              >
                                <i
                                  className='fa fa-trash-o'
                                  style={{ fontSize: 16 }}
                                />
                              </Button>
                            </Td>
                          ) : null}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </>
              ) : (
                <div>No items on wishlist.</div>
              )}
            </Container>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    )
  }
}

MyWishList.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral,
  errors: state.errors
})

export default connect(mapStateToProps, { getWishList })(MyWishList)

import React from 'react'
import { connect } from 'react-redux'
// import styles from "./AllCoral.module.css";
import PropTypes from 'prop-types'
import Select from 'react-select'
import Coral from 'variables/coral.jsx'

import { getCoralList, saveSearch, setPage } from 'actions/coralActions'
import colors from 'variables/colorFamilies.jsx'
import ColorCheckBox from 'components/ColorCheckBox/ColorCheckBox.js'
import isEmpty from 'validation/is-empty.js'

// reactstrap components
import {
  Button,
  Badge,
  ListGroup,
  ListGroupItem,
  InputGroup,
  InputGroupText,
  Input
} from 'reactstrap'

var zipcodes = require('zipcodes')

function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

//capitalize_Words
function capitalizeWords (str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
// console.log(capitalize_Words('js string exercises'));

class SearchItems extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      type: '',
      genus: '',
      species: '',
      keyword: '',
      zipcode: '',
      distance: '',
      // minPricePlaceHolder: "",
      // maxPricePlaceholder: "",
      maxPrice: '',
      minPrice: '',
      postalCodeError: '',
      // mountSearch: false,

      defaultType: '',
      defaultGenus: '',
      defaultSpecies: '',
      defaultDistance: '',
      shipping: false,
      checked: false,
      primaryHues: [],
      // secondaryHues: null,
      primaryHues2: [],
      // secondaryHues2: null,
      primaryHues3: [],
      // secondaryHues3: null,
      status: 'available'
      // typeTest: { value: "", label: "Coral Type" }
    }
  }

  componentDidMount () {
    if (this.props.coral.searchCriteria) {
      const search = this.props.coral.searchCriteria
      this.setState({ ...search })
    } else {
      this.submitSearch(1)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.updateSearch === true) {
      if (this.props.coral.page) {
        this.submitSearch(this.props.coral.page)
      } else {
        this.submitSearch(1)
      }
      this.props.clearUpdate()
    }

    const search = nextProps.coral.searchCriteria
    if (search !== this.props.coral.searchCriteria) {
      let sDefaults = {}
      if (search) {
        {
          search.type ? (sDefaults.type = search.type) : (sDefaults.type = '')
          search.genus
            ? (sDefaults.genus = search.genus)
            : (sDefaults.genus = '')
          search.species
            ? (sDefaults.species = search.species)
            : (sDefaults.species = '')
          search.distance
            ? (sDefaults.distance = search.distance)
            : (sDefaults.distance = '')
        }
        this.setState({
          defaultType: sDefaults.type,
          defaultGenus: sDefaults.genus,
          defaultSpecies: sDefaults.species,
          defaultDistance: sDefaults.distance
        })
      } else {
        this.setState({
          defaultType: '',
          defaultGenus: '',
          defaultSpecies: '',
          defaultDistance: ''
        })
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // let secondaryHues = [];
    // let secondaryHues2 = [];
    // let secondaryHues3 = [];
    // if (prevState.primaryHues !== this.state.primaryHues) {
    //   this.state.primaryHues.forEach(hue => {
    //     let hueIndex = colors.map(fam => fam.hue).indexOf(hue);
    //     secondaryHues = secondaryHues.concat(colors[hueIndex].subHues);
    //   });
    //   console.log(secondaryHues);
    //   if (secondaryHues.length === 0) {
    //     this.setState({ secondaryHues: null });
    //   } else {
    //     this.setState({ secondaryHues: secondaryHues });
    //   }
    // }

    // if (prevState.primaryHues2 !== this.state.primaryHues2) {
    //   this.state.primaryHues2.forEach(hue => {
    //     let hueIndex = colors.map(fam => fam.hue).indexOf(hue);
    //     secondaryHues2 = secondaryHues2.concat(colors[hueIndex].subHues);
    //   });
    //   console.log(secondaryHues2);
    //   if (secondaryHues2.length === 0) {
    //     this.setState({ secondaryHues2: null });
    //   } else {
    //     this.setState({ secondaryHues2: secondaryHues2 });
    //   }
    // }

    // if (prevState.primaryHues3 !== this.state.primaryHues3) {
    //   this.state.primaryHues3.forEach(hue => {
    //     let hueIndex = colors.map(fam => fam.hue).indexOf(hue);
    //     secondaryHues3 = secondaryHues3.concat(colors[hueIndex].subHues);
    //   });
    //   console.log(secondaryHues3);
    //   if (secondaryHues3.length === 0) {
    //     this.setState({ secondaryHues3: null });
    //   } else {
    //     this.setState({ secondaryHues3: secondaryHues3 });
    //   }
    // }

    let pass = false
    if (this.props.coral.searchCriteria) {
      const search = this.props.coral.searchCriteria
      if (
        (search.minPrice || search.maxPrice || search.keyword) &&
        ((prevState.keyword !== this.state.keyword &&
          search.keyword === this.state.keyword) ||
          (prevState.minPrice !== this.state.minPrice &&
            search.minPrice === this.state.minPrice) ||
          (prevState.maxPrice !== this.state.maxPrice &&
            search.maxPrice === this.state.maxPrice))
      ) {
        pass = true
        // this.props.setPage(1);
        this.submitSearch(1)
      }
    }

    if (
      pass === false &&
      (prevState.status !== this.state.status ||
        prevState.type !== this.state.type ||
        prevState.genus !== this.state.genus ||
        prevState.species !== this.state.species ||
        prevState.distance !== this.state.distance ||
        prevState.type !== this.state.type ||
        prevState.genus !== this.state.genus ||
        prevState.species !== this.state.species ||
        prevState.shipping !== this.state.shipping ||
        prevState.primaryHues !== this.state.primaryHues ||
        prevState.primaryHues2 !== this.state.primaryHues2 ||
        prevState.primaryHues3 !== this.state.primaryHues3 ||
        // prevState.secondaryHues !== this.state.secondaryHues ||
        // prevState.secondaryHues2 !== this.state.secondaryHues2 ||
        // prevState.secondaryHues3 !== this.state.secondaryHues3 ||
        (prevState.keyword !== this.state.keyword &&
          this.state.keyword === '') ||
        (prevState.minPrice !== this.state.minPrice &&
          this.state.minPrice === '') ||
        (prevState.maxPrice !== this.state.maxPrice &&
          this.state.maxPrice === '') ||
        prevProps.coral.sort !== this.props.coral.sort)
    ) {
      pass = true
      // this.props.setPage(1);
      this.submitSearch(1)
    }

    if (pass === false && prevProps.coral.page !== this.props.coral.page) {
      this.submitSearch(this.props.coral.page)
    }
  }

  checkState = () => {
    console.log(this.state)
  }

  onChange = e => {
    if (e.target.name === 'zipcode') {
      e.target.value = e.target.value.toUpperCase()
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  generateCriteria = () => {
    let criteria = {}
    if (
      (this.state.zipcode || this.state.distance) &&
      this.state.zipcode !== '' &&
      this.state.distance !== 'any'
    ) {
      let zipInfo = zipcodes.lookup(this.state.zipcode)

      //validate zipcode
      if (zipInfo === undefined) {
        this.setState({
          postalCodeError: 'Postal code is not a valid US or Canada Postal Code'
        })
        return 'invalid search'
      }

      if (this.state.distance === 'any') {
        criteria.location = ''
      } else {
        criteria.location = [zipInfo.longitude, zipInfo.latitude]
      }

      criteria.distance = this.state.distance
      criteria.zipcode = this.state.zipcode
    }

    if (this.state.keyword) {
      criteria.keyword = this.state.keyword
    }

    if (this.state.type) {
      criteria.type = this.state.type
    }

    if (this.state.genus) {
      criteria.genus = this.state.genus
    }

    if (this.state.species) {
      criteria.species = this.state.species
    }

    if (this.state.shipping) {
      criteria.shipping = this.state.shipping
    }

    if (this.state.minPrice || this.state.maxPrice) {
      criteria.minPrice = this.state.minPrice
      criteria.maxPrice = this.state.maxPrice
    }

    if (this.state.primaryHues) {
      // criteria.extracted150Hex = this.state.primaryHues;
      criteria.primaryHues = this.state.primaryHues
    }

    if (this.state.primaryHues2) {
      // criteria.extracted250Hex = this.state.primaryHues2;
      criteria.primaryHues2 = this.state.primaryHues2
    }

    if (this.state.primaryHues3) {
      // criteria.extracted350Hex = this.state.primaryHues3;
      criteria.primaryHues3 = this.state.primaryHues3
    }

    if (this.state.keyword) {
      criteria.keyword = [
        this.state.keyword,
        capitalizeFirstLetter(this.state.keyword),
        capitalizeWords(this.state.keyword),
        this.state.keyword.toLowerCase(),
        this.state.keyword.toUpperCase()
      ]
    }

    if (this.state.status) {
      criteria.status = this.state.status
    }

    return criteria
  }

  closeTag = type => {
    if (type === 'keyword') {
      this.setState({ keyword: '' })
    } else if (type === 'location') {
      this.setState({ zipcode: '', distance: 'any' })
    } else if (type === 'type') {
      this.setState({ type: '', genus: '', species: '' })
    } else if (type === 'genus') {
      this.setState({ genus: '', species: '' })
    } else if (type === 'species') {
      this.setState({ species: '' })
    } else if (type === 'price') {
      this.setState({ minPrice: '', maxPrice: '' })
    } else if (type === 'extracted150Hex') {
      this.setState({ primaryHues: [] })
    } else if (type === 'extracted250Hex') {
      this.setState({ primaryHues2: [] })
    } else if (type === 'extracted350Hex') {
      this.setState({ primaryHues3: [] })
    } else if (type === 'shipping') {
      this.setState({ shipping: false })
    }
  }

  submitSearch = page => {
    let criteria = this.generateCriteria()

    if (criteria === 'invalid search') {
      return console.log('search invalid')
    }

    if (page) {
      if (page !== this.props.coral.page) {
        this.props.setPage(page)
      }

      this.props.getCoralList(criteria, this.props.coral.sort, page)
    }

    if (this.state.keyword) {
      criteria = { ...criteria, keyword: this.state.keyword }
    }

    this.props.saveSearch(criteria)
    this.setState({
      postalCodeError: ''
    })
  }

  updateColor = (color, action, abundance) => {
    if (!action) {
      if (abundance === 'most') {
        this.setState({ primaryHues: [color] })
        // this.setState({ primaryHues: [...this.state.primaryHues, color] });
      }
      if (abundance === '2nd') {
        this.setState({ primaryHues2: [color] })
        // this.setState({ primaryHues2: [...this.state.primaryHues2, color] });
      }
      if (abundance === '3rd') {
        this.setState({ primaryHues3: [color] })
        // this.setState({ primaryHues3: [...this.state.primaryHues3, color] });
      }
    } else {
      //slice from primary array
      if (abundance === 'most') {
        let hueIndex = this.state.primaryHues.indexOf(color)
        this.setState(prevState => ({
          primaryHues: prevState.primaryHues.filter((_, i) => i !== hueIndex)
        }))
      }
      if (abundance === '2nd') {
        let hueIndex1 = this.state.primaryHues2.indexOf(color)
        this.setState(prevState => ({
          primaryHues2: prevState.primaryHues2.filter((_, i) => i !== hueIndex1)
        }))
      }
      if (abundance === '3rd') {
        let hueIndex2 = this.state.primaryHues3.indexOf(color)
        this.setState(prevState => ({
          primaryHues3: prevState.primaryHues3.filter((_, i) => i !== hueIndex2)
        }))
      }
    }
    // console.log(color, action, this.state.primaryHues);
  }

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      // console.log('do validate');
      this.submitSearch(1)
    }
  }

  render () {
    // const { searchCriteria } = this.props.coral;
    // console.log(this.state.primaryHues, this.props.coral.searchCriteria);

    let genusOptions = [
      {
        value: '',
        label: "Genus (Select 'Coral Type' first)",
        isDisabled: true
      }
    ]
    let speciesOptions = [
      {
        value: '',
        label: "Species (Select 'Genus' first)",
        isDisabled: true
      }
    ]

    let typeOptions = [
      { value: 'SPS', label: 'SPS' },
      { value: 'LPS', label: 'LPS' },
      { value: 'Soft Coral', label: 'Soft Coral' },
      { value: 'Chalice', label: 'Chalice' },
      { value: 'Gorgonian', label: 'Gorgonian' },
      { value: 'Mushroom', label: 'Mushroom' },
      { value: 'Zoa-Paly', label: 'Zoa/Paly' },
      {
        value: 'Anemone',
        label: 'Anemone (Non-Coral Exception)'
      }
      // { value: "Other", label: "Other" },
    ]

    if (this.state.type !== '') {
      let typeIndex = Coral.map(coral => coral.type).indexOf(this.state.type)
      // console.log(typeIndex);
      genusOptions = [
        {
          value: '',
          label: `(${Coral[typeIndex].type}) Genus`,
          isDisabled: true
        }
      ]

      Coral[typeIndex].genus.forEach(genus => {
        let genusPush = {
          value: genus.name,
          label: genus.name
        }
        genusOptions.push(genusPush)
      })

      if (
        genusOptions.some(option => option.value === this.state.genus) &&
        this.state.genus !== ''
      ) {
        let index = Coral[typeIndex].genus
          .map(genus => genus.name)
          .indexOf(this.state.genus)
        speciesOptions = [
          {
            value: '',
            label: `(${this.state.genus}) Species`,
            isDisabled: true
          }
        ]
        Coral[typeIndex].genus[index].species.forEach(species => {
          let speciesPush = {
            value: species,
            label: species
          }
          speciesOptions.push(speciesPush)
        })
        // console.log(speciesOptions);
      }
    }

    return (
      <>
        <div
          style={{
            font: 'inheret',
            textAlign: 'center',

            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 1100,
            width: '20em'
          }}
        >
          <div
            style={{
              font: 'inheret',
              textAlign: 'center',
              backgroundColor: 'white',
              height: 53,
              borderBottom: '1px solid lightgrey',
              boxShadow: `${
                !isEmpty(this.props.coral.searchCriteria)
                  ? ''
                  : '0px 0px 4px lightgrey'
              }`
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 16,
                right: 25
              }}
            >
              <p
                style={{
                  letterSpacing: '2px',
                  textTransform: 'uppercase'
                }}
              >
                Coral Search
              </p>
            </div>
          </div>

          {!isEmpty(this.props.coral.searchCriteria) ? (
            <>
              <div
                style={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid lightgrey',
                  boxShadow: '1px 1px 3px lightgrey'
                }}
              >
                <div
                  style={{
                    letterSpacing: '2px'
                  }}
                >
                  <u>Current Filters:</u>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                  }}
                >
                  {this.props.coral.searchCriteria.keyword ? (
                    <Badge
                      style={{
                        padding: 0,
                        paddingLeft: 2,
                        backgroundColor: 'lightgrey',
                        color: 'black',
                        border: 'none'
                      }}
                    >
                      keyword : "{this.props.coral.searchCriteria.keyword}"
                      {'  '}
                      <Button
                        className='btn-round btn-icon btn-sm'
                        color='default'
                        style={{
                          height: 15,
                          width: 15,
                          margin: 0,
                          padding: 0,
                          backgroundColor: 'lightgrey'
                        }}
                        onClick={() => this.closeTag('keyword')}
                      >
                        <span>
                          <i className='fa fa-remove' />
                        </span>
                      </Button>
                    </Badge>
                  ) : null}

                  {this.props.coral.searchCriteria.type ? (
                    <Badge
                      style={{
                        padding: 0,
                        paddingLeft: 2,
                        backgroundColor: 'lightgrey',
                        color: 'black',
                        border: 'none'
                      }}
                    >
                      type : {this.props.coral.searchCriteria.type}
                      {'  '}
                      <Button
                        className='btn-round btn-icon btn-sm'
                        color='default'
                        style={{
                          height: 15,
                          width: 15,
                          margin: 0,
                          padding: 0,
                          backgroundColor: 'lightgrey'
                        }}
                        onClick={() => this.closeTag('type')}
                      >
                        <span>
                          <i className='fa fa-remove' />
                        </span>
                      </Button>
                    </Badge>
                  ) : null}

                  {this.props.coral.searchCriteria.genus ? (
                    <Badge
                      style={{
                        padding: 0,
                        paddingLeft: 2,
                        backgroundColor: 'lightgrey',
                        color: 'black',
                        border: 'none'
                      }}
                    >
                      genus : {this.props.coral.searchCriteria.genus}
                      {'  '}
                      <Button
                        className='btn-round btn-icon btn-sm'
                        color='default'
                        style={{
                          height: 15,
                          width: 15,
                          margin: 0,
                          padding: 0,
                          backgroundColor: 'lightgrey'
                        }}
                        onClick={() => this.closeTag('genus')}
                      >
                        <span>
                          <i className='fa fa-remove' />
                        </span>
                      </Button>
                    </Badge>
                  ) : null}

                  {this.props.coral.searchCriteria.species ? (
                    <Badge
                      style={{
                        padding: 0,
                        paddingLeft: 2,
                        backgroundColor: 'lightgrey',
                        color: 'black',
                        border: 'none'
                      }}
                    >
                      species : {this.props.coral.searchCriteria.species}
                      {'  '}
                      <Button
                        className='btn-round btn-icon btn-sm'
                        color='default'
                        style={{
                          height: 15,
                          width: 15,
                          margin: 0,
                          padding: 0,
                          backgroundColor: 'lightgrey'
                        }}
                        onClick={() => this.closeTag('species')}
                      >
                        <span>
                          <i className='fa fa-remove' />
                        </span>
                      </Button>
                    </Badge>
                  ) : null}

                  {this.props.coral.searchCriteria.shipping ? (
                    <Badge
                      style={{
                        padding: 0,
                        paddingLeft: 2,
                        backgroundColor: 'lightgrey',
                        color: 'black',
                        border: 'none'
                      }}
                    >
                      With Shipping
                      {'  '}
                      <Button
                        className='btn-round btn-icon btn-sm'
                        color='default'
                        style={{
                          height: 15,
                          width: 15,
                          margin: 0,
                          padding: 0,
                          backgroundColor: 'lightgrey'
                        }}
                        onClick={() => this.closeTag('shipping')}
                      >
                        <span>
                          <i className='fa fa-remove' />
                        </span>
                      </Button>
                    </Badge>
                  ) : null}

                  {this.props.coral.searchCriteria.minPrice ||
                  this.props.coral.searchCriteria.maxPrice ? (
                    <Badge
                      style={{
                        padding: 0,
                        paddingLeft: 2,
                        backgroundColor: 'lightgrey',
                        color: 'black',
                        border: 'none'
                      }}
                    >
                      price :
                      {this.props.coral.searchCriteria.minPrice ? (
                        <> ${this.props.coral.searchCriteria.minPrice}</>
                      ) : (
                        <span> $0 </span>
                      )}
                      <span> to </span>
                      {this.props.coral.searchCriteria.maxPrice ? (
                        <> ${this.props.coral.searchCriteria.maxPrice}</>
                      ) : (
                        <span> Any </span>
                      )}
                      {'  '}
                      <Button
                        className='btn-round btn-icon btn-sm'
                        color='default'
                        style={{
                          height: 15,
                          width: 15,
                          margin: 0,
                          padding: 0,
                          backgroundColor: 'lightgrey'
                        }}
                        onClick={() => this.closeTag('price')}
                      >
                        <span>
                          <i className='fa fa-remove' />
                        </span>
                      </Button>
                    </Badge>
                  ) : null}

                  {this.props.coral.searchCriteria.zipcode ? (
                    <Badge
                      style={{
                        padding: 0,
                        paddingLeft: 2,
                        backgroundColor: 'lightgrey',
                        color: 'black',
                        border: 'none'
                      }}
                    >
                      {this.props.coral.searchCriteria.zipcode} :{' '}
                      {this.props.coral.searchCriteria.distance} miles
                      {'  '}
                      <Button
                        className='btn-round btn-icon btn-sm'
                        color='default'
                        style={{
                          height: 15,
                          width: 15,
                          margin: 0,
                          padding: 0,
                          backgroundColor: 'lightgrey'
                        }}
                        onClick={() => this.closeTag('location')}
                      >
                        <span>
                          <i className='fa fa-remove' />
                        </span>
                      </Button>
                    </Badge>
                  ) : null}

                  {/* {this.props.coral.searchCriteria.extracted150Hex ? ( */}
                  {this.props.coral.searchCriteria.primaryHues.length > 0 ? (
                    <Badge
                      style={{
                        padding: 0,
                        paddingLeft: 2,
                        backgroundColor: 'lightgrey',
                        color: 'black',
                        border: 'none'
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'inline-block',
                            alignItem: 'center'
                          }}
                        >
                          Most Abundant Color:
                          <br />
                          {this.props.coral.searchCriteria.primaryHues.map(
                            color => (
                              <div
                                style={{
                                  height: 10,
                                  width: 10,
                                  backgroundColor: `#${color}`,
                                  display: 'inline-block',
                                  margin: 2
                                }}
                              />
                            )
                          )}
                        </div>
                        <Button
                          className='btn-round btn-icon btn-sm'
                          color='default'
                          style={{
                            height: 15,
                            width: 15,
                            display: 'inline-block',
                            margin: 0,
                            alignSelf: 'center',
                            padding: 0,

                            backgroundColor: 'lightgrey'
                          }}
                          onClick={() => this.closeTag('extracted150Hex')}
                        >
                          <span>
                            <i className='fa fa-remove' />
                          </span>
                        </Button>
                      </div>
                    </Badge>
                  ) : null}

                  {this.props.coral.searchCriteria.primaryHues2.length > 0 ? (
                    <Badge
                      style={{
                        padding: 0,
                        paddingLeft: 2,
                        backgroundColor: 'lightgrey',
                        color: 'black',
                        border: 'none'
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block'
                        }}
                      >
                        2nd Abundant Color:
                        <br />
                        {this.props.coral.searchCriteria.primaryHues2.map(
                          color => (
                            <div
                              style={{
                                height: 10,
                                width: 10,
                                backgroundColor: `#${color}`,
                                display: 'inline-block',
                                margin: 2
                              }}
                            />
                          )
                        )}
                      </div>
                      <Button
                        className='btn-round btn-icon btn-sm'
                        color='default'
                        style={{
                          height: 15,
                          width: 15,
                          display: 'inline-block',
                          margin: 0,
                          padding: 0,
                          backgroundColor: 'lightgrey'
                        }}
                        onClick={() => this.closeTag('extracted250Hex')}
                      >
                        <span>
                          <i className='fa fa-remove' />
                        </span>
                      </Button>
                    </Badge>
                  ) : null}

                  {this.props.coral.searchCriteria.primaryHues3.length > 0 ? (
                    <Badge
                      style={{
                        padding: 0,
                        paddingLeft: 2,
                        backgroundColor: 'lightgrey',
                        color: 'black',
                        border: 'none'
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block'
                        }}
                      >
                        3rd Abundant Color:
                        <br />
                        {this.props.coral.searchCriteria.primaryHues3.map(
                          color => (
                            <div
                              style={{
                                height: 10,
                                width: 10,
                                backgroundColor: `#${color}`,
                                display: 'inline-block',
                                margin: 2
                              }}
                            />
                          )
                        )}
                      </div>
                      <Button
                        className='btn-round btn-icon btn-sm'
                        color='default'
                        style={{
                          height: 15,
                          width: 15,
                          display: 'inline-block',
                          margin: 0,
                          padding: 0,
                          backgroundColor: 'lightgrey'
                        }}
                        onClick={() => this.closeTag('extracted350Hex')}
                      >
                        <span>
                          <i className='fa fa-remove' />
                        </span>
                      </Button>
                    </Badge>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>

        {/* <div
            style={{
              textAlign: "right"
            }}
          >
            <Button
              outline
              className="btn-round btn-icon"
              color="primary"
              style={{
                marginTop: 0,
                marginRight: 10
              }}
              onClick={() => this.submitSearch(1)}
            >
              <span>
                <i className="fa fa-search" />
              </span>
            </Button>
          </div> */}

        <ListGroup>
          <ListGroupItem style={{ padding: 5, border: 'none' }}>
            Keyword
            {/* <div
              style={{
                height: "3px"
              }}
            /> */}
            {/* <form onSubmit={() => this.submitSearch(1)}> */}
            <InputGroup
              style={
                {
                  // padding: 5
                  // paddingLeft: "1.2rem",
                  // paddingRight: "1.2rem"
                  // marginTop: 5
                }
              }
            >
              <Input
                placeholder='Keyword'
                name='keyword'
                type='text'
                value={this.state.keyword}
                onChange={this.onChange}
                style={{
                  border: '1px solid rgb(227, 227, 227)'
                }}
                onKeyDown={this.handleKeyDown}
              />
              {this.state.keyword ? (
                <InputGroupText>
                  <a href='#' onClick={() => this.submitSearch(1)}>
                    <div>
                      <i className='fa fa-search' />
                    </div>
                  </a>

                  {/* <button type="submit">
                      <div>
                        <i className="fa fa-search" />
                      </div>
                    </button> */}
                </InputGroupText>
              ) : null}
            </InputGroup>
            {/* </form> */}
          </ListGroupItem>
          <ListGroupItem style={{ padding: 5, border: 'none' }}>
            Identification
            {/* <div
              style={{
                height: "9px"
              }}
            /> */}
            <Select
              // style={{ readOnly: "readonly" }}
              // isClearable={false}
              // isRtl={true}
              // inputProps={{ readOnly: true }}
              isSearchable={false}
              placeholder='Coral Type'
              className='react-select primary'
              classNamePrefix='react-select'
              value={
                this.state.defaultType ? { label: this.state.defaultType } : ''
              }
              onChange={option => {
                this.setState({
                  type: option.value,

                  genus: '',
                  species: ''
                })
              }}
              options={typeOptions}
            />
            <div
              style={{
                height: '9px'
              }}
            />
            <Select
              isSearchable={false}
              className='react-select primary'
              classNamePrefix='react-select'
              // name="singleSelect"
              // value={genusValue}
              // defaultValue={getSelectDefault()}
              value={
                this.state.defaultGenus
                  ? { label: this.state.defaultGenus }
                  : ''
              }
              onChange={value => {
                this.setState({
                  genus: value.value,
                  species: ''
                })
              }}
              options={genusOptions}
              placeholder='Genus'
            />
            <div
              style={{
                height: '9px'
              }}
            />
            <Select
              isSearchable={false}
              className='react-select primary'
              classNamePrefix='react-select'
              name='singleSelect'
              value={
                this.state.defaultSpecies
                  ? { label: this.state.defaultSpecies }
                  : ''
              }
              onChange={value => this.setState({ species: value.value })}
              options={speciesOptions}
              placeholder='Species'
            />
          </ListGroupItem>
          {/* <ListGroupItem
            style={{
              padding: 5,
              border: "none",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="form-check-radio form-check-inline">
                <Label className="form-check-label">
                  <Input
                    type="radio"
                    name="status"
                    // id="size1"
                    value="all"
                    checked={this.state.status === "all"}
                    onClick={this.onChange}
                  />
                  All Coral
                  <span className="form-check-sign"></span>
                </Label>
              </div>
              <div className="form-check-radio form-check-inline">
                <Label className="form-check-label">
                  <Input
                    type="radio"
                    name="status"
                    // id="size2"
                    value="available"
                    checked={this.state.status === "available"}
                    onClick={this.onChange}
                  />
                  For Sale Only
                  <span className="form-check-sign"></span>
                </Label>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div className="form-check">
              <Label check>
                <Input
                  checked={this.state.shipping}
                  // defaultValue="option2"
                  // id="exampleRadios12"
                  type="checkbox"
                  name="shipping"
                  value={
                    // "Other Location (Please provide a full address of the other location.)"
                    this.state.shipping
                  }
                  onChange={() => {
                    this.setState({
                      shipping: !this.state.shipping,
                    });
                  }}
                />
                With Shipping
                <span className="form-check-sign" />
              </Label>
            </div>
          </ListGroupItem> */}
          {/* <button
            onClick={() => {
              console.log(this.state);
            }}
          >
            Check State
          </button> */}
          <ListGroupItem
            style={{
              padding: 5,
              border: 'none'
            }}
          >
            Price
            {/* <div
              style={{
                height: "9px"
              }}
            /> */}
            {/* <InputGroup
              style={{
                paddingLeft: "1.2rem",
                paddingRight: "1.2rem"
              }}
            > */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* <InputGroup
                style={{
                  width: "40%",
                  marginRight: "1em"
                }}
              > */}
              <Input
                placeholder='$ Min'
                name='minPrice'
                type='number'
                value={this.state.minPrice}
                onChange={this.onChange}
                style={{
                  border: '1px solid rgb(227, 227, 227)',
                  width: '45%',
                  marginRight: '0.5em'
                  // width: "30%"
                }}
              />
              {/* </InputGroup> */}
              to
              <Input
                placeholder='$ Max'
                name='maxPrice'
                type='number'
                value={this.state.maxPrice}
                onChange={this.onChange}
                style={{
                  border: '1px solid rgb(227, 227, 227)',
                  width: '45%',
                  marginLeft: '0.5em'
                }}
              />
              {this.state.minPrice || this.state.maxPrice ? (
                <Button
                  className='btn-icon btn-sm'
                  color='info'
                  style={{ margin: 0 }}
                  onClick={() => this.submitSearch(1)}
                >
                  <i className='fa fa-search' />
                </Button>
              ) : null}
            </div>
          </ListGroupItem>
          {/* <ListGroupItem
            style={{
              padding: 5,
              border: "none",
            }}
          >
            Location
            <Input
              placeholder="Zip Code"
              name="zipcode"
              type="text"
              value={this.state.zipcode}
              onChange={this.onChange}
              style={{
                border: "1px solid rgb(227, 227, 227)",
              }}
            />
            <div
              style={{
                color: "red",
              }}
            >
              {this.state.postalCodeError}
            </div>
            <div
              style={{
                height: "9px",
              }}
            />
            <Select
              isSearchable={false}
              className="react-select primary"
              classNamePrefix="react-select"
              name="distance"
              defaultInputValue={this.state.distance}
              // value={this.state.singleSelect}
              value={
                this.state.defaultDistance
                  ? { label: this.state.defaultDistance + " Miles" }
                  : ""
              }
              onChange={(value) => {
                this.setState({
                  distance: value.value,
                });
              }}
              options={[
                // {
                //   value: "",
                //   label: "Search Radius",
                //   isDisabled: true
                // },
                { value: "10", label: "10 Miles" },
                { value: "25", label: "25 Miles" },
                { value: "50", label: "50 Miles" },
                { value: "100", label: "100 Miles" },
                { value: "150", label: "150 Miles" },
                { value: "200", label: "200 Miles" },
                { value: "any", label: "Any Distance" },
                // { value: "Other", label: "Other" }
              ]}
              placeholder="Search Radius"
            />
          </ListGroupItem> */}
          <ListGroupItem
            style={{
              padding: 5,
              border: 'none'
            }}
          >
            Color
            <br />
            <div style={{ textAlign: 'center' }}>Most Abundant</div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginLeft: 20,
                marginRight: 20
              }}
            >
              {colors.map(color => (
                <ColorCheckBox
                  key={`most${color.hue}`}
                  color={color}
                  updateColor={this.updateColor}
                  abundance={'most'}
                  currentColor={
                    this.state.primaryHues.length > 0
                      ? this.state.primaryHues[0]
                      : null
                  }
                />
              ))}
            </div>
            <div style={{ textAlign: 'center' }}>2nd Most Abundant</div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginLeft: 20,
                marginRight: 20
              }}
            >
              {colors.map(color => (
                <ColorCheckBox
                  color={color}
                  updateColor={this.updateColor}
                  abundance={'2nd'}
                  currentColor={
                    this.state.primaryHues.length > 0
                      ? this.state.primaryHues2[0]
                      : null
                  }
                />
              ))}
            </div>
            <div style={{ textAlign: 'center' }}>3rd Most Abundant</div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginLeft: 20,
                marginRight: 20
              }}
            >
              {colors.map(color => (
                <ColorCheckBox
                  color={color}
                  updateColor={this.updateColor}
                  abundance={'3rd'}
                  currentColor={
                    this.state.primaryHues.length > 0
                      ? this.state.primaryHues3[0]
                      : null
                  }
                />
              ))}
            </div>
          </ListGroupItem>
        </ListGroup>
        {/* <button onClick={this.checkState}>Check State</button> */}
      </>
    )
  }
}

SearchItems.propTypes = {
  profile: PropTypes.object.isRequired,
  getCoralList: PropTypes.func.isRequired,
  saveSearch: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral
})

export default connect(mapStateToProps, { getCoralList, saveSearch, setPage })(
  SearchItems
)

import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { memberCoral, myCoral } from 'actions/coralActions'
import { getMyTradesCompleted } from 'actions/authActions'
import { getUser } from 'actions/communityActions'
import axios from 'axios'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

// reactstrap components
import { Button, Container, Input, Label, FormGroup } from 'reactstrap'

class TradeSales extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      errors: {}
    }
  }

  componentDidMount () {
    if (!this.props.auth.myTradesCompleted) {
      this.props.getMyTradesCompleted()
    }
  }

  // addCoral = coral => {
  //   console.log('added coral')
  //   this.setState({ addedCoral: [...this.state.addedCoral, coral] })
  // }

  // removeCoral = coralId => {
  //   console.log('remove coral')
  //   var array = [...this.state.addedCoral] // make a separate copy of the array
  //   var index = array.map(coral => coral._id).indexOf(coralId)
  //   if (index !== -1) {
  //     array.splice(index, 1)
  //     this.setState({ addedCoral: array })
  //   }
  //   console.log(this.state.addedCoral)
  // }

  // removeFromCart = coralId => {
  //   axios
  //     .post('/api/users/removefromcart', {
  //       userId: this.props.match.params.id,
  //       coralId: coralId
  //     })
  //     .then(res => {
  //       console.log(res.data)
  //       this.props.getMyCart()
  //     })
  // }

  getDate = tradeDate => {
    let date = new Date(tradeDate)
    let m = date.getMonth() + 1
    m = m.toString()
    let d = date.getDate().toString()
    let y = date.getFullYear().toString()
    console.log(y)
    return m + '-' + d + '-' + y
  }

  render () {
    const { memberCoral } = this.props.community
    const { myTradesCompleted } = this.props.auth
    const { myCoral } = this.props.coral
    const { member } = this.props.community
    const { localCart } = this.state
    let cartIndex = -1

    let wishListMatch = []
    let wishListNoMatch = []

    if (myCoral && member) {
      myCoral.map(coral => {
        if (
          coral.name &&
          member.wishList
            .map(wish => wish.nameId)
            .some(id => id === coral.name._id)
        ) {
          wishListMatch.push(coral)
        } else if (
          member.wishList
            .map(wish => wish.genus + wish.color)
            .some(id => id === coral.genus + coral.extracted150Hex)
        ) {
          wishListMatch.push(coral)
        } else {
          wishListNoMatch.push(coral)
        }
      })
    }
    // console.log(wishListMatch, wishListNoMatch)
    // console.log(this.state.localCart)
    return (
      <div className='login-page'>
        {myTradesCompleted ? (
          <>
            <Container>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <h4 style={{ textAlign: 'center', marginTop: 10 }}>
                  Trades Completed
                </h4>
                {/* <img
                  src={member.avatar}
                  style={{ margin: 10, borderRadius: 50 }}
                />
                <h4 style={{ textAlign: 'center', marginTop: 10 }}>
                  {member.username}
                </h4> */}
              </div>

              <>
                <div
                  style={{
                    border: '1px solid lightgrey',
                    borderRadius: 5,
                    padding: 10
                  }}
                >
                  <div
                  // style={{
                  //   border: '1px solid lightgrey',
                  //   borderRadius: 5,
                  //   padding: 10
                  // }}
                  >
                    {/* <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 5
                      }}
                    >
                      <img
                        src={member.avatar}
                        style={{ marginRight: 10, borderRadius: 50 }}
                      />
                      <h5 style={{ textAlign: 'center' }}>
                        {member.username} Gives
                      </h5>
                    </div> */}
                    {myTradesCompleted.length > 0 ? (
                      <Table id='trademember'>
                        <Thead>
                          <Tr style={{ background: '#F2F3F5' }}>
                            <Th className='text-center'>Trader</Th>
                            <Th className='text-center'> Cash</Th>
                            <Th className='text-center'>Corals Given</Th>
                            <Th className='text-center'>Corals Received</Th>
                            <Th className='text-center'>Status</Th>
                            {/* <Th className='text-center'>Message Count</Th> */}

                            <Th className='text-center'>Date</Th>
                            <Th className='text-center'>Action</Th>
                            {/* <Th className='text-center'>Trade</Th>
                          <Th className='text-center'>Cart</Th> */}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {myTradesCompleted.reverse().map(trade => (
                            <>
                              {trade.seller && trade.buyer ? (
                                <Tr>
                                  <Td className='text-center'>
                                    <img
                                      style={{
                                        height: 40,
                                        width: 40,
                                        borderRadius: 50,
                                        marginRight: 10
                                      }}
                                      src={
                                        trade.buyer._id ===
                                        this.props.auth.user.id
                                          ? `${trade.seller.avatar}`
                                          : `${trade.buyer.avatar}`
                                      }
                                    />
                                    {trade.buyer._id ===
                                    this.props.auth.user.id ? (
                                      <>{trade.seller.username}</>
                                    ) : (
                                      <>{trade.buyer.username}</>
                                    )}
                                  </Td>
                                  <Td className='text-center'>
                                    ${trade.moneyOffer}
                                  </Td>
                                  <Td className='text-center'>
                                    {trade.sellerCoral.length}
                                  </Td>

                                  <Td className='text-center'>
                                    {trade.buyerCoral.length}
                                    {/* {coral.name ? (
                                <Link to={`/coralnames/${coral.name._id}`}>
                                  <span
                                    style={{ textTransform: 'capitalize' }}
                                    className='text-info'
                                  >
                                    {coral.name.name}
                                  </span>
                                </Link>
                              ) : (
                                <>
                                  {coral.unverifiedName ? (
                                    <span>
                                      <span
                                        style={{
                                          textTransform: 'capitalize'
                                        }}
                                      >
                                        {coral.unverifiedName}
                                      </span>{' '}
                                    </span>
                                  ) : (
                                    <span>N/A</span>
                                  )}
                                </>
                              )} */}
                                  </Td>
                                  {/* {coral.extracted150Hex ? (
                              <Td className='text-center'>
                                <Link
                                  to={`/coralcolorshow/${coral.extracted150Hex}/${coral.genus}`}
                                >
                                  <div
                                    style={{
                                      background: `#${coral.extracted150Hex}`,
                                      height: 20,
                                      width: 20,
                                      textAlign: 'center',
                                      borderRadius: 5,
                                      margin: 'auto'
                                    }}
                                  />
                                </Link>
                              </Td>
                            ) : (
                              <Td className='text-center'>N/A</Td>
                            )} */}
                                  {/* <Td className='text-center'>
                              {trade.messages.length}
                            </Td> */}

                                  <Td className='text-center'>
                                    {trade.tradeStatus === 'complete' ? (
                                      <span
                                        className='text-info'
                                        style={{ textTransform: 'capitalize' }}
                                      >
                                        {trade.tradeStatus}
                                      </span>
                                    ) : null}
                                  </Td>
                                  <Td className='text-center'>
                                    {this.getDate(trade.date)}
                                    {/* {coral.forSale ? (
                                <span>${coral.price}</span>
                              ) : (
                                <i className='fa fa-times' />
                              )} */}
                                  </Td>
                                  {/* <Td className='text-center'>
                              {coral.forTrade ? (
                                <span>
                                  <i className='fa fa-check text-success' />
                                </span>
                              ) : (
                                <i className='fa fa-times' />
                              )}
                            </Td> */}

                                  <Td className='text-center'>
                                    <Link to={`/tradesummary/${trade._id}`}>
                                      <Button
                                        outline
                                        color='info'
                                        // onClick={() => this.addToCart(coral)}
                                      >
                                        View
                                      </Button>
                                    </Link>
                                    {/* {coral.forTrade || coral.forSale ? (
                                <>
                                  {localCart &&
                                  localCart.coral
                                    .map(coral => coral._id)
                                    .some(id => id === coral._id) ? (
                                    <Button
                                      outline
                                      color='danger'
                                      onClick={() =>
                                        this.removeFromCart(coral._id)
                                      }
                                    >
                                      <i className='fa fa-trash-o' />
                                    </Button>
                                  ) : (
                                    <Button
                                      outline
                                      color='success'
                                      onClick={() => this.addToCart(coral)}
                                    >
                                      Add
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <i className='fa fa-times' />
                              )} */}
                                  </Td>
                                </Tr>
                              ) : null}
                            </>
                          ))}
                        </Tbody>
                      </Table>
                    ) : (
                      <div className='text-center'>No completed trades.</div>
                    )}
                  </div>
                </div>
              </>
            </Container>
          </>
        ) : (
          <h4>Loading...</h4>
        )}
      </div>
    )
  }
}

TradeSales.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral,
  community: state.community,
  errors: state.errors
})

export default connect(mapStateToProps, {
  memberCoral,
  getMyTradesCompleted,
  getUser,
  myCoral
})(TradeSales)

const coral = [
  {
    type: 'SPS',
    genus: [
      // {
      //   name: "Unknown",
      //   species: ["Unknown"],
      //   searchNames: [],
      // },
      {
        name: 'Acropora',
        species: [
          'Unknown',
          'Anthocercis',
          'Cervicornis',
          // "Echinata", // this is a problem for pulling because there is also an echinata for acans
          'Humilis',
          'Horrida',
          'Lovelli',
          'Microclados',
          'Millepora',
          'Nasuta',
          'Prostrata',
          'Selago',
          'Speciosa',
          'Suharsonoi',
          'Tenuis',
          'Valida',
          'Other'
        ],
        searchNames: ['acropora', 'acro']
      },
      {
        name: 'Anacropora',
        species: ['Unknown', 'Forbesi', 'Other'],
        searchNames: ['anacropora', 'anacro']
      },
      {
        name: 'Astreopora',
        species: [
          'Unknown',
          'Expansa',
          'Montiporina',
          'Moretonensis',
          'Randalli',
          'Other'
        ],
        searchNames: ['astreopora']
      },
      {
        name: 'Cyphastrea',
        species: ['Unknown', 'Decadia', 'Ocellina', 'Serailia', 'Other'],
        searchNames: ['cyphastrea']
      },
      {
        name: 'Hydnophora',
        species: ['Unknown', 'Rigida', 'Other'],
        searchNames: ['hydnophora']
      },
      {
        name: 'Leptoseris',
        species: ['Unknown', 'Other'],
        searchNames: ['leptoseris', 'lepto', 'leptosarsis']
      },
      // {
      //   name: "Millepora",
      //   species: ["Unknown", "Other"],
      //   searchNames: ["Millepora"],
      // },
      {
        name: 'Montipora',
        species: [
          'Unknown',
          'Capricornus',
          'Digitata',
          'Foliosa',
          'Spongodes',
          'Undata',
          'Other'
        ],
        searchNames: ['montipora', 'monti']
      },
      // {
      //   name: "Mycedium",
      //   species: ["Unknown", "Other"],
      //   searchNames: ["mycedium"],
      // },
      {
        name: 'Pavona',
        species: ['Unknown', 'Other'],
        searchNames: ['pavona']
      },
      {
        name: 'Pocillopora',
        species: ['Unknown', 'Other'],
        searchNames: ['pocillopora', 'poci', 'pocci']
      },
      {
        name: 'Porites',
        species: ['Unknown', 'Other'],
        searchNames: ['porites', 'porite']
      },
      {
        name: 'Psammocora',
        species: ['Unknown', 'Other'],
        searchNames: ['psammocora', 'psammy', 'psamm', 'psam']
      },
      {
        name: 'Seriatopora',
        species: ['Unknown', 'Hystrix', 'Other'],
        searchNames: ['seriatopora', 'seriato']
      },
      {
        name: 'Styloconiella',
        species: ['Unknown', 'Other'],
        searchNames: ['styloconiella']
      },
      {
        name: 'Stylophora',
        species: ['Unknown', 'Other'],
        searchNames: ['stylophora', 'stylo']
      },
      {
        name: 'Turbinaria',
        species: [
          'Unknown',
          'Heronensis',
          'Mesenterina',
          'Reniformis',
          'Other'
        ],
        searchNames: ['turbinaria']
      }
      // {
      //   name: "Other",
      //   species: ["Unknown", "Other"],
      //   searchNames: [],
      // },
    ]
  },
  {
    type: 'LPS',
    genus: [
      // {
      //   name: "Unknown",
      //   species: ["Unknown"],
      //   searchNames: [],
      // },
      {
        name: 'Acanthastrea',
        species: [
          'Unknown',
          'Bowerbanki',
          'Echinata', // this is a problem for pulling
          'Lordhowensis',
          'Other'
        ],
        searchNames: ['acanthastrea', 'acan']
      },
      {
        name: 'Acanthophyllia',
        species: ['Unknown', 'Deshayesiana', 'Other'],
        searchNames: ['acanthophyllia']
      },
      {
        name: 'Alveopora',
        species: ['Unknown', 'Allingi', 'Catalai', 'Gigas', 'Other'],
        searchNames: ['alveopora', 'alveo']
      },
      {
        name: 'Blastomussa',
        species: ['Unknown', 'Merleti', 'Wellsi', 'Other'],
        searchNames: ['blasto', 'blastomusa', 'blasta']
      },
      {
        name: 'Caulastrea',
        species: ['Unknown', 'Curvata', 'Echinulata', 'Furcata', 'Other'],
        searchNames: ['caulastrea']
      },
      {
        name: 'Catalaphyllia',
        species: ['Unknown', 'Jardinei', 'Other'],
        searchNames: ['catalaphyllia']
      },
      {
        name: 'Cycloseris',
        species: ['Unknown', 'Other'],
        searchNames: []
      },
      {
        name: 'Cynarina',
        species: ['Unknown', 'Lacrymalis', 'Other'],
        searchNames: ['cynarina']
      },
      {
        name: 'Diaseris',
        species: ['Unknown', 'Other'],
        searchNames: []
      },
      {
        name: 'Duncanopsammia',
        species: ['Unknown', 'Other'],
        searchNames: ['duncanopsammia', 'duncan']
      },
      {
        name: 'Echinophyllia',
        species: ['Unknown', 'Other'],
        searchNames: ['echinophyllia']
      },
      {
        name: 'Euphyllia',
        species: [
          'Unknown',
          'Ancona',
          'Cristata',
          'Divisa',
          'Fimbriata',
          'Glabrescens',
          'Paraancora',
          'Other'
        ],
        searchNames: ['euphyllia']
      },
      // {
      //   name: 'Echinopora',
      //   species: ['Unknown', 'Other'],
      //   searchNames: ['echinopora']
      // },
      {
        name: 'Favia',
        species: ['Unknown', 'Other'],
        searchNames: ['favia']
      },
      {
        name: 'Favites',
        species: ['Unknown', 'Other'],
        searchNames: ['favites', 'favite']
      },
      {
        name: 'Fimbriaphyllia',
        species: ['Unknown', 'Other'],
        searchNames: ['fimbriaphyllia']
      },
      {
        name: 'Fungia',
        species: ['Unknown', 'Other'],
        searchNames: ['fungia']
      },
      {
        name: 'Galaxea',
        species: ['Unknown', 'Fascicularis', 'Other'],
        searchNames: ['galaxea', 'galaxia']
      },
      {
        name: 'Goneastrea',
        species: ['Unknown', 'Other'],
        searchNames: ['goneastrea']
      },
      {
        name: 'Goniopora',
        species: ['Unknown', 'Lobata', 'Somaliensis', 'Stokesi', 'Other'],
        searchNames: ['goniopora', 'goni ', 'gonipora']
      },
      {
        name: 'Heliofungia',
        species: ['Unknown', 'Actiniformis', 'Other'],
        searchNames: ['heliofungia', 'helio']
      },
      {
        name: 'Heliopora',
        species: ['Unknown', 'Other'],
        searchNames: ['heliopora']
      },
      {
        name: 'Hydnophora',
        species: ['Unknown', 'Cochlea', 'Other'],
        searchNames: ['hydnophora', 'hydno']
      },
      {
        name: 'Heteropsammia',
        species: ['Unknown', 'Cochlea', 'Other'],
        searchNames: ['heteropsammia']
      },
      {
        name: 'Leptastrea',
        species: ['Unknown', 'Other'],
        searchNames: ['lepta', 'leptastea']
      },
      {
        name: 'Lobophyllia',
        species: ['Unknown', 'Hemprichii', 'Other'],
        searchNames: ['lobophyllia', 'lobo']
      },
      {
        name: 'Micromussa',
        species: ['Unknown', 'Lordhowensis', 'Other'],
        searchNames: ['micromussa', 'lord', 'micro']
      },
      {
        name: 'Nemenzophyllia',
        species: ['Unknown', 'Turbida', 'Other'],
        searchNames: ['nemenzophyllia']
      },
      {
        name: 'Pectinia',
        species: ['Unknown', 'Other'],
        searchNames: ['pectinia']
      },
      {
        name: 'Physogyra',
        species: ['Unknown', 'Lichtensteini', 'Other'],
        searchNames: ['physogyra']
      },
      {
        name: 'Platygyra',
        species: ['Unknown', 'Lamellina', 'Other'],
        searchNames: ['platygyra', 'platy']
      },
      {
        name: 'Plerogyra',
        species: ['Unknown', 'Flexuosa', 'Sinuosa', 'Other'],
        searchNames: ['plerogyra']
      },
      {
        name: 'Polyphillia',
        species: ['Unknown', 'Other'],
        searchNames: ['polyphillia']
      },
      {
        name: 'Scolymia',
        species: ['Unknown', 'Vitiensis', 'Other'],
        searchNames: ['scolymia', 'scoly']
      },
      {
        name: 'Symphyllia',
        species: ['Unknown', 'Other'],
        searchNames: ['symphyllia']
      },
      {
        name: 'Trachyphyllia',
        species: ['Unknown', 'Geoffroyi', 'Other'],
        searchNames: ['trachyphyllia', 'trachy']
      },
      {
        name: 'Tubipora',
        species: ['Unknown', 'Musica', 'Other'],
        searchNames: ['tubipora']
      },
      {
        name: 'Tubastraea',
        species: ['Unknown', 'Aurea', 'Faulkneri', 'Other'],
        searchNames: ['tubastraea']
      },
      {
        name: 'Wellsophyllia',
        species: ['Unknown', 'Radiata', 'Other'],
        searchNames: ['wellso', 'welso']
      }
      // {
      //   name: "Turbinaria",
      //   species: ["Unknown", "Other"],
      // },
      // {
      //   name: "Other",
      //   species: ["Unknown", "Other"],
      //   searchNames: [],
      // },
    ]
  },
  {
    type: 'Soft Coral',
    genus: [
      // {
      //   name: "Unknown",
      //   species: ["Unknown"],
      //   searchNames: [],
      // },
      {
        name: 'Anthelia',
        species: ['Unknown', 'Other'],
        searchNames: ['anthelia']
      },
      {
        name: 'Briareum',
        species: ['Unknown', 'Other'],
        searchNames: ['briarium']
      },
      {
        name: 'Capnella',
        species: ['Unknown', 'Other'],
        searchNames: ['capnella']
      },
      {
        name: 'Clavularia',
        species: ['Unknown', 'Other'],
        searchNames: ['clavularia']
      },
      {
        name: 'Dendrophyllia',
        species: ['Unknown', 'fistula', 'Other'],
        searchNames: ['dendro']
      },
      {
        name: 'Litophyton',
        species: ['Unknown', 'arboteum', 'Other'],
        searchNames: ['litophyton']
      },
      {
        name: 'Lobophytum',
        species: ['Unknown', 'Other'],
        searchNames: ['lobophytum']
      },
      {
        name: 'Nephthea',
        species: ['Unknown', 'Other'],
        searchNames: ['nephthea']
      },
      {
        name: 'Pachyclavularia',
        species: ['Unknown', 'Violacea', 'Other'],
        searchNames: ['pachyclavularia']
      },
      {
        name: 'Sarcophyton',
        species: ['Unknown', 'Glaucum', 'Other'],
        searchNames: ['sarcophyton']
      },
      {
        name: 'Sinularia',
        species: ['Unknown', 'Other'],
        searchNames: ['sinularia']
      },
      {
        name: 'Sympodium',
        species: ['Unknown', 'Other'],
        searchNames: []
      },
      // {
      //   name: "Tubipora",
      //   species: ["Unknown", "Other"],
      //   searchNames: ["tubipora"],
      // },
      {
        name: 'Xenia',
        species: ['Unknown', 'Other'],
        searchNames: ['xenia']
      }
      // {
      //   name: "Discosoma",
      //   species: ["Unknown", "Other"],
      //   searchNames: ["hydnophora"],
      // },
      // {
      //   name: "Other",
      //   species: ["Unknown", "Other"],
      //   searchNames: [],
      // },
    ]
  },
  {
    type: 'Zoa-Paly',
    genus: [
      // {
      //   name: "Unknown",
      //   species: ["Unknown"],
      //   searchNames: [],
      // },
      {
        name: 'Palythoa',
        species: ['Unknown', 'Grandis', 'Other'],
        searchNames: ['palythoa', 'paly', 'palythoas', 'palys']
      },
      {
        name: 'Parazoanthus',
        species: ['Unknown', 'Axinellae', 'Other'],
        searchNames: ['parazoanthus', 'parazoa']
      },
      {
        name: 'Zoanthus',
        species: ['Unknown', 'Other'],
        searchNames: [
          'zoanthus',
          'zoa',
          'zoas',
          'zoo',
          'zoos',
          'zoanthid',
          'zoanthids'
        ]
      }
      // {
      //   name: "Other",
      //   species: ["Unknown", "Other"],
      //   searchNames: [],
      // },
    ]
  },
  {
    type: 'Chalice',
    genus: [
      // {
      //   name: "Unknown",
      //   species: ["Unknown"],
      //   searchNames: [],
      // },
      // {
      //   name: "Echinophyllia",
      //   species: ["Unknown", "Other"],
      //   searchNames: ["echinophyllia"],
      // },
      {
        name: 'Echinopora',
        species: ['Unknown', 'Lamellosa', 'Other'],
        searchNames: ['echinopora']
      },
      {
        name: 'Lithophyllon',
        species: ['Unknown', 'Undulatum', 'Other'],
        searchNames: ['lithophyllon']
      },
      {
        name: 'Mycedium',
        species: ['Unknown', 'Other'],
        searchNames: ['mycedium']
      },
      {
        name: 'Oxypora',
        species: ['Unknown', 'Other'],
        searchNames: ['oxypora']
      }
      // {
      //   name: "Other",
      //   species: ["Unknown", "Other"],
      //   searchNames: [],
      // },
    ]
  },
  {
    type: 'Mushroom',
    genus: [
      // {
      //   name: "Unknown",
      //   species: ["Unknown"],
      //   searchNames: [],
      // },
      {
        name: 'Discosoma',
        species: ['Unknown', 'Actinodiscus', 'Cardinalis', 'Other'],
        searchNames: ['actinodiscus', 'disco']
      },
      // {
      //   name: "Discosoma",
      //   species: ["Unknown", "Other"],
      //   searchNames: ["hydnophora"],
      // },
      {
        name: 'Pseudocorynactis',
        species: ['Unknown', 'Other'],
        searchNames: ['pseudocorynactis']
      },
      {
        name: 'Rhodactis',
        species: ['Unknown', 'Other'],
        searchNames: ['rhodactis', 'rhoda']
      },
      {
        name: 'Ricordea',
        species: ['Unknown', 'Florida', 'Yuma', 'Other'],
        searchNames: ['ricordea']
      }
      // {
      //   name: "Other",
      //   species: ["Unknown", "Other"],
      //   searchNames: [],
      // },
    ]
  },
  {
    type: 'Gorgonian',
    genus: [
      // {
      //   name: "Unknown",
      //   species: ["Unknown"],
      //   searchNames: [],
      // },
      {
        name: 'Guaiagorgia',
        species: ['Unknown', 'Other'],
        searchNames: ['guaiagorgia']
      },

      {
        name: 'Plexaura',
        species: ['Unknown', 'Flexuosa', 'Other'],
        searchNames: ['plexaura']
      }
      // {
      //   name: "Other",
      //   species: ["Unknown", "Other"],
      //   searchNames: [],
      // },
    ]
  },
  {
    type: 'Anemone',
    genus: [
      // {
      //   name: "Unknown",
      //   species: ["Unknown"],
      //   searchNames: [],
      // },
      {
        name: 'Entacmaea',
        species: ['Unknown', 'Quadricolor', 'Other'],
        searchNames: ['entacmaea']
      },
      {
        name: 'Phymanthus',
        species: ['Unknown', 'Crucifer', 'Other'],
        searchNames: ['phymanthus']
      },
      {
        name: 'Stichodactyla',
        species: ['Unknown', 'Gigantea', 'Other'],
        searchNames: ['phymanthus']
      }
      // {
      //   name: "Other",
      //   species: ["Unknown", "Other"],
      //   searchNames: [],
      // },
    ]
  }
  // {
  //   type: "Other",
  //   genus: [
  //     {
  //       name: "Unknown",
  //       species: ["Unknown"],
  //       searchNames: [],
  //     },
  //     {
  //       name: "Other",
  //       species: ["Unknown", "Other"],
  //       searchNames: [],
  //     },
  //   ],
  // },
]

module.exports = coral

import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import CoralEbayPreview from 'components/CoralPreview/CoralEbayPreview.js'
import ebaySmallLogo from 'assets/ebayLogos/US/Right-Now-108x45.gif'
import CustomUpload from 'components/CustomUpload/ExifImageUpload.jsx'
import { addFrag, myCoral } from 'actions/coralActions'
import { getUserLocation } from 'actions/authActions'
import {
  Row,
  Container,
  Label,
  Col,
  Input,
  FormGroup,
  Form,
  Button,
  Spinner
} from 'reactstrap'
import colors from 'variables/colorFamilies.jsx'
import ColorCheckBox from 'components/ColorCheckBox/ColorCheckBox.js'
import { isNull } from 'lodash'
import classes from 'variables/coral'

class EditCoral extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      coral: null,

      price: null,

      authorization: false,

      file: null,
      imgPreviewUrl: null,
      error: false,
      quantityError: false,
      wysiwyg: false,
      placeholderImage: 'image_placeholder.jpg',
      placeholderFragImage: 'image_placeholder.jpg',
      quantity: 1
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateScreen)
    this.updateScreen()
    this.props.getUserLocation()

    axios
      .get(`/api/coral/getsinglecoral/${this.props.match.params.id}`)
      .then(res => {
        console.log(res.data)
        if (this.props.auth.user.id === res.data.user._id) {
          this.setState({ coral: res.data, authorization: true })

          if (res.data.postalCode) {
            this.setState({ postalCode: res.data.postalCode })
          }

          if (res.data.image) {
            this.setState({ placeholderImage: res.data.image.address })
          }

          if (res.data.genericFragImage) {
            this.setState({
              placeholderFragImage: res.data.genericFragImage.address
            })
          }

          console.log(
            this.state.coral.frags.filter(frag => frag.status === 'active')
              .length
          )

          this.setState({ loading: false })
        }
      })
  }

  updateScreen = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true
      })
    } else {
      this.setState({
        screenSizeSmall: false
      })
    }
  }

  submitFrag = e => {
    e.preventDefault()
    console.log('submit hit')
    let quantityCount =
      +this.state.coral.frags.filter(frag => frag.status === 'active').length +
      +this.state.quantity
    if (quantityCount > 10) {
      console.log('quantity error')

      return this.setState({ quantityError: true })
    } else {
      this.setState({ quantityError: false })
    }

    if (
      (!this.state.file &&
        this.state.placeholderFragImage === 'image_placeholder.jpg') ||
      !this.state.price ||
      !this.props.auth.location ||
      this.props.auth.location.postal_code === 'unregistered'
    ) {
      console.log('error hit')
      return this.setState({
        error: true
      })
    } else {
      this.setState({ error: false })
    }

    const coralData = {
      coralId: this.state.coral._id,
      price: this.state.price,
      wysiwyg: this.state.wysiwyg,
      placeholderFragImage: this.state.placeholderFragImage,
      quantity: this.state.quantity
    }
    console.log('send data')
    this.props.addFrag(coralData, this.state.file)
    this.setState({ loading: true })
    setTimeout(() => {
      this.props.history.goBack()
    }, 3000)
  }

  onChange = e => {
    e.preventDefault()
    console.log(e.target)
    this.setState({ [e.target.name]: e.target.value })
  }

  onKeyPress = e => {
    e.preventDefault()
    console.log(e.keyCode, 'key pressed')
  }

  saveFileHandler = (file, imgPreviewUrl) => {
    console.log('save file handler hit')
    this.setState({
      file: file,
      imgPreviewUrl: imgPreviewUrl
    })
  }

  render () {
    console.log(this.state)
    // if (
    //   this.state.coral &&
    //   this.state.coral.frags.filter(frag => frag.status === 'active').length +
    //     this.state.quantity <=
    //     10
    // ) {
    //   console.log(
    //     this.state.coral.frags.filter(frag => frag.status === 'active').length +
    //       this.state.quantity
    //   )
    // }
    let speciesList = []
    if (this.state.coral) {
      let flatGenus = classes.map(type => type.genus).flat(1)
      let genusIndex = flatGenus
        .map(genus => genus.name)
        .indexOf(this.state.coral.genus)
      // console.log(flatGenus[genusIndex].species)
      speciesList = flatGenus[genusIndex].species
    }
    return (
      <>
        <Container style={{ marginTop: 10 }}>
          {this.state.authorization && !this.state.loading ? (
            <>
              <h4 className='text-center'>
                Add New Frag For {this.state.coral.genus}
              </h4>
              <div
                style={{
                  height: '10rem',
                  textAlign: 'center',
                  marginBottom: 7
                }}
              >
                <img
                  style={{ height: '85%' }}
                  src={`https://s3.amazonaws.com/reefconnector/${this.state.placeholderImage}`}
                />
                <p>Mother Colony</p>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <CustomUpload
                  currentImage={`https://s3.amazonaws.com/reefconnector/${this.state.placeholderFragImage}`}
                  saveFileHandler={this.saveFileHandler}
                />
                {/* <Form> */}
                <div>
                  <div>
                    <FormGroup check style={{ marginBottom: 10 }}>
                      <Input
                        type='checkbox'
                        name='wysiwyg'
                        checked={this.state.wysiwyg}
                        onChange={() => {
                          this.setState({
                            wysiwyg: !this.state.wysiwyg,
                            quantity: 1
                          })
                        }}
                        // onKeyUp={this.onKeyPress}
                        // id='WYSIWYG'
                      />
                      <Label
                        // for='WYSIWYG'
                        check
                      >
                        WYSIWYG{' '}
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <Label for='price'>Sale Price ($) </Label>
                      <Input
                        type='number'
                        name='price'
                        value={this.state.price}
                        onChange={this.onChange}
                        onKeyUp={this.onKeyPress}
                        id='price'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for='quantity'>Quantity </Label>
                      <Input
                        type='number'
                        name='quantity'
                        value={this.state.quantity}
                        onChange={this.onChange}
                        onKeyUp={this.onKeyPress}
                        id='quantity'
                        disabled={this.state.wysiwyg}
                      />
                    </FormGroup>
                  </div>
                  {/* <FormGroup> */}
                  <Label for='exampleZip'>
                    Location - Postal Code
                    <br />
                    <small>
                      * Only location postal code will be shown to buyers.
                    </small>
                    {/* {this.state.errors.zip ? (
                            <span className='text-danger'>
                              {' '}
                              * {this.state.errors.zip}
                            </span>
                          ) : null} */}
                  </Label>
                  {this.props.auth.location &&
                  this.props.auth.location.postal_code !== 'unregistered' ? (
                    <p id='exampleZip'>
                      {this.props.auth.location.country_short_name} -{' '}
                      {this.props.auth.location.postal_code}
                    </p>
                  ) : (
                    <p className='text-danger'>No Address Available</p>
                  )}
                  <Link to='/settings' className='text-info'>
                    <i className='fa fa-edit' /> Edit My Address
                  </Link>
                </div>
                {/* </FormGroup>
                </Form> */}
              </div>
              <div className='text-center'>
                <div style={{ margin: 5 }}>
                  {this.state.error ? (
                    <span className='text-danger'>
                      {' '}
                      * Frag must include an image, price, and location.
                    </span>
                  ) : null}
                  {this.state.quantityError ? (
                    <span className='text-danger'>
                      {' '}
                      * You cannot have more than 10 active frag listings of a
                      coral.
                    </span>
                  ) : null}
                </div>
                <Button color='info' onClick={this.submitFrag}>
                  Add Frag
                </Button>
              </div>
            </>
          ) : (
            <div style={{ textAlign: 'center', margin: 100 }}>
              <Spinner>Loading...</Spinner>
            </div>
          )}
        </Container>
      </>
    )
  }
}

EditCoral.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { addFrag, myCoral, getUserLocation })(
  EditCoral
)

import {
  CORAL_SEARCH_CRITERIA,
  CORAL_SORT,
  GET_CORAL_SEARCH_LIST,
  CORAL_PAGE_SET,
  UPDATE_CORAL_LIKE,
  GET_STRAIN_LIST,
  CORAL_LOADING,
  CLEAR_CORAL_LOADING,
  STRAIN_GO_BACK,
  GET_CORAL_LIST
} from '../actions/types'

const initialState = {
  searchCriteria: null,
  sort: [
    ['likesTotal', -1],
    ['date', -1]
  ],
  myCoral: null,
  results: null,
  count: null,
  page: 1,
  limit: null,
  strainList: null,
  strainGoBack: null,
  loading: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CORAL_LIST:
      return {
        ...state,
        myCoral: action.payload,
        loading: false
      }
    case CORAL_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: action.payload,
        loading: false
      }
    case GET_CORAL_SEARCH_LIST:
      return {
        ...state,
        results: action.payload.coralList,
        count: action.payload.count,
        limit: action.payload.limit,
        loading: false
      }
    case UPDATE_CORAL_LIKE:
      return {
        ...state,
        results: action.payload,
        loading: false
      }
    case CORAL_PAGE_SET:
      return {
        ...state,
        page: action.payload,
        loading: false
      }
    case CORAL_SORT:
      return {
        ...state,
        sort: action.payload,
        loading: false
      }
    case GET_STRAIN_LIST:
      return {
        ...state,
        strainList: action.payload,
        loading: false
      }
    case CORAL_LOADING:
      return {
        ...state,
        loading: true
      }
    case CLEAR_CORAL_LOADING:
      return {
        ...state,
        loading: false
      }
    case STRAIN_GO_BACK:
      return {
        ...state,
        strainGoBack: action.payload,
        loading: false
      }
    default:
      return state
  }
}

import {
  DEALS_LOADING,
  GET_DEALS,
} from '../actions/types';

const initialState = {
  deals: [],
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DEALS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_DEALS:
      return {
        ...state,
        deals: action.payload,
        loading: false
      };
    default:
      return state;
  }
}

import React from "react";
import axios from "axios";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Button,
} from "reactstrap";

class CheckName extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      email: "",
      password: "",
      errors: {},
      current: "mail",
      coralNames: [],
      name: "",
      exists: false,
      added: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  approveName = () => {
    axios
      .post("/api/coral/approvename", { id: this.props.name._id })
      .then((res) => {
        console.log(res.data);
      });
  };

  deleteName = () => {
    axios
      .post("/api/coral/deletename", { id: this.props.name._id })
      .then((res) => {
        console.log(res.data);
      });
  };

  handleClick = (e) => {
    console.log("click ", e);
    this.setState({ current: e.key });
  };

  render() {
    return (
      <>
        <div>
          {this.props.name.name}({this.props.name.genus})
          <Button onClick={this.approveName}>Approve</Button>
          <Button onClick={this.deleteName}>Delete</Button>
        </div>
        <Row>
          {this.props.name.coral.map((coral) => (
            <Col md={3}>
              <div style={{ height: "10rem" }}>
                <img src={coral.imageURL} style={{ height: "100%" }} alt={coral.name} />
              </div>
            </Col>
          ))}
        </Row>
      </>
    );
  }
}

CheckName.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(CheckName);

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { saveSort } from "actions/coralActions";

import Select from "react-select";

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999",
  }),
};

class CoralList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // sortField: "likesTotal",
      sortField: "likesTotal",
      param: "-1",
      smallScreenSize: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      let sort = [["date", -1]];

      if (this.state.sortField === "price") {
        sort.unshift(["price", this.state.param]);
      }
      if (this.state.sortField === "likesTotal") {
        // sort.likesTotal = this.state.param;
        sort.unshift(["likesTotal", this.state.param]);
      }
      console.log(sort);
      this.props.saveSort(sort);
    }

    // if (prevProps.smallScreenSize !== this.props.smallScreenSize) {
    //   console.log("screensize hit");
    //   this.setState({ smallScreenSize: !this.state.smallScreenSize });
    // }
  }

  // submitSort = () => {
  //   console.log(this.state.sortField, this.state.param);
  // };

  render() {
    const options = [
      {
        value: "price",
        param: "1",
        label: "Price: Low to High",
      },
      {
        value: "price",
        param: "-1",
        label: "Price: High to Low",
      },
      // { value: "likesTotal", param: "-1", label: "Likes" },
      // { value: "distance", param: "-1", label: "Shortest Distance" }
    ];

    const getSelectDefault = () => {
      if (this.props.coral.sort) {
        const { sort } = this.props.coral;
        if (sort.price) {
          if (sort.price === "1") {
            return options[0];
          } else {
            return options[1];
          }
        }
        if (sort.likesTotal) {
          return options[2];
        }
      } else {
        // return "";
        return options[2];
      }
    };

    return (
      <>
        <div
          style={{
            width: `${this.props.screenSizeSmall ? "8rem" : "15rem"}`,
            marginRight: `${this.props.screenSizeSmall ? "4rem" : "4rem"}`,
            // height: `${this.props.screenSizeSmall ? "2rem" : ""}`
          }}
        >
          <Select
            // menuContainerStyle={{ zIndex: "100000" }}
            styles={selectStyles}
            className="react-select primary"
            classNamePrefix="react-select"
            // name="singleSelect"
            // value={genusValue}
            defaultValue={getSelectDefault()}
            onChange={(option) => {
              this.setState({
                sortField: option.value,
                param: option.param,
              });
              // this.submitSort();
            }}
            options={options}
            placeholder="Sort by..."
          />
        </div>
      </>
    );
  }
}

CoralList.propTypes = {
  profile: PropTypes.object.isRequired,
  saveSort: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  coral: state.coral,
});

export default connect(mapStateToProps, { saveSort })(CoralList);

import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUserLocation } from 'actions/authActions'
import { Helmet } from 'react-helmet'
import CoralEbayPreview from 'components/CoralPreview/CoralEbayPreview.js'
import ebaySmallLogo from 'assets/ebayLogos/US/Right-Now-108x45.gif'

import {
  Row,
  Container,
  Form,
  Col,
  FormGroup,
  Label,
  Input,
  FormFroup,
  Button,
  Spinner
} from 'reactstrap'

class Nameshow extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      addressError: '',
      updated: false
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateScreen)
    this.updateScreen()
    this.props.getUserLocation()
  }

  updateScreen = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true
      })
    } else {
      this.setState({
        screenSizeSmall: false
      })
    }
  }

  submit = () => {
    console.log(this.state)
    this.setState({ loading: true })
    const params = new URLSearchParams({
      address: this.state.address + this.state.address2
      // address2: this.state.address2,
      // locality: this.state.city,
      // administrative_area_level_1: this.state.state,
      // postal_code: this.state.zip,
      // country: this.state.country
    })
    console.log(params.toString())

    let addressString = `${params.toString()}&components=locality:${
      this.state.city
    }|administrative_area_level_1:${this.state.state}|postal_code:${
      this.state.zip
    }|country:${this.state.country}`
    axios
      .post('/api/users/setnewlocation', {
        addressString
      })
      .then(res => {
        console.log(res.data)
        if (res.data === 'success') {
          this.props.getUserLocation()
          this.setState({ loading: false, addressError: '', updated: true })
        } else {
          this.setState({ addressError: res.data, loading: false })
        }
      })
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })

    //Prints "var1=value&var2=value2&arr=foo"
  }

  render () {
    console.log(this.state)
    const { location } = this.props.auth
    return (
      <>
        {this.props.auth.location ? (
          <Container>
            <h4 style={{ textAlign: 'center', marginTop: 20 }}>Settings</h4>
            <Label
              for='userInfo'
              style={{ textAlign: 'center', color: 'grey' }}
            >
              User Info
            </Label>
            <div
              style={{
                border: '1px solid lightgrey',
                padding: 20,
                borderRadius: 10,
                marginBottom: 10
              }}
            >
              <p>
                Click button below to edit user info settings, such as your
                avatar image, username, etc.
              </p>
              <p>
                * You may need to logout and log back in for changes to update.
              </p>
              <a
                href={`https://community.immortalreef.com/u/${this.props.auth.user.username}/preferences/account`}
              >
                <Button color='info'>Edit User Settings</Button>
              </a>
            </div>
            <Label
              for='addressForm'
              style={{ textAlign: 'center', color: 'grey' }}
            >
              Trade Address
            </Label>
            <div
              style={{
                border: '1px solid lightgrey',
                padding: 20,
                borderRadius: 10
              }}
            >
              <p>
                Current Address:{' '}
                {this.state.addressError ? (
                  <p className='text-danger'>{this.state.addressError}</p>
                ) : (
                  <>
                    {this.props.auth.location.postal_code === 'unregistered' ? (
                      <span>No address on record.</span>
                    ) : (
                      <>
                        <p style={{ marginBottom: 0 }}>
                          {location.street_number} {location.route}
                        </p>
                        <p>
                          {location.locality}{' '}
                          {location.administrative_area_level_1_short_name}{' '}
                          {location.postal_code} {location.country_short_name}
                        </p>
                      </>
                    )}
                  </>
                )}
              </p>
              <Form id='addressForm'>
                <FormGroup>
                  <Label for='exampleAddress'>Address</Label>
                  <Input
                    type='text'
                    name='address'
                    id='exampleAddress'
                    placeholder='1234 Main St'
                    value={this.state.address}
                    onChange={this.onChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='exampleAddress2'>Address 2</Label>
                  <Input
                    type='text'
                    name='address2'
                    id='exampleAddress2'
                    placeholder='Apartment, studio, or floor'
                    value={this.state.address2}
                    onChange={this.onChange}
                  />
                </FormGroup>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for='exampleCity'>City</Label>
                      <Input
                        type='text'
                        name='city'
                        id='exampleCity'
                        value={this.state.city}
                        onChange={this.onChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='exampleState'>State</Label>
                      <Input
                        type='text'
                        name='state'
                        id='exampleState'
                        value={this.state.state}
                        onChange={this.onChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for='exampleZip'>Zip</Label>
                      <Input
                        type='text'
                        name='zip'
                        id='exampleZip'
                        value={this.state.zip}
                        onChange={this.onChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for='exampleEmail'>Country</Label>
                      <Input
                        type='country'
                        name='country'
                        id='exampleEmail'
                        placeholder='e.g. US, CA, UK, AU, etc.'
                        value={this.state.county}
                        onChange={this.onChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {this.state.loading ? (
                  <Spinner style={{ width: '3rem', height: '3rem' }} />
                ) : (
                  <>
                    {this.state.updated ? (
                      <div className='text-success'>
                        <i className='fa fa-check' /> Updated
                      </div>
                    ) : (
                      <>
                        {this.state.addressError ? (
                          <p className='text-danger'>
                            {this.state.addressError}
                          </p>
                        ) : null}
                        <Button color='info' onClick={this.submit}>
                          Update
                        </Button>
                      </>
                    )}
                  </>
                )}
                <span style={{ marginLeft: 20, color: 'grey' }}>
                  * Only city and zip code will be visible in search.
                </span>
              </Form>
            </div>
          </Container>
        ) : (
          <span>Loading...</span>
        )}
      </>
    )
  }
}

Nameshow.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { getUserLocation })(Nameshow)

import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { myCoral, shareImageUpload } from 'actions/coralActions'
import CoralEbayPreview from 'components/CoralPreview/CoralEbayPreview.js'
import ebaySmallLogo from 'assets/ebayLogos/US/Right-Now-108x45.gif'
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'

import { Row, Container, Input, Button } from 'reactstrap'
// import waterBackground from 'assets/water_background.jpg'
import waterBackground from 'assets/SilverBackground2.jpg'
import IRLogoFinal from 'assets/IRLogoFinal.png'

class Nameshow extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      name: null,
      selectedCoral: [],
      submitted: false
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateScreen)
    this.updateScreen()
    if (!this.props.coral.myCoral) {
      this.props.myCoral()
    }
  }

  updateScreen = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true
      })
    } else {
      this.setState({
        screenSizeSmall: false
      })
    }
  }

  submit = () => {
    console.log('clicked')
    this.setState({ submitted: true })
    // htmlToImage
    //   .toPng(document.getElementById('my-node'))
    //   .then(function (dataUrl) {
    //     var img = new Image()
    //     img.src = dataUrl
    //     document.body.appendChild(img)
    //   })
    // htmlToImage //works but empty space is filled with black
    //   .toJpeg(document.getElementById('my-node'), { quality: 0.95 })
    //   .then(function (dataUrl) {
    //     var link = document.createElement('a')
    //     link.download = 'my-image-name.jpeg'
    //     link.href = dataUrl
    //     link.click()
    //   })
    // htmlToImage //???
    //   .toBlob(document.getElementById('my-node'))
    //   .then(function (blob) {
    //     window.saveAs(blob, 'my-node.png')
    //   })

    // toJpeg(document.getElementById('my-node'))
    //   .then(function (dataUrl) {
    //     const dataURItoBlob = dataURI => {
    //       // convert base64 to raw binary data held in a string
    //       // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    //       var byteString = atob(dataURI.split(',')[1])

    //       // separate out the mime component
    //       var mimeString = dataURI
    //         .split(',')[0]
    //         .split(':')[1]
    //         .split(';')[0]

    //       // write the bytes of the string to an ArrayBuffer
    //       var ab = new ArrayBuffer(byteString.length)
    //       var ia = new Uint8Array(ab)
    //       for (var i = 0; i < byteString.length; i++) {
    //         ia[i] = byteString.charCodeAt(i)
    //       }
    //       return new Blob([ab], { type: mimeString })
    //     }
    //     return dataURItoBlob(dataUrl)

    //     // var img = new Image()
    //     // img.src = dataUrl
    //     // console.log(img)
    //     // // console.log(img.src.name)
    //     // document.body.appendChild(img)

    //     // var blob = dataURItoBlob(dataUrl)
    //     // var fd = new FormData(document.forms[0])
    //     // fd.append('shareImage', blob)
    //     // this.setState({ file: fd })
    //   })

    toBlob(document.getElementById('my-node'))
      .then(blob => {
        console.log(blob)
        let newFile = new File([blob], 'shareImage', {
          type: 'image/jpeg',
          lastModified: Date.now()
        })
        // this.setState({ file: newFile })
        // this.props.shareImageUpload(newFile)
        return newFile
      })
      .then(file => this.props.shareImageUpload(file))
      .catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
  }

  addImage = address => {
    this.setState({ submitted: false })
    console.log(address)
    let findArray = []
    findArray = [...this.state.selectedCoral]
    let imgIndex = findArray.indexOf(address)

    if (imgIndex === -1) {
      this.setState({
        selectedCoral: [...this.state.selectedCoral, address]
      })
    } else {
      findArray.splice(imgIndex, 1)
      this.setState({ selectedCoral: findArray })
    }
  }

  // dataURItoBlob = dataURI => {
  //   // convert base64 to raw binary data held in a string
  //   // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  //   var byteString = atob(dataURI.split(',')[1])

  //   // separate out the mime component
  //   var mimeString = dataURI
  //     .split(',')[0]
  //     .split(':')[1]
  //     .split(';')[0]

  //   // write the bytes of the string to an ArrayBuffer
  //   var ab = new ArrayBuffer(byteString.length)
  //   var ia = new Uint8Array(ab)
  //   for (var i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i)
  //   }
  //   return new Blob([ab], { type: mimeString })
  // }

  render () {
    const { myCoral } = this.props.coral
    console.log(myCoral)
    let filteredCoral = []
    let genericFragImages = []
    let imgHeight = 100
    let imgHeight0 = 100
    let imgWidth0 = 100
    let imgWidth = 150
    console.log(this.state)
    if (myCoral) {
      filteredCoral = myCoral.filter(coral => coral.image)
      genericFragImages = myCoral.filter(coral => coral.genericFragImage)
    }
    // console.log(myCoral)
    // console.log(filteredCoral)
    let divWidth = 600
    let x = this.state.selectedCoral.length
    switch (x) {
      case (x = 1):
        imgHeight0 = 600 - 54
        imgHeight = 600 - 54
        imgWidth0 = 1200
        imgWidth = 1200
        divWidth = 1200
        break
      case (x = 2):
        imgHeight0 = 600 - 54
        imgWidth0 = 598
        imgHeight = 600 - 54
        imgWidth = 598
        divWidth = 1200
        break
      case (x = 3):
        imgHeight0 = 600 - 54
        imgWidth0 = 1200
        imgHeight = 298 - 27 //150
        imgWidth = 598 //280
        divWidth = 1200
        break
      case (x = 4):
        imgHeight0 = 600 - 54
        imgWidth0 = 1200
        imgHeight = 200 - 20 //150
        imgWidth = 598 //398 280
        divWidth = 1200
        break
      // case (x = 5):
      //   imgHeight0 = 600 - 54
      //   imgWidth0 = 598
      //   imgHeight = 200 - 20 //150
      //   imgWidth = 598 / 2 //398 280
      //   divWidth = 1200
      //   break
      default:
        imgHeight = 298
        imgWidth = 398
        divWidth = 1200
    }

    return (
      <>
        <Container>
          {myCoral ? (
            <>
              <h4 style={{ textAlign: 'center', margin: 5 }}>
                Add Images To Social Media Preview
              </h4>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {filteredCoral.map(coral => (
                  <div
                    style={{
                      height: 100,
                      minWidth: 100,
                      margin: 5,
                      display: 'flex',
                      padding: 3,
                      border: '1px solid lightgrey',
                      backgroundColor: 'lightgrey',
                      borderRadius: 5
                    }}
                  >
                    <Input
                      disabled={
                        this.state.selectedCoral.length >= 4 &&
                        !this.state.selectedCoral.some(
                          address => address === coral.image.address
                        )
                      }
                      // addon
                      type='checkbox'
                      onChange={() => this.addImage(coral.image.address)}
                      style={{ margin: 5 }}
                      // aria-label='Checkbox for following text input'
                    />
                    <img
                      src={`https://reefconnector.s3.amazonaws.com/${coral.image.address}`}
                      style={{ height: '100%' }}
                    />
                  </div>
                ))}
                {genericFragImages.map(coral => (
                  <div
                    style={{
                      height: 100,
                      minWidth: 100,
                      margin: 5,
                      display: 'flex',
                      padding: 3,
                      border: '1px solid lightgrey',
                      backgroundColor: 'lightgrey',
                      borderRadius: 5
                    }}
                  >
                    <Input
                      disabled={
                        this.state.selectedCoral.length >= 4 &&
                        !this.state.selectedCoral.some(
                          address => address === coral.genericFragImage.address
                        )
                      }
                      // addon
                      type='checkbox'
                      onChange={() =>
                        this.addImage(coral.genericFragImage.address)
                      }
                      style={{ margin: 5 }}
                      // aria-label='Checkbox for following text input'
                    />
                    <img
                      src={`https://reefconnector.s3.amazonaws.com/${coral.genericFragImage.address}`}
                      style={{ height: '100%' }}
                    />
                  </div>
                ))}
              </div>

              {this.state.selectedCoral.length > 0 ? (
                <>
                  <div>
                    <p>
                      * The preview below will not fit into the display window
                      of mobile devices - that's ok. It will still display
                      properly on social media platforms.
                    </p>
                  </div>
                  {!this.state.submitted ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        color='info'
                        onClick={this.submit}
                        style={{ margin: 8 }}
                      >
                        Save
                      </Button>
                    </div>
                  ) : (
                    <>
                      <p className='text-success'>
                        <i className='fa fa-check' /> Saved
                      </p>
                      <p>
                        * Wait at least 2 minutes for popular social media sites
                        to update before sharing. Return to{' '}
                        <Link className='text-info' to={`/myreef`}>
                          My Coral Collection
                        </Link>{' '}
                        and click on the Facebook share icon.
                      </p>
                    </>
                  )}
                  <div
                    id='my-node'
                    style={{
                      height: 600,
                      width: divWidth,
                      borderRadius: 5,
                      // display: 'flex',
                      // flexWrap: 'wrap',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      backgroundImage: `url(${waterBackground})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'

                      // backgroundColor: 'lightgrey'
                    }}
                  >
                    {/* <div
                      style={{
                        height: 52,
                        width: 52,
                        display: 'flex',
                        // position: 'relative',
                        // left: 30,
                        // top: 30,
                        // zIndex: 1000,
                        // backgroundColor: 'lightgrey',
                        alignItems: 'center'
                      }}
                    >
                      <img
                        style={{
                          height: '100%',
                          borderRadius: 50
                        }}
                        src='https://s3.amazonaws.com/reefconnector/IRLogoFinal.png'
                      />
                      <h2 style={{ marginLeft: 10 }}>IMMORTALREEF.COM</h2>
                    </div> */}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {this.state.selectedCoral.length < 3 ? (
                        <>
                          {this.state.selectedCoral.map((address, index) => (
                            <div
                              key={address + index}
                              style={{
                                height: imgHeight,
                                // maxWidth: imgWidth,
                                margin: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              {/* <div
                              crossOrigin='anonymous'
                              style={{
                                borderRadius: 5,
                                height: index === 0 ? imgHeight0 : imgHeight, //150,
                                width: imgWidth, //150,

                                backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${address}?timestamp=${Date.now()})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                              }}
                            /> */}
                              <img
                                crossOrigin='anonymous'
                                src={`https://reefconnector.s3.amazonaws.com/${address}?timestamp=${Date.now()}`}
                                style={{
                                  maxWidth: imgWidth,
                                  maxHeight: '100%',
                                  borderRadius: 5
                                }}
                              />
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              height: imgHeight0,
                              // maxWidth: imgWidth,
                              margin: 1,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            {/* <div
                            crossOrigin='anonymous'
                            style={{
                              borderRadius: 5,
                              height: imgHeight0, //150,
                              width: 598, //150,

                              backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${
                                this.state.selectedCoral[0]
                              }?timestamp=${Date.now()})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat'
                            }}
                          /> */}
                            <img
                              crossOrigin='anonymous'
                              src={`https://reefconnector.s3.amazonaws.com/${
                                this.state.selectedCoral[0]
                              }?timestamp=${Date.now()}`}
                              style={{
                                maxWidth: imgWidth0,
                                maxHeight: '100%',
                                borderRadius: 5
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              flexWrap: 'wrap',
                              height: 600 - 54
                              // backgroundColor: 'black'
                              // flexWrap: 'wrap'
                            }}
                          >
                            {this.state.selectedCoral.map((address, index) => (
                              <>
                                {index > 0 ? (
                                  // <div
                                  //   crossOrigin='anonymous'
                                  //   style={{
                                  //     borderRadius: 5,
                                  //     height: imgHeight, //150,
                                  //     width: imgWidth, //150,
                                  //     margin: 1,

                                  //     backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${address}?timestamp=${Date.now()})`,
                                  //     backgroundPosition: 'center',
                                  //     backgroundSize: 'cover',
                                  //     backgroundRepeat: 'no-repeat'
                                  //   }}
                                  // />
                                  <div
                                    style={{
                                      height: imgHeight,
                                      // maxWidth: imgWidth,
                                      margin: 1,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                      // backgroundColor: 'blue'
                                    }}
                                  >
                                    <img
                                      crossOrigin='anonymous'
                                      src={`https://reefconnector.s3.amazonaws.com/${address}?timestamp=${Date.now()}`}
                                      style={{
                                        maxWidth: imgWidth,
                                        // width: imgWidth,
                                        maxHeight: '100%',
                                        borderRadius: 5
                                      }}
                                    />
                                  </div>
                                ) : // <div>{index}</div>
                                null}
                              </>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        height: 52,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        // position: 'relative',
                        // left: 30,
                        // top: 30,
                        // zIndex: 1000,
                        // backgroundColor: 'lightgrey',
                        alignItems: 'center'
                      }}
                    >
                      <div style={{ alignItems: 'center' }}>
                        <h4
                          style={{
                            // marginLeft: 200,
                            marginBottom: 0,
                            alignItems: 'center'
                          }}
                        >
                          {this.props.auth.user.username}'s Coral Collection
                        </h4>
                      </div>
                      <div
                        style={{
                          height: 52,
                          // width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          // position: 'relative',
                          // left: 30,
                          // top: 30,
                          // zIndex: 1000,
                          // backgroundColor: 'lightgrey',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          style={{
                            height: '100%',
                            borderRadius: 50
                          }}
                          src={IRLogoFinal}
                          // src='https://s3.amazonaws.com/reefconnector/IRLogoFinal.png'
                        />
                        <h4 style={{ marginLeft: 10, marginBottom: 0 }}>
                          IMMORTALREEF.COM
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* <Button color='info' onClick={this.submit}>
                    Save
                  </Button> */}
                </>
              ) : null}
            </>
          ) : null}
        </Container>
      </>
    )
  }
}

Nameshow.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral,
  errors: state.errors
})

export default connect(mapStateToProps, { myCoral, shareImageUpload })(Nameshow)

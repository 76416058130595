import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCommunityUsers } from 'actions/communityActions'
import { getUserLocation } from 'actions/authActions'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData
} from 'react-country-region-selector'
import { Helmet } from 'react-helmet'
import CoralEbayPreview from 'components/CoralPreview/CoralEbayPreview.js'
import ebaySmallLogo from 'assets/ebayLogos/US/Right-Now-108x45.gif'

import {
  Row,
  Container,
  Jumbotron,
  Card,
  Col,
  Label,
  Input,
  FormGroup,
  Button,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap'

var zipcodes = require('zipcodes')

class TradeCommunity extends React.Component {
  constructor () {
    super()
    this.state = {
      postalCode: null,
      searchDistance: null,
      loading: false,
      errorPostalCode: '',
      errorSearchDistance: '',
      activeTab: '1',
      country: '',
      region: ''
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateScreen)
    this.updateScreen()
    // if (this.props.auth.isAuthenticated && !this.props.auth.location) {
    //   this.props.getUserLocation()
    // }

    // this.props.getCommunityUsers('')
    const params = new URLSearchParams(this.props.location.search)
    if (this.props.location.search && params.get('postal_code')) {
      this.setState({
        postalCode: params.get('postal_code'),
        searchDistance: params.get('search_distance')
      })
      this.getTraders(this.props.location.search)
    } else {
      this.props.getCommunityUsers('?empty=true')
    }
  }

  componentWillReceiveProps (nextProps) {
    // if (
    //   nextProps.auth.location &&
    //   nextProps.auth.location.location.coordinates[0] === 0
    // ) {
    //   // console.log('invalid location')
    //   navigator.geolocation.getCurrentPosition(function (position) {
    //     console.log(position)
    //     console.log('Latitude is :', position.coords.latitude)
    //     console.log('Longitude is :', position.coords.longitude)
    //     axios
    //       .post('/api/users/setlocation', {
    //         coordinates: [position.coords.latitude, position.coords.longitude]
    //       })
    //       .then(res => this.props.getUserLocation())
    //   })
    // }

    if (
      nextProps.location.search &&
      nextProps.location.search !== this.props.location.search
    ) {
      const params = new URLSearchParams(nextProps.location.search)

      console.log(params.get('postal_code'), params.get('search_distance'))
      // this.setState({
      //   postalCode: params.get('postal_code'),
      //   searchDistance: params.get('search_distance')
      // })

      this.props.getCommunityUsers(nextProps.location.search)
    }

    if (!nextProps.location.search && this.props.location.search) {
      this.props.getCommunityUsers('?empty=true')
    }

    // if (
    //   !this.state.postalCode &&
    //   nextProps.auth.location &&
    //   nextProps.auth.location.postal_code !== 'unregistered'
    // ) {
    //   this.setState({
    //     postalCode: nextProps.auth.location.postal_code,
    //     searchDistance: '50'
    //   })
    // }
  }

  onChange = e => {
    if (e.target.name === 'zipcode') {
      e.target.value = e.target.value.toUpperCase()
    }

    this.setState({ [e.target.name]: e.target.value })
  }

  updateScreen = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true
      })
    } else {
      this.setState({
        screenSizeSmall: false
      })
    }
  }

  submitSearch = () => {
    this.setState({ errorPostalCode: null, errorSearchDistance: null })
    if (!this.state.postalCode) {
      this.setState({ errorPostalCode: 'Postal Code Required' })
      return
    }

    if (!zipcodes.lookup(this.state.postalCode)) {
      this.setState({ errorPostalCode: 'Invalid Postal Code' })
      return
    }

    if (this.state.postalCode && !this.state.searchDistance) {
      this.setState({ errorSearchDistance: 'Enter Search Radius' })
      return
    }

    this.props.history.push(
      `/tradecommunity?${
        this.state.postalCode ? `postal_code=${this.state.postalCode}&` : ''
      }${
        this.state.searchDistance
          ? `search_distance=${this.state.searchDistance}`
          : ''
      }`
    )
  }

  submitInternationalSearch = () => {
    // this.setState({ errorPostalCode: null, errorSearchDistance: null })
    // if (!this.state.postalCode) {
    //   this.setState({ errorPostalCode: 'Postal Code Required' })
    //   return
    // }

    // if (!zipcodes.lookup(this.state.postalCode)) {
    //   this.setState({ errorPostalCode: 'Invalid Postal Code' })
    //   return
    // }

    // if (this.state.postalCode && !this.state.searchDistance) {
    //   this.setState({ errorSearchDistance: 'Enter Search Radius' })
    //   return
    // }
    if (!this.state.country) {
      return
    } else {
      this.props.history.push(
        `/tradecommunity?region=international&${
          this.state.country ? `country_long_name=${this.state.country}&` : ''
        }${
          this.state.region
            ? `administrative_area_level_1_long_name=${this.state.region}`
            : ''
        }`
      )
    }
  }

  getTraders = search => {
    this.props.getCommunityUsers(search)
    // axios
    //   .get(`/api/users/findusers${search}`)
    //   .then(res => console.log(res.data))

    // const params = new URLSearchParams(search)
    // let searchInfo = {
    //   postal_code: params.get('postal_code'),
    //   search_distance: params.get('search_distance')
    // }
    // //find coordinates
    // console.log(params.get('postal_code'), params.get('search_distance'))
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        postalCode: '',
        searchDistance: '',
        country: '',
        region: ''
      })
    }
  }

  selectCountry = val => {
    this.setState({ country: val, region: '' })
  }

  selectRegion = val => {
    this.setState({ region: val })
    console.log(this.state)
  }

  render () {
    console.log(this.state)
    const { country, region } = this.state
    // console.log(zipcodes.lookup(this.state.postalCode))
    const { allUsers } = this.props.community
    return (
      <>
        <Container>
          <h4 style={{ textAlign: 'center', marginTop: 20 }}>
            Coral Collectors
          </h4>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => {
                  this.toggleTab('1')
                  this.props.history.push('/tradecommunity')
                }}
              >
                US & Canada
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => {
                  this.toggleTab('2')
                  this.props.history.push(
                    '/tradecommunity?region=international&empty=true'
                  )
                }}
              >
                International
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId='1'>
              <Row form style={{ marginTop: 10 }}>
                <Col md={5}>
                  <FormGroup style={{ marginBottom: 0 }}>
                    <Label for='postalCode'>Zip Code</Label>
                    <Input
                      type='text'
                      name='postalCode'
                      id='postalCode'
                      value={this.state.postalCode}
                      onChange={this.onChange}
                    />
                  </FormGroup>
                  {this.state.errorPostalCode ? (
                    <div className='text-danger'>
                      {this.state.errorPostalCode}
                    </div>
                  ) : null}
                </Col>
                <Col md={3}>
                  <FormGroup style={{ marginBottom: 0 }}>
                    <Label for='exampleSelect'>Within (Miles)</Label>
                    <Input
                      type='select'
                      name='searchDistance'
                      id='exampleSelect'
                      value={this.state.searchDistance}
                      style={{ height: 45 }}
                      onChange={this.onChange}
                      // disabled={this.state.postalCode ? false : true}
                    >
                      <option></option>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                      <option>200</option>
                      <option>500</option>
                      <option>Any</option>
                    </Input>
                  </FormGroup>
                  {this.state.errorSearchDistance ? (
                    <div className='text-danger'>
                      {this.state.errorSearchDistance}
                    </div>
                  ) : null}
                </Col>
                {/* <Col md={3}>
                  <FormGroup>
                    <Label for='exampleSelect'>Sort</Label>
                    <Input
                      type='select'
                      name='select'
                      id='exampleSelect'
                      style={{ height: 45 }}
                    >
                      <option></option>
                      <option>Collection Size</option>
                      <option>For Sale</option>
                      <option>For Trade</option>
                      <option>Wish List</option>
                    </Input>
                  </FormGroup>
                </Col> */}
                <Col md={1} style={{ textAlign: 'left', paddingTop: 26 }}>
                  <Button color='info' onClick={this.submitSearch}>
                    Search
                  </Button>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId='2'>
              <div style={{ height: 10 }} />
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <CountryDropdown
                  style={{
                    height: 50,
                    marginRight: 10,
                    border: '1px solid grey'
                  }}
                  value={country}
                  onChange={val => this.selectCountry(val)}
                />

                <RegionDropdown
                  style={{ height: 50, marginRight: 10 }}
                  country={country}
                  value={region}
                  onChange={val => this.selectRegion(val)}
                />
                <Button color='info' onClick={this.submitInternationalSearch}>
                  Search
                </Button>
              </div>
            </TabPane>
          </TabContent>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {this.props.community.allUsers ? (
              allUsers.map(user => (
                <Card style={{ margin: 5 }}>
                  {' '}
                  <CardBody>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 5
                      }}
                    >
                      <div
                      // style={{ display: 'inline-block' }}
                      >
                        <img
                          src={user.avatar}
                          style={{
                            height: 50,
                            width: 50,
                            margin: 10,
                            marginTop: 0,
                            borderRadius: 50
                          }}
                        />
                      </div>
                      <div
                      // style={{ display: 'inline-block' }}
                      >
                        <CardTitle tag='h5' style={{ textAlign: 'right' }}>
                          {user.username}
                        </CardTitle>
                        <CardSubtitle
                          tag='h6'
                          className='mb-2 text-muted'
                          style={{ textAlign: 'right' }}
                        >
                          {user.locality},{' '}
                          {user.administrative_area_level_1_short_name}
                        </CardSubtitle>
                      </div>
                    </div>

                    <CardText style={{ marginBottom: 0 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span>
                          <Label for='Total'>Corals</Label>{' '}
                          <p
                            id='Total'
                            style={{ textAlign: 'center', margin: 0 }}
                          >
                            {user.totalCoralCount}
                          </p>
                        </span>
                        <span>
                          <Label for='Sale'>Frags</Label>{' '}
                          <p
                            id='Sale'
                            style={{ textAlign: 'center', margin: 0 }}
                          >
                            {user.forSaleCount}
                          </p>
                        </span>
                        <span>
                          <Label for='Trade'>Trade</Label>{' '}
                          <p
                            id='Trade'
                            style={{ textAlign: 'center', margin: 0 }}
                          >
                            {user.forTradeCount}
                          </p>
                        </span>
                        <span>
                          <Label for='Wish'>Wish</Label>{' '}
                          <p
                            id='Wish'
                            style={{ textAlign: 'center', margin: 0 }}
                          >
                            {user.wishListCount}
                          </p>
                        </span>
                      </div>
                    </CardText>
                    {user.totalCoralCount > 0 ? (
                      <Link to={`membercollection/${user._id}`}>
                        <Button>
                          <span className='text-info'>Collection</span>
                        </Button>
                      </Link>
                    ) : (
                      <Button border disabled>
                        Collection
                      </Button>
                    )}
                    {user.totalCoralCount > 0 ? (
                      <Link to={`memberfrags/${user._id}`}>
                        <Button>
                          <span className='text-info'>Frags</span>
                        </Button>
                      </Link>
                    ) : (
                      <Button border disabled>
                        Frags
                      </Button>
                    )}
                    {user.wishListCount > 0 ? (
                      <Link to={`userwishlist/${user._id}`}>
                        <Button border>
                          <span className='text-info'>WishList</span>
                        </Button>
                      </Link>
                    ) : (
                      <Button border disabled>
                        WishList
                      </Button>
                    )}
                  </CardBody>
                </Card>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </Container>
      </>
    )
  }
}

TradeCommunity.propTypes = {
  auth: PropTypes.object.isRequired,
  community: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  community: state.community,
  errors: state.errors
})

export default connect(mapStateToProps, { getCommunityUsers, getUserLocation })(
  TradeCommunity
)

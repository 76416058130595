import {
  GET_PROFILE,
  GET_PROFILES,
  PROFILE_LOADING,
  CLEAR_CURRENT_PROFILE,
  GET_PROFILE_IMAGES,
  GET_PROFILE_PRIMARY_IMAGE,
  GET_CORAL_LIST,
  GET_OWNER_PROFILE_IMAGES,
  GET_TAGGED_CORAL,
} from "../actions/types";

const initialState = {
  profile: {
    details: null,
    user: null,
    lists: {
      lengths: null,
    },
  },
  profileCorals: null,
  taggedCoral: null,
  profileImages: null,
  profiles: null,
  ownerImages: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };
    case GET_PROFILE_IMAGES:
      return {
        ...state,
        // profile: {
        //   ...state.profile,
        //   lists: {
        //     ...state.profile.lists,
        //     images: action.payload
        //   }
        // },
        profileImages: action.payload,
        loading: false,
      };
    case GET_PROFILE_PRIMARY_IMAGE:
      return {
        ...state,
        profile: {
          ...state.profile,
          details: {
            ...state.profile.details,
            primaryImage: action.payload,
          },
        },
        loading: false,
      };
    case GET_CORAL_LIST:
      return {
        ...state,
        profileCorals: action.payload,
        loading: false,
      };
    case GET_TAGGED_CORAL:
      return {
        ...state,
        taggedCoral: action.payload,
        loading: false,
      };
    case GET_PROFILES:
      return {
        ...state,
        profiles: action.payload,
        loading: false,
      };
    case CLEAR_CURRENT_PROFILE:
      return {
        ...state,
        profile: null,
      };
    case GET_OWNER_PROFILE_IMAGES:
      return {
        ...state,
        ownerImages: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "Pages/AllCoral/AllCoral.module.css";

class ColorCheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // checked: false
      checked: this.props.color.hue === this.props.currentColor ? true : false,
      loading: false,
    };
  }

  // componentWillUpdate(nextState) {
  //   if (
  //     nextState.checked !== this.state.checked &&
  //     this.state.loading === true
  //   ) {
  //     this.setState({ loading: false });
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.coral.searchCriteria !== this.props.coral.searchCriteria) {
      if (
        this.props.abundance === "most" &&
        nextProps.coral.searchCriteria.primaryHues.length > 0
      ) {
        if (
          nextProps.coral.searchCriteria.primaryHues.some(
            (c) => c === this.props.color.hue
          )
        ) {
          this.setState({ checked: true });
        }
      } else if (
        this.props.abundance === "2nd" &&
        nextProps.coral.searchCriteria.primaryHues2.length > 0
      ) {
        if (
          nextProps.coral.searchCriteria.primaryHues2.some(
            (c) => c === this.props.color.hue
          )
        ) {
          this.setState({ checked: true });
        }
      } else if (
        this.props.abundance === "3rd" &&
        nextProps.coral.searchCriteria.primaryHues3.length > 0
      ) {
        if (
          nextProps.coral.searchCriteria.primaryHues3.some(
            (c) => c === this.props.color.hue
          )
        ) {
          this.setState({ checked: true });
        }
      } else {
        this.setState({ checked: false });
      }
    }

    //this turns the slector in the coral registration into a radio button
    if (this.props.currentColor) {
      if (nextProps.currentColor !== this.props.color.hue) {
        this.setState({ checked: false });
      }
    }
  }

  toggleCheck = () => {
    //sends the previous value so we don't have to wait for state to update
    this.props.updateColor(
      this.props.color.hue,
      this.state.checked,
      this.props.abundance
    );
    this.setState({ checked: !this.state.checked });
    // this.props.updateColor(this.props.color.hue);
  };
  render() {
    const { color } = this.props;

    return (
      <>
        {/* {!this.state.loading ? ( */}
        <div
          className={styles.MadeBox}
          style={{
            // backgroundColor: `rgb(${color.hue.r}, ${color.hue.g}, ${
            //   color.hue.b
            // } )`,
            backgroundColor: `#${color.hue}`,
            height: "26px",
            width: "26px",
            margin: 2,
            borderRadius: 2,
            textAlign: "center",
            "&:hover": {
              backgroundColor: "#ff0000",
            },
          }}
          onClick={this.toggleCheck}
        >
          {this.state.checked ? (
            <i
              className="fa fa-check"
              style={{ color: "white", fontSize: "1.5rem" }}
            />
          ) : null}
        </div>
        {/* ) : null} */}
      </>
    );
  }
}

ColorCheckBox.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  coral: state.coral,
});

export default connect(mapStateToProps, {})(ColorCheckBox);

import React from 'react'
import axios from 'axios'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { Input, Button } from 'reactstrap'

class AddCartRow extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      errors: {},

      coralNames: [],
      name: '',
      exists: false,
      added: false
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })

    this.props.selectFrag({
      coralId: this.props.componentCoral._id,
      frag: e.target.value
    })
    // console.log(e.target.value)
  }

  render () {
    // console.log(this.state)
    return (
      <>
        {this.props.componentCoral.frags.length > 0 ? (
          <>
            <Input
              type='select'
              name='frag'
              id='exampleSelect'
              value={this.state.frag}
              style={{ height: '3rem' }}
              onChange={this.onChange}
              // disabled={this.props.disabled}
            >
              <option>Select</option>
              {this.props.componentCoral.frags.map(frag => (
                <>
                  {frag.status === 'active' ? (
                    <option>
                      {frag._id
                        .toString()
                        .substr(frag._id.toString().length - 4)}
                    </option>
                  ) : null}
                </>
              ))}
            </Input>

            {/* <Td className='text-center'>
              <div style={{ height: '5rem', margin: 3 }}>
                <img
                  style={{ height: '100%', borderRadius: 5 }}
                  src={`https://s3.amazonaws.com/reefconnector/${this.props.componentCoral.frags[0].image.address}`}
                />
              </div>
            </Td> */}
          </>
        ) : (
          <span>-</span>
        )}
      </>
    )
  }
}

AddCartRow.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, {})(AddCartRow)

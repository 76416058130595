import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { memberCoral, myCoral } from 'actions/coralActions'
import { getMyCart } from 'actions/authActions'
import { getUser } from 'actions/communityActions'
import { getMyTradeSales, getMyTradePurchases } from 'actions/authActions'
import axios from 'axios'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

// reactstrap components
import {
  Button,
  Container,
  Input,
  Label,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  FormGroup
} from 'reactstrap'

class Login extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      // cart: [],
      // addedCoral: [],
      // moneyOffer: 0,
      message: null,
      // localCart: null,
      trade: { trade: null, messages: null },
      notFound: false,
      // email: "",
      // password: "",
      errors: {}
    }
  }

  componentDidMount () {
    // axios
    //   .get(`/api/users/gettradesummary/${this.props.match.params.id}`)
    //   .then(res => {
    //     console.log(res.data)
    //     this.setState({ trade: res.data })
    //   })
    //   .catch(err => console.log(err))
    this.getTrade()
  }

  getTrade = () => {
    axios
      .get(`/api/users/gettradesummary/${this.props.match.params.id}`)
      .then(res => {
        console.log(res.data)
        if (res.data === 'not found') {
          this.setState({ notFound: true })
        } else {
          this.setState({ trade: res.data })
        }
      })
      .catch(err => console.log(err))
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  addMessage = () => {
    console.log(this.state.message)
    axios
      .post(`/api/users/sendmessage`, {
        message: this.state.message,
        topicId: this.state.trade.trade.topicId
      })
      .then(res => {
        console.log(res.data)
        this.setState({ message: '' })
        this.getTrade()
      })
      .catch(err => console.log(err))
  }

  markComplete = action => {
    console.log(action)
    let actionBody = {
      action: action,
      tradeId: this.state.trade.trade._id,
      buyer: false,
      seller: false
    }
    if (this.state.trade.trade.buyer._id === this.props.auth.user.id) {
      actionBody.buyer = true
    } else {
      actionBody.seller = true
    }
    axios
      .post(`/api/users/markcomplete`, {
        actionBody
      })
      .then(res => {
        console.log(res.data)
        if (this.props.auth.myTradeSales) {
          this.props.getMyTradeSales()
        }
        if (this.props.auth.myTradePurchases) {
          this.props.getMyTradePurchases()
        }

        if (this.props.coral.myCoral) {
          this.props.myCoral()
        }

        // this.setState({ message: '' })
        if (action === 'ended') {
          // if (this.props.auth.myTradeSales) {
          //   this.props.getMyTradeSales()
          // }
          // if (this.props.auth.myTradePurchases) {
          //   this.props.getMyTradePurchases()
          // }

          this.props.history.goBack()
        } else {
          this.getTrade()
        }
      })
      .catch(err => console.log(err))
  }

  render () {
    const { trade } = this.state.trade
    const { messages } = this.state.trade
    console.log(trade)
    let tradeIdString = ''
    let userSide = null
    if (trade && trade.buyer._id === this.props.auth.user.id) {
      userSide = 'buyer'
    } else {
      userSide = 'seller'
    }

    let tradeEnded = false
    if (trade) {
      tradeIdString = trade._id.toString()
    }

    if (
      (trade && userSide === 'buyer' && trade.buyerStatus === 'ended') ||
      (trade && userSide === 'seller' && trade.sellerStatus === 'ended')
    ) {
      tradeEnded = true
    }

    return (
      <div className='login-page'>
        {trade ? (
          <>
            {trade && !tradeEnded ? (
              <Container>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <h4 style={{ textAlign: 'center', marginTop: 10 }}>
                    Trade Summary - #
                    {tradeIdString.substr(tradeIdString.length - 4)}
                  </h4>
                </div>
                {/* <div>
                  <h6
                    style={{
                      textAlign: 'center',
                      marginTop: 10
                    }}
                  >
                    Status - <span className='text-success'>Completed</span>
                  </h6>
                </div> */}

                <>
                  <div
                    style={{
                      border: '1px solid lightgrey',
                      borderRadius: 5,
                      padding: 10
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: 5
                        }}
                      >
                        <img
                          src={trade.seller.avatar}
                          style={{ marginRight: 10, borderRadius: 50 }}
                        />
                        <h5 style={{ textAlign: 'center' }}>
                          {trade.seller.username} Gives
                        </h5>
                      </div>

                      <Table>
                        <Thead>
                          <Tr style={{ background: '#F2F3F5' }}>
                            <Th className='text-center'>Mother</Th>
                            <Th className='text-center'>Type</Th>
                            <Th>Genus</Th>
                            <Th>Name</Th>
                            <Th className='text-center'>Color</Th>
                            <Th className='text-center'>Value</Th>
                            <Th className='text-center'>Trade</Th>
                            <Th className='text-center'>Frag</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {trade.sellerCoral.map(coral => (
                            <Tr>
                              <Td className='text-center'>
                                <Link to={`/coralshow/${coral.coral._id}`}>
                                  {coral.coral.image ? (
                                    <img
                                      style={{
                                        maxHeight: '8rem',
                                        maxWidth: '8rem',
                                        borderRadius: 5
                                      }}
                                      src={`https://s3.amazonaws.com/reefconnector/${coral.coral.image.address}`}
                                    />
                                  ) : (
                                    <i
                                      className='fa fa-image'
                                      style={{ fontSize: 64 }}
                                    />
                                  )}
                                </Link>
                              </Td>
                              <Td className='text-center'>
                                {coral.coral.type}
                              </Td>
                              <Td>{coral.coral.genus}</Td>

                              <Td>
                                {coral.coral.name ? (
                                  <Link
                                    to={`/coralnames/${coral.coral.name._id}`}
                                  >
                                    <span
                                      style={{ textTransform: 'capitalize' }}
                                      className='text-info'
                                    >
                                      {coral.coral.name.name}
                                    </span>
                                  </Link>
                                ) : (
                                  <>
                                    {coral.coral.unverifiedName ? (
                                      <span>
                                        <span
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                        >
                                          {coral.coral.unverifiedName}
                                        </span>{' '}
                                      </span>
                                    ) : (
                                      <span>N/A</span>
                                    )}
                                  </>
                                )}
                              </Td>
                              {coral.coral.extracted150Hex ? (
                                <Td className='text-center'>
                                  <Link
                                    to={`/coralcolorshow/${coral.coral.extracted150Hex}/${coral.genus}`}
                                  >
                                    <div
                                      style={{
                                        background: `#${coral.coral.extracted150Hex}`,
                                        height: 20,
                                        width: 20,
                                        textAlign: 'center',
                                        borderRadius: 5,
                                        margin: 'auto'
                                      }}
                                    />
                                  </Link>
                                </Td>
                              ) : (
                                <Td className='text-center'>N/A</Td>
                              )}
                              <Td className='text-center'>
                                <span>
                                  $
                                  {
                                    coral.coral.frags[
                                      coral.coral.frags
                                        .map(frag => frag._id)
                                        .indexOf(coral.frag)
                                    ].price
                                  }
                                </span>
                              </Td>
                              <Td className='text-center'>
                                {coral.coral.forTrade ? (
                                  <span>
                                    <i className='fa fa-check text-success' />
                                  </span>
                                ) : (
                                  <i className='fa fa-times' />
                                )}
                              </Td>
                              <Td className='text-center'>
                                {coral.frag ? (
                                  <img
                                    style={{
                                      maxHeight: '7rem',
                                      maxWidth: '7rem',
                                      borderRadius: 5
                                    }}
                                    src={`https://s3.amazonaws.com/reefconnector/${
                                      coral.coral.frags[
                                        coral.coral.frags
                                          .map(frag => frag._id)
                                          .indexOf(coral.frag)
                                      ].image.address
                                    }`}
                                  />
                                ) : (
                                  <span>-</span>
                                )}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        fontSize: 20,
                        justifyContent: 'center',
                        margin: 10
                        // border: '1px solid blue'
                      }}
                    >
                      <i
                        className='fa fa-arrow-down text-info'
                        style={{ margin: 8 }}
                      />
                      <i
                        className='fa fa-arrow-up text-info'
                        style={{ margin: 8 }}
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: 5
                        }}
                      >
                        <img
                          src={trade.buyer.avatar}
                          style={{ marginRight: 10, borderRadius: 50 }}
                        />
                        <h5 style={{ textAlign: 'center' }}>
                          {trade.buyer.username} Gives
                        </h5>
                      </div>
                      <Table>
                        <Thead>
                          <Tr style={{ background: '#F2F3F5' }}>
                            {/* <Th className='text-center'>Offer Money ($)</Th> */}
                            <Th style={{ textAlign: 'center' }}>Cash ($)</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr className='text-center'>
                            {/* <Td >
                              <i className='fa fa-check text-success' />
                            </Td> */}
                            <Td>
                              {/* <span style={{ textAlign: 'center' }}> */}$
                              {trade.moneyOffer}
                              {/* </span> */}
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                      <div style={{ height: 10 }} />
                      {trade.buyerCoral.length > 0 ? (
                        <Table>
                          <Thead>
                            <Tr style={{ background: '#F2F3F5' }}>
                              <Th className='text-center'>Mother</Th>
                              <Th className='text-center'>Type</Th>
                              <Th>Genus</Th>
                              <Th>Name</Th>
                              <Th className='text-center'>Color</Th>
                              <Th className='text-center'>Value</Th>
                              <Th className='text-center'>Trade</Th>
                              <Th className='text-center'>Frag</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {trade.buyerCoral.map(coral => (
                              <Tr>
                                <Td className='text-center'>
                                  <Link to={`/coralshow/${coral.coral._id}`}>
                                    {coral.coral.image ? (
                                      <img
                                        style={{
                                          maxHeight: '8rem',
                                          maxWidth: '8rem',
                                          borderRadius: 5
                                        }}
                                        src={`https://s3.amazonaws.com/reefconnector/${coral.coral.image.address}`}
                                      />
                                    ) : (
                                      <i
                                        className='fa fa-image'
                                        style={{ fontSize: 64 }}
                                      />
                                    )}
                                  </Link>
                                </Td>
                                <Td className='text-center'>
                                  {coral.coral.type}
                                </Td>
                                <Td>{coral.coral.genus}</Td>

                                <Td>
                                  {coral.coral.name ? (
                                    <Link
                                      to={`/coralnames/${coral.coral.name._id}`}
                                    >
                                      <span
                                        style={{ textTransform: 'capitalize' }}
                                        className='text-info'
                                      >
                                        {coral.coral.name.name}
                                      </span>
                                    </Link>
                                  ) : (
                                    <>
                                      {coral.coral.unverifiedName ? (
                                        <span>
                                          <span
                                            style={{
                                              textTransform: 'capitalize'
                                            }}
                                          >
                                            {coral.coral.unverifiedName}
                                          </span>{' '}
                                        </span>
                                      ) : (
                                        <span>N/A</span>
                                      )}
                                    </>
                                  )}
                                </Td>
                                {coral.coral.extracted150Hex ? (
                                  <Td className='text-center'>
                                    <Link
                                      to={`/coralcolorshow/${coral.coral.extracted150Hex}/${coral.coral.genus}`}
                                    >
                                      <div
                                        style={{
                                          background: `#${coral.coral.extracted150Hex}`,
                                          height: 20,
                                          width: 20,
                                          textAlign: 'center',
                                          borderRadius: 5,
                                          margin: 'auto'
                                        }}
                                      />
                                    </Link>
                                  </Td>
                                ) : (
                                  <Td className='text-center'>N/A</Td>
                                )}
                                <Td className='text-center'>
                                  <span>
                                    $
                                    {
                                      coral.coral.frags[
                                        coral.coral.frags
                                          .map(frag => frag._id)
                                          .indexOf(coral.frag)
                                      ].price
                                    }
                                  </span>
                                </Td>
                                <Td className='text-center'>
                                  {coral.coral.forTrade ? (
                                    <span>
                                      <i className='fa fa-check text-success' />
                                    </span>
                                  ) : (
                                    <i className='fa fa-times' />
                                  )}
                                </Td>
                                <Td className='text-center'>
                                  {coral.frag ? (
                                    <img
                                      style={{
                                        maxHeight: '7rem',
                                        maxWidth: '7rem',
                                        borderRadius: 5
                                      }}
                                      src={`https://s3.amazonaws.com/reefconnector/${
                                        coral.coral.frags[
                                          coral.coral.frags
                                            .map(frag => frag._id)
                                            .indexOf(coral.frag)
                                        ].image.address
                                      }`}
                                    />
                                  ) : (
                                    <span>-</span>
                                  )}
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      ) : (
                        <p
                          style={{
                            textAlign: 'center',
                            textTransform: 'capitalize'
                          }}
                        >
                          {trade.buyer.username} gives no coral
                        </p>
                      )}
                    </div>
                    <hr />

                    <h5 style={{ textAlign: 'center' }}>
                      status -{' '}
                      {trade.tradeStatus === 'active' ? (
                        <span className='text-info'>{trade.tradeStatus}</span>
                      ) : null}
                      {trade.tradeStatus === 'complete' ? (
                        <span className='text-success'>
                          {trade.tradeStatus}
                        </span>
                      ) : null}
                      {trade.tradeStatus === 'pending' ? (
                        <span className='text-warning'>
                          {trade.tradeStatus}
                        </span>
                      ) : null}
                      {trade.tradeStatus === 'ended' ? (
                        <span className='text-danger'>{trade.tradeStatus}</span>
                      ) : null}
                    </h5>
                    <div style={{ padding: 20 }}>
                      <ul>
                        <li style={{ display: 'inline-block', margin: 8 }}>
                          <div style={{ display: 'inline-block' }}>
                            {trade.buyer.username}:
                          </div>{' '}
                          <div>Sent trade request</div>
                        </li>
                        {trade.buyerStatus === 'complete' ? (
                          <li style={{ display: 'inline-block', margin: 8 }}>
                            <div style={{ display: 'inline-block' }}>
                              {trade.buyer.username}:
                            </div>{' '}
                            <div className='text-success'>
                              Marked trade complete
                            </div>
                          </li>
                        ) : null}
                        {trade.buyerStatus === 'ended' ? (
                          <li style={{ display: 'inline-block', margin: 8 }}>
                            <div style={{ display: 'inline-block' }}>
                              {trade.buyer.username}:
                            </div>{' '}
                            <div className='text-danger'>Ended Trade</div>
                          </li>
                        ) : null}
                        {trade.sellerStatus === 'complete' ? (
                          <li style={{ display: 'inline-block', margin: 8 }}>
                            <div style={{ display: 'inline-block' }}>
                              {trade.seller.username}:
                            </div>{' '}
                            <div className='text-success'>
                              Marked trade complete
                            </div>
                          </li>
                        ) : null}
                        {trade.sellerStatus === 'ended' ? (
                          <li style={{ display: 'inline-block', margin: 8 }}>
                            <div style={{ display: 'inline-block' }}>
                              {trade.seller.username}:
                            </div>{' '}
                            <div className='text-danger'>Ended Trade</div>
                          </li>
                        ) : null}
                        {trade.tradeStatus === 'complete' ? (
                          <li style={{ display: 'inline-block', margin: 8 }}>
                            <div style={{ display: 'inline-block' }}>
                              System :
                            </div>{' '}
                            <div className='text-success'>
                              Successfully transfered coral data
                            </div>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                    <hr />

                    <h5 style={{ textAlign: 'center' }}>message history</h5>
                    <div style={{ padding: 20 }}>
                      {messages.map((message, index) => (
                        <ul>
                          <li>
                            <div style={{ display: 'inline-block' }}>
                              {message.username}:
                            </div>{' '}
                            {index > 0 ? (
                              <div style={{ marginLeft: 10 }}>
                                {message.cooked.replace(/<\/?[^>]+(>|$)/g, '')}
                              </div>
                            ) : (
                              <div style={{ marginLeft: 10 }}>
                                {trade.message}
                              </div>
                            )}
                          </li>
                        </ul>
                      ))}
                    </div>
                    {trade.tradeStatus !== 'ended' ? (
                      <>
                        <FormGroup
                          style={{ marginTop: 10, textAlign: 'center' }}
                        >
                          <Label
                            for='exampleText'
                            style={{ textAlign: 'center' }}
                          >
                            Add Message
                          </Label>
                          <Input
                            type='textarea'
                            name='message'
                            id='exampleText'
                            value={this.state.message}
                            onChange={e => this.onChange(e)}
                          />
                        </FormGroup>
                      </>
                    ) : null}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      {trade.tradeStatus !== 'ended' ? (
                        <>
                          <div>
                            <Button
                              color='info'
                              style={{ margin: 10 }}
                              onClick={this.addMessage}
                              disabled={this.state.message ? false : true}
                            >
                              Send Message
                            </Button>
                          </div>
                        </>
                      ) : null}

                      <div>
                        {(userSide === 'buyer' &&
                          trade.buyerStatus !== 'complete' &&
                          trade.sellerStatus !== 'ended') ||
                        (userSide === 'seller' &&
                          trade.sellerStatus !== 'complete' &&
                          trade.buyerStatus !== 'ended') ? (
                          <Button
                            // id='PopoverLegacyComplete'
                            type='button'
                            color='success'
                            style={{ margin: 10 }}
                            onClick={() => this.markComplete('complete')}
                          >
                            Mark Complete
                          </Button>
                        ) : null}
                        {/* {(userSide === 'buyer' &&
                          trade.buyerStatus !== 'complete' &&
                          trade.buyerStatus !== 'ended') ||
                        (userSide === 'seller' &&
                          trade.sellerStatus !== 'complete' &&
                          trade.sellerStatus !== 'ended') ||
                        (userSide === 'buyer' &&
                          trade.sellerStatus === 'ended' &&
                          trade.buyerStatus !== 'ended') ||
                        (userSide === 'seller' &&
                          trade.buyerStatus === 'ended' &&
                          trade.sellerStatus !== 'ended') ? (
                          <>
                            <Button
                              id='PopoverLegacy'
                              type='button'
                              color='danger'
                              style={{ margin: 10 }}
                            >
                              Delete Trade
                            </Button>

                            <UncontrolledPopover
                              trigger='legacy'
                              placement='bottom'
                              target='PopoverLegacy'
                            >
                              <PopoverHeader>Delete Trade</PopoverHeader>
                              <PopoverBody>
                                Deleting this trade will terminate the trade and
                                no coral data will be transfered between
                                parties. Do you wish to continue?
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <Button
                                    color='danger'
                                    style={{ padding: 10 }}
                                    onClick={() => this.markComplete('ended')}
                                  >
                                    Confirm Delete
                                  </Button>
                                </div>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </>
                        ) : null} */}
                        {userSide === 'seller' &&
                        trade.sellerStatus !== 'complete' &&
                        trade.buyerStatus !== 'ended' ? (
                          <>
                            <Button
                              id='PopoverLegacy'
                              type='button'
                              color='danger'
                              style={{ margin: 10 }}
                            >
                              Decline Trade
                            </Button>

                            <UncontrolledPopover
                              trigger='legacy'
                              placement='bottom'
                              target='PopoverLegacy'
                            >
                              <PopoverHeader>Decline Trade</PopoverHeader>
                              <PopoverBody>
                                Declining this trade will terminate the trade
                                and no coral data will be transfered between
                                parties. Do you wish to continue?
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <Button
                                    color='danger'
                                    style={{ padding: 10 }}
                                    onClick={() => this.markComplete('ended')}
                                  >
                                    Confirm Decline
                                  </Button>
                                </div>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </>
                        ) : null}
                        {userSide === 'seller' &&
                        trade.buyerStatus === 'ended' &&
                        trade.sellerStatus !== 'ended' ? (
                          <>
                            <Button
                              id='PopoverLegacy'
                              type='button'
                              color='danger'
                              style={{ margin: 10 }}
                            >
                              End Trade
                            </Button>

                            <UncontrolledPopover
                              trigger='legacy'
                              placement='bottom'
                              target='PopoverLegacy'
                            >
                              <PopoverHeader>End Trade</PopoverHeader>
                              <PopoverBody>
                                Ending this trade will terminate the trade and
                                no coral data will be transfered between
                                parties. Do you wish to continue?
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <Button
                                    color='danger'
                                    style={{ padding: 10 }}
                                    onClick={() => this.markComplete('ended')}
                                  >
                                    Confirm End
                                  </Button>
                                </div>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </>
                        ) : null}

                        {userSide === 'buyer' &&
                        trade.buyerStatus !== 'complete' &&
                        trade.sellerStatus !== 'ended' ? (
                          <>
                            <Button
                              id='PopoverLegacy'
                              type='button'
                              color='danger'
                              style={{ margin: 10 }}
                            >
                              Cancel Trade
                            </Button>

                            <UncontrolledPopover
                              trigger='legacy'
                              placement='bottom'
                              target='PopoverLegacy'
                            >
                              <PopoverHeader>Cancel Trade</PopoverHeader>
                              <PopoverBody>
                                Canceling this trade will terminate the trade
                                and no coral data will be transfered between
                                parties. Do you wish to continue?
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <Button
                                    color='danger'
                                    style={{ padding: 10 }}
                                    onClick={() => this.markComplete('ended')}
                                  >
                                    Confirm Cancel
                                  </Button>
                                </div>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </>
                        ) : null}
                        {userSide === 'buyer' &&
                        trade.sellerStatus === 'ended' &&
                        trade.buyerStatus !== 'ended' ? (
                          <>
                            <Button
                              id='PopoverLegacy'
                              type='button'
                              color='danger'
                              style={{ margin: 10 }}
                            >
                              End Trade
                            </Button>

                            <UncontrolledPopover
                              trigger='legacy'
                              placement='bottom'
                              target='PopoverLegacy'
                            >
                              <PopoverHeader>End Trade</PopoverHeader>
                              <PopoverBody>
                                Ending this trade will terminate the trade and
                                no coral data will be transfered between
                                parties. Do you wish to continue?
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <Button
                                    color='danger'
                                    style={{ padding: 10 }}
                                    onClick={() => this.markComplete('ended')}
                                  >
                                    Confirm End
                                  </Button>
                                </div>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              </Container>
            ) : (
              <>
                <div style={{ height: 20 }}></div>
                <h4 style={{ textAlign: 'center' }}>
                  Trade does not exist or has ended.
                </h4>
              </>
            )}
          </>
        ) : (
          <>
            {this.state.notFound ? (
              <h4 style={{ textAlign: 'center' }}>
                Trade not found or expired.
              </h4>
            ) : (
              <h4>Loading...</h4>
            )}
          </>
        )}
      </div>
    )
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,

  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral,
  community: state.community,
  errors: state.errors
})

export default connect(mapStateToProps, {
  memberCoral,
  getMyCart,
  getUser,
  myCoral,
  getMyTradePurchases,
  getMyTradeSales
})(Login)

export const GET_ERRORS = 'GET_ERRORS'
export const GET_GLOBAL_ERRORS = 'GET_GLOBAL_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const GET_PROFILE = 'GET_PROFILE'
export const PROFILE_LOADING = 'PROFILE_LOADING'
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND'
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE'
export const GET_PROFILES = 'GET_PROFILES'
export const POST_LOADING = 'POST_LOADING'
export const CORAL_LOADING = 'CORAL_LOADING'
export const CLEAR_CORAL_LOADING = 'CLEAR_CORAL_LOADING'
export const ITEM_LOADING = 'ITEM_LOADING'
export const GET_POSTS = 'GET_POSTS'
export const GET_PROFILE_POSTS = 'GET_PROFILE_POSTS'
export const GET_POST = 'GET_POST'
export const ADD_POST = 'ADD_POST'
export const DELETE_POST = 'DELETE_POST'
export const ADD_ITEM = 'ADD_ITEM'
export const SET_CURRENT_USER_INFO = 'SET_CURRENT_USER_INFO'
export const SET_CURRENT_USER_EXTENDED_INFO = 'SET_CURRENT_USER_EXTENDED_INFO'
export const SET_CURRENT_USER_BASIC_INFO = 'SET_CURRENT_USER_BASIC_INFO'
export const GET_PROFILE_IMAGES = 'GET_PROFILE_IMAGES'
export const GET_PROFILE_PRIMARY_IMAGE = 'GET_PROFILE_PRIMARY_IMAGE'
export const GET_CORAL_LIST = 'GET_CORAL_LIST'
export const CORAL_PAGE_SET = 'CORAL_PAGE_SET'
export const GET_CORAL_SEARCH_LIST = 'GET_CORAL_SEARCH_LIST'
export const GET_COMMUNITY_LIST = 'GET_COMMUNITY_LIST'
export const GET_CORAL = 'GET_CORAL'
export const GET_REQUESTS_RECEIVED = 'ET_REQUESTS_RECEIVED'
export const GET_REQUESTS_MADE = 'ET_REQUESTS_MADE'
export const GET_SHOPPING_BAG = 'GET_SHOPPING_BAG'
export const GET_MEMBER = 'GET_MEMBER'
export const GET_MEMBER_CORAL = 'GET_MEMBER_CORAL'
export const MEMBER_SEARCH_CRITERIA = 'MEMBER_SEARCH_CRITERIA'
export const CORAL_SEARCH_CRITERIA = 'CORAL_SEARCH_CRITERIA'
export const CORAL_SORT = 'CORAL_SORT'
export const GET_CURRENT_USER_SELLING_PREFERENCES =
  'GET_CURRENT_USER_SELLING_PREFERENCES'
export const UPDATE_CORAL_LIKE = 'UPDATE_CORAL_LIKE'
export const SET_SHARE_URL = 'SET_SHARE_URL'
export const COMMUNITY_PAGE_SET = 'COMMUNITY_PAGE_SET'
export const GET_STRAIN_LIST = 'GET_STRAIN_LIST'
export const STRAIN_GO_BACK = 'STRAIN_GO_BACK'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING'
export const SET_SUBSCRIPTION_DATA = 'SET_SUBSCRIPTION_DATA'
export const SET_BRAINTREE_DATA = 'SET_BRAINTREE_DATA'
export const GET_OWNER_PROFILE_IMAGES = 'GET_OWNER_PROFILE_IMAGES'
export const SET_SELL_TRANSFERS = 'SET_SELL_TRANSFERS'
export const SET_BUY_TRANSFERS = 'SET_BUY_TRANSFERS'
export const GET_TAGGED_CORAL = 'GET_TAGGED_CORAL'
export const GET_RESTOPROJECT_INFO = 'GET_RESTOPROJECT_INFO'
export const AUTH_SET_LOADING = 'AUTH_SET_LOADING'
export const DEALS_LOADING = 'DEALS_LOADING'
export const GET_DEALS = 'GET_DEALS'
export const NAMES_LOADING = 'NAMES_LOADING'
export const GET_NAMES = 'GET_NAMES'
export const GET_WISH_LIST = 'GET_WISH_LIST'
export const GET_MY_CART = 'GET_MY_CART'
export const GET_MY_TRADE_SALES = 'GET_MY_TRADE_SALES'
export const GET_MY_TRADE_PURCHASES = 'GET_MY_TRADE_PURCHASES'
export const GET_MY_TRADES_COMPLETED = 'GET_MY_TRADES_COMPLETED'
export const GET_COMMUNITY_USERS = 'GET_COMMUNITY_USERS'
export const GET_USER_LOCATION = 'GET_USER_LOCATION'
export const GET_MY_MESSAGES = 'GET_MY_MESSAGES'

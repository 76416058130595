import {
  GET_COMMUNITY_LIST,
  GET_MEMBER,
  GET_MEMBER_CORAL,
  MEMBER_SEARCH_CRITERIA,
  COMMUNITY_PAGE_SET,
  GET_COMMUNITY_USERS
} from '../actions/types'

const initialState = {
  allUsers: null,
  member: null,
  memberCoral: null,
  searchCriteria: null,
  count: null,
  page: 1,
  limit: null,
  loading: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMMUNITY_USERS:
      return {
        ...state,
        allUsers: action.payload,
        loading: false
      }
    case GET_COMMUNITY_LIST:
      return {
        ...state,
        allUsers: action.payload.userList,
        count: action.payload.count,
        limit: action.payload.limit,
        loading: false
      }
    case COMMUNITY_PAGE_SET:
      return {
        ...state,
        page: action.payload,
        loading: false
      }
    case GET_MEMBER:
      return {
        ...state,
        member: action.payload,
        loading: false
      }
    case GET_MEMBER_CORAL:
      return {
        ...state,
        memberCoral: action.payload,
        loading: false
      }
    case MEMBER_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: action.payload,
        loading: false
      }
    default:
      return state
  }
}

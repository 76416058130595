import isEmpty from '../validation/is-empty'

import {
  SET_CURRENT_USER,
  SET_CURRENT_USER_INFO,
  SET_CURRENT_USER_EXTENDED_INFO,
  SET_CURRENT_USER_BASIC_INFO,
  GET_CURRENT_USER_SELLING_PREFERENCES,
  SET_SHARE_URL,
  SET_SUBSCRIPTION_DATA,
  SET_BRAINTREE_DATA,
  SET_SELL_TRANSFERS,
  SET_BUY_TRANSFERS,
  GET_RESTOPROJECT_INFO,
  AUTH_SET_LOADING,
  GET_WISH_LIST,
  GET_MY_CART,
  GET_MY_TRADE_SALES,
  GET_MY_TRADE_PURCHASES,
  GET_MY_TRADES_COMPLETED,
  GET_USER_LOCATION,
  GET_MY_MESSAGES
} from '../actions/types'

const initialState = {
  isAuthenticated: false,
  user: {
    basicInfo: null
  },
  location: null,
  wishList: null,
  extendedInfo: null,
  sellingPreferences: null,
  lists: {
    lengths: null,
    favorites: {
      fish: null,
      corals: null,
      inverts: null,
      equip: null
    },
    following: null,
    privateMessages: null,
    notifications: null,
    followers: null
  },
  socialMediaFeed: null,
  buyTransfers: null,
  sellTransfers: null,
  shareUrl: null,
  restoProjectInfo: null,
  myCart: null,
  myTradeSales: null,
  myTradePurchases: null,
  myTradesCompleted: null,
  myMessages: null,
  loading: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_SET_LOADING:
      return { ...state, loading: true }
    case GET_WISH_LIST:
      return { ...state, wishList: action.payload }
    case GET_MY_CART:
      return { ...state, myCart: action.payload }
    case GET_MY_MESSAGES:
      return { ...state, myMessages: action.payload }
    case GET_MY_TRADE_SALES:
      return { ...state, myTradeSales: action.payload }
    case GET_MY_TRADE_PURCHASES:
      return { ...state, myTradePurchases: action.payload }
    case GET_MY_TRADES_COMPLETED:
      return { ...state, myTradesCompleted: action.payload }
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      }
    case SET_CURRENT_USER_INFO:
      return {
        ...state,
        info: action.payload
      }
    case GET_USER_LOCATION:
      return {
        ...state,
        location: action.payload
      }
    case SET_CURRENT_USER_BASIC_INFO:
      return {
        ...state,
        user: { ...state.user, basicInfo: action.payload }
      }
    case SET_CURRENT_USER_EXTENDED_INFO:
      return {
        ...state,
        extendedInfo: action.payload
      }
    case GET_CURRENT_USER_SELLING_PREFERENCES:
      return {
        ...state,
        sellingPreferences: action.payload
      }
    case SET_SHARE_URL:
      return {
        ...state,
        shareUrl: action.payload
      }
    case SET_BUY_TRANSFERS:
      return {
        ...state,
        buyTransfers: action.payload
      }
    case SET_SELL_TRANSFERS:
      return {
        ...state,
        sellTransfers: action.payload
      }
    case SET_SUBSCRIPTION_DATA:
      return { ...state, subscription: action.payload }
    case SET_BRAINTREE_DATA:
      return { ...state, braintree: action.payload }
    case GET_RESTOPROJECT_INFO:
      return { ...state, restoProject: action.payload, loading: false }
    default:
      return state
  }
}

import {
  GET_REQUESTS_RECEIVED,
  GET_REQUESTS_MADE,
  GET_SHOPPING_BAG
} from "../actions/types";

const initialState = {
  shoppingBag: null,
  requestsMade: null,
  requestsReceived: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SHOPPING_BAG:
      return {
        ...state,
        shoppingBag: action.payload
      };
    case GET_REQUESTS_RECEIVED:
      return {
        ...state,
        requestsReceived: action.payload
      };
    case GET_REQUESTS_MADE:
      return {
        ...state,
        requestsMade: action.payload
      };
    default:
      return state;
  }
}

import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { memberCoral } from 'actions/coralActions'
import { getMyCart } from 'actions/authActions'
import axios from 'axios'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

// reactstrap components
import { Button, Container, Alert } from 'reactstrap'

class Login extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      cart: [],

      // email: "",
      // password: "",
      errors: {}
    }
  }

  componentDidMount () {
    // console.log()
    // if (!this.props.community.memberCoral) {
    //   this.props.memberCoral(this.props.match.params.id)
    // } else {
    //   if (this.props.community.memberCoral.length > 0) {
    //     if (
    //       this.props.community.memberCoral[0].user !==
    //       this.props.match.params.id
    //     ) {
    //       this.props.memberCoral(this.props.match.params.id)
    //     }
    //   } else {
    //     this.props.memberCoral(this.props.match.params.id)
    //   }
    // }

    if (!this.props.auth.myCart) {
      this.props.getMyCart()
    }
  }

  addToCart = coralId => {
    axios
      .post('/api/users/addtocart', {
        userId: this.props.match.params.id,
        coralId: coralId
      })
      .then(res => {
        console.log(res.data)
        this.props.getMyCart()
      })
  }

  removeFromCart = (coralId, userId) => {
    axios
      .post('/api/users/removefromcart', {
        userId: userId,
        coralId: coralId
      })
      .then(res => {
        console.log(res.data)
        this.props.getMyCart()
      })
  }

  render () {
    const { memberCoral } = this.props.community
    const { myCart } = this.props.auth
    let cartIndex = -1
    let localCart = null

    if (myCart) {
      cartIndex = myCart
        .map(cart => cart.user)
        .indexOf(this.props.match.params.id)
      if (cartIndex != -1) {
        localCart = myCart[cartIndex]
      }
      console.log(myCart)
    }

    return (
      <div className='login-page'>
        {myCart ? (
          <>
            {myCart.length > 0 ? (
              <Container>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <h4 style={{ textAlign: 'center', marginTop: 10 }}>
                    My Cart
                  </h4>
                </div>

                <>
                  <Table>
                    <Thead>
                      <Tr style={{ background: '#F2F3F5' }}>
                        <Th className='text-center'>Collector</Th>
                        {/* <Th className='text-center'>Mother</Th> */}
                        {/* <Th className='text-center'>Type</Th>
                        <Th>Genus</Th>
                        <Th>Name</Th> */}
                        {/* <Th className='text-center'>Color</Th>
                        <Th className='text-center'>Price</Th>
                        <Th className='text-center'>Trade</Th> */}
                        <Th className='text-center'># of Frags</Th>
                        <Th className='text-center'>Cart</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {myCart.map(cart => (
                        <>
                          <Tr>
                            <Td className='text-center'>
                              <Link to={`/membercollection/${cart.user._id}`}>
                                <img
                                  style={{
                                    height: '3rem',
                                    width: '3rem',
                                    borderRadius: 50
                                  }}
                                  src={`${cart.user.avatar}`}
                                />
                                <div>{cart.user.username}</div>
                              </Link>
                            </Td>

                            <Td className='text-center'>{cart.coral.length}</Td>

                            <Td className='text-center'>
                              <>
                                <Link
                                  to={`/trade/${cart.user._id}`}
                                  style={{ marginRight: 5 }}
                                >
                                  <Button outline color='info'>
                                    View
                                  </Button>
                                </Link>
                              </>
                            </Td>
                          </Tr>
                        </>
                      ))}
                    </Tbody>
                  </Table>
                  {/* <Table>
                    <Thead>
                      <Tr style={{ background: '#F2F3F5' }}>
                        <Th className='text-center'>Collector</Th>
                        <Th className='text-center'>Mother</Th>
                        <Th className='text-center'>Type</Th>
                        <Th>Genus</Th>
                        <Th>Name</Th>
                        <Th className='text-center'>Color</Th>
                        <Th className='text-center'>Price</Th>
                        <Th className='text-center'>Trade</Th>
                        <Th className='text-center'>Frag</Th>
                        <Th className='text-center'>Cart</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {myCart.map(cart => (
                        <>
                          {cart.coral.map(coral => (
                            <Tr>
                              <Td className='text-center'>
                                <Link to={`/membercollection/${cart.user._id}`}>
                                  <img
                                    style={{
                                      height: '3rem',
                                      width: '3rem',
                                      borderRadius: 50
                                    }}
                                    src={`${cart.user.avatar}`}
                                  />
                                  <div>{cart.user.username}</div>
                                </Link>
                              </Td>
                              <Td className='text-center'>
                                <Link to={`/coralshow/${coral.coral._id}`}>
                                  {coral.coral.image ? (
                                    <img
                                      style={{
                                        maxHeight: '8rem',
                                        maxWidth: '8rem',
                                        borderRadius: 5
                                      }}
                                      src={`https://s3.amazonaws.com/reefconnector/${coral.coral.image.address}`}
                                    />
                                  ) : (
                                    <i
                                      className='fa fa-image'
                                      style={{ fontSize: 64 }}
                                    />
                                  )}
                                </Link>
                              </Td>
                              <Td className='text-center'>
                                {coral.coral.type}
                              </Td>
                              <Td>{coral.coral.genus}</Td>

                              <Td>
                                {coral.name ? (
                                  <Link to={`/coralnames/${coral.name._id}`}>
                                    <span
                                      style={{ textTransform: 'capitalize' }}
                                      className='text-info'
                                    >
                                      {coral.coral.name.name}
                                    </span>
                                  </Link>
                                ) : (
                                  <>
                                    {coral.coral.unverifiedName ? (
                                      <span>
                                        <span
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                        >
                                          {coral.coral.unverifiedName}
                                        </span>{' '}
                                      </span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </>
                                )}
                              </Td>
                              {coral.coral.extracted150Hex ? (
                                <Td className='text-center'>
                                  <Link
                                    to={`/coralcolorshow/${coral.coral.extracted150Hex}/${coral.coral.genus}`}
                                  >
                                    <div
                                      style={{
                                        background: `#${coral.coral.extracted150Hex}`,
                                        height: 20,
                                        width: 20,
                                        textAlign: 'center',
                                        borderRadius: 5,
                                        margin: 'auto'
                                      }}
                                    />
                                  </Link>
                                </Td>
                              ) : (
                                <Td className='text-center'>-</Td>
                              )}

                              <Td className='text-center'>
                                {coral.coral.frags[
                                  coral.coral.frags
                                    .map(frag => frag._id)
                                    .indexOf(coral.fragId)
                                ].price ? (
                                  <span>
                                    $
                                    {
                                      coral.coral.frags[
                                        coral.coral.frags
                                          .map(frag => frag._id)
                                          .indexOf(coral.fragId)
                                      ].price
                                    }
                                  </span>
                                ) : (
                                  <span>-</span>
                                )}
                              </Td>
                              <Td className='text-center'>
                                {coral.coral.forTrade ? (
                                  <span>
                                    <i className='fa fa-check text-success' />
                                  </span>
                                ) : (
                                  <i className='fa fa-times' />
                                )}
                              </Td>
                              <Td className='text-center'>
                                {coral.fragId ? (
                                  <img
                                    style={{
                                      maxHeight: '7rem',
                                      maxWidth: '7rem',
                                      borderRadius: 5
                                    }}
                                    src={`https://s3.amazonaws.com/reefconnector/${
                                      coral.coral.frags[
                                        coral.coral.frags
                                          .map(frag => frag._id)
                                          .indexOf(coral.fragId)
                                      ].image.address
                                    }`}
                                  />
                                ) : (
                                  <span>-</span>
                                )}
                              </Td>
                              <Td className='text-center'>
                                <>
                                  <Link
                                    to={`/trade/${cart.user._id}`}
                                    style={{ marginRight: 5 }}
                                  >
                                    <Button outline color='info'>
                                      Trade
                                    </Button>
                                  </Link>
                                  <Button
                                    outline
                                    color='danger'
                                    onClick={() =>
                                      this.removeFromCart(
                                        coral._id,
                                        cart.user._id
                                      )
                                    }
                                  >
                                    <i className='fa fa-trash-o' />
                                  </Button>
                                </>
                              </Td>
                            </Tr>
                          ))}
                        </>
                      ))}
                    </Tbody>
                  </Table> */}
                </>
              </Container>
            ) : (
              <h4 style={{ textAlign: 'center' }}>No items in your cart.</h4>
            )}
          </>
        ) : (
          <h4>Loading...</h4>
        )}
      </div>
    )
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral,
  community: state.community,
  errors: state.errors
})

export default connect(mapStateToProps, { memberCoral, getMyCart })(Login)

import { GET_ERRORS, CLEAR_ERRORS } from "../actions/types";

const initialState = {
  // global: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload;
    // case GET_GLOBAL_ERRORS:
    //   return {
    //     ...state,
    //     global: action.payload
    //   };
    case CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}

import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'
import EXIF from 'exif-js'

// import { Button } from "reactstrap";
import blueImp from 'blueimp-load-image'
import { detect } from 'detect-browser'

import defaultImage from 'assets/img/image_placeholder.jpg'
// import defaultAvatar from "assets/img/placeholder.jpg";
import defaultAvatar from 'assets/img/default-avatar.png'
import ReactCrop from 'react-image-crop'
import CropImgPreview from 'components/CropImgPreview/CropImgPreview.js'
// import Img from "react-fix-image-orientation";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal
} from 'reactstrap'

import 'react-image-crop/dist/ReactCrop.css'
const browser = detect()
class ImageUpload extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      file: null,
      fileName: '',
      // imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      imagePreviewUrl: this.props.currentImage
        ? this.props.currentImage
        : defaultImage,
      cropOpen: false,
      isBlob: false,
      orientation: 0,
      // orientationOffset: 1,
      add: true,
      browserError: ''
    }

    // this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  componentDidMount () {
    // handle the case where we don't detect the browser

    console.log(browser)
    switch (browser && browser.name) {
      case 'edge':
        console.log('edge')
        this.setState({
          browserError:
            'Image upload is not compatable with your browser! Please try using Chrome or Safari.'
        })
        break
      case 'ie':
        console.log('internet explorer')
        this.setState({
          browserError:
            'Image upload is not compatable with your browser! Please try using Chrome or Safari.'
        })
        break

      case 'edge-ios':
        console.log('edge-ios')
        this.setState({
          browserError:
            'Image upload is not compatable with your browser! Please try using Chrome or Safari.'
        })
        break

      // case "chrome":
      //   console.log("edge-ios");
      //   this.setState({
      //     browserError:
      //       "Image upload is not compatable with your browser! Please try using Chrome or Safari.",
      //   });
      //   break;

      default:
        console.log(browser, 'browser supported')
    }
  }

  handleImageChange = async e => {
    e.preventDefault()
    let file = e.target.files[0]
    var sendFile = file => {
      this.props.saveFileHandler(file)
      this.setState({ file: file })
    }

    var sendPreview = preview => {
      this.setState({ imagePreviewUrl: preview })
    }

    var setOrientation = orientation => {
      if (orientation === 8) {
        this.setState({ add: false })
      } else if (orientation === 1) {
        this.setState({ add: true })
      }
      this.setState({ orientation: orientation })
    }

    if (!file.name) {
      file.name = 'forcedfilename'
    }
    var orientation = 0
    if (file && file.name) {
      EXIF.getData(file, async function () {
        orientation = await EXIF.getTag(this, 'Orientation')
        // setOrientation(orientation);
        // if (!orientation) {
        //   orientation = 4;
        // }
        // console.log(orientation);
        // orientation = orientation + 4;
        // console.log(orientation);
        var can = document.createElement('canvas')
        var ctx = can.getContext('2d')
        var thisImage = new Image()
        thisImage.onload = function () {
          can.width = thisImage.width
          can.height = thisImage.height
          ctx.save()
          var width = can.width
          var styleWidth = can.style.width
          var height = can.height
          var styleHeight = can.style.height
          if (orientation) {
            if (orientation > 4) {
              can.width = height
              can.style.width = styleHeight
              can.height = width
              can.style.height = styleWidth
            }
            switch (orientation) {
              case 2:
                ctx.translate(width, 0)
                ctx.scale(-1, 1)
                break
              case 3:
                ctx.translate(width, height)
                ctx.rotate(Math.PI)
                break
              case 4:
                ctx.translate(0, height)
                ctx.scale(1, -1)
                break
              case 5:
                ctx.rotate(0.5 * Math.PI)
                ctx.scale(1, -1)
                break
              case 6:
                ctx.rotate(0.5 * Math.PI)
                ctx.translate(0, -height)
                break
              case 7:
                ctx.rotate(0.5 * Math.PI)
                ctx.translate(width, -height)
                ctx.scale(-1, 1)
                break
              case 8:
                ctx.rotate(-0.5 * Math.PI)
                ctx.translate(-width, 0)
                break
            }
          }

          ctx.drawImage(thisImage, 0, 0)
          ctx.restore()
          if (!orientation) {
            orientation = 4
          }
          setOrientation(orientation)
          can.toBlob(
            blob => {
              console.log(blob)
              let newFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now()
              })
              sendFile(newFile)
              sendPreview(URL.createObjectURL(blob))
            },
            'image/jpeg',
            0.9
          )
        }

        // now trigger the onload function by setting the src to your HTML5 file object (called 'file' here)
        thisImage.src = URL.createObjectURL(file)
      })
    }
  }

  turnImage = async (oldFile, newOrientation) => {
    window.URL.revokeObjectURL(this.state.imagePreviewUrl)
    // e.preventDefault();
    // let file = e.target.files[0];
    let file = oldFile
    var sendFile = file => {
      this.props.saveFileHandler(file)
      this.setState({ file: file })
    }

    var sendPreview = preview => {
      this.setState({ imagePreviewUrl: preview })
    }

    var setOrientation = orientation => {
      if (orientation === 8) {
        this.setState({ add: false })
      } else if (orientation === 1) {
        this.setState({ add: true })
      }
      this.setState({ orientation: orientation })
    }

    if (!file.name) {
      file.name = 'forcedfilename'
    }
    var orientation = newOrientation
    if (file && file.name) {
      EXIF.getData(file, async function () {
        // orientation = await EXIF.getTag(this, "Orientation");
        // setOrientation(orientation);
        // if (!orientation) {
        //   orientation = 4;
        // }
        // console.log(orientation);
        // orientation = orientation + 4;
        // console.log(orientation);
        var can = document.createElement('canvas')
        var ctx = can.getContext('2d')
        var thisImage = new Image()
        thisImage.onload = function () {
          can.width = thisImage.width
          can.height = thisImage.height
          ctx.save()
          var width = can.width
          var styleWidth = can.style.width
          var height = can.height
          var styleHeight = can.style.height
          if (orientation) {
            if (orientation > 4) {
              can.width = height
              can.style.width = styleHeight
              can.height = width
              can.style.height = styleWidth
            }
            switch (orientation) {
              case 2:
                ctx.translate(width, 0)
                ctx.scale(-1, 1)
                break
              case 3:
                ctx.translate(width, height)
                ctx.rotate(Math.PI)
                break
              case 4:
                ctx.translate(0, height)
                ctx.scale(1, -1)
                break
              case 5:
                ctx.rotate(0.5 * Math.PI)
                ctx.scale(1, -1)
                break
              case 6:
                ctx.rotate(0.5 * Math.PI)
                ctx.translate(0, -height)
                break
              case 7:
                ctx.rotate(0.5 * Math.PI)
                ctx.translate(width, -height)
                ctx.scale(-1, 1)
                break
              case 8:
                ctx.rotate(-0.5 * Math.PI)
                ctx.translate(-width, 0)
                break
            }
          }

          ctx.drawImage(thisImage, 0, 0)
          ctx.restore()
          if (!orientation) {
            orientation = 4
          }
          setOrientation(orientation)
          can.toBlob(
            blob => {
              console.log(blob)
              let newFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now()
              })
              sendFile(newFile)
              sendPreview(URL.createObjectURL(blob))
            },
            'image/jpeg',
            0.9
          )
        }

        // now trigger the onload function by setting the src to your HTML5 file object (called 'file' here)
        thisImage.src = URL.createObjectURL(file)
      })
    }
  }

  handleSubmit (e) {
    e.preventDefault()
  }

  handleClick () {
    this.refs.fileInput.click()
  }

  handleRemove () {
    this.setState({
      file: null,
      imagePreviewUrl: defaultImage
    })
    this.refs.fileInput.value = null
    this.props.saveFileHandler(null, null)
  }

  toggleCropModal = () => {
    this.setState({ cropOpen: !this.state.cropOpen })
  }

  render () {
    console.log(this.state)
    return (
      <>
        {/* <button onClick={() => console.log(this.state)}>Check State</button> */}
        <div className='fileinput text-center'>
          <input
            type='file'
            onChange={this.handleImageChange}
            ref='fileInput'
            style={{ display: 'none' }}
          />

          <div className={'thumbnail'}>
            {!this.state.imagePreviewUrl ? (
              <img
                src={defaultImage}
                alt='...'
                style={{ minHeight: '8rem', maxHeight: '20rem' }}
              />
            ) : (
              // <img src={this.props.currentImage} alt="..." />
              <img
                src={this.state.imagePreviewUrl}
                alt='...'
                style={{
                  height: this.props.logo ? '8rem' : '',
                  minHeight: '8rem',
                  maxHeight: '15rem'
                }}
              />
            )}
          </div>

          <div>
            {this.state.file === null ? (
              <>
                {this.state.browserError ? (
                  <div className='text-danger'>
                    <i className=' fa fa-exclamation-triangle' />{' '}
                    {this.state.browserError}
                  </div>
                ) : (
                  <Button
                    // outline
                    className='btn-round'
                    onClick={() => this.handleClick()}
                    disabled={this.props.disabled ? true : false}
                  >
                    <i className='fa fa-upload' />
                    {'  '}
                    {this.props.avatar
                      ? 'Add Photo'
                      : this.props.logo
                      ? 'Select Logo'
                      : 'Update Image'}
                  </Button>
                )}{' '}
              </>
            ) : (
              <span>
                {this.state.orientation ? (
                  <>
                    {/* <Label>Orienation Position: {this.state.orientation}</Label> */}
                    <Label>
                      <small>
                        Press <strong>TURN</strong> to correct orientation. It
                        may take a few times.
                      </small>
                    </Label>
                    <br />
                  </>
                ) : null}

                <Button
                  color='primary'
                  style={{ marginRight: 5 }}
                  onClick={() => this.toggleCropModal()}
                >
                  <i className='fa fa-crop' /> <small>Crop</small>
                </Button>

                <Button
                  color='primary'
                  // className='btn-round'
                  style={{ marginRight: 5 }}
                  onClick={() =>
                    this.turnImage(
                      this.state.file,
                      this.state.add
                        ? this.state.orientation + 1
                        : this.state.orientation - 1
                    )
                  }
                >
                  <i className='fa fa-undo' />
                  <small>Turn</small>
                </Button>
                {/* <Button
                  color="primary"
                  className="btn-round"
                  onClick={() =>
                    this.turnImage(this.state.file, this.state.orientation + 1)
                  }
                >
                  <i className="fa fa-share" />
                  <small>Turn</small>
                </Button> */}

                {/* <Label>
                  <small>Rotate</small>
                </Label> */}

                <Button
                  color='info'
                  style={{ marginRight: 5 }}
                  // className='btn-round'
                  onClick={() => this.handleClick()}
                >
                  Change
                </Button>
                {/* {this.props.avatar ? <br /> : null} */}
                <Button
                  color='danger'
                  style={{ marginRight: 5 }}
                  // className='btn-round'
                  onClick={() => this.handleRemove()}
                >
                  <i className='fa fa-times' />
                </Button>
              </span>
            )}
            <>
              {/* {this.props.auth.user.admin ? (
                <Button onClick={() => console.log(this.state)}>S</Button>
              ) : null} */}
            </>
            {/* <br />
            <small>
              * We are currently experiencing orientation problems for some
              Apple devices. (File types supported: jpeg and png)
            </small> */}
          </div>
        </div>

        <Modal
          className={`modal-lg`}
          isOpen={this.state.cropOpen}
          toggle={this.toggleCropModal}
          zIndex={'20000'}
        >
          <div className='modal-header'>
            {/* <button
              aria-hidden={true}
              className='close'
              data-dismiss='modal'
              type='button'
              onClick={this.toggleCropModal}
            >
              <i className='nc-icon nc-simple-remove' />
            </button> */}
            <h5
              style={{ textAlign: 'center' }}
              // className='modal-title'
              id='myModalLabel'
            >
              Image Crop
            </h5>
          </div>
          <div className='modal-body'>
            <small>
              *Cropper may take a few seconds to respond on mobile devices.
            </small>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CropImgPreview
                toggle={this.toggleCropModal}
                // primaryImage={this.state.imagePreviewUrl}
                upload={(file, preview) => {
                  this.props.saveFileHandler(file, preview)
                  this.setState({
                    file: file,
                    imagePreviewUrl: preview,
                    isBlob: true
                  })
                }}
                primaryImage={this.state.file}
                imagePreviewUrl={this.state.imagePreviewUrl}
              />
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool
}

export default ImageUpload

import React from "react";
import axios from "axios";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import CheckName from "components/CheckName/CheckName.js";

import {
  Container,
} from "reactstrap";

class CheckNames extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      coralNames: [],
    };
  }

  componentDidMount() {
    console.log("component did mount");
    axios.get("/api/coral/checkcoralnames/1").then((res) => {
      console.log(res.data);
      this.setState({ coralNames: res.data });
    });
  }

  saveName = () => {
    axios
      .post("/api/coral/savename", { newName: this.state.name })
      .then((res) => console.log(res));
  };

  render() {
    return (
      <>
        <Container>
          {this.state.coralNames.length > 0 ? (
            <>
              {this.state.coralNames.map((coralName) => (
                <>
                  <CheckName name={coralName} />
                </>
              ))}
            </>
          ) : null}
        </Container>
      </>
    );
  }
}

CheckNames.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(CheckNames);

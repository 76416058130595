import axios from 'axios'

import {
  GET_ERRORS,
  GET_COMMUNITY_LIST,
  GET_MEMBER,
  MEMBER_SEARCH_CRITERIA,
  COMMUNITY_PAGE_SET,
  GET_COMMUNITY_USERS
} from './types'

//post search members
export const getCommunityList = (criteria, page) => async dispatch => {
  const buildQuery = criteria => {
    const query = {}

    if (criteria.keyword) {
      query.$text = { $search: criteria.keyword }
    }

    if (criteria.location) {
      query.location = {
        $geoWithin: {
          //distance radians
          $centerSphere: [criteria.location, criteria.distance / 3959]
        }
      }
    }

    if (criteria.location && !criteria.keyword) {
      query.location = {
        $nearSphere: {
          $geometry: {
            type: 'Point',
            coordinates: criteria.location
          },
          //distance in metersc
          $minDistance: 0,
          $maxDistance: criteria.distance * 1609.34
        }
      }
    }

    if (criteria.withColonies) {
      query.colonyCount = {
        $gt: 0
      }
    }

    if (criteria.withFrags) {
      query.fragCount = {
        $gt: 0
      }
    }

    if (criteria.restorationGroups) {
      query.subscriptionPlanId = {
        $eq: 'Conservation'
      }
    }

    // query.$acceptTerms = { $search: true };

    // query = { "basicInfo.acceptTerms": { $eq: true } };
    // query.basicInfo.acceptTerms = { $eq: true };

    return query
  }

  // console.log(buildQuery(criteria));
  let search = {
    query: buildQuery(criteria),
    specialQuery: {
      acceptTerms: true,
      withProfile: criteria.withProfile
      // withColonies: criteria.withColonies,
      // withFrags: criteria.withFrags
    },
    page: page
  }

  axios
    .post('/api/users/searchusers', search)
    .then(res => {
      dispatch({
        type: GET_COMMUNITY_LIST,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Get community users
export const getCommunityUsers = search => dispatch => {
  // dispatch(setProfileLoading());
  // console.log("is this working?");
  axios
    .get(`/api/users/getcommunityusers${search}`)
    .then(res => {
      // console.log(res);
      dispatch({
        type: GET_COMMUNITY_USERS,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    })
}

//save search criteria
export const saveSearch = criteria => async dispatch => {
  dispatch({
    type: MEMBER_SEARCH_CRITERIA,
    payload: criteria
  })
}

//get individual member
export const getUser = id => async dispatch => {
  axios
    .get(`/api/users/getuser/${id}`)
    .then(res => {
      dispatch({
        type: GET_MEMBER,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    )
}

export const setPage = page => async dispatch => {
  dispatch({
    type: COMMUNITY_PAGE_SET,
    payload: page
  })
}

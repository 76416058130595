import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import Spinner from 'components/common/Spinner.js'

// reactstrap components
import { Button, Row } from 'reactstrap'

class CropImgPreview extends Component {
  state = {
    // croppedImageUrl: this.props.imagePreviewUrl,
    croppedImageUrl: null,
    primaryFile: null,
    src: null,
    crop: {
      //   unit: "%",
      //   width: 30,
      //   aspect: 16 / 9,
      //   aspect: 16 / 9,
      width: 80,
      height: 80,
      x: 5,
      y: 5
    },
    pixelCrop: null,
    loading: true
  }

  componentDidMount () {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      console.log(reader.result)
      this.setState({ src: reader.result })
    })
    this.setState({
      croppedImageUrl: reader.readAsDataURL(this.props.primaryImage)
    })
  }

  //   componentWillUpdate(nextProps, nextState) {
  //     console.log(nextState);
  //     if (nextState.croppedImageUrl && nextState.loading) {
  //       this.setState({ loading: false });
  //     }
  //   }

  // onSelectFile = e => {
  //     if (e.target.files && e.target.files.length > 0) {
  //         const reader = new FileReader();
  //         reader.addEventListener('load', () =>
  //             this.setState({ src: reader.result }),
  //         );
  //         reader.readAsDataURL(e.target.files[0]);
  //     }
  // };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image
    // this.makeClientCrop(this.state.crop, pixelCrop);
    console.log('On load Image', this.state.crop, pixelCrop)
    this.setState({ pixelCrop: pixelCrop, loading: false })
  }

  onCropComplete = (crop, pixelCrop) => {
    console.log(
      'crop completed',
      this.state.crop,
      crop,
      this.state.pixelCrop,
      pixelCrop
    )
    // this.makeClientCrop(crop, pixelCrop);
    this.setState({ crop: crop, pixelCrop: pixelCrop })
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  savePrimaryImage = () => {
    // console.log(this.state.primaryFile);
    // this.props.upload(this.state.primaryFile, this.state.croppedImageUrl);
    // window.URL.revokeObjectURL(this.fileUrl);
    this.makeClientCrop(this.state.crop, this.state.pixelCrop)
    this.setState({
      primaryFile: null,
      src: null
    })
    this.props.toggle()
  }

  cancelCrop = () => {
    console.log('crop canceled')
    window.URL.revokeObjectURL(this.fileUrl)
    this.props.toggle()
  }

  async makeClientCrop (crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        'newFile.jpeg'
      )
      console.log(croppedImageUrl)
      //   this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg (image, pixelCrop, fileName) {
    const canvas = document.createElement('canvas')
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty')
          return
        }
        // this.setState({ primaryFile: blob });
        blob.name = fileName
        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        this.props.upload(blob, this.fileUrl)
        resolve(this.fileUrl)
      }, 'image/jpeg')
    })
  }

  render () {
    const { crop, croppedImageUrl, src } = this.state
    // console.log(src);

    return (
      <>
        {/* <Row> */}
        {/* <div style={{ textAlign: 'center', display: 'flex' }}> */}
        <div
        // style={{ maxHeight: "20rem", maxWidth: "20rem", margin: "1rem" }}
        // style={{ maxHeight: '20rem', maxWidth: '20rem', margin: 'auto' }}
        >
          {this.state.loading && <Spinner />}
          {/* {src && !this.state.loading && croppedImageUrl ? ( */}

          {src && (
            <ReactCrop
              // style={{ maxHeight: 300 }}
              src={src}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              // height={111}
            />
          )}
        </div>
        {/* <div style={{ height: "16rem", maxWidth: "20rem", margin: "1rem" }}>
            {croppedImageUrl ? (
              <img
                alt="Crop"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={croppedImageUrl}
              />
            ) : (
              <img
                alt="Crop"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={this.props.imagePreviewUrl}
              />
            )}
            <br />
            <small>This image will be saved.</small>
          </div> */}
        {/* </div> */}
        {/* </Row>
        <Row> */}
        <div style={{ textAlign: 'center' }}>
          <Button color='info' onClick={this.savePrimaryImage}>
            Save Image
          </Button>
          <Button color='default' onClick={this.cancelCrop}>
            Cancel
          </Button>
        </div>
        {/* </Row> */}
      </>
    )
  }
}

export default CropImgPreview

import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loginUser, getWebToken } from 'actions/authActions'

// reactstrap components
import { Container } from 'reactstrap'

class Login extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      errors: {}
    }
  }

  componentDidMount () {
    if (this.props.location.search) {
      this.props.getWebToken(this.props.location.search, this.props.history)
    } else {
      this.props.loginUser()
    }
  }

  render () {
    return (
      <div className='login-page'>
        <Container>
          <h2>Authenticating ...</h2>
        </Container>
      </div>
    )
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  getWebToken: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { loginUser, getWebToken })(Login)

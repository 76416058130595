import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/setAuthToken'
import { setCurrentUser, logoutUser } from './actions/authActions'

import { Provider } from 'react-redux'
import store from './store'

import PrivateRoute from './components/common/PrivateRoute'
// import SharedPrivateRoute from "./components/common/SharedPrivateRoute";

import NotFound from './components/not-found/NotFound'

import './App.css'

// import PrivateLayout from "layouts/Private/Private.jsx";
// import PublicLayout from "layouts/Public/Public.jsx";

import Login from 'Pages/Login.jsx'
import Logout from 'Pages/Logout.jsx'

import Landing from 'Pages/Landing.jsx'

import MakeNames from 'Pages/MakeNames.js'
import UserSuggestName from 'Pages/UserSuggestName.js'
import CheckNames from 'Pages/CheckNames.js'
import CoralNames from 'Pages/CoralNames.js'
import CoralTypes from 'Pages/CoralTypes.js'
import MyWishList from 'Pages/MyWishListResTable.jsx'
import UserWishList from 'Pages/UserWishList.jsx'
import MemberCollection from 'Pages/MemberCollection.jsx'
import MemberFrags from 'Pages/MemberFrags.jsx'
import MyCart from 'Pages/MyCart.jsx'
import Trade from 'Pages/Trade.jsx'
import TradeSummary from 'Pages/TradeSummary.jsx'
import TradeSales from 'Pages/TradeSales.jsx'

import TradesComplete from 'Pages/TradesComplete.jsx'
import TradePurchases from 'Pages/TradePurchases.jsx'
import NameShow from 'Pages/NameShow.js'
import MediaPreview from 'Pages/MediaPreview.js'
import SpeciesShow from 'Pages/SpeciesShow.js'
import CoralColorShow from 'Pages/CoralColorShow.js'
import CoralDeals from 'Pages/CoralDeals.js'
import CoralEdit from 'Pages/CoralEdit.js'
import AddFrag from 'Pages/AddFrag.js'
import CoralShow from 'Pages/CoralShow.js'
import FragShow from 'Pages/FragShow.js'
import TradeCommunity from 'Pages/TradeCommunity.js'
import Settings from 'Pages/Settings.js'
import MyReef from 'Pages/MyReef.jsx'
import LayoutWrapper from 'layouts/LayoutWrapper.js'
import AllCoral from 'Pages/AllCoral/AllCoral.jsx'
// import "antd/dist/antd.css";
import 'bootswatch/dist/lux/bootstrap.min.css'
// import "bootstrap/dist/css/bootstrap.css";
// import "assets/scss/paper-dashboard.scss";
import 'assets/demo/demo.css'

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken)
  // console.log("localstorage token", localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken)
  // console.log("decoded", decoded);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded))

  // // Check for expired token
  const currentTime = Date.now() / 1000
  // // console.log("currenttime", currentTime);
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser('login'))
    //   // Clear current Profile
    //   store.dispatch(clearCurrentProfile())
    //   // Redirect to login
    //   window.location.href = '/login'
  }
}

class App extends Component {
  render () {
    return (
      <>
        <Provider store={store}>
          {/* <BrowserRouter> */}
          <LayoutWrapper>
            <Switch>
              <PrivateRoute path='/myreef' component={MyReef} />
              <PrivateRoute path='/mywishlist' component={MyWishList} />
              <PrivateRoute path='/settings' component={Settings} />
              <PrivateRoute path='/trade/:id' component={Trade} />
              <PrivateRoute path='/tradesales' component={TradeSales} />
              <PrivateRoute path='/tradescomplete' component={TradesComplete} />
              <PrivateRoute path='/tradepurchases' component={TradePurchases} />
              <PrivateRoute path='/tradesummary/:id' component={TradeSummary} />
              <PrivateRoute
                path='/usersuggestname'
                component={UserSuggestName}
              />
              <PrivateRoute path='/mycart' component={MyCart} />
              <PrivateRoute path='/mediapreview' component={MediaPreview} />
              <PrivateRoute exact path='/coraledit/:id' component={CoralEdit} />
              <PrivateRoute exact path='/addfrag/:id' component={AddFrag} />
              {/* <PrivateRoute>
                <Route exact path='/myreef' component={MyReef} />
              </PrivateRoute> */}
              <Route exact path='/login' component={Login} />
              <Route exact path='/logout' component={Logout} />
              <Route exact path='/coralsearch' component={AllCoral} />
              <Route exact path='/coralnames' component={CoralNames} />

              <Route exact path='/coralnames/:id' component={NameShow} />
              <Route
                exact
                path='/coraltypes/:type/:genus'
                component={CoralTypes}
              />
              <Route exact path='/species/:species' component={SpeciesShow} />
              {/* <Route exact path='/coraledit/:id' component={CoralEdit} /> */}
              <Route exact path='/coralshow/:id' component={CoralShow} />
              <Route exact path='/userwishlist/:id' component={UserWishList} />
              <Route
                exact
                path='/MemberCollection/:id'
                component={MemberCollection}
              />
              <Route exact path='/MemberFrags/:id' component={MemberFrags} />
              <Route
                exact
                path='/coralcolorshow/:color/:genus'
                component={CoralColorShow}
              />
              <Route
                exact
                path='/fragshow/:coralId/:fragId'
                component={FragShow}
              />
              <Route exact path='/coraldeals' component={CoralDeals} />
              <Route exact path='/tradecommunity' component={TradeCommunity} />

              <Route exact path='/' component={Landing} />
              {process.env.NODE_ENV === 'production' ? null : (
                <>
                  <Route exact path='/makenames' component={MakeNames} />
                  <Route exact path='/checknames' component={CheckNames} />
                </>
              )}
              <Route path='/' component={NotFound} />
            </Switch>

            {/* <Route exact path="/" component={AuthLayout} />
              <Route
                path="/auth"
                render={(props) => <AuthLayout {...props} />}
              />
              <PrivateRoute path="/private" component={PrivateLayout} />
              <Route path="/members" component={MembersLayout} />

              <Route path="/public" component={PublicLayout} />
              <PrivateRoute path="/dashboard" component={DashboardLayout} />
              <Route path="/strains" component={Strains} />
              <Route path="/redeem" component={Redeem} />
              <Route path="/stopnotifications" component={StopNotifications} /> */}
          </LayoutWrapper>
          {/* </BrowserRouter> */}
        </Provider>
      </>
    )
  }
}

export default App

import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { myCoral } from 'actions/coralActions'
import axios from 'axios'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import styles from './CoralNames.module.css'

// reactstrap components
import { Button, Container } from 'reactstrap'

import { FacebookShareButton, TwitterShareButton } from 'react-share'

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon
} from 'react-share'

class Login extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      // email: "",
      // password: "",
      errors: {}
    }
  }

  componentDidMount () {
    if (!this.props.coral.myCoral) {
      this.props.myCoral()
    }
  }

  deleteCoral (coral) {
    axios.post('/api/coral/deletecoral', { id: coral._id }).then(res => {
      if (res.data === 'success') {
        this.props.myCoral()
      }
    })
  }

  render () {
    let { myCoral } = this.props.coral

    if (myCoral) {
      //sort by trade first
      myCoral = myCoral.sort((x, y) => {
        return x.forTrade === y.forTrade ? 0 : x.forTrade ? -1 : 1
      })
      //sort by for sale
      myCoral = myCoral.sort((x, y) => {
        return x.forSale === y.forSale ? 0 : x.forSale ? -1 : 1
      })
    }
    return (
      <div className='login-page'>
        <Container>
          <h4 style={{ textAlign: 'center', marginTop: 10 }}>
            My Coral Collection
          </h4>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              margin: 8
            }}
          >
            <FacebookShareButton
              style={{ marginRight: 3 }}
              url={`https://www.immortalreef.com/membercollection/${this.props.auth.user.id}`}
            >
              <FacebookIcon size={28} />
            </FacebookShareButton>
            <TwitterShareButton
              style={{ marginRight: 3 }}
              url={`https://www.immortalreef.com/membercollection/${this.props.auth.user.id}`}
            >
              <TwitterIcon size={28} />
            </TwitterShareButton>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              margin: 8
            }}
          >
            <Link to={'/mediapreview'} className='text-info'>
              <small>Social Media Preview Collage</small>
            </Link>
          </div>
          {/* <h4 style={{ textAlign: 'center', marginTop: 10 }}>
            My Coral Collection
          </h4> */}
          {/* <div
            style={{ display: 'flex', justifyContent: 'end', marginBottom: 3 }}
          >
            <FacebookShareButton
              style={{ marginRight: 3 }}
              url={`immortalreef.com/membercollection/${this.props.auth.user.id}`}
            >
              <FacebookIcon size={36} />
            </FacebookShareButton>
            <TwitterShareButton
              style={{ marginRight: 3 }}
              url={`immortalreef.com/membercollection/${this.props.auth.user.id}`}
            >
              <TwitterIcon size={36} />
            </TwitterShareButton>
          </div> */}
          {myCoral && myCoral.length > 0 ? (
            <>
              <p>
                <small>
                  Add coral to your collection by going to the "
                  <Link to='/coraltypes/SPS/Acropora' style={{ color: 'blue' }}>
                    Coral Database
                  </Link>
                  " page, and clicking the "+" button next to the corresponding
                  name or color of your coral.
                </small>
              </p>
              <Table>
                <Thead>
                  <Tr style={{ background: '#F2F3F5' }}>
                    <Th className='text-center'>
                      {/* <i className='fa fa-image' /> */}
                      Mother
                    </Th>
                    <Th className='text-center'>Type</Th>
                    <Th>Genus</Th>
                    <Th>Species</Th>
                    <Th>Name</Th>
                    <Th className='text-center'>Color</Th>
                    {/* <Th className='text-center'>Price</Th> */}
                    <Th className='text-center'>Trade</Th>
                    <Th className='text-center'># of Frags</Th>
                    <Th className='text-center'>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {myCoral.map(coral => (
                    <Tr
                      style={{
                        borderBottom: '1px solid lightgrey'
                      }}
                    >
                      <Td className='text-center'>
                        <Link to={`/coralshow/${coral._id}`}>
                          {coral.image ? (
                            // <div
                            //   style={{
                            //     height: '4rem',
                            //     maxWidth: '4rem',
                            //     borderRadius: 5
                            //   }}
                            // >
                            // <img
                            //   style={{
                            //     maxHeight: '8rem',
                            //     maxWidth: '8rem',
                            //     borderRadius: 5
                            //   }}
                            //   src={`https://s3.amazonaws.com/reefconnector/${coral.image.address}`}
                            // />
                            <div
                              style={{
                                borderRadius: 5,
                                height: '8rem',
                                width: '8rem',
                                backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${coral.image.address})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                margin: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                              }}
                            />
                          ) : (
                            // </div>
                            // <img
                            //   style={{
                            //     maxHeight: '8rem',
                            //     maxWidth: '8rem',
                            //     borderRadius: 5
                            //   }}
                            //   src={`https://s3.amazonaws.com/reefconnector/image_placeholder.jpg`}
                            // />
                            <div
                              style={{
                                borderRadius: 5,
                                height: '8rem',
                                width: '8rem',
                                backgroundImage: `url(https://s3.amazonaws.com/reefconnector/image_placeholder.jpg)`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                margin: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                              }}
                            />
                          )}
                        </Link>
                      </Td>
                      <Td
                      // className='text-center'
                      >
                        {coral.type}
                      </Td>
                      <Td>{coral.genus}</Td>
                      {coral.species ? (
                        <Td>
                          {/* <Link
                            to={`/species/${coral.species.toLowerCase()}`}
                            className='text-info'
                          >
                            {coral.species}
                          </Link> */}
                          {coral.species === 'Other' ? (
                            <span>{coral.otherSpecies}</span>
                          ) : (
                            <>
                              {coral.species === 'Unknown' ? (
                                <span>-</span>
                              ) : (
                                <Link
                                  to={`/species/${coral.species.toLowerCase()}`}
                                  className='text-info'
                                >
                                  {coral.species}
                                </Link>
                              )}
                            </>
                          )}
                        </Td>
                      ) : (
                        <Td>-</Td>
                      )}

                      <Td>
                        {coral.name ? (
                          <Link to={`/coralnames/${coral.name._id}`}>
                            <div
                              style={{ textTransform: 'capitalize' }}
                              className='text-info'
                            >
                              {coral.name.name}
                            </div>
                          </Link>
                        ) : (
                          <>
                            {coral.unverifiedName ? (
                              <div style={{ textTransform: 'capitalize' }}>
                                {coral.unverifiedName}
                              </div>
                            ) : (
                              <span>-</span>
                            )}
                          </>
                        )}
                      </Td>
                      {coral.extracted150Hex ? (
                        <Td
                          className='text-center'
                          // style={{
                          //   background: `#${coral.extracted150Hex}`
                          // }}
                        >
                          <Link
                            to={`/coralcolorshow/${coral.extracted150Hex}/${coral.genus}`}
                          >
                            <div
                              style={{
                                background: `#${coral.extracted150Hex}`,
                                height: 20,
                                width: 20,
                                textAlign: 'center',
                                borderRadius: 5,
                                margin: 'auto'
                              }}
                            />
                          </Link>
                        </Td>
                      ) : (
                        <Td className='text-center'>-</Td>
                      )}
                      {/* <Td className='text-center'>Coral Color</Td> */}
                      {/* <Td className='text-center'>
                        {coral.forSale ? (
                          <span>${coral.price}</span>
                        ) : (
                          <span>-</span>
                        )}
                      </Td> */}
                      <Td className='text-center'>
                        {coral.forTrade ? (
                          <span>
                            <i className='fa fa-check text-success' />
                          </span>
                        ) : (
                          // <i className='fa fa-times' />
                          <span>-</span>
                        )}
                      </Td>
                      <Td className='text-center'>
                        {
                          coral.frags.filter(frag => frag.status === 'active')
                            .length
                        }
                      </Td>
                      <Td className='text-center'>
                        {/* <Link to={`coralshow/${coral._id}`}>
                          <Button
                            className='btn-icon'
                            color='info'
                            size='sm'
                            style={{ borderRadius: 5 }}
                          >
                            <i
                              className='fa fa-eye'
                              style={{ fontSize: 16 }}
                            ></i>
                          </Button>
                        </Link> */}
                        {` `}
                        <Link to={`/coraledit/${coral._id}`}>
                          <div className={styles.tooltip}>
                            <Button
                              outline
                              className='btn-icon'
                              color='warning'
                              size='sm'
                              style={{ borderRadius: 5 }}
                            >
                              <i
                                className='fa fa-edit text-dark'
                                style={{ fontSize: 16 }}
                              ></i>
                            </Button>
                            <span className={styles.tooltiptext}>
                              Edit Coral Info and Picture
                            </span>
                          </div>
                        </Link>{' '}
                        <Link to={`/addfrag/${coral._id}`}>
                          <div className={styles.tooltip}>
                            <Button
                              outline
                              className='btn-icon'
                              color='info'
                              size='sm'
                              style={{ borderRadius: 5 }}
                            >
                              <i
                                className='fa fa-plus'
                                style={{ fontSize: 16 }}
                              ></i>
                            </Button>
                            <span className={styles.tooltiptext}>Add Frag</span>
                          </div>
                        </Link>
                        {` `}
                        <div className={styles.tooltip}>
                          <Button
                            outline
                            className='btn-icon'
                            color='danger'
                            size='sm'
                            style={{ borderRadius: 5 }}
                            onClick={() => this.deleteCoral(coral)}
                          >
                            <i
                              className='fa fa-trash-o'
                              style={{ fontSize: 16 }}
                            />
                          </Button>
                          <span className={styles.tooltiptext}>
                            Remove from collection
                          </span>
                        </div>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </>
          ) : (
            <>
              <hr />
              <p
                style={{
                  textAlign: 'center',
                  marginLeft: '20%',
                  marginRight: '20%'
                }}
              >
                Add coral to your collection by going to the "
                <Link to='/coraltypes/SPS/Acropora' style={{ color: 'blue' }}>
                  Coral Database
                </Link>
                " page, and clicking the "+" button next to the corresponding
                name or color of your coral.
              </p>
            </>
          )}
        </Container>
      </div>
    )
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral,
  errors: state.errors
})

export default connect(mapStateToProps, { myCoral })(Login)

import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { memberCoral, myCoral } from 'actions/coralActions'
import AddCartSelect from 'components/AddCartRow/AddCartSelect.js'
import {
  getMyCart,
  getMyTradePurchases,
  getMyTradeSales
} from 'actions/authActions'
import { getUser } from 'actions/communityActions'
import axios from 'axios'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

// reactstrap components
import { Button, Container, Input, Label, FormGroup, Spinner } from 'reactstrap'

class Login extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      fragsSelected: [],
      cart: [],
      addedCoral: [],
      moneyOffer: 0,
      message: '',
      localCart: null,
      submitted: false,
      submitLoading: false,
      // email: "",
      // password: "",
      errors: {}
    }
  }

  componentDidMount () {
    this.props.getUser(this.props.match.params.id)

    if (!this.props.coral.myCoral) {
      this.props.myCoral()
    }

    if (!this.props.auth.myCart) {
      this.props.getMyCart()
    }
  }

  componentWillReceiveProps (nextProps) {
    let cartIndex = null

    if (nextProps.auth.myCart) {
      cartIndex = nextProps.auth.myCart
        .map(cart => cart.user._id)
        .indexOf(this.props.match.params.id)
      if (cartIndex != -1) {
        this.setState({ localCart: nextProps.auth.myCart[cartIndex] })
      } else {
        this.setState({ localCart: null })
      }
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  submitTrade = () => {
    // console.log(this.state.addedCoral, this.state.fragsSelected)
    this.setState({ submitLoading: true })
    let tradeItems = {
      buyer: this.props.auth.user.id,
      seller: this.props.community.member._id,
      moneyOffer: this.state.moneyOffer,
      message: this.state.message,
      buyerCoral: this.state.fragsSelected.map(item => {
        return { coral: item.coralId, frag: item.frag._id }
      }),
      sellerCoral: this.state.localCart.coral.map(item => {
        return { coral: item.coral._id, frag: item.fragId }
      }),
      sellerUserName: this.props.community.member.username,
      buyerUserName: this.props.auth.user.username
    }
    axios
      .post('/api/users/submittrade', tradeItems)
      .then(res => {
        console.log(res.data)
        this.props.getMyCart()
        this.props.getMyTradePurchases()
        this.props.getMyTradeSales()
        this.props.history.push(`/tradesummary/${res.data}`)
      })
      .catch(err => console.log(err))
  }

  addCoral = coral => {
    console.log('added coral')
    this.setState({ addedCoral: [...this.state.addedCoral, coral] })
  }

  removeCoral = coralId => {
    console.log('remove coral')
    var array = [...this.state.addedCoral] // make a separate copy of the array
    var index = array.map(coral => coral._id).indexOf(coralId)
    if (index !== -1) {
      array.splice(index, 1)
      this.setState({ addedCoral: array })
    }
    console.log(this.state.addedCoral)
  }

  removeFromCart = coralId => {
    axios
      .post('/api/users/removefromcart', {
        userId: this.props.match.params.id,
        coralId: coralId
      })
      .then(res => {
        console.log(res.data)
        this.props.getMyCart()
      })
  }

  selectFrag = frag => {
    console.log(frag)
    //delete old frag if new one is selected
    let newList = [...this.state.fragsSelected]
    let oldFragIndex = this.state.fragsSelected
      .map(frag => frag.coralId)
      .indexOf(frag.coralId)
    console.log(oldFragIndex)
    if (oldFragIndex !== -1) {
      newList.splice(oldFragIndex, 1)

      // this.setState({ fragsSelected: newList })
    }

    let coralIndex = this.state.addedCoral
      .map(coral => coral._id)
      .indexOf(frag.coralId)
    console.log(coralIndex)
    let fragList = this.state.addedCoral[coralIndex].frags.map(frag => frag._id)
    let fragIndex = fragList
      .map(id => id.toString().substr(id.toString().length - 4))
      .indexOf(frag.frag)
    console.log(fragIndex)
    let selectedFrag = this.state.addedCoral[coralIndex].frags[fragIndex]
    if (frag.frag !== 'Select') {
      let pushFrag = { coralId: frag.coralId, frag: selectedFrag }
      newList.push(pushFrag)
    }

    console.log(newList)
    this.setState({ fragsSelected: newList })
    // this.setState({ fragsSelected: [...this.state.fragsSelected, pushFrag] })
  }

  render () {
    let selectChanged = this.state.fragsSelected.map(frag => frag.coralId)
    const { myCart } = this.props.auth
    const { myCoral } = this.props.coral
    const { member } = this.props.community
    const { localCart } = this.state
    let cartIndex = -1
    // let localCart = null
    let wishListMatch = []
    let wishListNoMatch = []

    if (myCoral && member) {
      myCoral.map(coral => {
        if (
          coral.name &&
          member.wishList
            .map(wish => wish.nameId)
            .some(id => id === coral.name._id)
        ) {
          wishListMatch.push(coral)
        } else if (
          member.wishList
            .map(wish => wish.genus + wish.color)
            .some(id => id === coral.genus + coral.extracted150Hex)
        ) {
          wishListMatch.push(coral)
        } else if (
          member.wishList
            .map(wish => wish.genus + wish.species)
            .some(id => id === coral.genus + coral.species)
        ) {
          wishListMatch.push(coral)
        } else {
          wishListNoMatch.push(coral)
        }
      })
    }
    // console.log(wishListMatch, wishListNoMatch)
    console.log(this.state.localCart)
    console.log(this.state.fragsSelected)
    if (this.state.fragsSelected) {
      console.log(
        this.state.fragsSelected.map(item => {
          return { coral: item.coralId, frag: item.frag._id }
        })
      )
    }

    return (
      <div className='login-page'>
        {myCart && member && !this.state.submitted ? (
          <>
            {localCart ? (
              <Container>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <h4 style={{ textAlign: 'center', marginTop: 10 }}>
                    Trade -
                  </h4>

                  <img
                    src={member.avatar}
                    style={{ margin: 10, borderRadius: 50 }}
                  />
                  <h4 style={{ textAlign: 'center', marginTop: 10 }}>
                    {member.username}
                  </h4>
                </div>

                <>
                  <div
                    style={{
                      border: '1px solid lightgrey',
                      borderRadius: 5,
                      padding: 10
                    }}
                  >
                    <div
                    // style={{
                    //   border: '1px solid lightgrey',
                    //   borderRadius: 5,
                    //   padding: 10
                    // }}
                    >
                      <Link to={`/membercollection/${member._id}`}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 5
                          }}
                        >
                          <img
                            src={member.avatar}
                            style={{ marginRight: 10, borderRadius: 50 }}
                          />
                          <h5 style={{ textAlign: 'center' }}>
                            {member.username} Gives
                          </h5>
                        </div>
                      </Link>

                      <Table id='trademember'>
                        <Thead>
                          <Tr style={{ background: '#F2F3F5' }}>
                            <Th className='text-center'>Mother</Th>
                            <Th className='text-center'>Type</Th>
                            <Th>Genus</Th>
                            <Th>Name</Th>
                            <Th className='text-center'>Color</Th>
                            <Th className='text-center'>Price</Th>
                            <Th className='text-center'>Trade</Th>
                            <Th className='text-center'>Frag</Th>
                            <Th className='text-center'>Cart</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {localCart.coral.map(coral => (
                            <Tr>
                              <Td className='text-center'>
                                <Link to={`/coralshow/${coral.coral._id}`}>
                                  {coral.coral.image ? (
                                    <img
                                      style={{
                                        maxHeight: '8rem',
                                        maxWidth: '8rem',
                                        borderRadius: 5
                                      }}
                                      src={`https://s3.amazonaws.com/reefconnector/${coral.coral.image.address}`}
                                    />
                                  ) : (
                                    <i
                                      className='fa fa-image'
                                      style={{ fontSize: 64 }}
                                    />
                                  )}
                                </Link>
                              </Td>
                              <Td className='text-center'>
                                {coral.coral.type}
                              </Td>
                              <Td>{coral.coral.genus}</Td>

                              <Td>
                                {coral.coral.name ? (
                                  <Link
                                    to={`/coralnames/${coral.coral.name._id}`}
                                  >
                                    <span
                                      style={{ textTransform: 'capitalize' }}
                                      className='text-info'
                                    >
                                      {coral.coral.name.name}
                                    </span>
                                  </Link>
                                ) : (
                                  <>
                                    {coral.coral.unverifiedName ? (
                                      <span>
                                        <span
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                        >
                                          {coral.coral.unverifiedName}
                                        </span>{' '}
                                        {/* <small>(unregistered)</small> */}
                                      </span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </>
                                )}
                              </Td>
                              {coral.coral.extracted150Hex ? (
                                <Td className='text-center'>
                                  <Link
                                    to={`/coralcolorshow/${coral.coral.extracted150Hex}/${coral.genus}`}
                                  >
                                    <div
                                      style={{
                                        background: `#${coral.coral.extracted150Hex}`,
                                        height: 20,
                                        width: 20,
                                        textAlign: 'center',
                                        borderRadius: 5,
                                        margin: 'auto'
                                      }}
                                    />
                                  </Link>
                                </Td>
                              ) : (
                                <Td className='text-center'>-</Td>
                              )}
                              <Td className='text-center'>
                                {/* {coral.forSale ? ( */}
                                <span>
                                  $
                                  {
                                    coral.coral.frags[
                                      coral.coral.frags
                                        .map(frag => frag._id)
                                        .indexOf(coral.fragId)
                                    ].price
                                  }
                                </span>
                                {/* ) : (
                                  <i className='fa fa-times' />
                                )} */}
                              </Td>
                              <Td className='text-center'>
                                {coral.coral.forTrade ? (
                                  <span>
                                    <i className='fa fa-check text-success' />
                                  </span>
                                ) : (
                                  <i className='fa fa-times' />
                                )}
                              </Td>
                              <Td className='text-center'>
                                {coral.fragId ? (
                                  <img
                                    style={{
                                      maxHeight: '7rem',
                                      maxWidth: '7rem',
                                      borderRadius: 5
                                    }}
                                    src={`https://s3.amazonaws.com/reefconnector/${
                                      coral.coral.frags[
                                        coral.coral.frags
                                          .map(frag => frag._id)
                                          .indexOf(coral.fragId)
                                      ].image.address
                                    }`}
                                  />
                                ) : (
                                  <span>-</span>
                                )}
                              </Td>
                              <Td className='text-center'>
                                {/* {coral.forTrade || coral.forSale ? (
                                  <>
                                    {localCart &&
                                    localCart.coral
                                      .map(coral => coral._id)
                                      .some(id => id === coral._id) ? ( */}
                                <Button
                                  outline
                                  color='danger'
                                  onClick={() => this.removeFromCart(coral._id)}
                                >
                                  <i className='fa fa-trash-o' />
                                </Button>
                                {/* ) : (
                                      <Button
                                        outline
                                        color='success'
                                        onClick={() => this.addToCart(coral)}
                                      >
                                        Add
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  <i className='fa fa-times' />
                                )} */}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                    <hr />
                    <h3 style={{ textAlign: 'center' }}>
                      Total Price : ${' '}
                      {localCart.coral
                        .map(
                          coral =>
                            coral.coral.frags[
                              coral.coral.frags
                                .map(frag => frag._id)
                                .indexOf(coral.fragId)
                            ].price
                        )
                        .reduce((partialSum, a) => +partialSum + +a, 0)}
                    </h3>
                    <div
                      style={{
                        display: 'flex',
                        fontSize: 20,
                        justifyContent: 'center',
                        margin: 10
                        // border: '1px solid blue'
                      }}
                    >
                      {/* <div
                        style={{
                          border: '1px solid blue',
                          borderRadius: 10
                        }}> */}

                      <i
                        className='fa fa-arrow-down text-info'
                        style={{ margin: 8 }}
                      />
                      <i
                        className='fa fa-arrow-up text-info'
                        style={{ margin: 8 }}
                      />
                      {/* </div> */}
                    </div>
                    <div
                    // style={{
                    //   border: '1px solid lightgrey',
                    //   borderRadius: 5,
                    //   padding: 10

                    // }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: 5
                        }}
                      >
                        <img
                          src={this.props.auth.user.avatar}
                          style={{ marginRight: 10, borderRadius: 50 }}
                        />
                        <h5 style={{ textAlign: 'center' }}>
                          {this.props.auth.user.username} Gives
                        </h5>
                      </div>
                      <Table>
                        <Thead>
                          <Tr style={{ background: '#F2F3F5' }}>
                            <Th className='text-center'>Offer Money ($)</Th>
                            {/* <Th className='text-center'>Offer Confirm ($)</Th> */}
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td className='text-center'>
                              <input
                                style={{ width: 60, margin: 5 }}
                                name='moneyOffer'
                                value={this.state.moneyOffer}
                                onChange={e => this.onChange(e)}
                              />
                            </Td>
                            {/* <Td className='text-center'>
                              ${this.state.moneyOffer}
                            </Td> */}
                          </Tr>
                        </Tbody>
                      </Table>
                      <div style={{ height: 10 }} />
                      {this.state.addedCoral.length > 0 ? (
                        <Table>
                          <Thead>
                            <Tr style={{ background: '#F2F3F5' }}>
                              <Th className='text-center'>Mother</Th>
                              <Th className='text-center'>Type</Th>
                              <Th>Genus</Th>
                              <Th>Name</Th>
                              <Th className='text-center'>Color</Th>
                              <Th className='text-center'>Wishlist Match</Th>

                              <Th key={1} className='text-center'>
                                Frag
                              </Th>
                              <Th key={2} className='text-center'>
                                Frag Img
                              </Th>
                              <Th className='text-center'>Trade</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {this.state.addedCoral.map(coral => (
                              <Tr>
                                <Td className='text-center'>
                                  <Link to={`/coralshow/${coral._id}`}>
                                    {coral.image ? (
                                      <img
                                        style={{
                                          maxHeight: '8rem',
                                          maxWidth: '8rem',
                                          borderRadius: 5
                                        }}
                                        src={`https://s3.amazonaws.com/reefconnector/${coral.image.address}`}
                                      />
                                    ) : (
                                      <i
                                        className='fa fa-image'
                                        style={{ fontSize: 64 }}
                                      />
                                    )}
                                  </Link>
                                </Td>
                                <Td className='text-center'>{coral.type}</Td>
                                <Td>{coral.genus}</Td>

                                <Td>
                                  {coral.name ? (
                                    <Link to={`/coralnames/${coral.name._id}`}>
                                      <span
                                        style={{ textTransform: 'capitalize' }}
                                        className='text-info'
                                      >
                                        {coral.name.name}
                                      </span>
                                    </Link>
                                  ) : (
                                    <>
                                      {coral.unverifiedName ? (
                                        <span>
                                          <span
                                            style={{
                                              textTransform: 'capitalize'
                                            }}
                                          >
                                            {coral.unverifiedName}
                                          </span>{' '}
                                          {/* <small>(unregistered)</small> */}
                                        </span>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </>
                                  )}
                                </Td>
                                {coral.extracted150Hex ? (
                                  <Td className='text-center'>
                                    <Link
                                      to={`/coralcolorshow/${coral.extracted150Hex}/${coral.genus}`}
                                    >
                                      <div
                                        style={{
                                          background: `#${coral.extracted150Hex}`,
                                          height: 20,
                                          width: 20,
                                          textAlign: 'center',
                                          borderRadius: 5,
                                          margin: 'auto'
                                        }}
                                      />
                                    </Link>
                                  </Td>
                                ) : (
                                  <Td className='text-center'>-</Td>
                                )}
                                <Td className='text-center'>
                                  {(coral.name &&
                                    member.wishList
                                      .map(wish => wish.nameId)
                                      .some(id => id === coral.name._id)) ||
                                  (member &&
                                    member.wishList
                                      .map(wish => wish.genus + wish.color)
                                      .some(
                                        id =>
                                          id ===
                                          coral.genus + coral.extracted150Hex
                                      )) ||
                                  (member &&
                                    member.wishList
                                      .map(wish => wish.genus + wish.species)
                                      .some(
                                        id => id === coral.genus + coral.species
                                      )) ? (
                                    <i className='fa fa-check text-success' />
                                  ) : (
                                    <i className='fa fa-times' />
                                  )}
                                </Td>
                                <Td className='text-center'>
                                  <AddCartSelect
                                    componentCoral={coral}
                                    selectFrag={this.selectFrag}
                                  />
                                </Td>
                                <Td className='text-center'>
                                  <>
                                    {selectChanged.some(
                                      id => id === coral._id
                                    ) ? (
                                      <img
                                        style={{
                                          maxHeight: '7rem',
                                          maxWidth: '7rem',
                                          borderRadius: 5
                                        }}
                                        src={`https://s3.amazonaws.com/reefconnector/${
                                          this.state.fragsSelected[
                                            selectChanged.indexOf(coral._id)
                                          ].frag.image.address
                                        }`}
                                      />
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </>
                                </Td>
                                <Td className='text-center'>
                                  <Button
                                    outline
                                    color='danger'
                                    onClick={() => this.removeCoral(coral._id)}
                                  >
                                    <i className='fa fa-trash-o' />
                                  </Button>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      ) : (
                        <p style={{ textAlign: 'center' }}>
                          Add your coral to trade from the list below.
                        </p>
                      )}
                    </div>
                    {/* <hr /> */}
                    <FormGroup style={{ marginTop: 10 }}>
                      <Label for='exampleText'>Include Message</Label>
                      <Input
                        type='textarea'
                        name='message'
                        id='exampleText'
                        value={this.state.message}
                        onChange={e => this.onChange(e)}
                      />
                    </FormGroup>
                    {this.state.addedCoral.length !=
                    this.state.fragsSelected.length ? (
                      <div className='text-danger text-right'>
                        You must select a frag for each coral you are offering
                        in trade!
                      </div>
                    ) : null}
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        color='info'
                        style={{ margin: 10 }}
                        onClick={() => this.props.history.goBack()}
                      >
                        Go Back
                      </Button>
                      {this.state.submitLoading ? (
                        <div
                          style={{
                            width: 130,
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Spinner color='info' style={{ margin: 'auto' }} />
                        </div>
                      ) : (
                        <Button
                          color='info'
                          style={{ margin: 10 }}
                          onClick={this.submitTrade}
                          disabled={
                            this.state.addedCoral.length !=
                            this.state.fragsSelected.length
                          }
                        >
                          Submit Trade
                        </Button>
                      )}
                    </div>
                  </div>
                  {myCoral ? (
                    <>
                      <h5 style={{ textAlign: 'center', marginTop: 10 }}>
                        Add your coral to trade
                      </h5>
                      <Table>
                        <Thead>
                          <Tr style={{ background: '#F2F3F5' }}>
                            <Th className='text-center'>Mother</Th>
                            <Th className='text-center'>Type</Th>
                            <Th>Genus</Th>
                            <Th>Species</Th>
                            <Th>Name</Th>
                            <Th className='text-center'>Color</Th>
                            <Th className='text-center'>Wishlist Match</Th>
                            {/* <Th className='text-center'>Trade</Th> */}
                            <Th className='text-center'>Trade</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {wishListMatch.map(coral => (
                            <>
                              {(this.state.addedCoral.length > 0 &&
                                this.state.addedCoral
                                  .map(coral1 => coral1._id)
                                  .some(id => id === coral._id)) ||
                              coral.frags.filter(
                                frag => frag.status === 'active'
                              ).length === 0 ? null : (
                                <Tr>
                                  <Td className='text-center'>
                                    <Link to={`/coralshow/${coral._id}`}>
                                      {coral.image ? (
                                        <img
                                          style={{
                                            maxHeight: '8rem',
                                            maxWidth: '8rem',
                                            borderRadius: 5
                                          }}
                                          src={`https://s3.amazonaws.com/reefconnector/${coral.image.address}`}
                                        />
                                      ) : (
                                        <i
                                          className='fa fa-image'
                                          style={{ fontSize: 64 }}
                                        />
                                      )}
                                    </Link>
                                  </Td>
                                  <Td className='text-center'>{coral.type}</Td>
                                  <Td>{coral.genus}</Td>
                                  <Td>
                                    {coral.species ? (
                                      <span>{coral.species}</span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </Td>
                                  <Td>
                                    {coral.name ? (
                                      <Link
                                        to={`/coralnames/${coral.name._id}`}
                                      >
                                        <span
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                          className='text-info'
                                        >
                                          {coral.name.name}
                                        </span>
                                      </Link>
                                    ) : (
                                      <>
                                        {coral.unverifiedName ? (
                                          <span>
                                            <span
                                              style={{
                                                textTransform: 'capitalize'
                                              }}
                                            >
                                              {coral.unverifiedName}
                                            </span>{' '}
                                            {/* <small>(unregistered)</small> */}
                                          </span>
                                        ) : (
                                          <span>-</span>
                                        )}
                                      </>
                                    )}
                                  </Td>
                                  {coral.extracted150Hex ? (
                                    <Td className='text-center'>
                                      <Link
                                        to={`/coralcolorshow/${coral.extracted150Hex}/${coral.genus}`}
                                      >
                                        <div
                                          style={{
                                            background: `#${coral.extracted150Hex}`,
                                            height: 20,
                                            width: 20,
                                            textAlign: 'center',
                                            borderRadius: 5,
                                            margin: 'auto'
                                          }}
                                        />
                                      </Link>
                                    </Td>
                                  ) : (
                                    <Td className='text-center'>-</Td>
                                  )}
                                  <Td className='text-center'>
                                    <i className='fa fa-check text-success' />
                                    {/* {coral.name &&
                                member.wishList
                                  .map(wish => wish.nameId)
                                  .some(id => id === coral.name._id) ? (
                                  <i className='fa fa-check text-success' />
                                ) : (
                                  <i className='fa fa-times' />
                                )} */}
                                  </Td>

                                  <Td className='text-center'>
                                    {this.state.addedCoral.length > 0 &&
                                    this.state.addedCoral
                                      .map(coral1 => coral1._id)
                                      .some(id => id === coral._id) ? (
                                      <Button
                                        outline
                                        color='danger'
                                        onClick={() =>
                                          this.removeCoral(coral._id)
                                        }
                                      >
                                        <i className='fa fa-trash-o' />
                                      </Button>
                                    ) : (
                                      <Button
                                        outline
                                        color='success'
                                        onClick={() => this.addCoral(coral)}
                                      >
                                        Add
                                      </Button>
                                    )}
                                  </Td>
                                </Tr>
                              )}
                            </>
                          ))}
                          {wishListNoMatch.map(coral => (
                            <>
                              {(this.state.addedCoral.length > 0 &&
                                this.state.addedCoral
                                  .map(coral1 => coral1._id)
                                  .some(id => id === coral._id)) ||
                              coral.frags.filter(
                                frag => frag.status === 'active'
                              ).length === 0 ? null : (
                                <Tr>
                                  <Td className='text-center'>
                                    <Link to={`/coralshow/${coral._id}`}>
                                      {coral.image ? (
                                        <img
                                          style={{
                                            maxHeight: '8rem',
                                            maxWidth: '8rem',
                                            borderRadius: 5
                                          }}
                                          src={`https://s3.amazonaws.com/reefconnector/${coral.image.address}`}
                                        />
                                      ) : (
                                        <i
                                          className='fa fa-image'
                                          style={{ fontSize: 64 }}
                                        />
                                      )}
                                    </Link>
                                  </Td>
                                  <Td className='text-center'>{coral.type}</Td>
                                  <Td>{coral.genus}</Td>
                                  <Td>
                                    {coral.species ? (
                                      <span>{coral.species}</span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </Td>
                                  <Td>
                                    {coral.name ? (
                                      <Link
                                        to={`/coralnames/${coral.name._id}`}
                                      >
                                        <span
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                          className='text-info'
                                        >
                                          {coral.name.name}
                                        </span>
                                      </Link>
                                    ) : (
                                      <>
                                        {coral.unverifiedName ? (
                                          <span>
                                            <span
                                              style={{
                                                textTransform: 'capitalize'
                                              }}
                                            >
                                              {coral.unverifiedName}
                                            </span>{' '}
                                            {/* <small>(unregistered)</small> */}
                                          </span>
                                        ) : (
                                          <span>-</span>
                                        )}
                                      </>
                                    )}
                                  </Td>
                                  {coral.extracted150Hex ? (
                                    <Td className='text-center'>
                                      <Link
                                        to={`/coralcolorshow/${coral.extracted150Hex}/${coral.genus}`}
                                      >
                                        <div
                                          style={{
                                            background: `#${coral.extracted150Hex}`,
                                            height: 20,
                                            width: 20,
                                            textAlign: 'center',
                                            borderRadius: 5,
                                            margin: 'auto'
                                          }}
                                        />
                                      </Link>
                                    </Td>
                                  ) : (
                                    <Td className='text-center'>-</Td>
                                  )}
                                  <Td className='text-center'>
                                    <i className='fa fa-times' />
                                    {/* {coral.name &&
                                member.wishList
                                  .map(wish => wish.nameId)
                                  .some(id => id === coral.name._id) ? (
                                  <i className='fa fa-check text-success' />
                                ) : (
                                  <i className='fa fa-times' />
                                )} */}
                                  </Td>

                                  <Td className='text-center'>
                                    {this.state.addedCoral.length > 0 &&
                                    this.state.addedCoral
                                      .map(coral1 => coral1._id)
                                      .some(id => id === coral._id) ? (
                                      <Button
                                        outline
                                        color='danger'
                                        onClick={() =>
                                          this.removeCoral(coral._id)
                                        }
                                      >
                                        <i className='fa fa-trash-o' />
                                      </Button>
                                    ) : (
                                      <Button
                                        outline
                                        color='success'
                                        onClick={() => this.addCoral(coral)}
                                      >
                                        Add
                                      </Button>
                                    )}
                                  </Td>
                                </Tr>
                              )}
                            </>
                          ))}
                        </Tbody>
                      </Table>
                    </>
                  ) : null}
                </>
              </Container>
            ) : (
              <h4 style={{ textAlign: 'center' }}>No Coral Selected</h4>
            )}
          </>
        ) : (
          <>
            {/* {this.state.submitted ? (
              <>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ height: 20 }} />
                  <h4>Your trade request has been submitted!</h4>{' '}
                  <Button
                    color='info'
                    style={{ margin: 10 }}
                    onClick={() => this.props.history.goBack()}
                  >
                    Go Back
                  </Button>
                </div>
              </>
            ) : ( */}
            <h4>Loading...</h4>
            {/* )} */}
          </>
        )}
      </div>
    )
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  coral: PropTypes.object.isRequired,
  community: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral,
  community: state.community,
  errors: state.errors
})

export default connect(mapStateToProps, {
  memberCoral,
  getMyCart,
  getUser,
  myCoral,
  getMyTradePurchases,
  getMyTradeSales
})(Login)

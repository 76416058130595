import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getNames } from 'actions/namesActions'
import { getWishList } from 'actions/authActions'
import { myCoral } from 'actions/coralActions'
import classes from 'variables/coral'
import styles from './CoralNames.module.css'
import axios from 'axios'
import colors from 'variables/colorFamilies.jsx'
import SweetAlert from 'react-bootstrap-sweetalert'

import {
  Row,
  Container,
  Col,
  Button,
  Tooltip,
  Jumbotron,
  Alert,
  Spinner
} from 'reactstrap'

class CoralNames extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      coralNames: null,
      addToolTipOpen: false,
      showAlert: false,
      addInfo: ''
    }
  }

  componentDidMount () {
    if (this.props.coralNames.names.length === 0) {
      this.props.getNames()
    }
    if (!this.props.auth.wishList) {
      this.props.getWishList()
    }
  }

  toggleAddToolTip = () => {
    this.setState({ addToolTipOpen: !this.state.addToolTipOpen })
  }

  addCoral = coralName => {
    if (!this.props.auth.isAuthenticated) {
      return this.props.history.push('/login')
    }
    console.log(coralName)
    axios.post('/api/coral/addusercoral', coralName).then(res => {
      console.log(res.data)
      if (res.data === 'success') {
        this.setState({
          showAlert: true,
          addInfo: (
            <div style={{ textTransform: 'uppercase' }}>
              {coralName.genus} - {coralName.name}
            </div>
          )
        })
        if (this.props.coral.myCoral) {
          this.props.myCoral()
        }
      }
    })
  }

  addCoralByColor = coralColor => {
    if (!this.props.auth.isAuthenticated) {
      return this.props.history.push('/login')
    }
    console.log(coralColor)
    axios.post('/api/coral/addusercoralbycolor', coralColor).then(res => {
      console.log(res.data)
      if (res.data === 'success') {
        this.setState({
          showAlert: true,
          addInfo: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div>
                {coralColor.type} - {coralColor.genus} -{' '}
              </div>
              <div
                style={{
                  marginLeft: 5,
                  backgroundColor: `#${coralColor.color}`,
                  height: 20,
                  width: 20,
                  borderRadius: 5
                }}
              ></div>
            </div>
          )
        })
        if (this.props.coral.myCoral) {
          this.props.myCoral()
        }
      }
    })
  }

  addCoralBySpecies = coral => {
    if (!this.props.auth.isAuthenticated) {
      return this.props.history.push('/login')
    }
    console.log(coral)
    axios.post('/api/coral/addusercoralbyspecies', coral).then(res => {
      console.log(res.data)
      if (res.data === 'success') {
        this.setState({
          showAlert: true,
          addInfo: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div>
                {coral.type} - {coral.genus} - {coral.species}
              </div>
              {/* <div
                style={{
                  marginLeft: 5,
                  backgroundColor: `#${coralColor.color}`,
                  height: 20,
                  width: 20,
                  borderRadius: 5
                }}
              ></div> */}
            </div>
          )
        })
        if (this.props.coral.myCoral) {
          this.props.myCoral()
        }
      }
    })
  }

  addToWishList = coral => {
    if (!this.props.auth.isAuthenticated) {
      return this.props.history.push('/login')
    }
    console.log(coral)
    axios.post('/api/users/addwishlist', coral).then(res => {
      console.log(res.data)
      if (res.data === 'success') {
        this.props.getWishList()
      }
    })
  }

  removeFromWishList = coral => {
    console.log(coral)
    axios.post('/api/users/removewishlist', coral).then(res => {
      console.log(res.data)
      if (res.data === 'success') {
        this.props.getWishList()
      }
    })
  }

  render () {
    const { wishList } = this.props.auth
    let nameIds = []
    let colorGenus = []
    let speciesList = []
    // console.log(wishList)
    if (wishList) {
      nameIds = wishList.map(item => item.nameId)
      speciesList = wishList.map(item => item.species)
      colorGenus = wishList.map(item => item.color + item.genus)
      console.log(colorGenus)
    }

    return (
      <>
        {/* <div style={{ postion: 'fixed', bottom: '10px' }}>
          <Alert color='info' isOpen={this.state.showAlert}>
            I am an alert and I can be dismissed!
          </Alert>
        </div> */}
        <SweetAlert
          title='Coral Added To Your Collection'
          show={this.state.showAlert}
          onConfirm={() => this.setState({ showAlert: false })}
        >
          {this.state.addInfo}
          <Link to={'/myreef/'}>
            <span className='text-info'>View Collection</span>
          </Link>
        </SweetAlert>
        <div>
          <Jumbotron fluid style={{ textAlign: 'center', padding: 10 }}>
            <Container fluid>
              <h4 className='display-6'>Coral Database</h4>
              <p
                // classNam</div>e='lead'
                style={{ fontsize: 8 }}
              >
                Click a color, species, or name to view corals, and use the plus
                icon to add similar coral that you own to your online
                collection.
              </p>
              <p
                // className='lead'
                style={{ fontsize: 8 }}
              >
                To suggest an unlisted coral name click{' '}
                <span style={{ color: 'blue' }}>
                  <Link
                    style={{ textDecoration: 'underline', color: 'blue' }}
                    to='usersuggestname'
                  >
                    here
                  </Link>
                </span>
                .
              </p>
            </Container>
          </Jumbotron>
        </div>
        <Container>
          {/* <div style={{ height: 20 }}></div> */}

          {!this.props.coralNames.loading &&
          this.props.coralNames.names.length > 0 ? (
            <>
              {classes.map((coralClass, index) => (
                <>
                  <h1
                    style={{
                      marginTop: 15,
                      marginBottom: 15,
                      textAlign: 'center'
                    }}
                  >
                    {coralClass.type}
                  </h1>
                  {/* <div
                    style={{
                      border: '5px solid black',
                      margin: 10,
                      borderRadius: 5,
                      padding: 10
                    }}
                  > */}
                  {/* {coralClass.type !== 'Chalice' ? (
                      <> */}
                  {coralClass.genus.map((genus, index) => (
                    <>
                      <div
                        style={{
                          border: '5px solid black',
                          margin: 10,
                          borderRadius: 5,
                          padding: 10
                        }}
                      >
                        <h4 style={{ marginLeft: 10 }}>{genus.name}</h4>
                        <>
                          <h6 style={{ textAlign: 'center' }}>Colors</h6>
                          <Row
                            style={{
                              justifyContent: 'center',
                              margin: 10,
                              alignItems: 'center'
                            }}
                          >
                            {colors.map(color => (
                              <div
                                style={{
                                  marginRight: 15,
                                  border: '1px solid lightgrey',
                                  borderRadius: 10,
                                  margin: 3
                                }}
                              >
                                <div className={styles.tooltip}>
                                  <Button
                                    style={{
                                      padding: 5,
                                      marginRight: 3,
                                      borderRadius: 50
                                    }}
                                    onClick={() =>
                                      this.addCoralByColor({
                                        type: coralClass.type,
                                        genus: genus.name,
                                        color: color.hue
                                      })
                                    }
                                  >
                                    <i
                                      className='fa fa-plus'
                                      style={{ color: 'lightgrey' }}
                                    ></i>
                                  </Button>

                                  <span className={styles.tooltiptext}>
                                    Add To My Coral Collection
                                  </span>
                                </div>
                                {colorGenus.some(
                                  item => item === color.hue + genus.name
                                ) ? (
                                  <div className={styles.tooltip}>
                                    <Button
                                      style={{
                                        padding: 5,
                                        marginRight: 3,
                                        borderRadius: 50
                                      }}
                                      onClick={() =>
                                        this.removeFromWishList({
                                          type: coralClass.type,
                                          genus: genus.name,
                                          color: color.hue
                                        })
                                      }
                                    >
                                      <i
                                        className='fa fa-heart'
                                        // style={{ color: 'lightgrey' }}
                                        style={{ color: 'red' }}
                                      ></i>
                                    </Button>

                                    <span className={styles.tooltiptext}>
                                      Remove From Wish List
                                    </span>
                                  </div>
                                ) : (
                                  <div className={styles.tooltip}>
                                    <Button
                                      style={{
                                        padding: 5,
                                        marginRight: 3,
                                        borderRadius: 50
                                      }}
                                      onClick={() =>
                                        this.addToWishList({
                                          type: coralClass.type,
                                          genus: genus.name,
                                          color: color.hue
                                        })
                                      }
                                    >
                                      <i
                                        className='fa fa-heart'
                                        // style={{ color: 'lightgrey' }}
                                        style={{ color: 'lightgrey' }}
                                      ></i>
                                    </Button>

                                    <span className={styles.tooltiptext}>
                                      Add To My Wish List
                                    </span>
                                  </div>
                                )}

                                <Link
                                  to={`coralcolorshow/${color.hue}/${genus.name}`}
                                >
                                  <Button
                                    style={{
                                      paddingTop: 3,
                                      paddingBottom: 3,
                                      paddingRight: 10,
                                      paddingLeft: 10
                                    }}
                                  >
                                    <div
                                      style={{
                                        background: `#${color.hue}`,
                                        height: 16,
                                        width: 16,
                                        borderRadius: 5
                                      }}
                                    />
                                  </Button>
                                </Link>
                              </div>
                            ))}
                          </Row>
                          <hr />
                          <h6 style={{ textAlign: 'center' }}>Species</h6>
                          <Row style={{ marginLeft: 20 }}>
                            {genus.species.map(species => (
                              <>
                                {species !== 'Unknown' &&
                                species !== 'Other' ? (
                                  <Col md={3}>
                                    <div>
                                      <div className={styles.tooltip}>
                                        <Button
                                          style={{
                                            padding: 5,

                                            borderRadius: 50
                                          }}
                                          onClick={() =>
                                            this.addCoralBySpecies({
                                              type: coralClass.type,
                                              genus: genus.name,
                                              species: species
                                            })
                                          }
                                        >
                                          <i
                                            className='fa fa-plus'
                                            style={{ color: 'lightgrey' }}
                                          ></i>
                                        </Button>

                                        <span className={styles.tooltiptext}>
                                          Add To My Coral Collection
                                        </span>
                                      </div>
                                      {speciesList.some(
                                        item => item === species
                                      ) ? (
                                        <div className={styles.tooltip}>
                                          <Button
                                            style={{
                                              padding: 5,

                                              borderRadius: 50
                                            }}
                                            onClick={() => {
                                              this.removeFromWishList({
                                                type: coralClass.type,
                                                genus: genus.name,
                                                species: species
                                              })
                                            }}
                                          >
                                            <i
                                              className='fa fa-heart'
                                              style={{ color: 'red' }}
                                            ></i>
                                          </Button>

                                          <span className={styles.tooltiptext}>
                                            Remove From Wish List
                                          </span>
                                        </div>
                                      ) : (
                                        <div className={styles.tooltip}>
                                          <Button
                                            style={{
                                              padding: 5,

                                              borderRadius: 50
                                            }}
                                            onClick={() => {
                                              this.addToWishList({
                                                type: coralClass.type,
                                                genus: genus.name,
                                                species: species
                                              })
                                            }}
                                          >
                                            <i
                                              className='fa fa-heart'
                                              style={{ color: 'lightgrey' }}
                                            ></i>
                                          </Button>

                                          <span className={styles.tooltiptext}>
                                            Add To My Wish List
                                          </span>
                                        </div>
                                      )}

                                      {/* <div className={styles.tooltip}>
                                        <Button
                                          style={{
                                            padding: 5,

                                            borderRadius: 50
                                          }}
                                          onClick={() => {
                                            this.addToWishList({
                                              type: coralClass.type,
                                              genus: genus.name,
                                              species: species
                                            })
                                          }}
                                        >
                                          <i
                                            className='fa fa-heart'
                                            style={{ color: 'lightgrey' }}
                                          ></i>
                                        </Button>

                                        <span className={styles.tooltiptext}>
                                          Add To My Wish List
                                        </span>
                                      </div> */}

                                      {'  '}
                                      <Link
                                        to={`/species/${species.toLowerCase()}`}
                                      >
                                        <Button style={{ padding: 3 }}>
                                          <span
                                            style={{
                                              textTransform: 'capitalize'
                                            }}
                                          >
                                            {species}
                                            {/* {coralName.name} (
                                            {coralName.coralTotal}) */}
                                          </span>
                                        </Button>
                                      </Link>
                                    </div>
                                  </Col>
                                ) : null}
                              </>
                            ))}
                          </Row>
                          <hr />
                          <h6 style={{ textAlign: 'center' }}>Common Names</h6>
                          <Row style={{ marginLeft: 20 }}>
                            {this.props.coralNames.names.map(coralName => (
                              <>
                                {coralName.genus === genus.name ? (
                                  <Col md={3}>
                                    <div>
                                      <div className={styles.tooltip}>
                                        <Button
                                          style={{
                                            padding: 5,

                                            borderRadius: 50
                                          }}
                                          onClick={() =>
                                            this.addCoral(coralName)
                                          }
                                        >
                                          <i
                                            className='fa fa-plus'
                                            style={{ color: 'lightgrey' }}
                                          ></i>
                                        </Button>

                                        <span className={styles.tooltiptext}>
                                          Add To My Coral Collection
                                        </span>
                                      </div>
                                      {nameIds.some(
                                        id => id === coralName._id
                                      ) ? (
                                        <div className={styles.tooltip}>
                                          <Button
                                            style={{
                                              padding: 5,

                                              borderRadius: 50
                                            }}
                                            onClick={() => {
                                              this.removeFromWishList({
                                                type: coralClass.type,
                                                genus: coralName.genus,
                                                nameId: coralName._id,
                                                name: coralName.name
                                              })
                                            }}
                                          >
                                            <i
                                              className='fa fa-heart'
                                              style={{ color: 'red' }}
                                            ></i>
                                          </Button>

                                          <span className={styles.tooltiptext}>
                                            Remove From Wish List
                                          </span>
                                        </div>
                                      ) : (
                                        <div className={styles.tooltip}>
                                          <Button
                                            style={{
                                              padding: 5,

                                              borderRadius: 50
                                            }}
                                            onClick={() => {
                                              this.addToWishList({
                                                type: coralClass.type,
                                                genus: coralName.genus,
                                                nameId: coralName._id,
                                                name: coralName.name
                                              })
                                            }}
                                          >
                                            <i
                                              className='fa fa-heart'
                                              style={{ color: 'lightgrey' }}
                                            ></i>
                                          </Button>

                                          <span className={styles.tooltiptext}>
                                            Add To My Wish List
                                          </span>
                                        </div>
                                      )}

                                      {'  '}
                                      <Link to={`coralnames/${coralName._id}`}>
                                        <Button style={{ padding: 3 }}>
                                          <span
                                            style={{
                                              textTransform: 'capitalize'
                                            }}
                                          >
                                            {coralName.name} (
                                            {coralName.coralTotal})
                                          </span>
                                        </Button>
                                      </Link>
                                    </div>
                                  </Col>
                                ) : null}
                              </>
                            ))}
                          </Row>
                        </>{' '}
                      </div>
                    </>
                  ))}
                  {/* </>
                    ) : (
                      <Row style={{ marginLeft: 20 }}>
                        {this.props.coralNames.names.map(coralName => (
                          <>
                            {coralName.genus === 'chalice' ? (
                              <Col md={3}>
                                <div>
                                  <div className={styles.tooltip}>
                                    <Button
                                      style={{
                                        padding: 5,

                                        borderRadius: 50
                                      }}
                                      onClick={() => this.addCoral(coralName)}
                                    >
                                      <i className='fa fa-plus'></i>
                                    </Button>

                                    <span className={styles.tooltiptext}>
                                      Add To My Reef
                                    </span>
                                  </div>
                                  {'  '}
                                  <Link
                                    to={`coralnames/${coralName._id}`}
                                    // style={{ textDecoration: "underline" }}
                                  >
                                    <Button style={{ padding: 3 }}>
                                      <span
                                        style={{ textTransform: 'capitalize' }}
                                      >
                                        {coralName.name} ({coralName.coralTotal}
                                        )
                                      </span>
                                    </Button>
                                  </Link>
                                </div>
                              </Col>
                            ) : null}
                          </>
                        ))}
                      </Row>
                    )} */}
                  {/* </div> */}
                </>
              ))}{' '}
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner style={{ margin: 'auto' }} />
            </div>
          )}
        </Container>
      </>
    )
  }
}

CoralNames.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  coral: PropTypes.object.isRequired,
  names: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  coralNames: state.coralNames,
  coral: state.coral
})

export default connect(mapStateToProps, { getNames, getWishList, myCoral })(
  CoralNames
)

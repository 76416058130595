import axios from 'axios'

import { NAMES_LOADING, GET_NAMES } from './types'

// Get deals
export const getNames = params => dispatch => {
  dispatch(setNamesLoading())
  axios
    .get(`/api/coral/approvedcoralnames/${params.type}/${params.genus}`)
    .then(res => {
      dispatch({
        type: GET_NAMES,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    })
}

// // Set loading state
export const setNamesLoading = () => {
  return {
    type: NAMES_LOADING
  }
}

// // // Set loading state
// export const clearCoralLoading = () => {
//   return {
//     type: CLEAR_CORAL_LOADING
//   };
// };

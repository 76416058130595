import React from 'react'

import PropTypes from 'prop-types'

import FragImage from 'components/AddCartRow/FragImage.js'
import AddCartSelect from 'components/AddCartRow/AddCartSelect.js'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { memberCoral } from 'actions/coralActions'
import { getMyCart } from 'actions/authActions'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import classnames from 'classnames'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import styles from './CoralNames.module.css'

// reactstrap components
import {
  Button,
  Container,
  Alert,
  Input,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardLink
} from 'reactstrap'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon
} from 'react-share'

class Login extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      cart: [],
      fragsSelected: [],
      errors: {},
      activeTab: '1'
    }
  }

  componentDidMount () {
    // console.log()
    if (!this.props.community.memberCoral) {
      this.props.memberCoral(this.props.match.params.id)
    } else {
      if (this.props.community.memberCoral.length > 0) {
        if (
          this.props.community.memberCoral[0].user !==
          this.props.match.params.id
        ) {
          this.props.memberCoral(this.props.match.params.id)
        }
      } else {
        this.props.memberCoral(this.props.match.params.id)
      }
    }

    if (!this.props.auth.myCart && this.props.auth.isAuthenticated) {
      this.props.getMyCart()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.community.memberCoral !== this.props.community.memberCoral) {
      this.setState({ showTd: true })
    }
  }

  deleteCoral (coral) {
    axios.post('/api/coral/deletecoral', { id: coral._id }).then(res => {
      if (res.data === 'success') {
        this.props.memberCoral(this.props.match.params.id)
      }
    })
  }

  addToCart = coral => {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/login')
    }

    let newList = [...this.state.fragsSelected]
    let oldFragIndex = this.state.fragsSelected
      .map(frag => frag.coralId)
      .indexOf(coral._id)
    console.log(oldFragIndex)
    if (oldFragIndex !== -1) {
      newList.splice(oldFragIndex, 1)

      this.setState({ fragsSelected: newList })
    }

    console.log(
      coral,
      this.state.fragsSelected.map(item => item.coralId)
    )
    let fragIndex = this.state.fragsSelected
      .map(item => item.coralId)
      .indexOf(coral._id)
    console.log(fragIndex)
    let frag = this.state.fragsSelected[fragIndex].frag
    axios
      .post('/api/users/addtocart', {
        userId: this.props.match.params.id,
        coral: coral,
        fragId: frag._id
      })
      .then(res => {
        console.log(res.data)
        this.props.getMyCart()
      })
  }

  removeFromCart = coralId => {
    axios
      .post('/api/users/removefromcart', {
        userId: this.props.match.params.id,
        coralId: coralId
      })
      .then(res => {
        console.log(res.data)
        this.props.getMyCart()
      })
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  selectFrag = frag => {
    console.log(frag)
    //delete old frag if new one is selected
    let newList = [...this.state.fragsSelected]
    let oldFragIndex = this.state.fragsSelected
      .map(frag => frag.coralId)
      .indexOf(frag.coralId)
    console.log(oldFragIndex)
    if (oldFragIndex !== -1) {
      newList.splice(oldFragIndex, 1)

      // this.setState({ fragsSelected: newList })
    }

    let coralIndex = this.props.community.memberCoral
      .map(coral => coral._id)
      .indexOf(frag.coralId)
    console.log(coralIndex)
    let fragList = this.props.community.memberCoral[coralIndex].frags.map(
      frag => frag._id
    )
    let fragIndex = fragList
      .map(id => id.toString().substr(id.toString().length - 4))
      .indexOf(frag.frag)
    console.log(fragIndex)
    let selectedFrag = this.props.community.memberCoral[coralIndex].frags[
      fragIndex
    ]
    if (frag.frag !== 'Select') {
      let pushFrag = { coralId: frag.coralId, frag: selectedFrag }
      newList.push(pushFrag)
    }

    console.log(newList)
    this.setState({ fragsSelected: newList })
    // this.setState({ fragsSelected: [...this.state.fragsSelected, pushFrag] })
  }

  render () {
    let { memberCoral } = this.props.community
    let selectChanged = this.state.fragsSelected.map(frag => frag.coralId)
    let ogImage =
      'https://s3.amazonaws.com/reefconnector/facebooklogopreview.png'

    // console.log(memberCoral)
    // console.log(this.state)
    // let showTd = false
    // this.setState({ renderCount: this.state.renderCount + 1 })
    // console.log
    // if (this.state.renderCount > 0) {
    //   showTd = true
    // }
    const { myCart } = this.props.auth
    let cartIndex = -1
    let localCart = null
    if (myCart) {
      cartIndex = myCart
        .map(cart => cart.user._id)
        .indexOf(this.props.match.params.id)
      if (cartIndex != -1) {
        localCart = myCart[cartIndex]
      }
      console.log(localCart)
    }

    if (memberCoral) {
      if (memberCoral[0].user.shareImage) {
        ogImage = `https://s3.amazonaws.com/reefconnector/${memberCoral[0].user.shareImage}`
      }
    }

    return (
      <div className='login-page'>
        {memberCoral ? (
          <>
            {memberCoral.length > 0 ? (
              <Helmet>
                <title style={{ textTransform: 'uppercase' }}>
                  {memberCoral[0].user.username +
                    "'s Coral Collection (" +
                    memberCoral.length +
                    'Corals)'}
                </title>

                <meta
                  name='description'
                  content={`Trade with ${memberCoral[0].user.username} for free at Immortal Reef.`}
                />
                <meta property='og:image' content={ogImage} />

                {/* {!memberCoral[0].user.shareImage ? (
                  <>
                    <meta
                      // property='og:image'
                      property='og:image'
                      // content={`https://s3.amazonaws.com/reefconnector/${memberCoral[0].image.address}`}
                      content={`https://s3.amazonaws.com/reefconnector/facebooklogopreview.png`}
                    />
                  </>
                ) : (
                  <>
                    <meta
                      // property='og:image'
                      property='og:image'
                      content={`https://s3.amazonaws.com/reefconnector/${memberCoral[0].user.shareImage}`}
                    />
                  </>
                )} */}

                <meta
                  property='og:url'
                  content={`https://www.immortalreef.com/membercollection/${this.props.match.params.id}`}
                />
              </Helmet>
            ) : null}
            {memberCoral.length > 0 ? (
              <Container>
                {localCart && localCart.coral.length > 0 ? (
                  <Alert
                    color='warning'
                    style={{
                      position: 'fixed',
                      top: 72,
                      right: 0,
                      zIndex: 1000
                    }}
                  >
                    You have {localCart.coral.length} coral in your cart from
                    this collector.{' '}
                    <Link to={`/trade/${localCart.coral[0].coral.user}`}>
                      <Button color='info' style={{ padding: 2, margin: 3 }}>
                        Continue
                      </Button>
                    </Link>
                  </Alert>
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                    // flexWrap: 'wrap'
                  }}
                >
                  <img
                    src={this.props.community.memberCoral[0].user.avatar}
                    style={{ margin: 10, borderRadius: 50 }}
                  />
                  <h4 style={{ textAlign: 'center', marginTop: 10 }}>
                    {this.props.community.memberCoral[0].user.username}'s Coral
                    Collection
                  </h4>
                </div>

                <div style={{ textAlign: 'center', marginBottom: 10 }}>
                  <a
                    className='text-info'
                    href={`https://community.immortalreef.com/u/${this.props.community.memberCoral[0].user.username}/summary`}
                  >
                    Contact
                  </a>
                  {' - '}
                  {this.props.community.memberCoral[0].user.locality},{' '}
                  {
                    this.props.community.memberCoral[0].user
                      .administrative_area_level_1_short_name
                  }
                </div>
                {/* <div style={{ textAlign: 'center' }}>
                  <Link
                    className='text-info'
                    to={`/userwishlist/${this.props.community.memberCoral[0].user._id}`}
                  >
                    View Wish List
                  </Link>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Link
                    style={{ marginRight: 20 }}
                    className='text-info'
                    to={`/memberfrags/${this.props.community.memberCoral[0].user._id}`}
                  >
                    FRAG RACK
                  </Link>

                  <span> - </span>

                  <Link
                    style={{ marginLeft: 20 }}
                    className='text-info'
                    to={`/userwishlist/${this.props.community.memberCoral[0].user._id}`}
                  >
                    WISH LIST
                  </Link>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    justifySelf: 'end'
                    //margin: 8
                    // marginLeft: 50
                  }}
                >
                  <FacebookShareButton
                    style={{ marginRight: 3 }}
                    url={`https://www.immortalreef.com/membercollection/${this.props.match.params.id}`}
                  >
                    <FacebookIcon size={28} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    style={{ marginRight: 3 }}
                    url={`https://www.immortalreef.com/membercollection/${this.props.match.params.id}`}
                  >
                    <TwitterIcon size={28} />
                  </TwitterShareButton>
                </div>
                {this.props.auth.isAuthenticated &&
                this.props.auth.user.id === this.props.match.params.id ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      margin: 8
                    }}
                  >
                    <Link to={'/mediapreview'} className='text-info'>
                      <small>Social Media Preview Collage</small>
                    </Link>
                  </div>
                ) : null}
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      // href='#parents'
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTab === '1'
                      })}
                      onClick={() => {
                        this.toggleTab('1')
                      }}
                    >
                      Images
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      // href='#children'
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: this.state.activeTab === '2'
                      })}
                      onClick={() => {
                        this.toggleTab('2')
                      }}
                    >
                      Table
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId='2'>
                    <>
                      <p style={{ textAlign: 'center' }}>
                        <small>
                          * Trade by adding coral to your cart. You will be
                          prompted to offer currancy or coral from your
                          collection in the next step.
                        </small>
                      </p>
                      <Table>
                        <Thead>
                          <Tr style={{ background: '#F2F3F5' }}>
                            <Th className='text-center'>Mother</Th>
                            <Th className='text-center'>Type</Th>
                            <Th>Genus</Th>
                            <Th>Species</Th>
                            <Th>Name</Th>
                            <Th className='text-center'>Color</Th>
                            <Th className='text-center'>Price</Th>
                            <Th className='text-center'>Trade*</Th>

                            <Th key={1} className='text-center'>
                              Frag
                            </Th>
                            <Th key={2} className='text-center'>
                              Frag Img
                            </Th>
                            <Th key={3} className='text-center'>
                              {this.props.auth.isAuthenticated &&
                              this.props.auth.user.id ===
                                this.props.match.params.id ? (
                                <span>Actions</span>
                              ) : (
                                <span>Cart</span>
                              )}
                            </Th>
                          </Tr>
                        </Thead>

                        <Tbody>
                          {memberCoral.map(coral => (
                            <>
                              <Tr
                                style={{
                                  borderBottom: '1px solid lightgrey'
                                }}
                                key={coral._id}
                              >
                                <Td className='text-center'>
                                  <Link to={`/coralshow/${coral._id}`}>
                                    {coral.image ? (
                                      // <img
                                      //   style={{
                                      //     maxHeight: '8rem',
                                      //     maxWidth: '8rem',
                                      //     borderRadius: 5
                                      //   }}
                                      //   src={`https://s3.amazonaws.com/reefconnector/${coral.image.address}`}
                                      // />
                                      <div
                                        style={{
                                          borderRadius: 5,
                                          height: '8rem',
                                          width: '8rem',
                                          backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${coral.image.address})`,
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                          backgroundRepeat: 'no-repeat',
                                          margin: 3,
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between'
                                        }}
                                      />
                                    ) : (
                                      // <i
                                      //   className='fa fa-image'
                                      //   style={{ fontSize: 64 }}
                                      // />
                                      // <img
                                      //   style={{
                                      //     maxHeight: '8rem',
                                      //     maxWidth: '8rem',
                                      //     borderRadius: 5
                                      //   }}
                                      //   src={`https://s3.amazonaws.com/reefconnector/image_placeholder.jpg`}
                                      // />
                                      <div
                                        style={{
                                          borderRadius: 5,
                                          height: '8rem',
                                          width: '8rem',
                                          backgroundImage: `url(https://s3.amazonaws.com/reefconnector/image_placeholder.jpg)`,
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                          backgroundRepeat: 'no-repeat',
                                          margin: 3,
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between'
                                        }}
                                      />
                                    )}
                                  </Link>
                                </Td>
                                <Td className='text-center'>{coral.type}</Td>
                                <Td>{coral.genus}</Td>
                                {coral.species ? (
                                  <Td>
                                    {coral.species === 'Other' ? (
                                      <span>{coral.otherSpecies}</span>
                                    ) : (
                                      <>
                                        {coral.species === 'Unknown' ? (
                                          <span>-</span>
                                        ) : (
                                          <Link
                                            to={`/species/${coral.species.toLowerCase()}`}
                                            className='text-info'
                                          >
                                            {coral.species}
                                          </Link>
                                        )}
                                      </>
                                    )}
                                  </Td>
                                ) : (
                                  <Td>-</Td>
                                )}
                                <Td>
                                  {coral.name ? (
                                    <Link to={`/coralnames/${coral.name._id}`}>
                                      <div
                                        className='text-info'
                                        style={{ textTransform: 'capitalize' }}
                                      >
                                        {coral.name.name}
                                      </div>
                                    </Link>
                                  ) : (
                                    <>
                                      {coral.unverifiedName ? (
                                        <div
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                        >
                                          {coral.unverifiedName}
                                        </div>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </>
                                  )}
                                </Td>
                                {coral.extracted150Hex ? (
                                  <Td className='text-center'>
                                    <Link
                                      to={`/coralcolorshow/${coral.extracted150Hex}/${coral.genus}`}
                                    >
                                      <div
                                        style={{
                                          background: `#${coral.extracted150Hex}`,
                                          height: 20,
                                          width: 20,
                                          textAlign: 'center',
                                          borderRadius: 5,
                                          margin: 'auto'
                                        }}
                                      />
                                    </Link>
                                  </Td>
                                ) : (
                                  <Td className='text-center'>-</Td>
                                )}

                                <Td className='text-center'>
                                  {localCart &&
                                  localCart.coral
                                    .map(coral => coral.coral)
                                    .some(id => id === coral._id) ? (
                                    <span>
                                      $
                                      {
                                        coral.frags[
                                          coral.frags
                                            .map(frag => frag._id)
                                            .indexOf(
                                              localCart.coral[
                                                localCart.coral
                                                  .map(coral => coral.coral)
                                                  .indexOf(coral._id)
                                              ].fragId
                                            )
                                        ].price
                                      }
                                    </span>
                                  ) : (
                                    <>
                                      {selectChanged.some(
                                        id => id === coral._id
                                      ) ? (
                                        <span>
                                          $
                                          {
                                            this.state.fragsSelected[
                                              selectChanged.indexOf(coral._id)
                                            ].frag.price
                                          }
                                        </span>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </>
                                  )}
                                </Td>
                                <Td className='text-center'>
                                  {coral.forTrade ? (
                                    <span>
                                      <i className='fa fa-check text-success' />
                                    </span>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </Td>
                                <Td className='text-center'>
                                  {(localCart &&
                                    localCart.coral
                                      .map(coral => coral.coral._id)
                                      .some(id => id === coral._id)) ||
                                  (this.props.auth.isAuthenticated &&
                                    this.props.auth.user.id ===
                                      this.props.match.params.id) ? (
                                    <span>-</span>
                                  ) : (
                                    <AddCartSelect
                                      componentCoral={coral}
                                      selectFrag={this.selectFrag}
                                    />
                                  )}
                                </Td>
                                <Td className='text-center'>
                                  {localCart &&
                                  localCart.coral
                                    .map(coral => coral.coral._id)
                                    .some(id => id === coral._id) ? (
                                    // <img
                                    //   style={{
                                    //     maxHeight: '7rem',
                                    //     maxWidth: '7rem',
                                    //     borderRadius: 5
                                    //   }}
                                    //   src={`https://s3.amazonaws.com/reefconnector/${
                                    //     coral.frags[
                                    //       coral.frags
                                    //         .map(frag => frag._id)
                                    //         .indexOf(
                                    //           localCart.coral[
                                    //             localCart.coral
                                    //               .map(coral => coral.coral._id)
                                    //               .indexOf(coral._id)
                                    //           ].fragId
                                    //         )
                                    //     ].image.address
                                    //   }`}
                                    // />
                                    <div
                                      style={{
                                        borderRadius: 5,
                                        height: '8rem',
                                        width: '8rem',
                                        backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${
                                          coral.frags[
                                            coral.frags
                                              .map(frag => frag._id)
                                              .indexOf(
                                                localCart.coral[
                                                  localCart.coral
                                                    .map(
                                                      coral => coral.coral._id
                                                    )
                                                    .indexOf(coral._id)
                                                ].fragId
                                              )
                                          ].image.address
                                        })`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        margin: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                      }}
                                    />
                                  ) : (
                                    <>
                                      {selectChanged.some(
                                        id => id === coral._id
                                      ) ? (
                                        // <img
                                        //   style={{
                                        //     maxHeight: '7rem',
                                        //     maxWidth: '7rem',
                                        //     borderRadius: 5
                                        //   }}
                                        //   src={`https://s3.amazonaws.com/reefconnector/${
                                        //     this.state.fragsSelected[
                                        //       selectChanged.indexOf(coral._id)
                                        //     ].frag.image.address
                                        //   }`}
                                        // />
                                        <div
                                          style={{
                                            borderRadius: 5,
                                            height: '8rem',
                                            width: '8rem',
                                            backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${
                                              this.state.fragsSelected[
                                                selectChanged.indexOf(coral._id)
                                              ].frag.image.address
                                            })`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            margin: 3
                                            // display: 'flex',
                                            // flexDirection: 'column',
                                            // justifyContent: 'space-between'
                                          }}
                                        />
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </>
                                  )}
                                </Td>
                                <Td className='text-center'>
                                  {this.props.auth.isAuthenticated &&
                                  this.props.auth.user.id ===
                                    this.props.match.params.id ? (
                                    <>
                                      <Link to={`/coraledit/${coral._id}`}>
                                        <div className={styles.tooltip}>
                                          <Button
                                            outline
                                            className='btn-icon'
                                            color='warning'
                                            size='sm'
                                            style={{ borderRadius: 5 }}
                                          >
                                            <i
                                              className='fa fa-edit text-dark'
                                              style={{ fontSize: 16 }}
                                            ></i>
                                          </Button>
                                          <span className={styles.tooltiptext}>
                                            Edit Coral Info and Picture
                                          </span>
                                        </div>
                                      </Link>
                                      {` `}
                                      <Link to={`/addfrag/${coral._id}`}>
                                        <div className={styles.tooltip}>
                                          <Button
                                            outline
                                            className='btn-icon'
                                            color='info'
                                            size='sm'
                                            style={{ borderRadius: 5 }}
                                          >
                                            <i
                                              className='fa fa-plus'
                                              style={{ fontSize: 16 }}
                                            ></i>
                                          </Button>
                                          <span className={styles.tooltiptext}>
                                            Add Frag
                                          </span>
                                        </div>
                                      </Link>
                                      {` `}
                                      <div className={styles.tooltip}>
                                        <Button
                                          outline
                                          className='btn-icon'
                                          color='danger'
                                          size='sm'
                                          style={{ borderRadius: 5 }}
                                          onClick={() =>
                                            this.deleteCoral(coral)
                                          }
                                        >
                                          <i
                                            className='fa fa-trash-o'
                                            style={{ fontSize: 16 }}
                                          />
                                        </Button>
                                        <span className={styles.tooltiptext}>
                                          Remove from collection
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {localCart &&
                                      localCart.coral
                                        .map(coral => coral.coral._id)
                                        .some(id => id === coral._id) ? (
                                        <Button
                                          outline
                                          color='danger'
                                          style={{ padding: 4 }}
                                          onClick={() =>
                                            this.removeFromCart(coral._id)
                                          }
                                        >
                                          Remove
                                        </Button>
                                      ) : (
                                        <>
                                          {selectChanged.some(
                                            id => id === coral._id
                                          ) ? (
                                            <Button
                                              outline
                                              color='success'
                                              style={{ padding: 6 }}
                                              onClick={() =>
                                                this.addToCart(coral)
                                              }
                                            >
                                              Add
                                            </Button>
                                          ) : (
                                            <>
                                              {coral.frags.length > 0 ? (
                                                <Button
                                                  outline
                                                  color='success'
                                                  disabled
                                                  style={{ padding: 6 }}
                                                >
                                                  Add
                                                </Button>
                                              ) : (
                                                <span>-</span>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </Td>
                              </Tr>
                            </>
                          ))}
                        </Tbody>
                      </Table>
                    </>
                  </TabPane>
                  <TabPane tabId='1'>
                    <p style={{ textAlign: 'center' }}>
                      <small>
                        * Trade by adding coral to your cart. You will be
                        prompted to offer currancy or coral from your collection
                        in the next step.
                      </small>
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-around',
                        margin: 5
                      }}
                    >
                      {memberCoral.map(coral => (
                        <Card
                          style={{
                            margin: 3,
                            paddingBottom: 5,
                            backgroundColor: '#F3F3F3'
                          }}
                        >
                          <Link to={`/coralshow/${coral._id}`}>
                            {coral.image ? (
                              <div
                                style={{
                                  borderRadius: 5,
                                  height: 250,
                                  width: 250,
                                  backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${coral.image.address})`,
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  margin: 3,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between'
                                }}
                              >
                                {/* <div
                                className='text-light'
                                style={{
                                  textAlign: 'center'
                                }}
                              >
                                <span
                                  style={{
                                    background: 'rgba(0, 0, 0, 0.35)',
                                    borderRadius: 2,
                                    padding: 1
                                  }}
                                >
                                  <small>
                                    {coral.genus} - {coral.species}
                                  </small>
                                </span>
                              </div> */}
                              </div>
                            ) : (
                              <div
                                style={{
                                  borderRadius: 5,
                                  height: 250,
                                  width: 250,
                                  backgroundImage: `url(https://s3.amazonaws.com/reefconnector/image_placeholder.jpg)`,
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  margin: 3,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between'
                                }}
                              />
                            )}
                          </Link>
                          <CardTitle className='text-center'>
                            {/* {coral.genus} - {coral.species} */}
                            <div
                              style={{
                                textAlign: 'center'
                              }}
                            >
                              <div>
                                {coral.genus}
                                <>
                                  {coral.species ? (
                                    <>
                                      {coral.species !== 'Other' ? (
                                        <span>
                                          {' '}
                                          -{' '}
                                          <Link
                                            to={`/species/${coral.species.toLowerCase()}`}
                                            className='text-info'
                                          >
                                            {coral.species}
                                          </Link>
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                        >
                                          {' '}
                                          - {coral.otherSpecies}
                                        </span>
                                      )}
                                    </>
                                  ) : null}
                                </>
                              </div>
                              <div>
                                {coral.name ? (
                                  <Link
                                    to={`/coralnames/${coral.name._id}`}
                                    className='text-info'
                                  >
                                    <span
                                      style={{ textTransform: 'capitalize' }}
                                    >
                                      <small>({coral.name.name})</small>
                                    </span>
                                  </Link>
                                ) : (
                                  <>
                                    {coral.unverifiedName ? (
                                      <span
                                        style={{ textTransform: 'capitalize' }}
                                      >
                                        <small>({coral.unverifiedName})</small>
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </div>
                          </CardTitle>
                          {/* <CardSubtitle className='mb-2 text-muted text-center'>
                            {coral.name ? (
                              <span style={{ textTransform: 'capitalize' }}>
                                {coral.name.name}
                              </span>
                            ) : (
                              <span>-</span>
                            )}
                          </CardSubtitle> */}
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'no-wrap',
                              justifyContent: 'space-around'
                            }}
                          >
                            {/* <div>
                              <i className='fa fa-dollar text-success' />
                            </div> */}
                            {coral.frags.filter(
                              frag => frag.status === 'active'
                            ).length > 0 ? (
                              <div>
                                $
                                {
                                  coral.frags
                                    .filter(frag => frag.status === 'active')
                                    .sort((x, y) => {
                                      return x.price < y.price ? -1 : 1
                                    })[0].price
                                }
                                +
                              </div>
                            ) : null}

                            <div>
                              Trade{' '}
                              {coral.forTrade ? (
                                <i className='fa fa-check text-success' />
                              ) : (
                                <i className='fa fa-remove' />
                              )}
                            </div>
                            {coral.frags.filter(
                              frag => frag.status === 'active'
                            ).length > 0 ? (
                              <div>
                                (
                                <Link to={`/coralshow/${coral._id}`}>
                                  <span className='text-info'>
                                    {
                                      coral.frags.filter(
                                        frag => frag.status === 'active'
                                      ).length
                                    }
                                  </span>
                                </Link>
                                ) Frags
                              </div>
                            ) : (
                              <div>Unavailable</div>
                            )}
                            {/* {coral.frags.length > 0 ? (
                              <div>({coral.frags.length}) Frags</div>
                            ) : (
                              
                              <div>
                                <Button
                                  color='info'
                                  outline
                                  style={{ padding: 4 }}
                                >
                                  Waitlist
                                </Button>{' '}
                              </div>
                            )} */}
                          </div>
                        </Card>
                      ))}
                      {/* {memberCoral.map(coral => (
                        <div
                          style={{
                            //background: '#F2F3F5',
                            background: 'black',
                            margin: 5,
                            padding: 5,
                            borderRadius: 5,
                            textAlign: 'center',
                            border: '2px solid black'
                          }}
                        >
                          <h6 className='text-light'>
                            {coral.genus} - {coral.species}
                          </h6>
                          {coral.name ? (
                            <h6 className='text-light'>{coral.name.name}</h6>
                          ) : null}
                          <div
                            style={{
                              textAlign: 'center',
                              margin: 10,
                              padding: 10,
                              borderRadius: 5,
                              background: '#F2F3F5',
                              // background: 'black',
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'space-around'
                            }}
                          > */}
                      {/* <div>
                          <h6>Mother Colony</h6>

                          <Link to={`/coralshow/${coral._id}`}>
                            {coral.image ? (
                              <div
                                style={{
                                  margin: 5,
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: 150
                                }}
                              >
                                <img
                                  style={{
                                    maxHeight: 150,
                                    maxWidth: 150,
                                    borderRadius: 5
                                  }}
                                  src={`https://s3.amazonaws.com/reefconnector/${coral.image.address}`}
                                />
                              </div>
                            ) : (
                              <i
                                className='fa fa-image'
                                style={{ fontSize: 64 }}
                              />
                            )}
                          </Link>
                        </div> */}
                      {/* {coral.frags.length > 0 ? ( */}

                      {/* <h6>Available Frags</h6> */}
                      {/* <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                flexWrap: 'wrap'
                              }}
                            >
                              <Link to={`/coralshow/${coral._id}`}>
                                {coral.image ? (
                                  <div
                                    style={{
                                      borderRadius: 5,
                                      height: 250,
                                      width: 250,
                                      backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${coral.image.address})`,
                                      backgroundPosition: 'center',
                                      backgroundSize: 'cover',
                                      backgroundRepeat: 'no-repeat',
                                      margin: 3,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between'
                                    }}
                                  >
                                    <div
                                      className='text-light'
                                      style={{
                                        textAlign: 'center'
                                      }}
                                    >
                                      <span
                                        style={{
                                          background: 'rgba(0, 0, 0, 0.35)',
                                          borderRadius: 2,
                                          padding: 1
                                        }}
                                      >
                                        <small>
                                          {coral.genus} - {coral.species}
                                        </small>
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <i
                                    className='fa fa-image'
                                    style={{ fontSize: 64 }}
                                  />
                                )}
                              </Link>
                              {coral.frags.length > 0 ? (
                                <>
                                  {coral.frags.map(frag => (
                                    <div
                                      style={{
                                        borderRadius: 5,
                                        height: 150,
                                        width: 150,
                                        backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${frag.image.address})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        margin: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                      }}
                                    >
                                      <div
                                        style={{
                                          // color: 'white',
                                          textAlign: 'end',
                                          margin: 5,
                                          display: 'flex',
                                          alignItems: 'start',
                                          justifyContent: 'space-between'
                                        }}
                                      >
                                        <span
                                          style={{
                                            // backgroundColor:
                                            //   !coralInCart ||
                                            //   selectedFrag === frag._id
                                            //     ? 'rgba(0, 0, 0, 0.35)'
                                            //     : 'rgba(0, 0, 0, 0)',
                                            borderRadius: 2,
                                            padding: 2
                                          }}
                                        > */}
                      {/* {// !coralInCart &&
                                          this.props.auth.user.id !=
                                          coral.user._id ? (
                                            <div className={styles.tooltip}>
                                              <Button
                                                color='secondary'
                                                style={{
                                                  padding: 3,
                                                  border: 0
                                                }}
                                                size='sm'
                                                outline
                                                onClick={() =>
                                                  this.addToCart(frag._id)
                                                }
                                              >
                                                <i
                                                  style={{ fontSize: 18 }}
                                                  className='fa fa-shopping-cart'
                                                  //className='fa fa-check'
                                                />
                                              </Button>
                                              <span
                                                className={styles.tooltiptext}
                                              >
                                                Add To Cart
                                              </span>
                                            </div>
                                          ) : null} */}

                      {/* {selectedFrag === frag._id ? (
                                            <div className={styles.tooltip}>
                                              <Button
                                                color='light'
                                                style={{
                                                  padding: 3,
                                                  border: 0
                                                }}
                                                size='sm'
                                                outline
                                                onClick={this.removeFromCart}
                                              >
                                                <i
                                                  style={{ fontSize: 18 }}
                                                  className='fa fa-check'
                                                />
                                              </Button>
                                              <span
                                                className={styles.tooltiptext}
                                              >
                                                Remove From Cart
                                              </span>
                                            </div>
                                          ) : null} */}
                      {/* </span>
                                        <span
                                          className='text-light'
                                          style={{
                                            backgroundColor:
                                              'rgba(0, 0, 0, 0.35)',
                                            borderRadius: 2,
                                            padding: 2
                                          }}
                                        >
                                          ${frag.price}
                                        </span>
                                      </div>
                                      <div
                                        className='text-light'
                                        style={{
                                          textAlign: 'right'
                                        }}
                                      >
                                        <span
                                          style={{
                                            background: 'rgba(0, 0, 0, 0.35)',
                                            borderRadius: 2,
                                            padding: 1
                                          }}
                                        >
                                          <small>
                                            {frag._id
                                              .toString()
                                              .substr(
                                                frag._id.toString().length - 4
                                              )}
                                          </small>
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : null}
                            </div> */}

                      {/* ) : null} */}
                      {/* </div>
                        </div>
                      ))} */}
                    </div>
                  </TabPane>
                </TabContent>
              </Container>
            ) : (
              <h4 style={{ textAlign: 'center' }}>User has no collection.</h4>
            )}
          </>
        ) : (
          <h4>Loading...</h4>
        )}
      </div>
    )
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral,
  community: state.community,
  errors: state.errors
})

export default connect(mapStateToProps, { memberCoral, getMyCart })(Login)

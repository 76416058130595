import React from 'react'
import { Link } from 'react-router-dom'

// import blackTransparent from "assets/logo/blackTransparent.png";
import { Helmet } from 'react-helmet'

// reactstrap components
import { Container } from 'reactstrap'

class Landing extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      screenSizeSmall: false
    }
  }

  componentDidMount () {
    document.body.classList.toggle('register-page')
    this.updateScreen()
  }
  componentWillUnmount () {
    document.body.classList.toggle('register-page')
  }

  updateScreen = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true
      })
    } else {
      this.setState({
        screenSizeSmall: false
      })
    }
  }

  render () {
    return (
      <div className='register-page'>
        <Helmet>
          <title>
            {/* {coral.genus} {coral.species} */}
            {/* Test Title */}
            Immortal Reef
          </title>

          <meta
            name='description'
            content='Quick and easy coral trading with user collections, wishlists, and lineage tracking. All supported by a standardized database of colors, common names, and scientific classifications. Start trading today for FREE!'
          />
          <meta
            property='og:image'
            content='https://s3.amazonaws.com/reefconnector/facebooklogopreview.png'
            // content={blackTransparent}
          />

          <meta property='og:url' content='https://www.immortalreef.com' />
        </Helmet>
        <Container>
          <div className='container'>
            <div className='row' style={{}}>
              <div
                className='col-md-12 text-center'
                style={{ textAlign: 'center' }}
              >
                <div style={{ height: 73 }} />
                {/* <h1 style={{ marginBottom: 2 }}>Welcome to Immortal Reef!</h1> */}
                {/* <h4 style={{ marginBottom: 2 }}>-Under Redevelopment-</h4> */}
                <div
                  style={{
                    // borderRadius: 1000,
                    height: '20rem',
                    width: '20rem',

                    // backgroundColor: 'white',
                    margin: 'auto'
                  }}
                >
                  <img
                    style={{
                      // borderRadius: 1000,
                      height: '100%'
                    }}
                    src='https://s3.amazonaws.com/reefconnector/IRLogoFinal.png'
                    alt='immortalreef logo'
                  />
                </div>
                <p style={{ marginRight: 5, marginLeft: 5 }}>
                  Quick and easy coral trading with user collections, wishlists,
                  and lineage tracking.
                </p>{' '}
                <p>
                  All supported by a standardized database of colors, common
                  names, and scientific classifications.
                </p>{' '}
                <p>Start trading today for FREE!</p>
                <Link to='/coralsearch' className='btn btn-lg btn-info m-2'>
                  Browse Coral
                </Link>
                <Link
                  to='/coraltypes/SPS/Acropora'
                  className='btn btn-lg btn-info m-2'
                >
                  Create Collection
                </Link>
                <Link to='/tradecommunity' className='btn btn-lg btn-info m-2'>
                  Trade
                </Link>
              </div>
              {/* <p>
                Quick and easy coral trading with user collections, wishlists,
                and lineage tracking. All supported by a standardized database
                of colors, common names, and scientific classifications. Start
                trading today for FREE!
              </p> */}
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default Landing

// const rgbHex = require("rgb-hex");
// let colorFamilies = [
//   {
//     //Red
//     hue: {
//       r: 250,
//       g: 0,
//       b: 0
//     },
//     subHues: [
//       {
//         r: 250,
//         g: 50,
//         b: 50
//       },
//       {
//         r: 250,
//         g: 50,
//         b: 0
//       },
//       {
//         r: 250,
//         g: 0,
//         b: 50
//       },
//       {
//         r: 200,
//         g: 50,
//         b: 50
//       },
//       {
//         r: 200,
//         g: 0,
//         b: 50
//       },
//       {
//         r: 200,
//         g: 50,
//         b: 0
//       },
//       {
//         r: 200,
//         g: 0,
//         b: 0
//       },
//       {
//         r: 150,
//         g: 50,
//         b: 50
//       },
//       {
//         r: 150,
//         g: 0,
//         b: 50
//       },
//       {
//         r: 150,
//         g: 50,
//         b: 0
//       },
//       {
//         r: 150,
//         g: 0,
//         b: 0
//       },
//       {
//         r: 100,
//         g: 50,
//         b: 50
//       },
//       {
//         r: 100,
//         g: 0,
//         b: 50
//       },
//       {
//         r: 100,
//         g: 0,
//         b: 0
//       },
//       {
//         r: 50,
//         g: 0,
//         b: 0
//       }
//     ]
//   },
//   {
//     //Orange
//     hue: {
//       r: 250,
//       g: 150,
//       b: 0
//     },
//     subHues: [
//       {
//         r: 250,
//         g: 200,
//         b: 150
//       },
//       {
//         r: 250,
//         g: 200,
//         b: 100
//       },
//       {
//         r: 250,
//         g: 200,
//         b: 50
//       },
//       {
//         r: 250,
//         g: 200,
//         b: 0
//       },
//       {
//         r: 250,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 250,
//         g: 150,
//         b: 100
//       },
//       {
//         r: 250,
//         g: 150,
//         b: 50
//       },
//       {
//         r: 250,
//         g: 150,
//         b: 0
//       },
//       {
//         r: 250,
//         g: 100,
//         b: 100
//       },
//       {
//         r: 250,
//         g: 100,
//         b: 50
//       },
//       {
//         r: 250,
//         g: 100,
//         b: 0
//       },
//       {
//         r: 200,
//         g: 100,
//         b: 50
//       },
//       {
//         r: 200,
//         g: 100,
//         b: 0
//       },
//       {
//         r: 200,
//         g: 50,
//         b: 0
//       },
//       {
//         r: 150,
//         g: 50,
//         b: 0
//       }
//     ]
//   },
//   {
//     //Yellow
//     hue: {
//       r: 250,
//       g: 250,
//       b: 0
//     },
//     subHues: [
//       {
//         r: 250,
//         g: 250,
//         b: 200
//       },
//       {
//         r: 250,
//         g: 250,
//         b: 150
//       },
//       {
//         r: 250,
//         g: 250,
//         b: 100
//       },
//       {
//         r: 250,
//         g: 250,
//         b: 50
//       },
//       {
//         r: 250,
//         g: 200,
//         b: 100
//       },
//       {
//         r: 250,
//         g: 200,
//         b: 50
//       },
//       {
//         r: 250,
//         g: 200,
//         b: 0
//       },
//       {
//         r: 200,
//         g: 150,
//         b: 0
//       }
//     ]
//   },
//   {
//     //Brown & Beige
//     hue: {
//       r: 250,
//       g: 200,
//       b: 150
//     },
//     subHues: [
//       {
//         r: 250,
//         g: 200,
//         b: 200
//       },
//       {
//         r: 250,
//         g: 200,
//         b: 100
//       },
//       {
//         r: 200,
//         g: 200,
//         b: 150
//       },
//       {
//         r: 200,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 200,
//         g: 150,
//         b: 100
//       },
//       {
//         r: 200,
//         g: 150,
//         b: 50
//       },
//       {
//         r: 200,
//         g: 150,
//         b: 0
//       },
//       {
//         r: 200,
//         g: 100,
//         b: 100
//       },
//       {
//         r: 200,
//         g: 100,
//         b: 50
//       },
//       {
//         r: 200,
//         g: 100,
//         b: 0
//       },
//       {
//         r: 200,
//         g: 150,
//         b: 50
//       },
//       {
//         r: 150,
//         g: 150,
//         b: 100
//       },
//       {
//         r: 150,
//         g: 100,
//         b: 100
//       },
//       {
//         r: 150,
//         g: 100,
//         b: 50
//       },
//       {
//         r: 150,
//         g: 100,
//         b: 0
//       },
//       {
//         r: 150,
//         g: 50,
//         b: 50
//       },
//       {
//         r: 150,
//         g: 50,
//         b: 0
//       },
//       {
//         r: 100,
//         g: 50,
//         b: 50
//       },
//       {
//         r: 100,
//         g: 50,
//         b: 0
//       }
//     ]
//   },
//   {
//     //Earthy green
//     hue: {
//       r: 100,
//       g: 100,
//       b: 0
//     },
//     subHues: [
//       {
//         r: 200,
//         g: 200,
//         b: 150
//       },
//       {
//         r: 200,
//         g: 200,
//         b: 100
//       },
//       {
//         r: 200,
//         g: 200,
//         b: 50
//       },
//       {
//         r: 200,
//         g: 200,
//         b: 0
//       },
//       {
//         r: 150,
//         g: 150,
//         b: 100
//       },
//       {
//         r: 150,
//         g: 150,
//         b: 50
//       },
//       {
//         r: 150,
//         g: 150,
//         b: 0
//       },
//       {
//         r: 100,
//         g: 150,
//         b: 100
//       },
//       {
//         r: 100,
//         g: 150,
//         b: 50
//       },
//       {
//         r: 100,
//         g: 150,
//         b: 0
//       },
//       {
//         r: 100,
//         g: 100,
//         b: 50
//       },
//       {
//         r: 100,
//         g: 100,
//         b: 0
//       },
//       {
//         r: 50,
//         g: 50,
//         b: 0
//       }
//     ]
//   },
//   {
//     //Green
//     hue: {
//       r: 0,
//       g: 250,
//       b: 0
//     },
//     subHues: [
//       {
//         r: 0,
//         g: 250,
//         b: 200
//       },
//       {
//         r: 0,
//         g: 250,
//         b: 150
//       },
//       {
//         r: 0,
//         g: 250,
//         b: 100
//       },
//       {
//         r: 0,
//         g: 250,
//         b: 50
//       },
//       {
//         r: 0,
//         g: 200,
//         b: 150
//       },
//       {
//         r: 0,
//         g: 200,
//         b: 100
//       },
//       {
//         r: 0,
//         g: 200,
//         b: 50
//       },
//       {
//         r: 0,
//         g: 200,
//         b: 0
//       },
//       {
//         r: 0,
//         g: 150,
//         b: 100
//       },
//       {
//         r: 0,
//         g: 150,
//         b: 50
//       },
//       {
//         r: 0,
//         g: 150,
//         b: 0
//       },
//       {
//         r: 0,
//         g: 100,
//         b: 50
//       },
//       {
//         r: 0,
//         g: 100,
//         b: 0
//       },

//       {
//         r: 50,
//         g: 250,
//         b: 200
//       },
//       {
//         r: 50,
//         g: 250,
//         b: 150
//       },
//       {
//         r: 50,
//         g: 250,
//         b: 100
//       },
//       {
//         r: 50,
//         g: 250,
//         b: 50
//       },
//       {
//         r: 50,
//         g: 250,
//         b: 0
//       },
//       {
//         r: 50,
//         g: 200,
//         b: 150
//       },
//       {
//         r: 50,
//         g: 200,
//         b: 100
//       },
//       {
//         r: 50,
//         g: 200,
//         b: 50
//       },
//       {
//         r: 50,
//         g: 200,
//         b: 0
//       },
//       {
//         r: 50,
//         g: 150,
//         b: 100
//       },
//       {
//         r: 50,
//         g: 150,
//         b: 50
//       },
//       {
//         r: 50,
//         g: 150,
//         b: 0
//       },
//       {
//         r: 50,
//         g: 100,
//         b: 50
//       },
//       {
//         r: 50,
//         g: 100,
//         b: 0
//       },
//       {
//         r: 100,
//         g: 250,
//         b: 200
//       },
//       {
//         r: 100,
//         g: 250,
//         b: 150
//       },
//       {
//         r: 100,
//         g: 250,
//         b: 100
//       },
//       {
//         r: 100,
//         g: 250,
//         b: 50
//       },
//       {
//         r: 100,
//         g: 250,
//         b: 0
//       },
//       {
//         r: 100,
//         g: 200,
//         b: 150
//       },
//       {
//         r: 100,
//         g: 200,
//         b: 100
//       },
//       {
//         r: 100,
//         g: 200,
//         b: 50
//       },
//       {
//         r: 100,
//         g: 200,
//         b: 0
//       },
//       {
//         r: 100,
//         g: 150,
//         b: 100
//       },
//       {
//         r: 100,
//         g: 150,
//         b: 50
//       },
//       {
//         r: 100,
//         g: 150,
//         b: 0
//       },
//       {
//         r: 150,
//         g: 250,
//         b: 200
//       },
//       {
//         r: 150,
//         g: 250,
//         b: 150
//       },
//       {
//         r: 150,
//         g: 250,
//         b: 100
//       },
//       {
//         r: 150,
//         g: 250,
//         b: 50
//       },
//       {
//         r: 150,
//         g: 250,
//         b: 0
//       },
//       {
//         r: 150,
//         g: 200,
//         b: 150
//       },
//       {
//         r: 150,
//         g: 200,
//         b: 100
//       },
//       {
//         r: 150,
//         g: 200,
//         b: 50
//       },
//       {
//         r: 150,
//         g: 200,
//         b: 0
//       },
//       {
//         r: 200,
//         g: 250,
//         b: 200
//       },
//       {
//         r: 200,
//         g: 250,
//         b: 150
//       },
//       {
//         r: 200,
//         g: 250,
//         b: 100
//       },
//       {
//         r: 200,
//         g: 250,
//         b: 50
//       },
//       {
//         r: 200,
//         g: 250,
//         b: 0
//       },
//       {
//         r: 0,
//         g: 50,
//         b: 50
//       },
//       {
//         r: 0,
//         g: 50,
//         b: 0
//       }
//     ]
//   },
//   {
//     //Teal
//     hue: {
//       r: 0,
//       g: 200,
//       b: 200
//     },
//     subHues: [
//       {
//         r: 150,
//         g: 200,
//         b: 200
//       },
//       {
//         r: 100,
//         g: 200,
//         b: 200
//       },
//       {
//         r: 50,
//         g: 200,
//         b: 200
//       },
//       {
//         r: 100,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 50,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 0,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 50,
//         g: 100,
//         b: 100
//       },
//       {
//         r: 0,
//         g: 100,
//         b: 100
//       },
//       {
//         r: 50,
//         g: 150,
//         b: 200
//       },
//       {
//         r: 0,
//         g: 150,
//         b: 200
//       },
//       {
//         r: 0,
//         g: 100,
//         b: 150
//       },
//       {
//         r: 0,
//         g: 50,
//         b: 50
//       }
//     ]
//   },
//   {
//     //Turquoise
//     hue: {
//       r: 100,
//       g: 250,
//       b: 250
//     },
//     subHues: [
//       {
//         r: 0,
//         g: 250,
//         b: 250
//       },
//       {
//         r: 50,
//         g: 250,
//         b: 250
//       },
//       {
//         r: 150,
//         g: 250,
//         b: 250
//       },
//       {
//         r: 200,
//         g: 250,
//         b: 250
//       },
//       {
//         r: 0,
//         g: 250,
//         b: 200
//       },
//       {
//         r: 50,
//         g: 250,
//         b: 200
//       },
//       {
//         r: 100,
//         g: 250,
//         b: 200
//       },
//       {
//         r: 0,
//         g: 200,
//         b: 200
//       },
//       {
//         r: 50,
//         g: 200,
//         b: 200
//       },
//       {
//         r: 0,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 50,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 0,
//         g: 200,
//         b: 250
//       },
//       {
//         r: 50,
//         g: 200,
//         b: 250
//       }
//     ]
//   },
//   {
//     //Blue
//     hue: {
//       r: 0,
//       g: 0,
//       b: 250
//     },
//     subHues: [
//       {
//         r: 200,
//         g: 250,
//         b: 250
//       },
//       {
//         r: 150,
//         g: 250,
//         b: 250
//       },
//       {
//         r: 100,
//         g: 250,
//         b: 250
//       },
//       {
//         r: 100,
//         g: 200,
//         b: 250
//       },
//       {
//         r: 100,
//         g: 150,
//         b: 250
//       },
//       {
//         r: 100,
//         g: 150,
//         b: 200
//       },
//       {
//         r: 50,
//         g: 250,
//         b: 250
//       },
//       {
//         r: 50,
//         g: 200,
//         b: 250
//       },
//       {
//         r: 50,
//         g: 150,
//         b: 250
//       },
//       {
//         r: 50,
//         g: 150,
//         b: 200
//       },
//       {
//         r: 50,
//         g: 100,
//         b: 250
//       },
//       {
//         r: 50,
//         g: 100,
//         b: 200
//       },
//       {
//         r: 50,
//         g: 100,
//         b: 150
//       },
//       {
//         r: 50,
//         g: 50,
//         b: 250
//       },
//       {
//         r: 50,
//         g: 50,
//         b: 200
//       },
//       {
//         r: 50,
//         g: 50,
//         b: 150
//       },
//       {
//         r: 50,
//         g: 50,
//         b: 100
//       },
//       {
//         r: 50,
//         g: 0,
//         b: 250
//       },
//       {
//         r: 50,
//         g: 0,
//         b: 200
//       },
//       {
//         r: 50,
//         g: 0,
//         b: 150
//       },
//       {
//         r: 0,
//         g: 250,
//         b: 250
//       },
//       {
//         r: 0,
//         g: 200,
//         b: 250
//       },
//       {
//         r: 0,
//         g: 150,
//         b: 250
//       },
//       {
//         r: 0,
//         g: 150,
//         b: 200
//       },
//       {
//         r: 0,
//         g: 100,
//         b: 250
//       },
//       {
//         r: 0,
//         g: 100,
//         b: 200
//       },
//       {
//         r: 0,
//         g: 100,
//         b: 150
//       },
//       {
//         r: 0,
//         g: 50,
//         b: 250
//       },
//       {
//         r: 0,
//         g: 50,
//         b: 200
//       },
//       {
//         r: 0,
//         g: 50,
//         b: 150
//       },
//       {
//         r: 0,
//         g: 50,
//         b: 100
//       },
//       {
//         r: 0,
//         g: 0,
//         b: 200
//       },
//       {
//         r: 0,
//         g: 0,
//         b: 150
//       },
//       {
//         r: 0,
//         g: 0,
//         b: 100
//       },
//       {
//         r: 0,
//         g: 0,
//         b: 50
//       }
//     ]
//   },
//   {
//     //Blue-violet
//     hue: {
//       r: 100,
//       g: 100,
//       b: 200
//     },
//     subHues: [
//       {
//         r: 200,
//         g: 200,
//         b: 250
//       },
//       {
//         r: 150,
//         g: 150,
//         b: 250
//       },
//       {
//         r: 100,
//         g: 100,
//         b: 250
//       },
//       {
//         r: 100,
//         g: 50,
//         b: 250
//       },
//       {
//         r: 100,
//         g: 0,
//         b: 250
//       },
//       {
//         r: 100,
//         g: 50,
//         b: 200
//       },
//       {
//         r: 100,
//         g: 0,
//         b: 200
//       },
//       {
//         r: 50,
//         g: 0,
//         b: 150
//       },
//       {
//         r: 50,
//         g: 0,
//         b: 100
//       }
//     ]
//   },
//   {
//     //Purple
//     hue: {
//       r: 150,
//       g: 50,
//       b: 250
//     },
//     subHues: [
//       {
//         r: 200,
//         g: 200,
//         b: 250
//       },
//       {
//         r: 200,
//         g: 150,
//         b: 250
//       },
//       {
//         r: 200,
//         g: 150,
//         b: 200
//       },
//       {
//         r: 200,
//         g: 100,
//         b: 250
//       },
//       {
//         r: 200,
//         g: 100,
//         b: 200
//       },
//       {
//         r: 200,
//         g: 50,
//         b: 250
//       },
//       {
//         r: 200,
//         g: 50,
//         b: 200
//       },
//       {
//         r: 200,
//         g: 0,
//         b: 250
//       },
//       {
//         r: 200,
//         g: 0,
//         b: 200
//       },
//       {
//         r: 150,
//         g: 150,
//         b: 250
//       },
//       {
//         r: 150,
//         g: 150,
//         b: 200
//       },
//       {
//         r: 150,
//         g: 100,
//         b: 250
//       },
//       {
//         r: 150,
//         g: 100,
//         b: 200
//       },
//       {
//         r: 150,
//         g: 100,
//         b: 150
//       },
//       {
//         r: 150,
//         g: 50,
//         b: 200
//       },
//       {
//         r: 150,
//         g: 50,
//         b: 150
//       },
//       {
//         r: 150,
//         g: 0,
//         b: 250
//       },
//       {
//         r: 150,
//         g: 0,
//         b: 200
//       },
//       {
//         r: 150,
//         g: 0,
//         b: 150
//       },
//       {
//         r: 100,
//         g: 100,
//         b: 150
//       },
//       {
//         r: 100,
//         g: 50,
//         b: 200
//       },
//       {
//         r: 100,
//         g: 50,
//         b: 150
//       },
//       {
//         r: 100,
//         g: 50,
//         b: 100
//       },
//       {
//         r: 100,
//         g: 0,
//         b: 200
//       },
//       {
//         r: 100,
//         g: 0,
//         b: 150
//       },
//       {
//         r: 100,
//         g: 0,
//         b: 100
//       },
//       {
//         r: 100,
//         g: 0,
//         b: 50
//       },
//       {
//         r: 50,
//         g: 0,
//         b: 100
//       },
//       {
//         r: 50,
//         g: 0,
//         b: 50
//       }
//     ]
//   },
//   {
//     //Fucshia/Magenta
//     hue: {
//       r: 250,
//       g: 0,
//       b: 250
//     },
//     subHues: [
//       {
//         r: 250,
//         g: 150,
//         b: 250
//       },
//       {
//         r: 250,
//         g: 100,
//         b: 250
//       },
//       {
//         r: 250,
//         g: 100,
//         b: 200
//       },
//       {
//         r: 250,
//         g: 100,
//         b: 150
//       },
//       {
//         r: 250,
//         g: 50,
//         b: 250
//       },
//       {
//         r: 250,
//         g: 50,
//         b: 200
//       },
//       {
//         r: 250,
//         g: 50,
//         b: 150
//       },
//       {
//         r: 250,
//         g: 50,
//         b: 100
//       },
//       {
//         r: 250,
//         g: 0,
//         b: 200
//       },
//       {
//         r: 250,
//         g: 0,
//         b: 150
//       },
//       {
//         r: 250,
//         g: 0,
//         b: 100
//       },
//       {
//         r: 200,
//         g: 100,
//         b: 150
//       },
//       {
//         r: 200,
//         g: 50,
//         b: 200
//       },
//       {
//         r: 200,
//         g: 50,
//         b: 150
//       },
//       {
//         r: 200,
//         g: 50,
//         b: 100
//       },
//       {
//         r: 200,
//         g: 0,
//         b: 200
//       },
//       {
//         r: 200,
//         g: 0,
//         b: 150
//       },
//       {
//         r: 200,
//         g: 0,
//         b: 100
//       },
//       {
//         r: 150,
//         g: 50,
//         b: 100
//       },
//       {
//         r: 150,
//         g: 0,
//         b: 150
//       },
//       {
//         r: 150,
//         g: 0,
//         b: 100
//       },
//       {
//         r: 100,
//         g: 0,
//         b: 100
//       },
//       {
//         r: 100,
//         g: 0,
//         b: 50
//       }
//     ]
//   },
//   {
//     //Soft Pink
//     hue: {
//       r: 250,
//       g: 150,
//       b: 200
//     },
//     subHues: [
//       {
//         r: 250,
//         g: 200,
//         b: 250
//       },
//       {
//         r: 250,
//         g: 200,
//         b: 200
//       },
//       {
//         r: 250,
//         g: 150,
//         b: 250
//       },
//       {
//         r: 250,
//         g: 150,
//         b: 200
//       },
//       {
//         r: 250,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 250,
//         g: 150,
//         b: 100
//       },
//       {
//         r: 250,
//         g: 100,
//         b: 250
//       },
//       {
//         r: 250,
//         g: 100,
//         b: 200
//       },
//       {
//         r: 250,
//         g: 100,
//         b: 150
//       },
//       {
//         r: 250,
//         g: 100,
//         b: 100
//       },
//       {
//         r: 200,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 200,
//         g: 100,
//         b: 150
//       },
//       {
//         r: 200,
//         g: 100,
//         b: 100
//       }
//     ]
//   },
//   {
//     //Black & gray
//     hue: {
//       r: 0,
//       g: 0,
//       b: 0
//     },
//     subHues: [
//       {
//         r: 200,
//         g: 200,
//         b: 250
//       },
//       {
//         r: 200,
//         g: 200,
//         b: 200
//       },
//       {
//         r: 150,
//         g: 200,
//         b: 200
//       },
//       {
//         r: 150,
//         g: 150,
//         b: 200
//       },
//       {
//         r: 150,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 150,
//         g: 150,
//         b: 100
//       },
//       {
//         r: 100,
//         g: 150,
//         b: 150
//       },
//       {
//         r: 100,
//         g: 100,
//         b: 150
//       },
//       {
//         r: 100,
//         g: 100,
//         b: 100
//       },
//       {
//         r: 50,
//         g: 50,
//         b: 50
//       }
//     ]
//   }
//   // {
//   //   //White
//   //   hue: {
//   //     r: 250,
//   //     g: 250,
//   //     b: 250
//   //   },
//   //   subHues: [
//   //     {
//   //       r: 250,
//   //       g: 250,
//   //       b: 250
//   //     }
//   //   ]
//   // },
// ];

let hexColorFamilies = [
  {
    //Dark Purple
    hue: "773eb8",
    subHues: ["773eb8"]
  },
  {
    //Light Purple
    hue: "c5bae8",
    subHues: ["c5bae8"]
  },
  {
    //Dark Blue
    hue: "3376f2",
    subHues: ["3376f2"]
  },
  {
    //Light Blue
    hue: "68e8e8",
    subHues: ["68e8e8"]
  },
  {
    //Dark Green
    hue: "058726",
    subHues: ["058726"]
  },
  {
    //Neon Green
    hue: "41e310",
    subHues: ["41e310"]
  },
  {
    //Yellow
    hue: "fffa5c",
    subHues: ["ffa5c"]
  },
  {
    //Orange
    hue: "f58414",
    subHues: ["f58414"]
  },
  {
    //Red
    hue: "c4170e",
    subHues: ["c4170e"]
  },
  {
    //Magenta
    hue: "e32b7e",
    subHues: ["e32b7e"]
  },
  {
    //Pink
    hue: "f2c9e2",
    subHues: ["f2c9e2"]
  },
  {
    //Brown
    hue: "ab7d71",
    subHues: ["ab7d71"]
  },
  // {
  //   //Brown
  //   hue: "593609",
  //   subHues: ["593609"]
  // },
  // {
  //   //Brown
  //   hue: "755f42",
  //   subHues: ["755f42"]
  // },

  {
    //light brown
    hue: "e8c68b",
    subHues: ["e8c68b"]
  },
  {
    //Light green
    hue: "aba641",
    subHues: ["aba641"]
  }
];

// colorFamilies.forEach(fam => {
//   hexColorFamilies.push({
//     hue: rgbHex(fam.hue.r, fam.hue.g, fam.hue.b),
//     subHues: fam.subHues.map(subHue => rgbHex(subHue.r, subHue.g, subHue.b))
//   });
// });

export default hexColorFamilies;

import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { getWishList } from 'actions/authActions'
import axios from 'axios'

// reactstrap components
import { Button, Container } from 'reactstrap'

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon
} from 'react-share'
class MyWishList extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,

      errors: {}
    }
  }

  componentDidMount () {
    this.props.getWishList()
  }

  deleteCoral (coral) {
    axios.post('/api/coral/deletecoral', { id: coral._id }).then(res => {
      if (res.data === 'success') {
        this.props.myCoral()
      }
    })
  }

  removeFromWishList = coral => {
    console.log(coral)
    axios.post('/api/users/removewishlist', coral).then(res => {
      console.log(res.data)
      if (res.data === 'success') {
        this.props.getWishList()
      }
    })
  }

  render () {
    const { wishList } = this.props.auth
    let sortedWishList = []
    if (wishList) {
      sortedWishList = wishList.sort((a, b) =>
        a.type > b.type
          ? 1
          : a.type === b.type
          ? a.genus > b.genus
            ? 1
            : a.genus === b.genus
            ? a.name > b.name
              ? 1
              : -1
            : -1
          : -1
      )
      console.log(sortedWishList)
    }
    return (
      <div className='login-page'>
        <Container>
          <h4 style={{ textAlign: 'center', marginTop: 10 }}>My Wish List</h4>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              margin: 8
            }}
          >
            <FacebookShareButton
              style={{ marginRight: 3 }}
              url={`immortalreef.com/userwishlist/${this.props.auth.user.id}`}
            >
              <FacebookIcon size={28} />
            </FacebookShareButton>
            <TwitterShareButton
              style={{ marginRight: 3 }}
              url={`immortalreef.com/userwishlist/${this.props.auth.user.id}`}
            >
              <TwitterIcon size={28} />
            </TwitterShareButton>
          </div>
          {sortedWishList.length > 0 ? (
            <>
              <Table>
                <Thead>
                  <Tr style={{ background: '#F2F3F5' }}>
                    <Th className='text-center'>Type</Th>
                    <Th>Genus</Th>
                    <Th>Species</Th>
                    <Th>Name</Th>
                    <Th className='text-center'>Color</Th>

                    <Th className='text-center'>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedWishList.map(coral => (
                    <Tr
                      style={{
                        borderBottom: '1px solid lightgrey'
                      }}
                    >
                      <Td className='text-center'>{coral.type}</Td>
                      <Td>{coral.genus}</Td>
                      {coral.species ? (
                        <Td>
                          <Link
                            to={`/species/${coral.species.toLowerCase()}`}
                            className='text-info'
                          >
                            {coral.species}
                          </Link>
                        </Td>
                      ) : (
                        <Td>-</Td>
                      )}
                      <Td>
                        {coral.name !== 'zzz' ? (
                          <Link to={`coralnames/${coral.nameId}`}>
                            <span
                              style={{ textTransform: 'capitalize' }}
                              className='text-info'
                            >
                              {coral.name}
                            </span>
                          </Link>
                        ) : (
                          <>
                            <span>-</span>
                          </>
                        )}
                      </Td>
                      {coral.color ? (
                        <Td>
                          <Link
                            to={`coralcolorshow/${coral.color}/${coral.genus}`}
                          >
                            <div
                              style={{
                                background: `#${coral.color}`,
                                height: 20,
                                width: 20,
                                // textAlign: 'center',
                                borderRadius: 5,
                                margin: 'auto'
                              }}
                            />
                          </Link>
                        </Td>
                      ) : (
                        <Td className='text-center'>-</Td>
                      )}

                      <Td className='text-center' style={{ padding: 5 }}>
                        {` `}
                        <Button
                          outline
                          className='btn-icon'
                          color='danger'
                          size='sm'
                          style={{ borderRadius: 5, margin: 5 }}
                          onClick={() => {
                            this.removeFromWishList({
                              type: coral.type,
                              genus: coral.genus,
                              nameId: coral.nameId,
                              name: coral.name,
                              color: coral.color
                            })
                          }}
                        >
                          <i
                            className='fa fa-trash-o'
                            style={{ fontSize: 16 }}
                          />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </>
          ) : (
            <>
              <hr />
              <p
                style={{
                  textAlign: 'center',
                  marginLeft: '20%',
                  marginRight: '20%'
                }}
              >
                Add coral to your collection by going to the{' '}
                <Link to='/coralnames' className='text-info'>
                  "Coral Database"
                </Link>{' '}
                page, and clicking the <i className='fa fa-heart' /> button next
                to the corresponding name or color of your coral.
              </p>
            </>
          )}
        </Container>
      </div>
    )
  }
}

MyWishList.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral,
  errors: state.errors
})

export default connect(mapStateToProps, { getWishList })(MyWishList)

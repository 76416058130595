import React from "react";

export default () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 106
      }}
    >
      <h1 className="display-4">Page Not Found</h1>
      <p>Sorry, this page does not exist</p>
    </div>
  );
};

import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getUserLocation,
  getMyCart,
  getMyMessages,
  getMyTradePurchases,
  getMyTradeSales
} from 'actions/authActions'

// import lgLogoWordsCropped from "assets/logo/lgWordsCropped.png";
import lgNoWordsCropped from 'assets/logo/lgNoWordsCropped.png'
import defaultAvatar from 'assets/img/default-avatar.png'
import faceExample from 'assets/img/face-2.jpg'
import classes from 'variables/coral'
import axios from 'axios'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge
} from 'reactstrap'

class LayoutWrapper extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      // email: "",
      // password: "",
      errors: {},
      // current: "mail",
      screenSizeSmall: true,
      isOpen: false
    }

    // this.onChange = this.onChange.bind(this);
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  componentDidMount () {
    this.updateSize()
    window.addEventListener('resize', this.updateSize)
    if (this.props.auth.isAuthenticated && !this.props.auth.location) {
      this.props.getUserLocation()
    }

    if (this.props.auth.isAuthenticated && !this.props.auth.myCart) {
      this.props.getMyCart()
    }

    if (this.props.auth.isAuthenticated && !this.props.auth.myMessages) {
      this.props.getMyMessages()
    }

    if (this.props.auth.isAuthenticated && !this.props.auth.myTradeSales) {
      this.props.getMyTradeSales()
    }

    if (this.props.auth.isAuthenticated && !this.props.auth.myTradePurchaces) {
      this.props.getMyTradePurchases()
    }
  }

  componentWillReceiveProps (nextProps, nextState) {
    if (
      nextProps.auth.isAuthenticated &&
      nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated &&
      !this.props.auth.location
    ) {
      this.props.getUserLocation()
    }

    if (
      nextProps.auth.isAuthenticated &&
      nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated &&
      !this.props.auth.myCart
    ) {
      this.props.getMyCart()
    }

    if (
      nextProps.auth.isAuthenticated &&
      nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated &&
      !this.props.auth.myMessages
    ) {
      this.props.getMyMessages()
    }

    if (
      nextProps.auth.isAuthenticated &&
      nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated &&
      !this.props.auth.myTradeSales
    ) {
      this.props.getMyTradeSales()
    }

    if (
      nextProps.auth.isAuthenticated &&
      nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated &&
      !this.props.auth.myTradePurchases
    ) {
      this.props.getMyTradePurchases()
    }
  }

  // componentDidUpdate (prevProps) {
  //   console.log(prevProps.history)
  // }

  updateSize = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true
      })
    } else {
      this.setState({
        screenSizeSmall: false
      })
    }
  }

  markAllAsRead = () => {
    axios.get('/api/users/markallmessagesread').then(res => {
      if (res.data === 'success') {
        this.props.getMyMessages()
      }
    })
  }

  // onChange(e) {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  // handleClick = (e) => {
  //   console.log("click ", e);
  //   this.setState({ current: e.key });
  // };

  render () {
    // console.log(this.props.history.location)
    return (
      <>
        <div style={{ position: 'fixed', width: '100%', zIndex: 20000 }}>
          <Navbar
            color='primary'
            dark
            expand='sm'
            style={{ padding: '5px 10px' }}
          >
            <NavbarBrand href='/'>
              <Link to='/' style={{ color: 'inherit' }} onClick={this.toggle}>
                <div
                  style={{
                    height: '3.25rem',
                    display: 'inline-block',
                    marginRight: 5
                  }}
                >
                  <img
                    style={{
                      borderRadius: 50,
                      height: '100%'
                    }}
                    src='https://s3.amazonaws.com/reefconnector/IRLogoFinal.png'
                    alt='immortalreef logo'
                  />
                </div>
                Immortal Reef
              </Link>
            </NavbarBrand>

            <NavbarToggler onClick={this.toggle} />
            <Collapse
              isOpen={this.state.isOpen}
              navbar
              style={{
                justifyContent: 'center'
              }}
            >
              <Nav className='' navbar>
                {/* <NavItem>
                  <Link to='/' style={{ color: 'inherit' }}>
                    <NavLink
                    //    href="/components/"
                    >
                      <i className='fa fa-home' /> Home
                    </NavLink>
                  </Link>
                </NavItem> */}

                <NavItem>
                  <Link
                    to='/coralsearch'
                    style={{ color: 'inherit' }}
                    onClick={this.toggle}
                  >
                    <NavLink

                    //    href="/components/"
                    >
                      <i className='fa fa-search' /> Coral Search
                    </NavLink>
                  </Link>
                </NavItem>

                {/* <NavItem>
                  <Link
                    to='/coralnames'
                    style={{ color: 'inherit' }}
                    onClick={this.toggle}
                  >
                    <NavLink>
                      <i className='fa fa-database' /> Coral Database
                    </NavLink>
                  </Link>
                </NavItem> */}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <i className='fa fa-database' /> Coral Database{' '}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {classes.map(item => (
                      <Link
                        to={`/coraltypes/${item.type}/${item.genus[0].name}`}
                        onClick={this.toggle}
                      >
                        <DropdownItem>
                          {item.type}{' '}
                          {item.type !== 'Anemone' ? <span>Coral</span> : null}
                        </DropdownItem>
                      </Link>
                    ))}
                    {/* <DropdownItem>SPS Coral</DropdownItem>
                    <DropdownItem>LPS Coral</DropdownItem>
                    <DropdownItem>Soft Coral</DropdownItem>
                    <DropdownItem>Zoa/Paly Coral</DropdownItem>
                    <DropdownItem>Chalice Coral</DropdownItem>
                    <DropdownItem>Mushroom Coral</DropdownItem>
                    <DropdownItem>Gorgonian Coral</DropdownItem>
                    <DropdownItem>Anemone</DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* <NavItem>
                  <Link to='/coraldeals' style={{ color: 'inherit' }}>
                    <NavLink
                    //    href="/components/"
                    >
                      <i className='fa fa-dollar' /> Coral Deals
                    </NavLink>
                  </Link>
                </NavItem> */}
                <NavItem>
                  <Link
                    to='/tradecommunity'
                    style={{ color: 'inherit' }}
                    onClick={this.toggle}
                  >
                    <NavLink
                    //    href="/components/"
                    >
                      <i className='fa fa-money' /> Trade
                    </NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  {/* <Link to="https://community.immortalreef.com" style={{ color: "inherit" }}> */}
                  <NavLink href='https://community.immortalreef.com'>
                    <i className='fa fa-users' /> Forums
                  </NavLink>
                  {/* </Link> */}
                </NavItem>
                <NavItem>
                  <Link
                    to='/mycart'
                    style={{ color: 'inherit' }}
                    onClick={this.toggle}
                  >
                    <NavLink
                    //    href="/components/"
                    >
                      <i className='fa fa-shopping-cart' /> Cart{' '}
                      {this.props.auth.myCart &&
                      this.props.auth.myCart.length > 0 ? (
                        <Badge color='info'>
                          {
                            this.props.auth.myCart
                              .map(cart => cart.coral)
                              .flat(1).length
                          }
                        </Badge>
                      ) : null}
                    </NavLink>
                  </Link>
                </NavItem>
                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <i className='fa fa-eye' /> Database
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>SPS</DropdownItem>
                    <DropdownItem>LPS</DropdownItem>

                    <DropdownItem>Soft Coral</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}

                {/* <NavItem>
                  <Link to='/myreef' style={{ color: 'inherit' }}>
                    <NavLink
                    //    href="/components/"
                    >
                      <i className='fa fa-user' /> My Reef
                    </NavLink>
                  </Link>
                </NavItem> */}
                {this.props.auth.isAuthenticated ? (
                  <>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 4
                          }}
                        >
                          <i
                            className='fa fa-bell'
                            style={{ fontSize: 20, marginTop: 0 }}
                          />
                          {this.props.auth.myMessages &&
                          this.props.auth.myMessages.length > 0 ? (
                            <Badge color='warning' style={{ marginBottom: 5 }}>
                              {this.props.auth.myMessages.length}
                            </Badge>
                          ) : null}
                        </div>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <span style={{ marginLeft: 5 }}>New Messages</span>
                        <hr style={{ margin: 3 }} />
                        {this.props.auth.myMessages &&
                        this.props.auth.myMessages.length > 0 ? (
                          <>
                            {this.props.auth.myMessages.map(message => (
                              <a
                                href={`https://community.immortalreef.com/t/${message.slug}/${message.topic_id}`}
                                style={{ color: 'inherit' }}
                                onClick={() => {
                                  this.toggle()
                                  this.props.getMyMessages()
                                }}
                              >
                                <DropdownItem>
                                  {message.fancy_title}
                                </DropdownItem>
                              </a>
                            ))}
                          </>
                        ) : (
                          <span style={{ margin: 5 }}>- No new messages -</span>
                        )}
                        <hr style={{ margin: 3 }} />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <a
                            href={`https://community.immortalreef.com/u/${this.props.auth.user.username}/messages`}
                            className='text-info'
                          >
                            <span style={{ marginLeft: 5 }}>View All</span>
                          </a>
                          {this.props.auth.myMessages &&
                          this.props.auth.myMessages.length > 0 ? (
                            <a
                              href={`#`}
                              onClick={this.markAllAsRead}
                              className='text-info'
                            >
                              <span style={{ marginLeft: 5 }}>
                                Mark All Read
                              </span>
                            </a>
                          ) : null}
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        {/* <div
                          style={{
                            display: 'inline-block'
                          }}
                        >
                          <div style={{ display: 'flex', flexWrap: 'no-wrap' }}> */}
                        <img
                          style={{ height: 28, borderRadius: 20 }}
                          // src='https://community.immortalreef.com/letter_avatar_proxy/v4/letter/j/977dab/32.png'
                          src={
                            this.props.auth.user.avatar
                              ? this.props.auth.user.avatar
                              : defaultAvatar
                          }
                        ></img>
                        {(this.props.auth.myTradePurchases &&
                          this.props.auth.myTradePurchases.length > 0) ||
                        (this.props.auth.myTradeSales &&
                          this.props.auth.myTradeSales.length > 0) ? (
                          <Badge
                            color='danger'
                            // style={{
                            //   alignSelf: 'flex-start',
                            //   fontSize: 14,
                            //   padding: 3
                            // }}
                            style={{
                              position: 'relative',
                              right: '5px',
                              top: -7,
                              zIndex: 10,
                              fontSize: 14,
                              padding: 3
                            }}
                          >
                            !
                          </Badge>
                        ) : null}
                        {/* </div>
                        </div> */}
                        {/* <div
                          style={{
                            borderRadius: 20,
                            height: 28, //150,
                            width: 28, //150,
                            display: 'inline-block',

                            backgroundImage: `url(${
                              this.props.auth.user.avatar
                                ? this.props.auth.user.avatar
                                : defaultAvatar
                            })`,

                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                          }}
                        /> */}
                      </DropdownToggle>
                      <DropdownMenu right>
                        <Link
                          to='/myreef'
                          style={{ color: 'inherit' }}
                          onClick={this.toggle}
                        >
                          <DropdownItem>My Coral Collection</DropdownItem>
                        </Link>
                        <Link
                          to={`/memberfrags/${this.props.auth.user.id}`}
                          style={{ color: 'inherit' }}
                          onClick={this.toggle}
                        >
                          <DropdownItem>My Frag Rack</DropdownItem>
                        </Link>
                        <Link
                          to='/mywishlist'
                          style={{ color: 'inherit' }}
                          onClick={this.toggle}
                        >
                          <DropdownItem>My Wish List</DropdownItem>
                        </Link>
                        <Link
                          to='/tradesales'
                          style={{ color: 'inherit' }}
                          onClick={this.toggle}
                        >
                          <DropdownItem>
                            Trade Offers Received
                            {this.props.auth.myTradeSales &&
                            this.props.auth.myTradeSales.length > 0 ? (
                              <span className='text-danger'>
                                {' '}
                                ({this.props.auth.myTradeSales.length})
                              </span>
                            ) : null}
                          </DropdownItem>
                        </Link>
                        <Link
                          to='/tradepurchases'
                          style={{ color: 'inherit' }}
                          onClick={this.toggle}
                        >
                          <DropdownItem>
                            Trade Offers Sent
                            {this.props.auth.myTradePurchases &&
                            this.props.auth.myTradePurchases.length > 0 ? (
                              <span className='text-danger'>
                                {' '}
                                ({this.props.auth.myTradePurchases.length})
                              </span>
                            ) : null}
                          </DropdownItem>
                        </Link>
                        <Link
                          to='/tradescomplete'
                          style={{ color: 'inherit' }}
                          onClick={this.toggle}
                        >
                          <DropdownItem>Trades Completed</DropdownItem>
                        </Link>
                        <Link
                          to='/settings'
                          style={{ color: 'inherit' }}
                          onClick={this.toggle}
                        >
                          <DropdownItem>Settings</DropdownItem>
                        </Link>
                        <Link
                          to='/logout'
                          style={{ color: 'inherit' }}
                          onClick={this.toggle}
                        >
                          <DropdownItem>Logout</DropdownItem>
                        </Link>
                        {/* <DropdownItem>Contact Us</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>Reset</DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                ) : (
                  // <NavItem>
                  //   <Link to='/logout' style={{ color: 'inherit' }}>
                  //     <NavLink
                  //     //    href="/components/"
                  //     >
                  //       <i className='fa fa-sign-out' aria-hidden='true' />{' '}
                  //       {/* <div> */}
                  //       <img
                  //         style={{ height: 25, borderRadius: 20 }}
                  //         src='https://community.immortalreef.com/letter_avatar_proxy/v4/letter/j/977dab/32.png'
                  //       ></img>
                  //       {/* </div> */}
                  //     </NavLink>
                  //   </Link>
                  // </NavItem>
                  <NavItem>
                    <Link to='/login' style={{ color: 'inherit' }}>
                      <NavLink
                      //    href="/components/"
                      >
                        <i className='fa fa-sign-in' /> Login
                      </NavLink>
                    </Link>
                  </NavItem>
                )}
                {/* <NavItem>
                  <NavLink href="https://github.com/reactstrap/reactstrap">
                    GitHub
                  </NavLink>
                </NavItem> */}
                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <i className='fa fa-newspaper-o' /> Media
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>About</DropdownItem>
                    <DropdownItem>Contact Us</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Reset</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <i className="fa fa-search" /> Search
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link to="/coralsearch">
                      <DropdownItem>Coral</DropdownItem>
                    </Link>
                    <DropdownItem>Contact Us</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Reset</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <i className="fa fa-database" /> Resources
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link to="/coralnames">
                      <DropdownItem>Coral Names</DropdownItem>
                    </Link>
                    <DropdownItem>About</DropdownItem>
                    <DropdownItem>Contact Us</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Reset</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <i className="fa fa-eye" /> Watch List
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>About</DropdownItem>
                    <DropdownItem>Contact Us</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Reset</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <i className='fa fa-info-circle' /> Info
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>About</DropdownItem>
                    <DropdownItem>Contact Us</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Reset</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
              </Nav>
              {/* <NavbarText>Simple Text</NavbarText> */}
            </Collapse>
            {this.state.screenSizeSmall ? (
              <>
                {this.props.auth.myMessages &&
                this.props.auth.myMessages.length > 0 ? (
                  <Badge
                    color='warning'
                    style={{
                      position: 'fixed',
                      right: '10px',
                      top: '10px',
                      zIndex: 10
                    }}
                  >
                    {this.props.auth.myMessages.length}
                  </Badge>
                ) : null}
                {(this.props.auth.myTradePurchases &&
                  this.props.auth.myTradePurchases.length > 0) ||
                (this.props.auth.myTradeSales &&
                  this.props.auth.myTradeSales.length > 0) ? (
                  <Badge
                    color='danger'
                    style={{
                      position: 'fixed',
                      right: '10px',
                      top: '40px',
                      zIndex: 10
                    }}
                  >
                    !
                  </Badge>
                ) : null}
              </>
            ) : null}
            {/* <Nav className='' navbar>
              

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                  <i className='fa fa-bell' style={{ fontSize: 12 }} />
                </DropdownToggle>
                <DropdownMenu right>
                  <Link
                    to='/myreef'
                    style={{ color: 'inherit' }}
                    onClick={this.toggle}
                  >
                    <DropdownItem>My Coral Collection</DropdownItem>
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav> */}
          </Navbar>
        </div>
        <div style={{ height: 72 }}></div>
        {/* <div style={{ height: 10 }}></div> */}
        {!this.props.auth.isAuthenticated ? (
          <div
            style={{ textAlign: 'center', background: 'orange', padding: 10 }}
            className='color-warning'
          >
            <h6>
              Immortal Reef is a FREE trading platform for all!{' '}
              <Link to='/login' className='text-info'>
                Sign Up
              </Link>{' '}
              to start your coral collection and trade!
            </h6>
          </div>
        ) : null}
        {this.props.children}
      </>
    )
  }
}

// export default LayoutWrapper;

LayoutWrapper.propTypes = {
  // loginUser: PropTypes.func.isRequired,
  // loginGoogleUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, {
  getUserLocation,
  getMyCart,
  getMyMessages,
  getMyTradePurchases,
  getMyTradeSales
})(LayoutWrapper)

import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { logoutUser } from 'actions/authActions'

// reactstrap components
import { Container } from 'reactstrap'

class Logout extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      errors: {}
    }
  }

  componentDidMount () {
    this.props.logoutUser('', this.props.auth.user.external_id)
  }

  render () {
    return (
      <div className='login-page'>
        <Container>
          <h2>Logging Out...</h2>
        </Container>
      </div>
    )
  }
}

Logout.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { logoutUser })(Logout)

import React from "react";
import ReactDOM from "react-dom";
// import styles from "./AllCoral.module.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import StackGrid from "react-stack-grid";
import { deleteCoral, setPage } from "actions/coralActions";
import { Helmet } from "react-helmet";

// import CoralPreview from "components/CoralPreview/CoralPreview.js";
import CoralEbayPreview from "components/CoralPreview/CoralEbayPreview.js";
import CoralSort from "components/CoralSort/CoralSort.js";
import Sidebar from "react-sidebar";
import Spinner from "components/common/Spinner.js";
import ebaySmallLogo from "assets/ebayLogos/US/Right-Now-108x45.gif";

// reactstrap components
import {
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import SearchItems from "./SearchItems";

const mql = window.matchMedia(`(min-width: 800px)`);

class CoralList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coralEditShow: false,
      editCoralId: null,
      alert: null,
      coralShowId: null,
      sidebarDocked: mql.matches,
      sidebarOpen: false,
      screenSizeSmall: false,
      updateSearch: false,
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateScreen);
    this.updateScreen();

    // if (!this.props.auth.isAuthenticated) {
    //   this.props.shareUrl(this.props.match.url);
    // }
  }

  updateScreen = () => {
    if (window.innerWidth < 993) {
      this.setState({
        screenSizeSmall: true,
      });
    } else {
      this.setState({
        screenSizeSmall: false,
      });
    }
  };

  updateSearch = () => {
    this.setState({ updateSearch: !this.state.updateSearch });
  };

  //sidebar functions
  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  toggleSidebarOpen = (ev) => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
    console.log("sidebar toggle");

    if (ev) {
      ev.preventDefault();
    }
  };
  //end of sidebar functions

  toggleCoralShow = (id) => {
    this.setState({
      coralShowId: id,
    });
  };

  targetElement = null;

  toggleCoralEdit = () => {
    this.setState({
      coralEditShow: !this.state.coralEditShow,
    });
  };

  editCoralIdHandler = (id) => {
    this.setState({
      editCoralId: id,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  // warningWithConfirmMessage = (coralId, imgKey) => {
  //   this.setState({
  //     alert: (
  //       <ReactBSAlert
  //         warning
  //         style={{ display: "block", marginTop: "-100px" }}
  //         title="Are you sure?"
  //         onConfirm={() => this.successDelete(coralId, imgKey)}
  //         onCancel={() => this.hideAlert()}
  //         confirmBtnBsStyle="info"
  //         cancelBtnBsStyle="danger"
  //         confirmBtnText="Yes, delete it!"
  //         cancelBtnText="Cancel"
  //         showCancel
  //       >
  //         You will not be able to recover this information!
  //       </ReactBSAlert>
  //     ),
  //   });
  // };

  // successDelete = (coralId, imgKey) => {
  //   this.props.deleteCoral(coralId, imgKey, this.props.coral.results);

  //   this.setState({
  //     alert: (
  //       <ReactBSAlert
  //         success
  //         style={{ display: "block", marginTop: "-100px" }}
  //         title="Deleted!"
  //         onConfirm={() => this.hideAlert()}
  //         onCancel={() => this.hideAlert()}
  //         confirmBtnBsStyle="info"
  //       >
  //         Your information has been deleted.
  //       </ReactBSAlert>
  //     ),
  //   });
  // };

  scrollHandler = () => {
    const tesNode = ReactDOM.findDOMNode(this.refs.myRef);
    tesNode.scrollIntoView();
  };

  notify = (place, message, color, icon) => {
    var options = {};
    options = {
      place: place,
      message: <div>{message}</div>,
      type: color,
      // icon: "now-ui-icons ui-1_bell-53",
      icon: icon,
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    const { results } = this.props.coral;

    let makeCoral = (coralArr) => {
      let corals;
      corals = coralArr.map((coral, key) => (
        <>
          {coral.source === "ebay" ? (
            // <Card>
            <CoralEbayPreview
              item={coral}
              date={this.state.date}
              screenSizeSmall={this.state.screenSizeSmall}
            />
          ) : null}
        </>
      ));
      return corals;
    };

    return (
      <>
        <Helmet>
          <title>
            {/* {coral.genus} {coral.species} */}
            {/* Test Title */}
            {/* Immortal Reef */}
            Coral Search
          </title>

          {/* <meta
            name="description"
            content="Immortal Reef: Creators of Recorded Propagation. A new marketplace for marine
                aquarium hobbiests to propagate and share coral."
          /> */}
          {/* <meta
            property="og:image"
            // content="https://s3.amazonaws.com/reefconnector/5d3b2cfa03953e11044c3af6/77afaba0-f2b3-11e9-a6a1-2dfd05e143df.jpeg"
            content={blackTransparent}
          /> */}
        </Helmet>

        {/* <NotificationAlert ref="notificationAlert" /> */}

        <Sidebar
          sidebar={
            <>
              <SearchItems
                updateSearch={this.state.updateSearch}
                clearUpdate={this.updateSearch}
              />
            </>
          }
          open={this.state.sidebarOpen}
          docked={this.state.sidebarDocked}
          onSetOpen={this.onSetSidebarOpen}
          shadow={false}
          styles={{
            root: {
              marginTop: "72px",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: "hidden",
            },
            sidebar: {
              width: "20em",
              zIndex: 3,
              position: "fixed",
              top: 72,
              bottom: 0,

              transition: "transform .3s ease-out",
              WebkitTransition: "-webkit-transform .3s ease-out",
              willChange: "transform",
              overflowY: "auto",
              background: "white",
              border: "1px solid lightgrey",
              borderRadius: "2px",
            },
            overlay: {
              zIndex: 2,
            },
            content: {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "auto",
              WebkitOverflowScrolling: "touch",
              transition: "left .3s ease-out, right .3s ease-out",
            },
          }}
        >
          <span>
            {!this.state.sidebarDocked && (
              <>
                <Button
                  outline
                  className="btn-round btn-icon"
                  color="secondary"
                  style={{
                    position: "fixed",
                    zIndex: 4,
                    right: 10,
                    top: 76,
                    padding: 5,
                    borderRadius: 50,
                  }}
                  onClick={this.toggleSidebarOpen}
                >
                  <span>
                    <i
                      className="fa fa-search"
                      style={{ fontSize: "1.50rem" }}
                    />
                  </span>
                </Button>
              </>
            )}
          </span>
          <div ref="myRef" />

          <div
            style={{
              border: "none",
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
              borderBottom: "1px solid lightgrey",
              alignItems: "center",
              boxShadow: "0px 0px 4px lightgrey",
              height: 50,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <p
                  style={{
                    paddingLeft: `${
                      !this.state.screenSizeSmall ? "3rem" : "1rem"
                    }`,
                    textAlign: `${!this.state.screenSizeSmall ? "" : "center"}`,
                    // paddingLeft: "3rem",
                    // textAlign: `${!this.state.screenSizeSmall ? "" : "center"}`,
                    letterSpacing: "2px",
                    margin: 0,
                  }}
                >
                  {!this.state.screenSizeSmall ? null : (
                    // <div style={{ height: 45, display: "inline-block" }}>
                    //   <a href="https://www.ebay.com" target="_blank">
                    //     <img
                    //       src={ebaySmallLogo}
                    //       style={{
                    //         height: "100%",
                    //         // float: "left",
                    //         // marginLeft: 10,
                    //         // marginTop: 5,
                    //       }}
                    //     />
                    //   </a>
                    // </div>
                    <>
                      <span
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <small>Coral Search</small>
                      </span>
                      <br />{" "}
                    </>
                  )}

                  {this.props.coral.count ? (
                    <>
                      <small>
                        {this.props.coral.page * this.props.coral.limit -
                          (this.props.coral.limit - 1)}
                        -
                        {this.props.coral.limit * this.props.coral.page >
                        this.props.coral.count
                          ? this.props.coral.count
                          : this.props.coral.page * this.props.coral.limit}{" "}
                        of {this.props.coral.count} Results
                      </small>
                    </>
                  ) : (
                    <small>
                      <span> (No Results)</span>
                    </small>
                  )}
                </p>
              </div>
              {this.state.screenSizeSmall ? null : (
                <div style={{ height: 45 }}>
                  <a href="https://www.ebay.com" target="_blank" rel="noopener noreferrer">
                    <img
                      src={ebaySmallLogo}
                      style={{
                        height: "100%",

                        marginLeft: 10,
                      }}
                      alt="ebay logo"
                    />
                  </a>
                </div>
              )}
            </div>

            <CoralSort screenSizeSmall={this.state.screenSizeSmall} />
          </div>
          <div
            style={{
              backgroundColor: "white",
              // paddingLeft: "2rem",
              paddingBottom: 20,
            }}
          >
            <div>
              {this.state.screenSizeSmall ? (
                // <div style={{ width: 200 }}>
                <div
                  style={{
                    height: 36,
                    width: 20,
                    // textAlign: "center",
                    backgroundColor: "white",
                    position: "sticky",
                    top: 50,
                    zIndex: 1,
                  }}
                >
                  <a
                    href="https://www.ebay.com"
                    target="_blank"
                    style={{ maxWidth: 200 }}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={ebaySmallLogo}
                      style={{
                        height: "100%",
                        float: "left",
                        marginLeft: 10,
                        // marginTop: 5,
                      }}
                      alt="ebay logo"
                    />
                  </a>
                </div>
              ) : // </div>
              null}
              {results ? (
                <>
                  <div style={{ marginTop: 10 }} />

                  {!this.state.screenSizeSmall ? (
                    <StackGrid
                      style={{ zIndex: 0 }}
                      gutterHeight={10}
                      gutterWidth={10}
                      columnWidth={300}
                      monitorImagesLoaded={true}
                    >
                      {makeCoral(results)}
                    </StackGrid>
                  ) : (
                    <StackGrid
                      style={{ zIndex: 0 }}
                      gutterHeight={5}
                      gutterWidth={5}
                      columnWidth={160}
                      monitorImagesLoaded={true}
                    >
                      {makeCoral(results)}
                    </StackGrid>
                  )}
                  {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {makeCoral(results)}
                  </div> */}
                </>
              ) : (
                <Spinner />
              )}
            </div>

            <div onClick={this.scrollHandler}>
              {/* <Form
                style={{
                  width: "5rem",
                }}
              >
                <FormGroup>
                  <Input type="text" placeholder={this.props.coral.page} />
                </FormGroup>
              </Form> */}

              <Pagination
                size={this.state.screenSizeSmall ? "sm" : "lg"}
                listClassName="justify-content-center"
                style={{ margin: 20 }}
              >
                {this.props.coral.page === 1 ? null : (
                  //  (
                  //   <>
                  //     <PaginationItem disabled>
                  //       <PaginationLink first href="#" />
                  //     </PaginationItem>
                  //     <PaginationItem disabled>
                  //       <PaginationLink href="#">Previous</PaginationLink>
                  //     </PaginationItem>
                  //   </>
                  // )
                  <>
                    <PaginationItem>
                      <PaginationLink
                        first
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.setPage(1);
                        }}
                      />
                    </PaginationItem>
                    <PaginationItem>
                      {this.state.screenSizeSmall ? (
                        <PaginationLink
                          href="#"
                          previous
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.setPage(this.props.coral.page - 1);
                          }}
                        />
                      ) : (
                        <PaginationLink
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.setPage(this.props.coral.page - 1);
                          }}
                        >
                          Previous
                        </PaginationLink>
                      )}
                    </PaginationItem>
                  </>
                )}

                <PaginationItem>
                  {this.props.coral.page !== 1 ? (
                    <PaginationLink
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.setPage(this.props.coral.page - 1);
                      }}
                    >
                      {this.props.coral.page - 1}
                    </PaginationLink>
                  ) : null}
                  {/* <PaginationLink href="#">1</PaginationLink> */}
                </PaginationItem>
                {/* <Input
                  type="text"
                  placeholder={this.props.coral.page}
                  style={{ width: "3rem", height: "3rem" }}
                /> */}

                <PaginationItem active>
                  <PaginationLink href="#">
                    {" "}
                    {this.props.coral.page}
                  </PaginationLink>
                </PaginationItem>

                {this.props.coral.count / this.props.coral.limit >=
                  this.props.coral.page &&
                this.props.coral.count / this.props.coral.limit -
                  this.props.coral.page >
                  1 ? (
                  <>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.setPage(this.props.coral.page + 1);
                        }}
                      >
                        {this.props.coral.page + 1}
                      </PaginationLink>
                    </PaginationItem>
                    {this.props.coral.count / this.props.coral.limit >=
                      this.props.coral.page + 1 &&
                    this.props.coral.count / this.props.coral.limit -
                      this.props.coral.page >
                      2 &&
                    !this.state.screenSizeSmall ? (
                      <>
                        <PaginationItem>
                          <PaginationLink
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.setPage(this.props.coral.page + 2);
                            }}
                          >
                            {this.props.coral.page + 2}
                          </PaginationLink>
                        </PaginationItem>
                      </>
                    ) : null}
                    {this.props.coral.count / this.props.coral.limit >= 1 &&
                    this.props.coral.count / this.props.coral.limit -
                      this.props.coral.page >
                      1 ? (
                      <>
                        <PaginationItem>
                          <PaginationLink
                            // disabled
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.setPage(
                                Math.floor(
                                  (Math.floor(
                                    this.props.coral.count /
                                      this.props.coral.limit
                                  ) -
                                    this.props.coral.page) /
                                    2
                                ) + this.props.coral.page
                              );
                            }}
                          >
                            ...
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.setPage(
                                Math.floor(
                                  this.props.coral.count /
                                    this.props.coral.limit
                                )
                              );
                            }}
                          >
                            {Math.floor(
                              this.props.coral.count / this.props.coral.limit
                            )}
                          </PaginationLink>
                        </PaginationItem>
                      </>
                    ) : null}
                    <PaginationItem>
                      {this.state.screenSizeSmall ? (
                        <PaginationLink
                          next
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.setPage(this.props.coral.page + 1);
                          }}
                        />
                      ) : (
                        <PaginationLink
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.setPage(this.props.coral.page + 1);
                          }}
                        >
                          Next
                        </PaginationLink>
                      )}
                    </PaginationItem>
                  </>
                ) : null}

                {this.props.coral.count / this.props.coral.limit >= 5 &&
                this.props.coral.count / this.props.coral.limit -
                  this.props.coral.page >
                  5 ? (
                  <PaginationItem>
                    <PaginationLink
                      last
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.setPage(this.props.coral.page + 5);
                      }}
                    />
                  </PaginationItem>
                ) : null}
              </Pagination>
            </div>
          </div>
        </Sidebar>
      </>
    );
  }
}

CoralList.propTypes = {
  profile: PropTypes.object.isRequired,
  deleteCoral: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  coral: state.coral,
});

export default connect(mapStateToProps, { deleteCoral, setPage })(
  CoralList
);

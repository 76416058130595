import React from 'react'
import axios from 'axios'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Input, Button } from 'reactstrap'

class NameInput extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      email: '',
      password: '',
      errors: {},
      current: 'mail',
      coralNames: [],
      name: '',
      exists: false,
      added: false
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  saveName = () => {
    axios
      .post('/api/coral/savename', { newName: this.state.name })
      .then(res => {
        console.log(res.data)
        if (res.data === 'Name Exists') {
          this.setState({ exists: true })
        } else {
          this.setState({ added: true })
        }
      })
  }

  // handleClick = (e) => {
  //   console.log("click ", e);
  //   this.setState({ current: e.key });
  // };

  render () {
    return (
      <>
        <div>{this.props.title}</div>
        <Input onChange={this.onChange} type='text' name='name' />
        <Button onClick={this.saveName}>Submit</Button>
        {this.state.exists ? (
          <div className='text-danger'>Name Exists</div>
        ) : null}
        {this.state.added ? (
          <div className='text-success'>Name Added</div>
        ) : null}
      </>
    )
  }
}

NameInput.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, {})(NameInput)

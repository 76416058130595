import React from "react";
import { connect } from "react-redux";

// reactstrap components
import {} from "reactstrap";


class CoralPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linkURL: "",
      modalNoticeNewFrag: false,
      addPhotoModal: false,
      removeAlert: null,
      deleteAlert: null,
      editPriceShow: false,
      loginOpen: false,
      screenSizeSmall: null,
      modifiedTime: null,
    };
  }

  render() {
    const {
      title,
      link,
      imageURL,
      shippingCost,
      price,
      storeName,
      listingType,
      itemId,
      endTime,
      completed,
    } = this.props.item;

    let modListing = "";

    if (listingType === "Auction") {
      modListing = "Auction";
    } else {
      modListing = "Buy Now";
    }
    return (
      <>
        <div
        
        >
          {title ? (
            <>
              {/* <Card> */}
              {/* <CardBody> */}
              <div style={{ border: "1px solid lightgrey", borderRadius: 5 }}>
                <a
                  href={
                    completed
                      ? `https://rover.ebay.com/rover/1/711-53200-19255-0/1?ff3=2&toolid=10044&campid=5338306282&customid=123&lgeo=1&vectorid=229466&item=${itemId}`
                      : link
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    style={{
                    
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      height: this.props.screenSizeSmall ? 150 : 280,
                      width: "100%",
                      margin: "auto",
                    }}
                  >
                    <img
                      src={imageURL}
                      style={{ maxHeight: "100%", maxWidth: "280px" }}
                      title={
                        this.props.keywordTitle
                          ? this.props.keywordTitle
                          : title
                      }
                      alt={
                        this.props.keywordAlt
                          ? this.props.keywordAlt
                          : `${title} $${price} + $${shippingCost} shipping`
                      }
                    />
                  </div>
                </a>
                <div
                  style={{
                    // height: this.props.screenSizeSmall ? 36 : 36,
                    width: this.props.screenSizeSmall ? 150 : 280,
                    fontSize: this.props.screenSizeSmall ? 8 : "",
                    backgroundColor: "white",
                    color: "black",
                    padding: 2,
                    margin: "auto",
                    // opacity: "0.5",
                  }}
                >
                  {/* <small> */}
                  <h6
                    style={{
                      marginTop: 4,
                      marginBottom: 4,
                      fontSize: "inherit",
                      textAlign: "center",
                    }}
                  >
                    {title.slice(0, 36)} ...
                  </h6>
                  {/* </small> */}
                  <p
                    style={{
                      color: "black",
                      textAlign: "center",
                      marginBottom: 4,
                    }}
                  >
                    $ {price} {/* <small> */}
                    <span
                      className="text-secondary"
                      // style={{ color: "black", textTransform: "lowercase" }}
                    >
                      + ${shippingCost}s -{" "}
                      {completed ? (
                        <strong>
                          <span className="text-danger">SOLD</span>
                        </strong>
                      ) : (
                        <>
                          <strong>
                            <span className="text-success">{modListing}</span>
                          </strong>
                        </>
                      )}
                    </span>
                    {/* </small> */}
                  </p>
                  <p
                    style={{
                      color: "black",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      marginBottom: 0,
                    }}
                  >
                    <span>
                      <small>
                        {completed ? "Ended" : "Ends"}-
                        {endTime.substring(5, 10)}
                      </small>
                      {/* <Timeago date={endTime} title={"Ending"} /> */}
                    </span>
                    <span
                      style={{
                        color: "black",
                        textTransform: "uppercase",
                      }}
                    >
                      {storeName}
                    </span>{" "}
                    
                  </p>
                 
                </div>

    
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

CoralPreview.propTypes = {
};

const mapStateToProps = (state) => ({

  auth: state.auth,
});

export default connect(mapStateToProps, {
})(CoralPreview);

import React from 'react'

import PropTypes from 'prop-types'

import FragImage from 'components/AddCartRow/FragImage.js'
import AddCartSelect from 'components/AddCartRow/AddCartSelect.js'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { memberCoral } from 'actions/coralActions'
import { getMyCart } from 'actions/authActions'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import classnames from 'classnames'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import styles from './CoralNames.module.css'

// reactstrap components
import {
  Button,
  Container,
  Alert,
  Input,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardLink,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon
} from 'react-share'

class Login extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      cart: [],
      fragsSelected: [],
      errors: {},
      activeTab: '1',
      openModal: false,
      fragImage: null,
      fragTitle: null,
      fragName: null,
      allFrags: null
    }
  }

  componentDidMount () {
    // console.log()
    if (!this.props.community.memberCoral) {
      this.props.memberCoral(this.props.match.params.id)
    } else {
      if (this.props.community.memberCoral.length > 0) {
        if (
          this.props.community.memberCoral[0].user !==
          this.props.match.params.id
        ) {
          this.props.memberCoral(this.props.match.params.id)
        }
      } else {
        this.props.memberCoral(this.props.match.params.id)
      }
    }

    if (!this.props.auth.myCart && this.props.auth.isAuthenticated) {
      this.props.getMyCart()
    }
  }

  componentWillReceiveProps (nextProps) {
    //console.log(nextProps.community.memberCoral)
    let filteredMemberCoral = []
    let allFrags = []

    if (nextProps.community.memberCoral !== this.props.community.memberCoral) {
      this.setState({ loading: false })
      filteredMemberCoral = nextProps.community.memberCoral.filter(
        coral =>
          coral.frags.length > 0 &&
          coral.frags.some(frag => frag.status === 'active')
      )
      //console.log(filteredMemberCoral)
      filteredMemberCoral.forEach(coral => {
        let filteredFrags = coral.frags.filter(frag => frag.status === 'active')
        console.log(filteredFrags)
        filteredFrags.forEach((frag, index) => {
          let pushCoral = {
            ...coral,
            fragIndex: coral.frags.map(frag2 => frag2._id).indexOf(frag._id)
          }

          if (index != 0) {
            if (
              frag.price != filteredFrags[index - 1].price ||
              frag.image.address != filteredFrags[index - 1].image.address
            ) {
              allFrags.push(pushCoral)

              //console.log(pushCoral.fragIndex)
            }
          } else {
            allFrags.push(pushCoral)
          }
        })
        //console.log(allFrags)
        this.setState({ allFrags: allFrags, loading: false })
      })
    }
  }

  deleteCoral (coral) {
    axios.post('/api/coral/deletecoral', { id: coral._id }).then(res => {
      if (res.data === 'success') {
        this.props.memberCoral(this.props.match.params.id)
      }
    })
  }

  addToCart = (coral, fragId) => {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/login')
    }

    axios
      .post('/api/users/addtocart', {
        // userId: this.state.coral.user._id,
        // coralId: this.state.coral._id
        userId: coral.user._id,
        coral: coral,
        fragId: fragId
      })
      .then(res => {
        console.log(res.data)
        if (res.data !== 'Cannot trade with self!') {
          this.props.getMyCart()
          this.setState({ added: true })
        }
      })
  }

  removeFromCart = coralId => {
    axios
      .post('/api/users/removefromcart', {
        userId: this.props.match.params.id,
        coralId: coralId
      })
      .then(res => {
        console.log(res.data)
        this.props.getMyCart()
      })
  }

  openModal = fragImage => {
    this.setState({
      modalOpen: true,
      fragImage: fragImage
    })
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  selectFrag = frag => {
    console.log(frag)
    //delete old frag if new one is selected
    let newList = [...this.state.fragsSelected]
    let oldFragIndex = this.state.fragsSelected
      .map(frag => frag.coralId)
      .indexOf(frag.coralId)
    console.log(oldFragIndex)
    if (oldFragIndex !== -1) {
      newList.splice(oldFragIndex, 1)

      // this.setState({ fragsSelected: newList })
    }

    let coralIndex = this.props.community.memberCoral
      .map(coral => coral._id)
      .indexOf(frag.coralId)
    console.log(coralIndex)
    let fragList = this.props.community.memberCoral[coralIndex].frags.map(
      frag => frag._id
    )
    let fragIndex = fragList
      .map(id => id.toString().substr(id.toString().length - 4))
      .indexOf(frag.frag)
    console.log(fragIndex)
    let selectedFrag = this.props.community.memberCoral[coralIndex].frags[
      fragIndex
    ]
    if (frag.frag !== 'Select') {
      let pushFrag = { coralId: frag.coralId, frag: selectedFrag }
      newList.push(pushFrag)
    }

    console.log(newList)
    this.setState({ fragsSelected: newList })
    // this.setState({ fragsSelected: [...this.state.fragsSelected, pushFrag] })
  }

  render () {
    let { memberCoral } = this.props.community

    let cartFragIds = []
    let selectChanged = this.state.fragsSelected.map(frag => frag.coralId)
    let ogImage =
      'https://s3.amazonaws.com/reefconnector/facebooklogopreview.png'

    const { myCart } = this.props.auth
    let cartIndex = -1
    let localCart = null
    if (myCart) {
      cartIndex = myCart
        .map(cart => cart.user._id)
        .indexOf(this.props.match.params.id)
      if (cartIndex != -1) {
        localCart = myCart[cartIndex]
        cartFragIds = localCart.coral.map(coral => coral.fragId)
      }
      console.log(localCart)
      console.log(cartFragIds)
    }

    if (memberCoral) {
      if (memberCoral[0].user.shareImage) {
        ogImage = `https://s3.amazonaws.com/reefconnector/${memberCoral[0].user.shareImage}`
      }
    }

    return (
      <div className='login-page'>
        {memberCoral && memberCoral.length > 0 ? (
          <>
            {this.state.allFrags ? (
              <>
                {memberCoral.length > 0 ? (
                  <Helmet>
                    <title style={{ textTransform: 'uppercase' }}>
                      {memberCoral[0].user.username +
                        "'s Frag Rack (" +
                        this.state.allFrags.length +
                        ' Frags Available)'}
                    </title>

                    <meta
                      name='description'
                      content={`Trade with ${memberCoral[0].user.username} for free at Immortal Reef.`}
                    />
                    <meta property='og:image' content={ogImage} />

                    {/* {!memberCoral[0].user.shareImage ? (
                  <>
                    <meta
                      // property='og:image'
                      property='og:image'
                      // content={`https://s3.amazonaws.com/reefconnector/${memberCoral[0].image.address}`}
                      content={`https://s3.amazonaws.com/reefconnector/facebooklogopreview.png`}
                    />
                  </>
                ) : (
                  <>
                    <meta
                      // property='og:image'
                      property='og:image'
                      content={`https://s3.amazonaws.com/reefconnector/${memberCoral[0].user.shareImage}`}
                    />
                  </>
                )} */}

                    <meta
                      property='og:url'
                      content={`https://www.immortalreef.com/memberfrags/${this.props.match.params.id}`}
                    />
                  </Helmet>
                ) : null}
                {memberCoral.length > 0 && this.state.allFrags.length > 0 ? (
                  <>
                    <Container>
                      {localCart && localCart.coral.length > 0 ? (
                        <Alert
                          color='warning'
                          style={{
                            position: 'fixed',
                            top: 72,
                            right: 0,
                            zIndex: 1000
                          }}
                        >
                          You have {localCart.coral.length} coral in your cart
                          from this collector.{' '}
                          <Link to={`/trade/${localCart.coral[0].coral.user}`}>
                            <Button
                              color='info'
                              style={{ padding: 2, margin: 3 }}
                            >
                              Continue
                            </Button>
                          </Link>
                        </Alert>
                      ) : null}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                          // flexWrap: 'wrap'
                        }}
                      >
                        <img
                          src={this.props.community.memberCoral[0].user.avatar}
                          style={{ margin: 10, borderRadius: 50 }}
                        />
                        <h4 style={{ textAlign: 'center', marginTop: 10 }}>
                          {this.props.community.memberCoral[0].user.username}'s
                          Frag Rack
                        </h4>
                      </div>

                      <div style={{ textAlign: 'center', marginBottom: 10 }}>
                        <a
                          className='text-info'
                          href={`https://community.immortalreef.com/u/${this.props.community.memberCoral[0].user.username}/summary`}
                        >
                          Contact
                        </a>
                        {' - '}
                        {
                          this.props.community.memberCoral[0].user.locality
                        },{' '}
                        {
                          this.props.community.memberCoral[0].user
                            .administrative_area_level_1_short_name
                        }
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Link
                          style={{ marginRight: 20 }}
                          className='text-info'
                          to={`/membercollection/${this.props.community.memberCoral[0].user._id}`}
                        >
                          CORAL COLLECTION
                        </Link>

                        <span> - </span>

                        <Link
                          style={{ marginLeft: 20 }}
                          className='text-info'
                          to={`/userwishlist/${this.props.community.memberCoral[0].user._id}`}
                        >
                          WISH LIST
                        </Link>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                          justifySelf: 'end'
                          //margin: 8
                          // marginLeft: 50
                        }}
                      >
                        <FacebookShareButton
                          style={{ marginRight: 3 }}
                          url={`https://www.immortalreef.com/memberfrags/${this.props.match.params.id}`}
                        >
                          <FacebookIcon size={28} />
                        </FacebookShareButton>
                        <TwitterShareButton
                          style={{ marginRight: 3 }}
                          url={`https://www.immortalreef.com/memberfrags/${this.props.match.params.id}`}
                        >
                          <TwitterIcon size={28} />
                        </TwitterShareButton>
                      </div>
                      {this.props.auth.isAuthenticated &&
                      this.props.auth.user.id === this.props.match.params.id ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'end',
                            margin: 8
                          }}
                        >
                          <Link to={'/mediapreview'} className='text-info'>
                            <small>Social Media Preview Collage</small>
                          </Link>
                        </div>
                      ) : null}

                      {/* <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId='1'> */}
                      <p style={{ textAlign: 'center' }}>
                        <small>
                          * Trade by adding coral to your cart. You will be
                          prompted to offer currancy or coral from your
                          collection in the next step.
                        </small>
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'space-around',
                          margin: 5
                        }}
                      >
                        {this.state.allFrags.map(coral => (
                          <Card
                            style={{
                              margin: 3,
                              paddingBottom: 5,
                              backgroundColor: '#F3F3F3'
                            }}
                          >
                            <Link to={`/coralshow/${coral._id}`}>
                              {/* {coral.image ? ( */}
                              <div
                                style={{
                                  borderRadius: 5,
                                  height: 250,
                                  width: 250,
                                  backgroundImage: `url(https://s3.amazonaws.com/reefconnector/${
                                    coral.frags[coral.fragIndex].image.address
                                  })`,
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  margin: 3,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between'
                                }}
                              ></div>
                              {/* ) : ( */}
                              {/* <div
                          style={{
                            borderRadius: 5,
                            height: 250,
                            width: 250,
                            backgroundImage: `url(https://s3.amazonaws.com/reefconnector/image_placeholder.jpg)`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            margin: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                          }}
                        /> */}
                              {/* )} */}
                            </Link>
                            <CardTitle
                            // className='text-center'
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  // justifyContent: 'space-between'
                                  alignItems: 'center'
                                }}
                              >
                                <div
                                  style={{ width: '25%', textAlign: 'center' }}
                                >
                                  <Button
                                    style={{
                                      margin: 5,
                                      borderRadius: 5,
                                      padding: 10
                                    }}
                                    onClick={() => {
                                      this.openModal(
                                        coral.frags[coral.fragIndex].image
                                          .address
                                      )
                                    }}
                                    color={'secondary'}
                                  >
                                    <i
                                      className='fa fa-expand'
                                      style={{
                                        fontSize: 18
                                      }}
                                    />
                                  </Button>
                                </div>{' '}
                                <div
                                  style={{
                                    textAlign: 'center',
                                    width: '75%'
                                  }}
                                >
                                  <div>
                                    {coral.genus}
                                    <>
                                      {coral.species ? (
                                        // <span> - {coral.species}</span>
                                        <>
                                          {coral.species !== 'Other' ? (
                                            <span>
                                              {' '}
                                              -{' '}
                                              <Link
                                                to={`/species/${coral.species.toLowerCase()}`}
                                                className='text-info'
                                              >
                                                {coral.species}
                                              </Link>
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                textTransform: 'capitalize'
                                              }}
                                            >
                                              {' '}
                                              - {coral.otherSpecies}
                                            </span>
                                          )}
                                        </>
                                      ) : null}
                                    </>
                                  </div>
                                  <div>
                                    {coral.name ? (
                                      <Link
                                        to={`/coralnames/${coral.name._id}`}
                                        className='text-info'
                                      >
                                        <span
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                        >
                                          <small>({coral.name.name})</small>
                                        </span>
                                      </Link>
                                    ) : (
                                      <>
                                        {coral.unverifiedName ? (
                                          <span
                                            style={{
                                              textTransform: 'capitalize'
                                            }}
                                          >
                                            <small>
                                              ({coral.unverifiedName})
                                            </small>
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </CardTitle>
                            {/* <CardSubtitle className='mb-2 text-muted text-center'>
                          {coral.name ? (
                            <span style={{ textTransform: 'capitalize' }}>
                              {coral.name.name}
                            </span>
                          ) : (
                            <>
                              {coral.unverifiedName ? (
                                <span style={{ textTransform: 'capitalize' }}>
                                  {coral.unverifiedName}
                                </span>
                              ) : null}
                            </>
                          )}
                        </CardSubtitle> */}
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'no-wrap',
                                justifyContent: 'space-around',
                                alignItems: 'center'
                              }}
                            >
                              {/* <div>
                              <i className='fa fa-dollar text-success' />
                            </div> */}
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  justifyContent: 'center',
                                  width: '66%'
                                }}
                              >
                                {coral.frags[coral.fragIndex].wysiwyg ? (
                                  <div style={{ textAlign: 'center' }}>
                                    <strong>(WYSIWYG)</strong>
                                  </div>
                                ) : null}
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    width: '100%'
                                  }}
                                >
                                  {coral.frags.filter(
                                    frag => frag.status === 'active'
                                  ).length > 0 ? (
                                    <div
                                    // style={{ display: 'inline-block', margin: 5 }}
                                    >
                                      $ {coral.frags[coral.fragIndex].price}
                                    </div>
                                  ) : (
                                    <div>$ - </div>
                                  )}

                                  <div>
                                    Trade{' '}
                                    {coral.forTrade ? (
                                      <i className='fa fa-check text-success' />
                                    ) : (
                                      <i className='fa fa-remove' />
                                    )}
                                  </div>
                                </div>
                              </div>
                              {this.props.auth.user.id ===
                              this.props.match.params.id ? null : (
                                <>
                                  {!cartFragIds.some(
                                    id =>
                                      id === coral.frags[coral.fragIndex]._id
                                  ) ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          this.addToCart(
                                            coral,
                                            coral.frags[coral.fragIndex]._id
                                          )
                                        }}
                                        color='success'
                                        style={{ borderRadius: 5 }}
                                        disabled={
                                          localCart &&
                                          localCart.coral
                                            .map(coral2 => coral2.coral._id)
                                            .some(id => id === coral._id)
                                        }
                                      >
                                        <i
                                          className='fa fa-cart-plus'
                                          style={{ fontSize: 24 }}
                                        />
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      color='danger'
                                      onClick={() =>
                                        this.removeFromCart(coral._id)
                                      }
                                      style={{ borderRadius: 5 }}
                                    >
                                      <i
                                        className='fa fa-cart-arrow-down'
                                        style={{ fontSize: 24 }}
                                      />
                                    </Button>
                                  )}
                                </>
                              )}
                            </div>
                            {/* {coral.frags[coral.fragIndex].wysiwyg ? (
                        <div style={{ textAlign: 'center' }}>WYSIWYG</div>
                      ) : null} */}
                          </Card>
                        ))}
                      </div>
                      {/* </TabPane>
                </TabContent> */}
                    </Container>
                    <Modal
                      toggle={this.toggleModal}
                      isOpen={this.state.modalOpen}
                      size='lg'
                      style={{ marginTop: 100 }}
                    >
                      <ModalHeader
                        style={{ borderBottom: 0, paddingBottom: 0 }}
                        toggle={this.toggleModal}
                      ></ModalHeader>
                      <ModalBody>
                        {/* <div
                      style={{
                        height: '30rem',

                        textAlign: 'center',
                        padding: 5
                      }}
                    >
                      <img
                        style={{
                          height: '100%',
                          maxWidth: '100%',
                          margin: 'auto',
                          borderRadius: 5
                        }}
                        src={`https://s3.amazonaws.com/reefconnector/${this.state.fragImage}`}
                      />
                    </div> */}
                        <div
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            padding: 5
                          }}
                        >
                          <img
                            style={{
                              maxHeight: '45rem',
                              maxWidth: '100%',

                              margin: 'auto',
                              borderRadius: 5
                            }}
                            src={`https://s3.amazonaws.com/reefconnector/${this.state.fragImage}`}
                          />
                        </div>
                      </ModalBody>
                    </Modal>
                  </>
                ) : (
                  <h4 style={{ textAlign: 'center' }}>
                    User has no collection.
                  </h4>
                )}
              </>
            ) : (
              <>
                {this.state.loading ? (
                  <h4>Loading...</h4>
                ) : (
                  <h4 className='text-center' style={{ padding: 20 }}>
                    No Frags Available
                  </h4>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {this.state.loading ? (
              <h4>Loading...</h4>
            ) : (
              <h4 className='text-center' style={{ padding: 20 }}>
                No Frags Available
              </h4>
            )}
          </>
          // <>
          //   {!this.state.allFrags ||
          //   (this.state.allFrags && this.state.allFrags.length === 0) ? (
          //     <h4 className='text-center' style={{ padding: 20 }}>
          //       No Frags Available
          //     </h4>
          //   ) : (
          //     <h4>Loading...</h4>
          //   )}
          // </>
        )}
        <div>Cache test</div>
      </div>
    )
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  coral: state.coral,
  community: state.community,
  errors: state.errors
})

export default connect(mapStateToProps, { memberCoral, getMyCart })(Login)

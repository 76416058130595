import {
  NAMES_LOADING,
  GET_NAMES,
} from '../actions/types';

const initialState = {
  names: [],
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NAMES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_NAMES:
      return {
        ...state,
        names: action.payload,
        loading: false
      };
    default:
      return state;
  }
}

import React from 'react'
import axios from 'axios'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NameInput from 'components/NameInput/NameInput.js'

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'

class MakeNames extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      name: '',
      message: ''
    }
  }

  componentDidMount () {
    // console.log("component did mount");
    // axios.get("/api/coral/getcoralnames/10").then((res) => {
    //   console.log(res.data);
    //   this.setState({ coralNames: res.data });
    // });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  saveName = () => {
    this.setState({ loading: true, message: '' })
    axios
      .post('/api/coral/usersavename', {
        newName: this.state.name,
        user: this.props.auth.user.id
      })
      .then(res => {
        console.log(res.data)
        this.setState({ message: res.data })
        // if (res.data === 'name should be added') {
        //   console.log(res.data)
        // } else {
        //   console.log('do something with the data')
        // }
      })
  }

  render () {
    return (
      <>
        <Container>
          <h4 style={{ textAlign: 'center', marginTop: 20 }}>
            Suggest a coral name
          </h4>
          <p>
            Names are often associated with different types and genuses of
            coral. We add names to our database when there are sufficient
            numbers of coral in our database that match the new name. Please
            continue to add your coral with images and species information to
            your collection to help our classifcation efforts.
          </p>
          <Row form style={{ marginTop: 10 }}>
            <Col md={3} />
            <Col md={5}>
              <FormGroup style={{ marginBottom: 0 }}>
                <Label for='postalCode'>New Name</Label>
                <Input
                  type='text'
                  name='name'
                  id='postalCode'
                  value={this.state.name}
                  onChange={this.onChange}
                />
              </FormGroup>
              <p style={{ marginTop: 10 }}>
                {this.state.message ? (
                  <div className='text-primary'>{this.state.message}</div>
                ) : null}
              </p>
            </Col>

            <Col md={1} style={{ textAlign: 'left', paddingTop: 26 }}>
              <Button
                color='info'
                onClick={this.saveName}
                disabled={this.state.name ? false : true}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

MakeNames.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, {})(MakeNames)

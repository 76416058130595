import React from 'react'
import axios from 'axios'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { Input, Button } from 'reactstrap'

class AddCartRow extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      errors: {},
      frag: null
    }
  }
  componentDidUpdate (nextProps) {
    console.log(nextProps)
    if (nextProps.fragsSelected !== this.props.fragsSelected) {
      let fragIndex = nextProps.fragsSelected
        .map(frag => frag.coralId)
        .indexOf(nextProps.componentCoral._id)

      if (fragIndex !== -1) {
        this.setState({ frag: nextProps.fragsSelected[fragIndex] })
      }
    }
  }

  render () {
    // let frag = null
    // console.log(this.state)

    // console.log(fragIndex)

    return (
      <>
        {this.state.frag ? (
          <>
            <div style={{ height: '5rem', margin: 3 }}>
              <img
                style={{ height: '100%', borderRadius: 5 }}
                // src={`https://s3.amazonaws.com/reefconnector/${this.props.componentCoral.frags[0].image.address}`}
                src={`https://s3.amazonaws.com/reefconnector/${this.state.frag.frag.image.address}`}
              />
            </div>
          </>
        ) : (
          <span>-</span>
        )}
      </>
    )
  }
}

AddCartRow.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, {})(AddCartRow)

import { GET_CORAL, ITEM_LOADING } from "../actions/types";

const initialState = {
  coral: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ITEM_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_CORAL:
      return {
        ...state,
        coral: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
